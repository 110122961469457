article {

  blockquote {
    header {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: 0;
      h1, h2, h3, h4, h5 {
        color: $text-color;
      }
    }
  }

  ul {
    margin: 30px 0;
  }

  header {
    padding-bottom: 50px;
    margin-bottom: 50px;
    border-bottom: 1px solid $color-secondary-lightest;

    @media all and (max-width: $screen-xs-max) {
      padding-bottom: 15px;
      margin-bottom: 15px;
    }

    h1, h2, h3, h4, h5, h6 {
      color: $brand-primary;
      margin-top: 0;
      small, .small {
        font-size: 45%;
        color: $text-color;
      }
    }
    h1 {
      margin-bottom: 25px;
      @media all and (max-width: $screen-xs-max) {
        font-size: ceil($font-size-h1*.66);
        margin-bottom: 10px;
      }
    }
    .lead {
      font-size: $article-font-size-lead;
      margin-bottom: 0;
    }
  }

  & > figure {
    padding-top: 20px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    max-width: 35%;
    display: inline-block;
    margin: 20px;
  }

  ul, ol {
    padding-left: 17px;
    li {
      padding: 5px 0;
    }
  }
}
