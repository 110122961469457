[class*="block-grid-"] {
  display: block;
  padding: 0;
  margin: 0;
  width: 100%;
  @include clearfix();
}

.block-grid-no-padding {
  .block-grid-item {
    padding: 0;
    & > * {
      padding: 0;
    }
  }
}

.block-grid-item {
  display: inline;
  margin: 0;
  padding: ($grid-gutter-width/2)px;
  height: auto;
  float: left;
  list-style: none;
}

@mixin block-grid ($per-row) {
  & > .block-grid-item {
    width: (100%/$per-row);

    $nth-equation: (#{$per-row}n) + '+ 1';
    &:nth-of-type(n) { clear: none; }
    &:nth-of-type(#{$nth-equation}) { clear: both; }
  }
}

@mixin block-grids($size) {
  .block-grid-#{$size}-1  { @include block-grid(1);  }
  .block-grid-#{$size}-2  { @include block-grid(2);  }
  .block-grid-#{$size}-3  { @include block-grid(3);  }
  .block-grid-#{$size}-4  { @include block-grid(4);  }
  .block-grid-#{$size}-5  { @include block-grid(5);  }
  .block-grid-#{$size}-6  { @include block-grid(6);  }
  .block-grid-#{$size}-7  { @include block-grid(7);  }
  .block-grid-#{$size}-8  { @include block-grid(8);  }
  .block-grid-#{$size}-9  { @include block-grid(9);  }
  .block-grid-#{$size}-10 { @include block-grid(10); }
  .block-grid-#{$size}-11 { @include block-grid(11); }
  .block-grid-#{$size}-12 { @include block-grid(12); }
}

@include block-grids(xs);
@media all and (min-width: $screen-sm-min) { @include block-grids(sm) }
@media all and (min-width: $screen-md-min) { @include block-grids(md) }
@media all and (min-width: $screen-lg-min) { @include block-grids(lg) }
