﻿.modal-open {
    #main-container {
        height: 100vh;
        overflow: hidden;
    }
}

.modal {
    background-color: rgba(255, 255, 255, 0.7);

    @media (min-width: $screen-lg-min) {
        text-align: center;

        &:before {
            content: "";
            height: 100%;
            width: 1px;
            display: inline-block;
            vertical-align: middle;
        }
    }

    .rfp-modal {
        @media (min-width: $screen-md-min) {
            width: 939px;
        }
    }

    .modal-dialog {
        @media (min-width: $screen-md-min) {
            width: 680px;
        }

        @media (min-width: $screen-lg-min) {
            text-align: left;
            margin: 10px auto;
            vertical-align: middle;
            display: inline-block;
        }
    }

    .modal-content {
        border: 0;
        border-radius: 0;
        box-shadow: 0px 0px 15.48px 2.52px rgba(0, 0, 0, 0.09);
    }

    .modal-body {
        padding: 50px 15px;

        @media (min-width: $screen-md-min) {
            padding: 50px 60px;
        }

        .btn {
            margin-right: 0;
        }
    }

    .modal-close {
        background-color: $color-primary;
        border: 0;
        height: 50px;
        line-height: 14px;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 50px;

        &:hover,
        &:focus {
            background-color: $color-dark-primary;

            svg {
                -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
            }
        }

        svg {
            height: 14px;
            -webkit-transition: all 0.5s ease;
            transition: all 0.5s ease;
            width: 14px;

            path {
                fill: $color-white;
            }
        }
    }
}

.modal-backdrop {
    background-color: transparent;
    display: none;

    &.in {
        opacity: 0.7;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    }
}

aside.block-contact-affix {
    display: block;
}

/**
* CSS applied only to the Related Files modal
*/
.modal-related-files .modal-dialog {
    margin: 10px auto;

    @media (min-width: $screen-md-min) {
        width: 90%;
    }

    @media (min-width: $screen-lg-min) {
        width: 1080px;
    }

    .modal-body {
        @media (min-width: $screen-md-min) {
            padding: 58px 110px 1px 110px;
        }

        > p.typo__15 {
            margin-bottom: 45px;
        }

        .multi-list-view {
            font-family: $montreal-medium;

            @media (min-width: $screen-md-min) {
                max-height: 260px;
                overflow-y: auto;
                margin-bottom: 40px;
                padding-right: 10px;
            }

            .mlv__item {
                padding-right: 0;
                margin-bottom: 30px;

                > div {
                    /*  min-height: 100px; */
                    min-height: 68px;
                }
            }

            .mlv__link {
                font-size: 14px;
                @extend .clearfix;
                /* min-height: 100px;
                display: table-cell;
                vertical-align: middle;
                position: relative;
                text-transform: none;*/
                padding: 20px;
                /* @media (min-width: $screen-md-min) {
                    font-size: 14px;
                }*/

                &:before,
                &:after {
                    display: none;
                }

                &:hover /*,
                &:focus*/ {
                    /*  color: $color-white;
                    background-color: $color-secondary;*/

                    p {
                        color: $color-white;
                        -webkit-transition: all 0.3s ease-in-out;
                        -o-transition: all 0.3s ease-in-out;
                        -moz-transition: all 0.3s ease-in-out;
                        transition: all 0.3s ease-in-out;
                    }

                    .related-file-thumbnail {
                        path,
                        polygon {
                            fill: $color-white;
                        }
                    }

                    svg {
                        path,
                        circle,
                        rect,
                        line {
                            fill: none;
                            stroke: $color-white;
                            -webkit-transition: all 0.3s ease-in-out;
                            -o-transition: all 0.3s ease-in-out;
                            -moz-transition: all 0.3s ease-in-out;
                            transition: all 0.3s ease-in-out;
                        }
                    }
                }
            }

            .related-file-thumbnail {
                position: relative;
                width: 64px;
                height: 64px;
                /*  left: 15px;
                top: 50%;
                transform: translateY(-50%); */
                float: left;
            }

            svg {
                position: relative;
                float: left;
                margin-top: 3px;
                width: 26px;
            }

            .related-file-name {
                float: left;
                width: calc(100% - 68px);
                padding: 0 0 0 20px;
                /* vertical-align: top;
                margin-left: 64px; */
            }

            p {
                font-family: $montreal-medium;
                font-size: 16px;
                line-height: 17px;
                text-transform: none;
                margin-bottom: 5px;
                /* @media (min-width: $screen-md-min) {
                    font-size: 14px;
                }*/
            }

            span {
                font-family: $montreal-regular;
                text-transform: uppercase;
            }
        }
    }
}

#countryModal .modal-dialog {
    a.btn {
        width: 100%;
        text-transform: uppercase;
    }

    p {
        font-size: 16px;
        margin-top: 13px;
        margin-bottom: 27px;

        @media (min-width: $screen-md-min) {
            font-size: 18px;
            margin-top: 16px;
            margin-bottom: 19px;
        }
    }

    .modal-text {
        font-family: $montreal-regular;
        margin-bottom: 32px;
        font-size: 16px;
        line-height: 1.2;
        padding: 0 27px;

        @media (min-width: $screen-md-min) {
            margin-bottom: 23px;
            font-size: 18px;
        }

        strong {
            font-family: $montreal-bold;
        }
    }

    .country-flag {
        display: inline-block;
        width: 30px;
        height: auto;
        margin: 10px 0 14px 0;

        @media (min-width: $screen-md-min) {
            margin-top: 10px;
        }
    }

    .row {
        margin: 0 -10px;
    }

    [class^="col"] {
        padding: 0 10px;
    }

    @media (min-width: $screen-md-min) {
        width: 860px;

        .modal-body {
            padding: 50px 100px;
        }
    }
}

.contact-us-modal {
    .modal-dialog {
        margin: 10px 0;

        @media (min-width: $screen-sm-min) {
            margin: 10px auto;
            width: 90%;
            max-width: 1082px;
        }
    }

    .modal-body {
        padding: 50px 25px 13px 25px;

        @media (min-width: $screen-sm-min) {
            padding: 50px 90px 100px 90px;
        }


        ul {
            list-style: none;
            padding: 0 15px 15px 30px;
            margin: 0;
            font-family: $montreal-regular;
            font-size: 14px;
            line-height: 1.62;



            li {
                position: relative;

                &:before {
                    content: "\2014";
                    position: absolute;
                    left: -30px;
                }
            }
        }
    }
    /* INC0112673 - wsp.service-now.com contacts are going out of modal box area*/
    /*.row {
        margin: 0 percentage(-37/902);
    }*/
    /*.col-md-3 {
        @media (min-width: $screen-md-min) {
            padding: 0 percentage(37/902);
        }
    }*/

    p.text {
        font-family: $montreal-medium;
        font-size: fluid(18px, 20px);
        margin: 0 0 49px 0;
        text-align: left;

        @media (min-width: $screen-md-min) {
            text-align: center;
        }
    }

    .modal-dialog.small {
        margin: 10px;

        p.text {
            margin: 0 25px 49px 25px;
        }

        .modal-body {
            padding: 50px 25px 13px 25px;

            @media (min-width: $screen-sm-min) {
                padding: 50px 60px 50px 60px;
            }
        }

        @media (min-width: $screen-sm-min) {
            margin: 10px auto;
            width: 50%;
            max-width: 1082px;
        }
    }

    .modal-dialog.medium {
        margin: 10px;

        p.text {
            margin: 0 25px 49px 25px;
        }

        .modal-body {
            padding: 50px 25px 13px 25px;

            @media (min-width: $screen-sm-min) {
                padding: 50px 60px 50px 60px;
            }
        }

        @media (min-width: $screen-sm-min) {
            margin: 10px auto;
            width: 70%;
            max-width: 1082px;
        }
    }

    .set-rec1-width {
        @media (min-width: $screen-sm-min) {
            width: 450px;

            .rec-1 > div[class^="col"] {
                width: 100%;
            }
        }
    }

    .set-rec2-width {
        @media (min-width: $screen-sm-min) {
            width: 700px;

            .rec-2 > div[class^="col"] {
                width: 50%;
            }
        }
    }

    .set-rec3-width {
        @media (min-width: $screen-md-min) {
            width: 850px;

            .rec-3 > div[class^="col"] {
                width: 33.33%;
            }
        }
    }
}

#modal-form,
#modal-contactUs-form {
    .modal-content {
        max-height: 97vh;
        overflow-y: auto;

        @media (min-width: $screen-md-min) {
            max-height: 90vh;
        }
    }

    .col-md-8 {
        width: 100%;
        padding-left: calc(4vw + 15px);
        padding-right: calc(4vw + 15px);
        max-height: 97vh;

        @media (min-width: $screen-md-min) {
            max-height: 90vh;
        }
    }

    .list-group {
        display: block;
    }
}

/**
* CSS applied to the modals on the Experience Editor
*/
.pagemode-edit {
    .block-contact-affix {
        margin-bottom: 0;
        padding-bottom: 20px;
    }

    .modal-dialog {
        margin-top: 0;
        right: 34vw;
        opacity: 0.3;
    }
}

.modal-dialog.small .contact-us-modal.contact-card {
    margin-bottom: 20px;
}

.modal-dialog.medium .contact-us-modal.contact-card {
    margin-bottom: 20px;
}
