.carousel-nav-container .owl-nav {
    display: none;
}

.owl-carousel {
    .owl-nav {
        position: absolute;
        width: 92px;
        top: -88px;
        right: 0;
    }

    .owl-prev,
    .owl-next {
        display: block;
    }
}

.owl-carousel,
.carousel-nav-container {
    .owl-nav.disabled {
        display: none !important;
    }

    .owl-prev,
    .owl-next {
        width: 46px;
        height: 48px;
        padding: 0;
        border: 1px solid $owl-btn-red-border;
        font-size: 0;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;

        button {
            position: relative;
            border: 0;
            font-size: 14px;
            margin: 0;
            padding: 0;
            width: 44px;
            height: 46px;
            border-radius: 0;
            background: transparent;

            &:before {
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .owl-prev {
        border: 1px solid $owl-btn-red-border;
    }

    .owl-next {
        border-left: 0;
    }


    .owl-prev {
        margin-right: 0;
        background: linear-gradient(to left, $color-primary 50%, $color-white 50%,);
        background-size: 200% 100%;
        background-position: 0 100%;
        transition: background-position 0.2s ease-in-out, color 0.2s ease-in-out;

        &:hover {
            background-position: 100% 0;
        }

        button {
            &:before {
                content: "";
                display: inline-block;
                height: 16px;
                width: 9px;
                position: absolute;
                right: 20px;
                top: 50%;
                transform: rotate(180deg);
                margin-top: -9px;
                margin-left: -5.5px;
                transition: none;
                background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5LjA5MSIgaGVpZ2h0PSIxNi4wNjEiIHZpZXdCb3g9IjAgMCA5LjA5MSAxNi4wNjEiPgogIDxwYXRoIGlkPSJpY24tYXJyb3ctbWVkaXVtLXdoaXRlLXJpZ2h0IiBkPSJNNy41LDE1LDAsNy41LDMuMTI1LDQuMzc1LDcuNSwwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4LjAzIDE1LjUzKSByb3RhdGUoMTgwKSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMTQxMjEyIiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8L3N2Zz4K");
                background-repeat: no-repeat;
            }

            &:hover {
                &:before {
                    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5LjA5MSIgaGVpZ2h0PSIxNi4wNjEiIHZpZXdCb3g9IjAgMCA5LjA5MSAxNi4wNjEiPgogIDxwYXRoIGlkPSJpY24tYXJyb3ctbWVkaXVtLXdoaXRlLXJpZ2h0IiBkPSJNNy41LDE1LDAsNy41LDMuMTI1LDQuMzc1LDcuNSwwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4LjAzIDE1LjUzKSByb3RhdGUoMTgwKSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmZmZmIiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8L3N2Zz4K")
                }
            }
        }
    }

    .owl-next {
        background: linear-gradient(to right, $color-primary 50%, $color-white 50%);
        background-size: 200% 100%;
        background-position: 100% 0;
        transition: background-position 0.2s ease-in-out, color 0.2s ease-in-out;

        &:hover {
            background-position: 0 0;
        }

        button {
            &:before {
                content: "";
                display: inline-block;
                height: 16px;
                width: 9px;
                position: absolute;
                right: 20px;
                top: 50%;
                transform: none;
                margin-top: -9px;
                margin-left: -5.5px;
                transition: none;
                background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5LjA5MSIgaGVpZ2h0PSIxNi4wNjEiIHZpZXdCb3g9IjAgMCA5LjA5MSAxNi4wNjEiPgogIDxwYXRoIGlkPSJpY24tYXJyb3ctbWVkaXVtLXdoaXRlLXJpZ2h0IiBkPSJNNy41LDE1LDAsNy41LDMuMTI1LDQuMzc1LDcuNSwwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4LjAzIDE1LjUzKSByb3RhdGUoMTgwKSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMTQxMjEyIiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8L3N2Zz4K");
                background-repeat: no-repeat;

                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    margin-left: -5.35px;
                }
            }

            &:hover {
                &:before {
                    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5LjA5MSIgaGVpZ2h0PSIxNi4wNjEiIHZpZXdCb3g9IjAgMCA5LjA5MSAxNi4wNjEiPgogIDxwYXRoIGlkPSJpY24tYXJyb3ctbWVkaXVtLXdoaXRlLXJpZ2h0IiBkPSJNNy41LDE1LDAsNy41LDMuMTI1LDQuMzc1LDcuNSwwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4LjAzIDE1LjUzKSByb3RhdGUoMTgwKSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmZmZmIiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8L3N2Zz4K")
                }
            }
        }
    }

    .carousel-nav-container {
        .owl-prev,
        .owl-next {
            padding: 0;
        }
    }
}

@media (min-width: $screen-md-min) {
    .carousel-nav-container {
        .owl-prev {
            button:before {
                content: "\e903";
                transform: translate(-50%, -50%) rotate(90deg);
            }
        }

        .owl-next {
            button:before {
                content: "\e903";
                transform: translate(-50%, -50%) rotate(-90deg);
            }
        }

        .owl-prev,
        .owl-next {
            button {
                font-size: 6px;
            }
        }
    }

    .carousel-nav-container .owl-nav {
        min-width: 98px;
        display: block;
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
    }
}



.var-height {
    @media (max-width: 1199px) {
        height: 50px;
    }

    @media (min-width: 1280px) {
        height: 44px;
    }

    @media (min-width: 1366px) {
        height: 45px;
    }

    @media (min-width: 1500px) {
        height: 50px;
    }

    @media (min-width: 1800px) {
        height: 51px;
    }
}

.home-featured-carousel-container {
    .home-featured-carousel {
        @media (max-width:$screen-sm-max) {
            padding-left: 4vw;
            margin-top: 20px;
        }

        .caption {
            color: $color-secondary-dark;
            font-family: $montreal-regular;
            font-size: 12px;
            line-height: 21px;

            a {
                h4 {
                    &.title-h4 {
                        &:hover, &:active {
                            color: $color-secondary-medium-gray;
                        }
                    }
                }
            }
        }




        .owl-stage {

            @media (max-width:$screen-sm-max) {
                padding-right: 50px !important;
                padding-left: 0 !important;
            }

            @media (min-width:$screen-md-min) {
                padding-right: 0 !important;
                padding-left: 35px !important;
            }

            @media (min-width:1024px) {
                padding-left: 41px !important;
            }

            @media (min-width:1152px) {
                padding-left: 46px !important;
            }

            @media (min-width:1280px) {
                padding-left: 51.2px !important;
            }

            @media (min-width:1360px) {
                padding-left: 54px !important;
            }

            @media (min-width:1366px) {
                padding-left: 54.5px !important;
            }

            @media (min-width:1400px) {
                padding-left: 55px !important;
            }

            @media (min-width:1440px) {
                padding-left: 60px !important;
            }

            @media (min-width:1510px) {
                padding-left: 75px !important;
            }

            @media (min-width:1570px) {
                padding-left: 110px !important;
            }

            @media (min-width:1600px) {
                padding-left: 115px !important;
            }

            @media (min-width:1650px) {
                padding-left: 150px !important;
            }

            @media (min-width:1680px) {
                padding-left: 155px !important;
            }

            @media (min-width:1860px) {
                padding-left: 265px !important;
            }

            @media (min-width:1920px) {
                padding-left: 275px !important;
            }

            @media (min-width:2040px) {
                padding-left: 338px !important;
            }

            @media (min-width:2140px) {
                padding-left: 390px !important;
            }

            @media (min-width:2450px) {
                padding-left: 545px !important;
            }

            @media (min-width:2500px) {
                padding-left: 570px !important;
            }

            @media (min-width:2560px) {
                padding-left: 575px !important;
            }

            @media (min-width:3750px) {
                padding-left: 1240px !important;
            }
        }

        .date-m.m-b-1 {
            margin-bottom: 15px !important;

            @media (min-width:$screen-md-min) {
                margin-bottom: 24px !important;
            }
        }

        .thumbnail {
            margin-bottom: 24px;

            &.var-img {
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                position: relative;
                overflow: hidden;
            }


            @media (min-width:$screen-view-condition-min) {
                height: 550px;

                img {
                    height: 550px !important;
                }
            }

            img {
                max-width: none;
                width: 100%;
                height: auto;
            }
        }
    }

    .project-list {
        h4 {
            a {
                color: $color-secondary-dark;
                text-decoration: none;
                display: -webkit-box;
                overflow: hidden;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;

                @media (max-width:$screen-sm-max) {
                    -webkit-line-clamp: 3;
                }

                &:hover, &:active, &:focus {
                    color: $color-secondary;
                    text-decoration: none;
                    transition: all 0.3s ease-in-out;
                }
            }
        }
    }

    .teaser-summary {


        p {

            &:empty {
                display: none;
            }

            &.limit-4 * {
                font-family: inherit;
                font-size: inherit;
                color: inherit;
                line-height: inherit;
                text-decoration: inherit;
                text-transform: inherit;
                margin-top: inherit;
                margin-bottom: inherit;
            }
        }
    }
    /* Owl Nav in next column start*/
    .owl-nav {
        position: relative;
        width: 90px;
        padding: 0;
        font-size: 0;
        transition: all .3s ease-in-out;
    }



    .owl-prev, .owl-next {
        border: 1px solid $owl-btn-border;
        cursor: pointer;
        font-size: 0;
        justify-content: center;
        display: flex;
        align-items: center;

        &.disabled {
            opacity: 0.3;
            pointer-events: none;
        }
    }

    .owl-next {
        background: linear-gradient(to left,$own-btn-hover-bg 50%, transparent 50%);
        background-size: 200% 100%;
        background-position: 0 100%;
        transition: background-position 0.2s ease-in-out, color 0.2s ease-in-out;

        &:before {
            content: "";
            display: inline-block;
            height: 16px;
            width: 9px;
            position: absolute;
            transition: none;
            background-repeat: no-repeat;
            background-position: center;
            -ms-transform: rotate(180deg);
            transform: rotate(180deg);
            background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5LjA5MSIgaGVpZ2h0PSIxNi4wNjEiIHZpZXdCb3g9IjAgMCA5LjA5MSAxNi4wNjEiPgogIDxwYXRoIGlkPSJpY24tYXJyb3ctbWVkaXVtLXdoaXRlLXJpZ2h0IiBkPSJNNy41LDE1LDAsNy41LDMuMTI1LDQuMzc1LDcuNSwwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4LjAzIDE1LjUzKSByb3RhdGUoMTgwKSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMTQxMjEyIiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8L3N2Zz4K);
        }

        &:hover {
            background-position: 100% 0;
        }
    }

    .owl-prev {
        background: linear-gradient(to right,$own-btn-hover-bg 50%,transparent 50%);
        background-size: 200% 100%;
        background-position: 100% 0;
        transition: background-position 0.2s ease-in-out, color 0.2s ease-in-out;

        &:before {
            content: "";
            display: inline-block;
            height: 16px;
            width: 9px;
            position: absolute;
            transform: none;
            transition: none;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5LjA5MSIgaGVpZ2h0PSIxNi4wNjEiIHZpZXdCb3g9IjAgMCA5LjA5MSAxNi4wNjEiPgogIDxwYXRoIGlkPSJpY24tYXJyb3ctbWVkaXVtLXdoaXRlLXJpZ2h0IiBkPSJNNy41LDE1LDAsNy41LDMuMTI1LDQuMzc1LDcuNSwwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4LjAzIDE1LjUzKSByb3RhdGUoMTgwKSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMTQxMjEyIiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8L3N2Zz4K);

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                margin-left: -5.35px;
            }
        }

        &:hover {
            background-position: 0 0;
        }
    }


    .owl-dots {
        display: none;
    }

    .owl-dot {
        &.active {
            span {
                background-color: $color-dark-red;
            }

            ~ * > span {
                background-color: #C3C5C6 !important;
                height: 1px;
                margin-top: 25px;
            }
        }

        span {
            background-color: $color-dark-red;
            content: '&nbsp;';
            height: 3px;
            display: inline-block;
            float: left;
            margin-top: 23px;
            cursor: pointer;
            -webkit-transition: background-color .3s cubic-bezier(.6,.04,.98,.34);
            -o-transition: background-color .3s cubic-bezier(.6,.04,.98,.34);
            -moz-transition: background-color .3s cubic-bezier(.6,.04,.98,.34);
            transition: background-color .3s cubic-bezier(.6,.04,.98,.34);
        }
    }
    /* swap previous and next button positions - start */
    .owl-nav {
        display: flex; /* Enable flexbox layout */
        &.disabled {
            display: none;
        }
    }

    .owl-prev {
        order: 2; /* Show after */
    }

    .owl-next {
        order: 1; /* Show first */
    }
    /* swap previous and next button positions - end */
    /* Owl Nav in next column end*/



    @media (max-width:$screen-sm-max) {
        .owl-nav-box-wrapper {
            display: none;
        }
    }
}

.what-we-do {
    .owl-carousel .owl-nav {
        width: 92px;
    }

    .owl-prev, .owl-next {
        border: 1px solid $owl-btn-border;
        cursor: pointer;
        font-size: 0;
        justify-content: center;
        display: flex;
        align-items: center;

        &.disabled {
            opacity: 0.3;
            pointer-events: none;
        }
    }
    //This is .owl-prev but reveresed as .owl-next
    .owl-prev {
        background: linear-gradient(to left,$own-btn-hover-bg 50%, transparent 50%);
        background-size: 200% 100%;
        background-position: 0 100%;
        transition: background-position 0.2s ease-in-out, color 0.2s ease-in-out;

        &:before {
            content: "";
            display: inline-block;
            height: 16px;
            width: 9px;
            position: absolute;
            transition: none;
            background-repeat: no-repeat;
            background-position: center;
            -ms-transform: rotate(180deg);
            transform: rotate(180deg);
            background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5LjA5MSIgaGVpZ2h0PSIxNi4wNjEiIHZpZXdCb3g9IjAgMCA5LjA5MSAxNi4wNjEiPgogIDxwYXRoIGlkPSJpY24tYXJyb3ctbWVkaXVtLXdoaXRlLXJpZ2h0IiBkPSJNNy41LDE1LDAsNy41LDMuMTI1LDQuMzc1LDcuNSwwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4LjAzIDE1LjUzKSByb3RhdGUoMTgwKSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMTQxMjEyIiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8L3N2Zz4K);
        }

        &:hover {
            background-position: 100% 0;
        }
    }
    //This is .owl-next but reveresed as .owl-prev
    .owl-next {
        background: linear-gradient(to right,$own-btn-hover-bg 50%,transparent 50%);
        background-size: 200% 100%;
        background-position: 100% 0;
        transition: background-position 0.2s ease-in-out, color 0.2s ease-in-out;

        &:before {
            content: "";
            display: inline-block;
            height: 16px;
            width: 9px;
            position: absolute;
            transform: none;
            transition: none;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5LjA5MSIgaGVpZ2h0PSIxNi4wNjEiIHZpZXdCb3g9IjAgMCA5LjA5MSAxNi4wNjEiPgogIDxwYXRoIGlkPSJpY24tYXJyb3ctbWVkaXVtLXdoaXRlLXJpZ2h0IiBkPSJNNy41LDE1LDAsNy41LDMuMTI1LDQuMzc1LDcuNSwwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4LjAzIDE1LjUzKSByb3RhdGUoMTgwKSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMTQxMjEyIiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8L3N2Zz4K);

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                margin-left: -5.35px;
            }
        }

        &:hover {
            background-position: 0 0;
        }
    }

    .owl-dots {
        display: none;
    }

    .owl-carousel .owl-prev {
        margin-right: 0;
    }
}

.bg-dark, .bg-secondary-grey80 {

    .home-featured-carousel-container {
        .owl-prev, .owl-next {
            border: 1px solid $color-pagination-dark;
        }

        .owl-next {
            background: linear-gradient(to left,$color-pagination-dark 50%, transparent 50%);
            background-size: 200% 100%;
            background-position: 0 100%;
            transition: background-position 0.2s ease-in-out, color 0.2s ease-in-out;

            &:before {
                background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5LjA5MSIgaGVpZ2h0PSIxNi4wNjEiIHZpZXdCb3g9IjAgMCA5LjA5MSAxNi4wNjEiPgogIDxwYXRoIGlkPSJpY24tYXJyb3ctbWVkaXVtLXdoaXRlLXJpZ2h0IiBkPSJNNy41LDE1LDAsNy41LDMuMTI1LDQuMzc1LDcuNSwwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4LjAzIDE1LjUzKSByb3RhdGUoMTgwKSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmZmZmIiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8L3N2Zz4K);
            }

            &:hover {
                background-position: 100% 0;
            }
        }

        .owl-prev {
            background: linear-gradient(to right,$color-pagination-dark 50%,transparent 50%);
            background-size: 200% 100%;
            background-position: 100% 0;
            transition: background-position 0.2s ease-in-out, color 0.2s ease-in-out;

            &:before {
                background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5LjA5MSIgaGVpZ2h0PSIxNi4wNjEiIHZpZXdCb3g9IjAgMCA5LjA5MSAxNi4wNjEiPgogIDxwYXRoIGlkPSJpY24tYXJyb3ctbWVkaXVtLXdoaXRlLXJpZ2h0IiBkPSJNNy41LDE1LDAsNy41LDMuMTI1LDQuMzc1LDcuNSwwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4LjAzIDE1LjUzKSByb3RhdGUoMTgwKSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmZmZmIiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8L3N2Zz4K);
            }

            &:hover {
                background-position: 0 0;
            }
        }
    }


    .what-we-do {

        .owl-prev, .owl-next {
            border: 1px solid $color-pagination-dark;
        }
        //This is .owl-prev but reveresed as .owl-next
        .owl-prev {
            background: linear-gradient(to left,$color-pagination-dark 50%, transparent 50%);
            background-size: 200% 100%;
            background-position: 0 100%;
            transition: background-position 0.2s ease-in-out, color 0.2s ease-in-out;

            &:before {
                background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5LjA5MSIgaGVpZ2h0PSIxNi4wNjEiIHZpZXdCb3g9IjAgMCA5LjA5MSAxNi4wNjEiPgogIDxwYXRoIGlkPSJpY24tYXJyb3ctbWVkaXVtLXdoaXRlLXJpZ2h0IiBkPSJNNy41LDE1LDAsNy41LDMuMTI1LDQuMzc1LDcuNSwwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4LjAzIDE1LjUzKSByb3RhdGUoMTgwKSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmZmZmIiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8L3N2Zz4K);
            }

            &:hover {
                background-position: 100% 0;
            }
        }
        //This is .owl-next but reveresed as .owl-prev
        .owl-next {
            background: linear-gradient(to right,$color-pagination-dark 50%,transparent 50%);
            background-size: 200% 100%;
            background-position: 100% 0;
            transition: background-position 0.2s ease-in-out, color 0.2s ease-in-out;

            &:before {
                background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5LjA5MSIgaGVpZ2h0PSIxNi4wNjEiIHZpZXdCb3g9IjAgMCA5LjA5MSAxNi4wNjEiPgogIDxwYXRoIGlkPSJpY24tYXJyb3ctbWVkaXVtLXdoaXRlLXJpZ2h0IiBkPSJNNy41LDE1LDAsNy41LDMuMTI1LDQuMzc1LDcuNSwwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4LjAzIDE1LjUzKSByb3RhdGUoMTgwKSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmZmZmIiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8L3N2Zz4K);
            }

            &:hover {
                background-position: 0 0;
            }
        }
    }
}
