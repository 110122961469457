﻿body.sideNavIsOpen {
    overflow: hidden;

    @media (min-width: $screen-lg-min) {
        overflow-y: auto;
    }

    &:before {
        content: "";
        width: 100vw;
        position: absolute;
        height: 200000px;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 1;

        @media (min-width: $screen-lg-min) {
            display: none;
        }
    }

    #main-container {
        height: 100vh;
        overflow: hidden;

        @media (min-width: $screen-lg-min) {
            height: auto;
            overflow: auto;
        }
    }
}

.side-nav {
    padding: 0 20px;
    position: absolute;
    top: 60px;
    width: 100%;
    z-index: 20000;
    left: -100%;
    opacity: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vh - 60px);
    background: rgba(0,0,0,0.7);

    @media (min-width: $screen-lg-min) {
        display: none;
    }



    &.isVisible {
        transition: all .4s cubic-bezier(0, 0.5, 0, 1);
        left: 0;
        opacity: 1;
    }

    &.isHidden {
        transition: all .4s cubic-bezier(0.6, 0.10, 0.85, 0.4);
        left: -100%;
        opacity: 0;
        overflow-y: scroll;
    }

    .sn__link + .sn__sub-list {
        display: none;
    }

    .sn__list {
        background-color: #fff;
        list-style: none;
        margin: 0;
        width: 100vw;
        left: -20px;
        position: relative;
        padding: 0 20px;
    }

    .sn__item {
        padding: 0;
        margin: 0;
        border-bottom: 1px solid #e4e4e6;

        &:last-child {
            border-bottom: 0;
        }
    }

    .sn__link {
        font-size: 18px;
        font-family: $montreal-medium;
        color: rgb(255, 67, 55);
        text-transform: uppercase;
        padding: 30px 0;
        display: block;
        position: relative;

        &:hover,
        &:focus {
            text-decoration: none;
        }

        &.targetIsShown,
        &.targetIsHidden {
            &:after {
                background-image: url("data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20viewBox%3D%220%200%2010.7%205.5%22%20style%3D%22enable-background%3Anew%200%200%2010.7%205.5%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cpolygon%20points%3D%2210%2C0%205.3%2C4.2%200.7%2C0%200%2C0.7%205.3%2C5.5%2010.7%2C0.7%20%22/%3E%0A%3C/svg%3E");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
                content: "";
                display: inline-block;
                height: 6px;
                width: 10px;
                position: absolute;
                right: 0;
                top: calc(50% - 3px);
                transform: rotate(-90deg);
            }
        }


        &.targetIsShown {
            color: rgb(255, 67, 55);
            text-decoration: none;

            &:after {
                transform: rotate(90deg);
            }
        }
    }

    .sn__item--dark .sn__link {
        color: $color-secondary-dark;
        font-family: $montreal-regular;
        text-transform: capitalize;


        &.targetIsShown {
            color: rgb(255, 67, 55);
            text-decoration: none;
        }
    }

    .subnav {
        position: relative;
        left: 0;
        max-height: 0;
        opacity: 0;

        &.isHidden {
            transition: opacity 0.4s ease-in-out 0s, left 0.4s ease-in-out 0.2s, max-height 0s ease-in-out 0.5s;
            left: calc(100% + 20px);
            max-height: 0;
            opacity: 0;
        }

        &.isShown {
            transition: opacity 0.4s ease-in-out 0.2s, left 0.4s ease-in-out, height 0s ease-in-out 0s;
            left: 0;
            max-height: 100%;
            opacity: 1;
        }
    }

    .sn__sub-list {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .sn__sub-item {
        padding: 0;
        margin: 0;
        border-bottom: 1px solid #e4e4e6;

        &:last-child {
            border-bottom: 0;
        }
    }

    .sn__sub-link {
        font-size: 14px;
        font-family: $montreal-regular;
        color: $color-secondary-dark;
        padding: 15px 15px;
        display: block;
    }

    .col-xs-12:nth-child(2) > div:nth-child(1) {
        margin-top: 30px;
    }

    .sn__child-toggle__list {
        margin: 0 0 30px 0;
        padding: 0;
        list-style: none;
        overflow: hidden;
    }

    .sn__child-toggle__item {
        background: linear-gradient(-90deg, #efeeee, #ffffff);
        background-size: 1500% 400%;
        position: relative;
        font-size: 14px;
        font-family: $montreal-medium;
        color: rgb(30, 37, 43);
        text-transform: uppercase;
        padding: 15px 0;
        border-bottom: 1px solid #c7c7c7;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
        width: 200%;
        margin: 0;
        left: 0;
        transition: left 0.4s ease-in-out;

        &:after {
            transition: all 0.4s ease-in-out;
            background-image: url("data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20viewBox%3D%220%200%2010.7%205.5%22%20style%3D%22enable-background%3Anew%200%200%2010.7%205.5%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cpolygon%20points%3D%2210%2C0%205.3%2C4.2%200.7%2C0%200%2C0.7%205.3%2C5.5%2010.7%2C0.7%20%22/%3E%0A%3C/svg%3E");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            content: "";
            display: inline-block;
            height: 6px;
            width: 10px;
            position: absolute;
            left: calc(100vw - 50px);
            top: calc(50% - 3px);
            transform: rotate(-90deg);
        }

        &.isActive {
            left: -100%;
            animation: child-toggle 0.5s ease;

            &:after {
                left: 50%;
                transform: rotate(90deg);
            }

            .sn__child-toggle__right {
                transition: all 0.4s ease 0.3s;
                right: 0;
            }
        }
    }

    .sn__item:last-child {
        .sn__child-toggle__item:not(.isActive) {
            &:after {
                @media (min-width: $screen-md-min) {
                    left: calc(50% - 10px);
                }
            }
        }
    }

    .sn__child-toggle__right {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        position: relative;
        right: -20px;
    }

    .sn__child-toggle__link {
        color:$color-secondary;
       
    }
}


@keyframes child-toggle {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}
