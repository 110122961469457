.carousel {
    .item {
        margin: 0 !important;
        padding: 0 !important;
    }

    .jumbotron {
        padding: 0;
        position: relative;

        img {
            height: auto;
            width: 100%;
        }
    }

    .currentslide {
        -webkit-box-align: center;
        -ms-flex-align: center;
        -ms-grid-row-align: center;
        background: $color-primary;
        bottom: 0;
        color: $color-white;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-family: $montreal-bold;
        font-size: $fonts-size-small;
        font-size: calc(13px + 0.07vw);
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;

        &.active {
            opacity: 1;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
            -webkit-transition: ease-in-out 0.2s opacity;
            transition: ease-in-out 0.2s opacity;
        }

        span {
            padding: 0 15px;
            top: calc(50% - 7px);
            white-space: nowrap;

            @media (min-width: $screen-sm-min) {
                top: 50%;
            }
        }
    }

    .caption {
        background: #000000;
        font-family: $montreal-regular;
        font-size: $fonts-size-small;
        font-size: calc(13px + 0.07vw);
        line-height: 1.625;
        padding: 10px calc(4vw + 80px) 10px 4vw;
        position: relative;
        -webkit-transition: ease-in-out 0.2s max-height;
        transition: ease-in-out 0.2s max-height;
        width: 100%;
        z-index: 1;

        @media (min-width: $screen-sm-min) {
            background: linear-gradient(to right, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.1) 100%);
            bottom: 0;
            /*max-height: 50px;*/ /* Trello 400 */
            max-height:auto;
            position: absolute;
        }

        p {
            color: #fff;
            font-family: $montreal-regular;
            font-size: $fonts-size-small;
            font-size: calc(13px + 0.07vw);
            line-height: calc(15px + 0.07vw);
            margin: 0;
            padding: 0;
        }
    }
}

.owl-prev {
    float: left;
    font-size: 20px;
    padding: 20px;
    text-transform: uppercase;
}

.owl-next {
    float: right;
    font-size: 20px;
    padding: 20px;
    text-transform: uppercase;
}


.owl-pagination {
    counter-reset: slides-num; /* Initialize counter. */
    left: 50%;
    margin-top: 15px;
    position: absolute;
    top: 100%;
    display: none;

    &:after {
        content: counter(slides-num); /* get total number of items - more info on http://www.sitepoint.com/a-little-known-way-to-replace-some-scripts-with-css-counters/ */
        display: inline-block;
        font-size: 20px;
        font-weight: 700;
        padding-left: 5px;
        vertical-align: middle;
    }
}

.owl-page {
    counter-increment: slides-num; /* Increment counter */
    display: inline-block;
    margin-right: 5px;

    &.active:before {
        content: counter(slides-num) " /"; /* Use the same counter to get current item. */
        display: inline-block;
        font-size: 20px;
        left: 0;
        position: absolute;
        top: 0;
        vertical-align: middle;
    }

    span {
        display: none;
    }
}

.info {
    margin-top: 110px;
    text-align: center;
}
