﻿.main-search {
    float: right;
    margin-right: percentage(46/1320);
    margin-left: percentage(30/1320);


    @media (max-width: $screen-md-max) {
        &:after {
            content: '';
            border-right: 1px solid $color-secondary-light;
            position: absolute;
            top: 0;
            z-index: 5;
            height: 60px;
            margin-left: 2.5vw;
        }
    }

    @media (max-width: $screen-sm-max) {
        &:after {
            margin-left: 3vw;
        }
    }

    @media (max-width: $screen-xs-max) {
        &:after {
            margin-left: 4vw;
        }
    }

    @media (min-width: $screen-lg-min) {
        /*margin-right: 2vw;*/
        /*margin-right: 35px;*/
        margin-right: 25px;
    }



    .mobile-nav & {
        margin-right: 9vw;
        margin-left: 0;

        @media (min-width: $screen-sm-min) {
            margin-right: 8vw;
        }

        @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
            margin-right: 6vw;
        }
    }
}

.ms__btn {
    background-color: transparent;
    border: 0;
    font-size: 0;
    padding: 7.5px 0;

    .mobile-nav & {
        /*padding: 19.5px 0;*/
        padding: 21px 0 0 0;
    }

    &:hover,
    &:focus {
        i {
            color: $color-primary;
        }
    }

    &:hover,
    &:focus {
        svg {
            color: $color-primary;
        }
    }

    i {
        font-size: 20px;
    }
}

.show-search .main-search {
    .ms__search-icon {
        padding-top: 2px;
    }

    .icon-im-search-icon:before {
        vertical-align: top;
        content: "\e908";
        font-size: 15px;
    }
}

.main-search--header {
    overflow: hidden;
    position: absolute;
    width: 100%;
    top: 0;
    background-color: #fff;
    padding: 9px 0;
    z-index: 1;
    transition: top 0.5s linear;

    @media (max-width: $screen-md-max) {
        padding-left: 4vw;
    }

    i {
        display: block;
        float: left;
        font-size: 20px;
        padding: 7.5px 0;
        margin-right: 0;
        margin-top: 3px;
    }

    .show-search & {
        top: 100%;
    }

    form {
        width: 100%;
        display: block;
        padding: 0 20px;

        @media (max-width: $screen-md-max) {
            padding: 0;
        }
    }

    .ms__btn-text {
        text-transform: uppercase;
        font-size: 13px;
        color: $color-secondary-on;
        font-family: $montreal-medium;
    }

    .ms__btn {
        padding: 8px 0 0 10px;
    }

    .ms__input {
        color: $color-secondary-dark;
        border-top: 0;
        border-right: 0;
        border-left: 0;
        /*border-bottom: 2px solid $color-secondary-light;*/
        border-bottom: 0;
        border-radius: 0;
        float: left;
        margin: 0;
        font-size: 14px;
        padding: 10px;
        outline: 0;
        width: calc(100% - 31px);
        font-family: $montreal-medium;
        background: $color-white;

        @media (max-width: $screen-md-max) {
            width: calc(100% - 29px);
        }

        &:focus, &:hover, &:active {
            padding: 10px;
            /*border-bottom: 2px solid $color-secondary-on;*/
            border-bottom: 0;
            background: $color-white;
        }
        /* 6382 - light blue overlay appears when I enter a search result - Backround color added in input above to fix mac browser behaviour on main search input */
        &::-webkit-input-placeholder {
            color: $input-placeholder-color;
        }

        &:-moz-placeholder {
            color: $input-placeholder-color;
        }

        &::-moz-placeholder {
            color: $input-placeholder-color;
        }

        &:-ms-input-placeholder {
            color: $input-placeholder-color;
        }
    }

    .CoveoSearchButton {
        border: 0;
        color: $text-color;
        width: auto;

        @media (max-width: $screen-md-max) {
            float: right;
            /*padding: 10px 10px 0 0;*/
            padding: 12px 10px 0 0;

            svg {
                width: 17px;
                margin-top: 2px;
            }
        }
    }

    .ms__btn.coveo-close {
        display: none;
    }
}



@media (max-width: $screen-md-max) {
    .main-search--header {
        /*width: 83vw;*/
        width: 82vw;
    }

    .show-search .main-search--header {
        z-index: 5;
        top: 0;
    }
}

@media (max-width: $screen-sm-max) {
    .main-search--header {
        /*width: 75vw;*/
        width: 83vw;
    }

    .show-search .main-search--header {
        z-index: 5;
        top: 0;
    }
}

@media (max-width: $screen-xs-max) {
    .main-search--header {
        /*width: 70vw;*/
        width: 79vw;
    }

    .show-search .main-search--header {
        z-index: 5;
        top: 0;
    }
}

@media (max-width: 480px) {
    .main-search--header {
        width: 75vw;
    }

    .show-search .main-search--header {
        z-index: 5;
        top: 0;
    }
}

@media (min-width: $screen-lg-min) {

    .show-search .main-search--header i.icon-im-search-icon {
        color: $color-primary;
    }

    .main-search--header {
        /*height: 99px;*/
        height: 70px;
        padding: 16px 0;
        /*padding: 18px 0;*/
        /*padding: 28px 0;*/
        display: none;
        width: 25px;
        transition: none;
        top: 0;




        .affix & {
            height: auto;
            /*padding: 18px 0;*/
            padding: 18px 0 0 0;
        }

        &.transitioning {
            transition: width 0.5s linear;
        }

        .show-search & {
            top: 0;
            display: block;
            /*top: 50%;
        transform: translateY(-50%);*/
        }

        form {
            float: right;
            padding: 0 0 0 3px;
            border-bottom: 1px solid $color-secondary-light;
        }

        .ms__input {
            width: calc(100% - 84px);
            float: right;
        }

        .ms__btn.coveo-close {
            display: block;
            float: right; /* left */
            margin-right: 0; /*5px */ /*20px;*/
            margin-left: 15px;
        }

        .ms__close-icon svg.a-close-icon:hover {
            stroke: $color-dark-red;
        }

        .ms__btn {
            padding: 19px 2px 4px 10px; /*14px 0 10px 10px;*/
            svg:hover {
                /* fill: #ff372f;
                width: 16px;
                height: 16px;*/
            }
        }

        .CoveoSearchButton {
            float: right;
            padding: 10px 16px 0 0px; /* 1px 16px 0 2px; */ /*1px 8px 0 10px*/ /* padding: 1px 3px 0 10px; */
        }
    }
}

.a-search2-icon:hover {
    stroke: $color-dark-red;
}

.ms__btn {
    @media (min-width: $screen-lg-min) and (max-width:1350px) {
        /*padding: 6px 0 0;*/
        padding: 13px 0 0;
    }

    @media (min-width: 1351px) and (max-width:1499px) {
        /*padding: 6px 0 0;*/
        padding: 13px 0 0;
    }

    @media (min-width: $screen-view-condition-min) {
        padding: 8px 0 0;
    }
}

@media (max-width: $screen-md-max) {
    #inputSearchBox.ms__input.CoveoQuerybox {
        float: right;
        padding-left: 0;
        padding-right: 0;
    }

    .main-search .ms__close-icon {
        display: none;
    }

    .show-search .main-search button.ms__btn .ms__search-icon {
        display: none;
    }

    .show-search .main-search button.ms__btn .ms__close-icon {
        display: block;
    }

    .main-search button.ms__btn svg {
        width: 17px;
    }
}

@media (max-width: $screen-xs-max) {
    #inputSearchBox.ms__input.CoveoQuerybox {
        font-size: 13px;
    }
}

@media (min-width: $screen-lg-min) {
    .main-search .ms__close-icon {
        display: none;
    }

    .show-search .mn__list a.active:before {
        background: none;
        -webkit-transition: width .5s ease;
        -o-transition: width .5s ease;
        transition: width .5s ease;
    }

    .show-search nav.main-nav {
        pointer-events: none;
    }
}

.col-md-3 .ft-structural .coveo-for-sitecore-search-box-container {
    margin-top: 0px !important;
}

/* global search with coveo component - start */
.main-search--header {
    .pos-rel {
        position: relative;
    }

    .CoveoSearchbox .CoveoOmnibox.magic-box .magic-box-clear, .CoveoSearchbox .CoveoOmnibox.magic-box .magic-box-input {
        /*background-color: #e4e4e6;*/
        background-color: $color-white;
    }

    .CoveoSearchbox .CoveoOmnibox.magic-box .magic-box-input input {
        background-color: $color-white;
        color: $color-secondary-dark;
        border-top: 0;
        border-right: 0;
        border-left: 0;
        border-bottom: 0;
        border-radius: 0;
        float: left;
        margin: 0;
        font-size: $font-size-14mn-16mx;
        /*padding: 10px;*/
        padding: 10px 45px 10px 0;
        outline: 0;
        /*width: calc(100% - 31px);*/
        background: #fff;
        /*height: calc(100% - 7px);*/
        font-family: $montreal-medium;
        border-bottom: 1px solid #e4e4e6;
    }

    .CoveoSearchbox .CoveoOmnibox.magic-box .magic-box-input input::placeholder {
        color: #898C8F !important;
        font-family: $montreal-medium;
    }

    .CoveoSearchbox .CoveoSearchButton {
        height: 51px;
        background-color: $color-white;
        border-bottom: 1px solid #e4e4e6;
    }

    .CoveoSearchbox .magic-box-icon svg {
        display: none;
    }

    .CoveoSearchbox .magic-box-icon {
        position: absolute;
        top: 50%;
        width: 18px;
        height: 18px;
        right: 10px;
        margin-top: 2px;
        transform: translateY(-50%);
        cursor: pointer;
        background-color: transparent;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNS43MDciIGhlaWdodD0iMTUuNzA3IiB2aWV3Qm94PSIwIDAgMTUuNzA3IDE1LjcwNyI+CiAgPGcgaWQ9Imljbl9jbG9zZV9maWx0ZXJfc2VhcmNoX2JhciIgZGF0YS1uYW1lPSJpY24gY2xvc2UgZmlsdGVyIHNlYXJjaCBiYXIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMzU0IDAuMzU0KSI+CiAgICA8bGluZSBpZD0iTGlnbmVfNzUiIGRhdGEtbmFtZT0iTGlnbmUgNzUiIHgyPSIxNSIgeTI9IjE1IiBmaWxsPSJub25lIiBzdHJva2U9IiM1Mzc4OTgiIHN0cm9rZS13aWR0aD0iMSIvPgogICAgPGxpbmUgaWQ9IkxpZ25lXzc2IiBkYXRhLW5hbWU9IkxpZ25lIDc2IiB4MT0iMTUiIHkyPSIxNSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNTM3ODk4IiBzdHJva2Utd2lkdGg9IjEiLz4KICA8L2c+Cjwvc3ZnPgo=);
        background-repeat: no-repeat;
        margin-top: 8px;
    }

    .CoveoSearchbox .magic-box .magic-box-clear-svg {
        /*color: #1d4f76;*/
        /*border: 1px solid #1d4f76;
        border-radius: 50%;
        padding: 3px;*/
    }


    .CoveoSearchbox .CoveoOmnibox.magic-box .magic-box-clear .magic-box-icon, .CoveoSearchbox .CoveoOmnibox.magic-box .magic-box-input .magic-box-icon {
        line-height: 45px;
        height: 42px;
        background-color: transparent;
        border-bottom: 0 none;
    }

    .CoveoSearchbox .CoveoOmnibox.magic-box .magic-box-clear {
        position: absolute;
        background-color: transparent;
        right: 10px;
    }

    .coveo-wrapper {
        /*width: 90%;*/
        width: 95%;
        padding: 0;
        margin: 0;
        position: relative;
        margin-left: 15px;
        /*margin-left: 25px;*/
    }
    /*.CoveoSearchbox {
        flex-direction: row-reverse;
    }*/

    .coveo-search-button-svg {
        color: $color-dark-red;
    }

    .CoveoSearchButton:hover .coveo-magnifier-circle-svg {
        fill: $color-dark-red;
    }

    .CoveoSearchbox .CoveoOmnibox.magic-box {
        border: 0 none;
        background-color: $color-white;
        border-radius: unset;
    }

    .CoveoSearchbox .CoveoOmnibox.magic-box .magic-box-clear {
        display: none;
    }

    .magic-box.magic-box-hasFocus .magic-box-suggestions.magic-box-hasSuggestion .coveo-magicbox-suggestions {
        border: 1px solid #e4e4e6; /*#e4e4e6 #D9D9D9*/
    }
}

@media (max-width:991px) {
    .main-search--header .coveo-wrapper {
        width: 94%;
        margin-left: 20px;
    }
}

@media (min-width: 992px) and (max-width:1199px) {
    .main-search--header .coveo-wrapper {
        width: 99%;
        margin-left: 20px;
    }
}

@media (max-width:1199px) {
    .main-search--header {
        padding: 0;
    }

    /* .main-search--header .CoveoSearchbox .CoveoOmnibox.magic-box .magic-box-clear {
        background-color: $color-white;
        height: 56px;
    }*/

    .main-search--header .CoveoSearchbox .CoveoOmnibox.magic-box .magic-box-input {
        background-color: $color-white;
        height: 52px !important;
    }


    .main-search--header .CoveoSearchbox .CoveoOmnibox.magic-box .magic-box-input input {
        height: 100%;
        /*padding-left: 0;*/
        padding: 16px 5px 10px 0;
    }

    .main-search--header .CoveoSearchbox .CoveoOmnibox.magic-box {
        background-color: transparent;
    }
    /*.main-search--header .CoveoSearchInterface{margin-left:4vw;}*/
    .main-search--header .CoveoSearchbox .CoveoOmnibox.magic-box .magic-box-clear > input, .CoveoSearchbox .CoveoOmnibox.magic-box .magic-box-input > input {
        text-indent: 0;
    }

    .main-search--header .CoveoSearchbox .CoveoSearchButton {
        height: 52px;
        padding-bottom: 8px;
    }

    /*.main-search--header .CoveoSearchbox .CoveoOmnibox.magic-box .magic-box-clear .magic-box-icon, .CoveoSearchbox .CoveoOmnibox.magic-box .magic-box-input .magic-box-icon {
        line-height: 57px
    }*/

    .main-search--header .CoveoSearchButton {
        padding: 12px 15px 0 0;
    }

    .main-search--header .CoveoSearchbox .CoveoOmnibox.magic-box .magic-box-input .magic-box-icon {
        height: 34px;
    }

    .main-search--header .CoveoSearchbox .CoveoOmnibox.magic-box .magic-box-clear .magic-box-icon, .main-search--header .CoveoSearchbox .CoveoOmnibox.magic-box .magic-box-input .magic-box-icon {
        line-height: 35px;
        height: 32px;
        background-color: transparent;
        border-bottom: 0 none;
    }
}


@media (min-width: 1200px) {
    .main-search--header .CoveoSearchButton {
        padding: 5px 10px 0 0;
    }
}
/* global search with coveo component - end */

/* Coveo search component - inner pages - start*/
.CoveoSearchInterface .coveo-search-section {
    max-width: inherit;
    margin-right: 0;
}

.CoveoSearchbox.CustUI {
    border: 1px solid #e4e4e6;
}

.CoveoSearchbox.CustUI .magic-box {
    border: 0 none;
    border-radius: 0;
}
/* Hide default coveo search icon */
.CoveoSearchbox.CustUI .CoveoSearchButton {
    width: 40px;
    text-align: left;
    border: 0 none;
    border-radius: 0;
    position: relative;
}

.CoveoSearchbox.CustUI .CoveoSearchButton .coveo-search-button-svg {
    display: none;
}
/* Display custom grey search icon */
.CoveoSearchbox.CustUI .CoveoSearchButton span.coveo-search-button {
    position: absolute;
    margin-left: 8px;
    top: 50%;
    width: 18px;
    height: 18px;
    transform: translateY(-50%);
    cursor: pointer;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNy44NDIiIGhlaWdodD0iMTguNDQ3IiB2aWV3Qm94PSIwIDAgMTcuODQyIDE4LjQ0NyI+DQogIDxnIGlkPSJpY24tc2VhcmNoLWdyYXkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yNDQuNTE1IC0xNS41KSI+DQogICAgPGNpcmNsZSBpZD0iRWxsaXBzZV8xIiBkYXRhLW5hbWU9IkVsbGlwc2UgMSIgY3g9IjcuMzA3IiBjeT0iNy4zMDciIHI9IjcuMzA3IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNDUuMDE1IDE2KSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNmM2ZTc0IiBzdHJva2Utd2lkdGg9IjEiLz4NCiAgICA8bGluZSBpZD0iTGlnbmVfMSIgZGF0YS1uYW1lPSJMaWduZSAxIiB4Mj0iNC44NzciIHkyPSI0Ljk2NyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjU3LjEyMyAyOC42MjkpIiBmaWxsPSJub25lIiBzdHJva2U9IiM2YzZlNzQiIHN0cm9rZS13aWR0aD0iMSIvPg0KICA8L2c+DQo8L3N2Zz4=");
    background-repeat: no-repeat;
}
/* Display custom blue search icon on hover */
.CoveoSearchbox.CustUI .CoveoSearchButton span.coveo-search-button:hover {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNy44NDIiIGhlaWdodD0iMTguNDQ3IiB2aWV3Qm94PSIwIDAgMTcuODQyIDE4LjQ0NyI+DQogIDxnIGlkPSJpY25fc2VhcmNoX2ZpbHRlcl9zZWFyY2hfYmFyX3NlbGVjdGVkIiBkYXRhLW5hbWU9ImljbiBzZWFyY2ggZmlsdGVyIHNlYXJjaCBiYXIgc2VsZWN0ZWQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMzM0LjUxNSAtMTA2Ni45MDcpIj4NCiAgICA8ZyBpZD0iaWNuX3NlYXJjaF9maWx0ZXJfc2VhcmNoX2Jhcl9zZWxlY3RlZC0yIiBkYXRhLW5hbWU9ImljbiBzZWFyY2ggZmlsdGVyIHNlYXJjaCBiYXIgc2VsZWN0ZWQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEzMzUuMDE1IDEwNjcuNDA3KSI+DQogICAgICA8Y2lyY2xlIGlkPSJFbGxpcHNlXzEiIGRhdGEtbmFtZT0iRWxsaXBzZSAxIiBjeD0iNy4zMDciIGN5PSI3LjMwNyIgcj0iNy4zMDciIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzUzNzg5OCIgc3Ryb2tlLXdpZHRoPSIxIi8+DQogICAgICA8bGluZSBpZD0iTGlnbmVfMSIgZGF0YS1uYW1lPSJMaWduZSAxIiB4Mj0iNC44NzciIHkyPSI0Ljk2NyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTIuMTA4IDEyLjYyOSkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzUzNzg5OCIgc3Ryb2tlLXdpZHRoPSIxIi8+DQogICAgPC9nPg0KICA8L2c+DQo8L3N2Zz4=");
    background-repeat: no-repeat;
}

/* Display custom X clear search icon */
.CoveoSearchbox.CustUI .magic-box-icon svg {
    display: none;
}

.CoveoSearchbox.CustUI .magic-box-icon {
    position: absolute;
    top: 50%;
    width: 18px;
    height: 18px;
    right: 10px;
    margin-top: 2px;
    transform: translateY(-50%);
    cursor: pointer;
    background-color: transparent;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNS43MDciIGhlaWdodD0iMTUuNzA3IiB2aWV3Qm94PSIwIDAgMTUuNzA3IDE1LjcwNyI+CiAgPGcgaWQ9Imljbl9jbG9zZV9maWx0ZXJfc2VhcmNoX2JhciIgZGF0YS1uYW1lPSJpY24gY2xvc2UgZmlsdGVyIHNlYXJjaCBiYXIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMzU0IDAuMzU0KSI+CiAgICA8bGluZSBpZD0iTGlnbmVfNzUiIGRhdGEtbmFtZT0iTGlnbmUgNzUiIHgyPSIxNSIgeTI9IjE1IiBmaWxsPSJub25lIiBzdHJva2U9IiM1Mzc4OTgiIHN0cm9rZS13aWR0aD0iMSIvPgogICAgPGxpbmUgaWQ9IkxpZ25lXzc2IiBkYXRhLW5hbWU9IkxpZ25lIDc2IiB4MT0iMTUiIHkyPSIxNSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNTM3ODk4IiBzdHJva2Utd2lkdGg9IjEiLz4KICA8L2c+Cjwvc3ZnPgo=) !important;
    background-repeat: no-repeat !important;
}
/* Hide coveo loading icon - not compatible with custom search and x clear icon */
.CoveoSearchbox.CustUI .coveo-search-button-loading {
    display: none;
}


@media (max-width: 480px) {
    .CoveoSearchInterface .CoveoSearchbox.CustUI {
        max-width: inherit;
        margin-right: 0;
    }

    .main-search--header .CoveoSearchInterface .CoveoSearchbox {
        margin-right: 10px;
    }
}
/* Coveo search component - inner pages - end*/

.magic-box-suggestions .magic-box-suggestion.coveo-omnibox-selectable {
    color: #333e48;
    padding-top: 6px;
    padding-bottom: 6px;
    letter-spacing: 0;
    font-family: $montreal-regular;
    /*font-size: calc(.0939vw + 12.65px);*/
    font-size: $font-size-14mn-16mx;
}

@media (min-width: $screen-view-condition-min) {
    .main-search--header {
        padding: 13px 0;
    }
}
