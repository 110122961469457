﻿.language-selector,
.country-language-selector {
    order: 1;
    width: 100%;
    padding: 0 20px;

    @media (max-width: $screen-md-max) {
        padding: 0 4vw;


        svg {
            width: 20px;
            padding-left: 3px;
            margin-top: 0;
            padding-right: 2px;
        }
    }

    @media (min-width: $screen-lg-min) {
        padding: 0;
        width: auto;
        float: right;
    }

    .back-link {
        padding: 0 20px;
        width: 100%;
        margin-bottom: 0; /*35px;*/ /*45px*/
        a {
            display: block;
            width: 100%;
            font-family: $montreal-regular;
            font-size: 16px;
            color: $color-secondary-dark;
            padding: 32px 0 0 0;
            text-decoration: none;
        }

        @media (min-width: $screen-lg-min) {
            display: none;
        }
    }

    .cls__btn {
        position: relative;
        text-align: left;
        font-size: 18px;
        text-transform: none;
        color: $color-secondary-dark;
        text-decoration: none;
        white-space: nowrap;
        user-select: none;
        cursor: pointer;
        border: 0;
        padding: 0;
        border-radius: 0;
        background: transparent;
        margin: 0;
        font-weight: 700;
        font-family: $montreal-regular;
        padding: 30px 0;
        width: 100%;


        @media (max-width: $screen-md-max) {
            font-family: $montreal-medium;
            font-size: 14px;
            font-weight: normal;
            padding: 25px 0;
            border-bottom: 1px solid $color-secondary-light-gray;

            &:hover .globe-svg-icon > svg .cl-a {
                stroke: $color-primary;
            }


            .globe-svg-icon {
                margin-right: 12px;
                float: left;
            }
        }




        &.mobile-opened + .cls__subnav {
            width: 100vw;
            right: -100vw;
        }

        &.subnav-trigger:after {
            @extend .icomoon;
            position: absolute;
            color: $color-secondary-dark;
            content: "\e902";
            font-size: 15px;
            right: 0;
            top: 50%;
            transform: translateY(-50%) rotate(180deg);

            @media (max-width: $screen-md-max) {
                color: $text-color;
                content: "\f105" !important;
                font-size: 22px;
                right: 5px;
                top: 26px !important;
                text-rendering: auto;
                font-family: font-awesome !important;
                z-index: 100;
                line-height: 0.7;
                height: 20px;
                transform: none;
            }
        }

        &.targetIsShown,
        &.targetIsHidden {
            &:after {
                @extend .icomoon;
            }
        }

        @media (min-width: $screen-lg-min) {
            padding: 0;
            font-size: 11px;
            line-height: 3;
            vertical-align: middle;
            display: inline-block;
            border-bottom: 0;
            padding: 30px 0 15px 10px;


            &:hover,
            &:active,
            &:focus {
                color: $color-primary;
            }



            &.targetIsShown,
            &.targetIsHidden {
                &:after {
                    content: "\e903";
                    font-size: 6px;
                    display: inline-block;
                    height: 6px;
                    transition: transform 0.3s ease;
                }
            }

            &.targetIsHidden {
                + .cls__subnav {
                    height: 0 !important;
                }
            }

            &.targetIsShown {
                color: $color-primary;

                &:after {
                    transform: rotate(180deg);
                }
            }

            &.targetIsHidden {
                &:after {
                    transform: rotate(0deg);
                }
            }

            .affix & {
                padding: 30px 0 15px 10px;
            }
        }

        @media (min-width: $screen-view-condition-min) {
            padding: 29px 0 19px 10px;

            .affix & {
                padding: 29px 0 19px 10px;
            }
        }
    }


    .cls__subnav {
        position: absolute;
        top: 0;
        background-color: rgba(255, 255, 255, 1);
        padding: 0;
        z-index: 1;
        overflow: hidden;
        right: 0;
        width: 0;
        height: calc(100vh - 60px);

        > .row {
            padding-top: 0;
            padding-bottom: 15px;
            list-style: none;
            position: absolute;
            top: 0;
            right: 0;
            padding-left: 7px;
            width: 100vw;
            transform: translateY(-7px);
            height: calc(100vh - 60px);
            margin: 0;
            overflow: auto;

            [class*=col-] {
                padding: 0 20px;
            }
        }

        &_title {
            color: $color-secondary-dark;
            @extend .new__typo__12; //Header
        }

        &_summary {
            color: $color-secondary-dark;
            @extend .new__typo__03; //Header
        }

        @media (min-width: $screen-lg-min) {
            top: 100%;
            width: 100vw;
            left: 0;
            right: auto;
            padding: 0 4.861111vw;
            box-shadow: none;
            height: 0;
            max-height: calc(100vh - 99px);
            overflow: hidden;

            .affix & {
                top: 101%;
            }

            > .row {
                padding-top: 20px;
                position: static;
                top: auto;
                right: auto;
                padding-left: 0;
                width: auto;
                height: auto;
                margin: 0 0 0 -15px;
                overflow: visible;

                [class*=col-] {
                    padding: 0 10px;
                }
            }

            &.transitioning {
                transition: height .5s ease-out;
            }
        }

        @media (min-width: $screen-view-condition-min) {
            top: 100%;
            width: 100vw;
            left: 0;
            right: auto;
            padding: 0 4vw;
            box-shadow: none;
            height: 0;
            max-height: calc(100vh - 50px);
            overflow: hidden;

            .affix & {
                top: 101.15%;
            }


            > .row {
                padding-top: 35px;
                position: static;
                top: auto;
                right: auto;
                padding-left: 0;
                width: auto;
                height: auto;
                margin: 0 0 0 -15px;
                overflow: visible;

                [class*=col-] {
                    padding: 0 15px;
                }
            }

            &.transitioning {
                transition: height .5s ease-out;
            }
        }
    }
}

.country-language-selector {

    @media (min-width: $screen-lg-min) {
        padding: 0;

        .affix & {
            padding: 0;
        }
        /* added close button in Country language selector menu //Start */
        .top-menu-close-btn {
            background-color: $color-white;
            border: 0;
            height: 25px;
            line-height: 14px;
            padding: 0;
            position: absolute;
            right: 4.5px;
            top: 0;
            width: 25px;
            margin-top: 5px;
            z-index: 300;

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                right: 0;
            }

            &:hover svg {
                -webkit-transform: rotate(90deg);
                -ms-transform: rotate(90deg);
                transform: rotate(90deg);

                .menu-close-svg-lines {
                    stroke: $color-primary;
                }
            }

            svg {
                width: 18px;
                height: 18px;
                -webkit-transition: all 0.5s ease;
                -o-transition: all 0.5s ease;
                transition: all 0.5s ease;

                .menu-close-svg-lines {
                    fill: none;
                    stroke: $color-secondary-dark;
                    stroke-width: 1.5px;
                }
            }
        }
        /* added close button in Country language selector menu //Start */
    }


    @media (min-width: $screen-view-condition-min) {

        .top-menu-close-btn {
            right: 4.5px;
            margin-top: -6px;
        }
    }
}

.language-selector {
    margin-left: 20px;

    .cls__subnav {
        padding: 0;
        left: auto;
        right: 15px;

        &:before,
        &:after {
            display: none;
        }
    }

    .tcl__list {
        padding: 0 20px;
        list-style: none;
    }

    .tcl__item {
        margin: 10px 0 0;
        text-align: center;
    }
}

.language-link {
    display: block;
    order: 2;
    width: 100%;
    padding: 0 20px;

    @media (max-width: $screen-md-max) {
        padding: 0 4vw;
    }

    @media (min-width: $screen-lg-min) {
        float: right;
        margin-left: 20px;
        width: auto;
        padding: 0;
    }

    a {
        font-family: $montreal-regular;
        text-transform: uppercase;
        color: $color-primary;
        font-size: 18px;
        line-height: 1;
        vertical-align: middle;
        display: inline-block;
        font-weight: 700;
        padding: 30px 0;
        width: 100%;
        border-bottom: 1px solid $color-secondary-light;
        text-decoration: none;

        @media (max-width: $screen-md-max) {
            font-family: $montreal-medium;
            font-size: 14px;
            text-transform: none;
            color: $color-secondary-dark;
            font-weight: normal;
            padding: 25px 0;
            border-bottom: none;
        }

        @media (min-width: $screen-lg-min) {
            padding: 0;
            font-size: 12px;
            line-height: 35px;
            width: auto;
            border-bottom: 0;
            color: $color-secondary-dark;
            font-family: $montreal-medium;
            font-weight: normal;
        }

        &:hover,
        &:active,
        &:focus {
            color: #898C8F;
            text-decoration: none;
        }
    }
}
/* 6315 */
.country-language-selector .cls__btn.targetIsHidden:after {
    content: "" !important;
}

.country-language-selector .cls__btn.targetIsShown svg .cl-a {
    stroke: $color-primary;
}

.country-language-selector .cls__btn:after {
    content: "" !important;
}

.pos-S {
    position: static !important;
    padding-right: 15px;
    margin-top: 20px;
}


.title-country-language .tcl__right.pos-S a {
    text-decoration: none;
}

.title-country-language .tcl__right.pos-S a:hover {
    color: $color-primary;
    text-decoration: none;
}

@media (max-width: $screen-md-max) {
    .title-country-language .ttl-world-link {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .ttl-world-link a {
        font-size: 12px;
    }
}

@media (min-width: $screen-lg-min) {
    .country-language-selector .cls__global-box {
        .col-xs-12.col-md-4.col-lg-3 .title-country-language:not(:first-of-type) {
            margin-top: 25px;
        }
    }

    .title-country-language .tcl__right.pos-S, .title-country-language .ttl-world-link {
        margin-top: -65px;
    }
}

@media (min-width: $screen-view-condition-min) {
    .country-language-selector .cls__global-box {
        max-width: 1396px !important;
        margin-left: auto !important;
        margin-right: auto !important;

        .col-xs-12.col-md-4.col-lg-3 .title-country-language:not(:first-of-type) {
            margin-top: 25px;
        }
    }

    .title-country-language .tcl__right.pos-S, .title-country-language .ttl-world-link {
        margin-top: -75px;
    }
}

@media (min-width: 1600px) {
    .country-language-selector .cls__global-box {

        .col-xs-12.col-md-4.col-lg-3 .title-country-language:not(:first-of-type) {
            margin-top: 30px;
        }
    }
}

@media (min-width: 1800px) {
    .country-language-selector .cls__global-box {
        .col-xs-12.col-md-4.col-lg-3 .title-country-language:not(:first-of-type) {
            margin-top: 35px;
        }
    }
}

.cls__subnav_summary_parent {
    border-bottom: 1px solid #c7c7c7;

    @media (min-width: 1600px) {
        padding-bottom: 5px;
        margin-bottom: 15px;
    }

    @media (min-width: 1800px) {
        padding-bottom: 7px;
        margin-bottom: 24px;
    }
}
