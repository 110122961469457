//Colors
$color-primary: #C8244F !default;

$color-secondary: #424C5F !default;
$color-secondary-dark: #2E384E !default;
$color-secondary-light: #4D5668 !default;
$color-secondary-lighter: #586071 !default;
$color-secondary-lightest: #d5d7dc !default;

$brand-primary: $color-primary !default;
$brand-success: #24C890 !default;
$brand-info: #24ABC8 !default;
$brand-warning: #ABC824 !default;
$brand-danger: #E65757 !default;

$gray-base: $color-secondary-dark !default;
$gray: $color-secondary-lighter !default;
$gray-light: $color-secondary-lightest !default;
$gray-darker: $color-secondary-dark !default;

//Scaffolding
$body-bg: $color-secondary-dark !default;
$main-bg: #FFF !default;
$navbar-bg: $main-bg !default;

$header-background-color: $color-secondary-dark !default;

$text-color: $color-secondary !default;
$link-color: $brand-primary !default;

$brand-text-color: #FFF !default;

$color-white: #fff !default;
$color-black: #000 !default;

$color-black: #000 !default;
$color-white: #fff !default;

$footer-background-color: $color-secondary-dark !default;
$footer-text-color: $color-secondary-lightest !default;

//Typography
$font-family-serif: serif !default;
$font-family-sans-serif: sans-serif !default;
$font-family-base: $font-family-serif !default;

$font-size-base: 14px !default;
$font-size-h1: 36px !default;
$font-size-h2: 30px !default;
$font-size-h3: 24px !default;
$font-size-h4: 18px !default;
$font-size-h5: 16px !default;

$text-muted: $color-secondary-lighter !default;
$headings-small-color: $color-secondary-lighter !default;
$headings-font-weight: 800 !default;

$list-font-weight: normal !default;
$active-list-text-color: $brand-primary !default;
$active-list-font-weight: 700 !default;

$article-font-size-lead: 20px !default;

//Components
$border-radius-base: 0px !default;
$border-radius-large: 0px !default;
$border-radius-small: 0px !default;

//Alerts
$alert-success-bg: lighten($brand-success, 35%) !default;
$alert-success-text: $brand-success !default;
$alert-info-bg: lighten($brand-info, 35%) !default;
$alert-info-text: $brand-info !default;
$alert-warning-bg: lighten($brand-warning, 35%) !default;
$alert-warning-text: $brand-warning !default;
$alert-danger-bg: lighten($brand-danger, 35%) !default;
$alert-danger-text: $brand-danger !default;

//Badges
$badge-bg: $color-primary !default;

//Breadcrumbs
$breadcrumb-bg: transparent !default;
$breadcrumb-separator: "\\" !default;
$breadcrumb-color: $color-secondary-lighter !default;
$breadcrumb-active-color: $color-secondary-dark !default;
$breadcrumb-padding-horizontal: 0 !default;
$breadcrumb-font-size: 14px !default;
$breadcrumb-font-weight: normal !default;
$breadcrumb-active-font-size: 12px !default;
$breadcrumb-active-font-weight: normal !default;

//Blockquote
$blockquote-lead-font-size: 32px !default;
$blockquote-lead-font-style: italic !default;
$blockquote-lead-color: $brand-primary !default;
$blockquote-footer-color: lighten($text-color, 30%) !default;
$blockquote-footer-font-style: italic !default;

//Buttons
$btn-font-weight: bold !default;
$btn-border-radius-base: $border-radius-base !default;
$btn-border-radius-large: $border-radius-large !default;
$btn-border-radius-small: $border-radius-small !default;
$btn-primary-bg: $brand-primary !default;
$btn-primary-border: $brand-primary !default;
$btn-default-border: $color-secondary-dark !default;
$btn-default-color: $color-secondary-dark !default;
$btn-success-bg: $brand-success !default;
$btn-success-border: $brand-success !default;
$btn-info-bg: $brand-info !default;
$btn-info-border: $brand-info !default;
$btn-warning-bg: $brand-warning !default;
$btn-warning-border: $brand-warning !default;
$btn-danger-bg: $brand-danger !default;
$btn-danger-border: $brand-danger !default;

//Carousel
$carousel-navigation-color: #FFF !default;
$carousel-navigation-font-size: 20px !default;
$carousel-navigation-icon-font-size: 30px !default;

//Counters
$counter-default-bg: $color-secondary-dark !default;
$counter-default-color: #FFF !default;
$counter-font-weight: bold !default;

//Dropdown
$dropdown-menu-border-radius: $border-radius-base !default;
$dropdown-header-color: $brand-primary !default;
$dropdown-header-font-size: 16px !default;
$dropdown-header-font-weight: 700 !default;

//Jumbotron
$jumbotron-font-size: 16px !default;
$jumbotron-font-weight: 700 !default;
$jumbotron-lead-font-size: 18px !default;
$jumbotron-xl-h1-font-size: 120px !default;
$jumbotron-xl-h2-font-size: 80px !default;
$jumbotron-xl-h3-font-size: 60px !default;
$jumbotron-xl-lead-font-size: 26px !default;
$jumbotron-xl-font-size: 23px !default;
$jumbotron-border-radius: $border-radius-large !default;

//Labels
$label-default-bg: $color-secondary !default;
$label-success-bg: $brand-success !default;
$label-info-bg: $brand-info !default;
$label-primary-bg: $brand-primary !default;
$label-warning-bg: $brand-warning !default;
$label-danger-bg: $brand-danger !default;

$label-inner-font-size: 14px !default;
$label-inner-font-style: normal !default;
$label-inner-text-transform: uppercase !default;
$label-inner-font-weight: bold !default;
$label-inner-color: $color-secondary !default;

//Forms
$input-bg-focus: #fff !default;
$input-bg-disabled: transparent !default;
$input-bg: transparent !default;
$input-label-font-size: 12px !default;
$input-label-font-weight: normal !default;

//Icons
$icon-font-size-md: 24px !default;
$icon-font-size-lg: 48px !default;
$icon-font-size-xl: 64px !default;
$icon-font-size-xxl: 92px !default;

//Lists
$list-divided-border-color: #eee !default;

//Pagination
$pagination-border: transparent !default;
$pagination-bg: transparent !default;
$pagination-active-border: $brand-primary !default;
$pagination-active-color: $brand-primary !default;
$pagination-active-bg: transparent !default;

//Panels
$panel-primary-heading-bg: $brand-primary !default;
$panel-primary-border: $brand-primary !default;
$panel-success-heading-bg: $brand-success !default;
$panel-success-border: $brand-success !default;
$panel-info-heading-bg: $brand-info !default;
$panel-info-border: $brand-info !default;
$panel-warning-heading-bg: $brand-warning !default;
$panel-warning-border: $brand-warning !default;
$panel-danger-heading-bg: $brand-danger !default;
$panel-danger-border: $brand-danger !default;
$panel-border-radius: 0 !default;
$panel-default-heading-bg: #FFF !default;
$panel-footer-bg: #FFF !default;
$panel-heading-padding: 20px 15px !default;

//Progress bars
$progress-bar-bg: #F2F3F4 !default;
$progress-bar-padding: 5px !default;
$progress-bar-font-size: 14px !default;
$progress-bar-font-weight: 800 !default;
$progress-bar-height: auto !default;
$progress-bar-min-height: 20px !default;
$progress-bar-color: $text-color !default;

//Thumbnail
$thumbnail-border: transparent !default;

//Tooltip
$tooltip-bg: $color-secondary-dark !default;
$tooltip-arrow-color: $tooltip-bg !default;

//Navbar
$navbar-default-bg: transparent !default;
$navbar-default-toggle-border-color: transparent !default;
$navbar-default-link-hover-color: #FFF !default;
$navbar-default-link-hover-bg: transparent !default;
$navbar-default-link-color: #FFF !default;
$navbar-default-disabled-color: #777777 !default;
$navbar-default-brand-hover-color: #FFF !default;
$navbar-toggle-icon-bar-color: $navbar-default-link-color !default;
$navbar-social-icon-color: $navbar-default-link-color !default;
$navbar-dropdown-bg: #fff !default;

//Mega-dropdown
$mega-dropdown-menu-padding: 30px 10px !default;
$mega-dropdown-font-size: 16px !default;

//Logo
$logo-fill: $navbar-default-link-color !default;
$logo-tagline-color: $navbar-default-link-color !default;

//Navs
$nav-link-hover-bg: #FFF !default;
$nav-link-font-weight: 700 !default;
$nav-service-link-color: #FFF !default;
$nav-service-link-hover-color: #FFF !default;
$nav-service-link-font-weight: lighter !default;

$nav-tabs-active-link-hover-color: $brand-text-color !default;
$nav-tabs-active-link-hover-bg: $color-secondary !default;
$nav-tabs-active-link-hover-border-color: $nav-tabs-active-link-hover-bg !default;
$nav-tabs-justified-link-border-color: $color-secondary !default;
$nav-tabs-justified-active-link-border-color: $color-secondary !default;

//Sidebar
$sidebar-bg: #FFF !default;
$sidebar-border-color: #ddd !default;
$sidebar-nav-item-bg: transparent !default;
$sidebar-nav-item-color: $text-color !default;
$sidebar-nav-item-font-weight: normal !default;
$sidebar-nav-item-bg-active: transparent !default;
$sidebar-nav-item-color-active: $brand-primary !default;
$sidebar-nav-item-border-color-active: $color-secondary-lightest !default;
$sidebar-nav-item-font-weight-active: 800 !default;
$sidebar-toggle-border-radius: 0px !default;

//Medialist
$media-list-divided-border-color: $list-divided-border-color !default;

//Well
$well-border-radius: $border-radius-base !default;

//Overlay
$bg-media-overlay-color: #000011 !default;
