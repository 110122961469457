.collapses-panel {
    position: relative;
}

.el-collapses {
    max-height: 0;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    overflow: hidden;
    transition: height 0.4s ease-in-out;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    margin-bottom: 60px;
    margin-top: 20px;

    @media (min-width: $screen-sm-min) {
        margin-top: 60px;
    }

    @media (min-width: $screen-md-min) {
        margin-top: 0;
    }

    &.open {
        max-height: 1000px;
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        transition: height 0.4s ease-in-out;
    }

    .coll-content {
        padding: 60px 4vw;
        background-color: $color-secondary-lightest;
        color: $color-secondary-dark;
        @extend .new__typo__03;


        a {
            color: $link-color;
            text-decoration: underline;

            &:hover, &:active {
                color: $link-hover-color;
                text-decoration: underline;
            }
        }
    }
}

.employeelist {
    @media (min-width: $screen-md-min) {
        padding: 0 4vw !important;
    }

    @media (min-width: $screen-view-condition-min) {
        padding: 0 !important;
        max-width: $screen-laptop-min;
        margin: auto !important;
    }

    .el-container {

        .el-item {
            padding-top: 0;
            padding-bottom: 0;
            width: 100%;

            @media (min-width: $screen-sm-min) {
                padding-bottom: 15px;
            }

            .el-item-content {
                padding-bottom: 0;

                @media (min-width: $screen-sm-min) {
                    padding-bottom: 30px;
                    position: relative;
                }

                &.open {
                    border-bottom: 2px solid $color-secondary;
                }
            }

            img {
                min-height: 0;
                width: 100%;
            }

            .el-title {
                margin: 20px 0 10px;

                @extend .new__typo__13; //Global Team
                color: $color-secondary-dark;
            }

            .el-name {
                margin: 0 0 20px;
                @extend .new__typo__12; //Global Team
            }

            .el-content {
                color: $color-secondary-dark;
                @extend .new__typo__03; //Global Team
                @media (min-width: $screen-md-min) {
                    margin-bottom: 60px;
                }
            }

            .btn {
                margin-top: 30px;

                @media (min-width: $screen-sm-min) {
                    position: absolute;
                    bottom: -50px;
                }

                @media (min-width: $screen-md-min) {
                    bottom: 20px;
                }
            }
        }
    }

    hr {
        padding: 0;
        margin-left: 0;
        margin-right: 0;

        @media (min-width: $screen-sm-min) {
            display: block;
            clear: none !important;
            width: 100%;

            &:nth-of-type(odd) {
                display: none;
            }
        }

        &:last-of-type {
            display: none;

            @media (min-width: $screen-md-min) {
                display: block;
            }
        }
    }
}
