


.img {
  &-responsive {
    .thumbnail & {
      width: 100%;
    }
  }
  &-rounded {
    border-radius: 10px;
  }
}
