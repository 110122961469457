﻿.country-language-selector .col-md-12:first-child {
    .country-name:first-child {
        margin-top: 0;
    }
}


.title-country-language {

    .tcl__list {
        padding: 0;
        margin: 0;
        list-style: none;
        overflow: hidden;
    }

    .tcl__item {
        padding: 15px 0 15px;
        border-bottom: 1px solid $color-secondary-light-gray;
        width: 100%;
        position: relative;
        transition: all 0.5s ease;
        transform: translateX(0);
        /* 6354 - Responsive menu implementation */
        &.show {
            transform: translateX(-50%);

            &:after {
                transform: translateY(-50%) rotate(0deg);
            }
        }

        @media (max-width: $screen-md-max) {
            padding: 12px 0;
        }
    }

    .tcl__item:first-child {
        border-top: 1px solid $color-secondary-light-gray;
    }

    .tcl__left {
        color: $color-secondary-dark;
        font-family: $montreal-medium;
        font-size: 12px;

        @media (max-width: $screen-md-max) {
            font-family: $montreal-bold;
        }
    }

    .tcl__right {
        text-transform: none;
        font-family: $montreal-medium;
        font-size: 12px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        @media (max-width: $screen-md-max) {
            font-size: 14px;

            a {
                &.active {
                    text-decoration: underline;
                }
            }
        }
    }

    .country-name {
        text-transform: none;
        color: $color-secondary-dark;
        font-family: $montreal-bold;
        font-size: 14px;
        margin-top: 25px;
        margin-bottom: 10px;

        @media (max-width: $screen-md-max) {
            text-transform: none;
            font-size: 25px;
        }
    }
}

@media (max-width: $screen-md-max) {
    .title-country-language {

        .tcl__list a.country:hover {
            color: $color-primary;
            text-decoration: none;
        }

        .tcl__item {


            &.active-bg {
                background: $bg-btn-grey;


                &::before {
                    content: ""; /* Necessary for the ::before pseudo-element */
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%; /* Matches the height of the parent div */
                    width: 5px; /* Width of the border */
                    background-color: $color-primary; /* Red border */
                }
            }
        }
    }
}

@media (min-width: $screen-lg-min) {
    .title-country-language {
        .tcl__list {
            margin: 0 0 10px 0;
        }



        .tcl__item {
            padding: 3px 0;
            border-bottom: 1px solid $color-secondary-light-gray;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            margin-bottom: 0;
            width: 100%;


            &:hover,
            &:focus {
                background: $bg-btn-grey;
            }

            &:after {
                display: none;
            }

            &.active-bg {
                background: $bg-btn-grey;


                &::before {
                    content: ""; /* Necessary for the ::before pseudo-element */
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%; /* Matches the height of the parent div */
                    width: 5px; /* Width of the border */
                    background-color: $color-primary; /* Red border */
                }
            }
        }

        .tcl__item:first-child {
            border-top: 1px solid $color-secondary-light-gray;
        }

        .tcl__right {
            color: $color-secondary-dark;
            font-family: $montreal-medium;
            font-size: fluid(12px, 12px);
            padding-right: 10px;
        }

        .tcl__right__global {
            font-family: $montreal-regular;
            font-size: fluid(12px, 12px);
            position: static;
            right: auto;
            text-transform: none;

            &.padTB10 {
                padding-top: 5px;
            }
        }

        .tcl__left {
            font-family: $montreal-bold;
            font-size: fluid(12px, 12px);
            position: static;
            right: auto;
            padding-left: 10px;
        }

        .tcl__link {
            text-transform: none;
            color: $text-color;
            font-family: $montreal-medium;
            font-size: fluid(12px, 12px);
            text-decoration: none;

            &:hover,
            &:focus,
            &.active {
                color: $text-color;
            }

            &.active {
                text-decoration: underline;
            }
        }

        .country-name {
            text-transform: none;
            color: $color-secondary-dark;
            font-family: $montreal-bold;
            font-size: 18px;
            margin-top: 0;
        }
    }


    .sn__child-toggle .country-name {
        font-size: fluid(20px, 20px);
    }
}

@media (min-width: $screen-view-condition-min) {
    .title-country-language {
        .tcl__list {
            margin: 0 0 15px 0;
        }

        .tcl__item {
            padding: 5px 0;
        }

        .tcl__link {

            &:hover,
            &:focus,
            &.active {
                font-size: 14px;
            }
        }

        .tcl__right__global {
            font-size: 16px;
        }

        .country-name {
            font-size: 25px;
            padding-bottom: 10px;
        }
    }
}

@media (min-width: 1600px) {
    .title-country-language {

        .tcl__item {
            padding: 5px 0;
        }
    }
}

@media (min-width: 1800px) {
    .title-country-language {

        .tcl__item {
            padding: 6px 0;
        }
    }
}

@media (min-width: 1900px) {
    .title-country-language {

        .tcl__item {
            padding: 7px 0;
        }
    }
}


.desktop-header .title-country-language .tcl__left {

    @media (max-width: $screen-md-max) {
        font-size: 14px;
        padding-left: 10px;
    }

    @media (min-width: $screen-lg-min) {
        width: 50%;
        font-size: 12px !important;
    }

    @media (min-width: $screen-view-condition-min) {
        font-size: 14px !important;
    }
}

.desktop-header .title-country-language .tcl__right {
    @media (max-width: $screen-md-max) {
        padding-right: 10px;
    }

    @media (min-width: $screen-lg-min) {
        font-size: 12px !important;
    }

    @media (min-width: $screen-view-condition-min) {
        font-size: 14px !important;
    }
}

.desktop-header .title-country-language .tcl__right a {

    @media (min-width: $screen-lg-min) {
        font-size: 12px !important;
    }

    @media (min-width: $screen-view-condition-min) {
        font-size: 14px !important;
    }
}
