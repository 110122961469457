.mosaic {
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: $line-height-computed;

  &:hover, &-overlay-show {
    .mosaic-overlay {
      opacity: 1 !important;
    }
  }
  &-overlay-show {
    &.mosaic-bar-bottom {
      .mosaic-overlay {
        bottom: 0;
      }
    }
    &.mosaic-bar-top {
      .mosaic-overlay {
        top: 0;
      }
    }
    &.mosaic-cover {
      .mosaic-backdrop {
        left: 100% !important;
      }
    }
  }
  &.mosaic-cover, &.mosaic-cover-corner {
    .mosaic-overlay {
      z-index: 0;
    }
    .mosaic-backdrop {
      position: relative;
      z-index: 1;
      left: 0%;
    }
    &:hover {
      .mosaic-backdrop {
        left: 100%;
      }
    }
  }
  &.mosaic-bar-bottom {
    &:hover {
      .mosaic-overlay {
        bottom: 0;
      }
    }
  }
  &.mosaic-bar-top {
    &:hover {
      .mosaic-overlay {
        top: 0;
      }
    }
  }
  &:hover {
    .mosaic-overlay {
      opacity: 1;
    }
  }
  &-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.4);
    color: #FFF;
    padding: 20px;
    opacity: 0;
    &.mosaic-overlay-no-pointer {
      pointer-events: none;
    }

    .mosaic-cover & {
      background-color: rgba(0,0,0,.8);
    }

    .mosaic-bar-bottom &, .mosaic-bar-top & {
      width: 100%;
      height: auto;
      top: auto;
      bottom: -100%;
    }

    .mosaic-bar-top & {
      bottom: auto;
      top: -100%;
    }

    .mosaic-circle & {
      top: 50%;
      left: 50%;
      width: 140px;
      height: 140px;
      margin: -70px 0 0 -70px;
      box-sizing: border-box;
      color: #FFF;
      border-radius: 50%;
      line-height: 1;
      opacity: 0;
      @media (max-width: $screen-sm-max) {
        opacity: 1;
      }
      @media (max-width: $screen-xs-max) {
        width: 80px;
        height: 80px;
        opacity: 1;
        margin: -40px 0 0 -40px;
      }
      & > .glyphicon, & > .fa {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -24px 0 0 -24px;
        @media (max-width: $screen-xs-max) {
          margin: -16px 0 0 -16px;
        }
      }
    }
  }
}
