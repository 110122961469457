﻿.article-info {
    
    /*font-size: 16px;*/
    color: $color-secondary-dark;
    font-size: 14px;
    font-family: $montreal-medium;

    .sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0,0,0,0);
        border: 0;
    }
}


.theme-tags-list {
    h2 {
        font-family: $montreal-bold;
        font-size: fluid(13px, 16px);
        line-height: 1.3;
    }

    h3 {
        font-family: $montreal-bold;
        margin-bottom: 6px;
        color: $color-secondary-dark;
    }

    .tag-list {
        margin-bottom: 5px;

        a {
            display: block;
            margin-bottom: 6px;
        }
    }
}
