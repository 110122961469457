@import "_variables.scss";
//@import "../../../bootstrap-sass/assets/stylesheets/bootstrap/mixins/";

@mixin borderbloc($color, $borderwidth) {
    padding-top: $boxspacing;
    padding-bottom: $boxspacing;
    border-top: $borderwidth solid $color;
    border-bottom: $borderwidth solid $color;
}

@function get-vw($target) {
    $vw-context: (1440 * 0.01) * 1px;
    @return ($target / $vw-context) * 1vw;
}


@function fluid($smallValue, $largeValue) {
    // font-size: fluid(15px, 26px);

    $small: 375px;
    $large: 1440px;

    $m: ($largeValue - $smallValue) / ($large - $small);
    $b: $smallValue - $m * $small;
    
    $sign: "+";

    @if ($b < 0) {
        $sign: "-";
        $b: abs($b);
    }

    @return calc(#{$m*100}vw #{$sign} #{$b});
}