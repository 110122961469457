.icon {
  &-md {
    font-size: $icon-font-size-md;
    @media all and (max-width: $screen-xs-max) {
      font-size: ceil($icon-font-size-md*.75);
    }
  }
  &-lg {
    font-size: $icon-font-size-lg;
    @media all and (max-width: $screen-xs-max) {
      font-size: ceil($icon-font-size-lg*.65);
    }
  }
  &-xl {
    font-size: $icon-font-size-xl;
    @media all and (max-width: $screen-xs-max) {
      font-size: ceil($icon-font-size-xl*.65);
    }
  }
  &-xxl {
    font-size: $icon-font-size-xxl;
    @media all and (max-width: $screen-xs-max) {
      font-size: ceil($icon-font-size-xxl*.65);
    }
  }
  &-centered {
    display: block;
    text-align: center;
  }
  &-spacing {
    padding:15px 0;
  }
}
