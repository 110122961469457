
@import "../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/media";

.media {
  &-align {
    &-middle {
      .media-body, .media-left, .media-right {
        vertical-align: middle;
      }
    }
  }

  &-left {
    img {
      vertical-align: top;
    }
  }

  &-body, &-left, &-right {
    vertical-align: top;
  }
}
