@import "../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";

body {
    &.sc-editor-enabled {
        margin-top: 185px;
    }

    @mixin headerPadding($default, $sm, $xs) {
        min-height: $default;

        @media all and (max-width: $screen-sm-max) {
            min-height: $sm;
        }

        @media all and (max-width: $screen-xs-max) {
            min-height: $xs;
        }

        & + main {
            padding-top: $default;

            @media all and (max-width: $screen-sm-max) {
                padding-top: $sm;
            }

            @media all and (max-width: $screen-xs-max) {
                padding-top: $xs;
            }
        }
    }

    header {
        &.header-fixed {
            @include headerPadding(82px, 78px, 70px);

            &.header-logo-center {
                @include headerPadding(125px, 115px, 111px);
            }

            &.header-has-top {
                @include headerPadding(118px, 78px, 70px);

                &.header-logo-center {
                    @include headerPadding(165px, 115px, 111px);
                }
            }

            &.bg-transparent {
                &.header-has-top {
                    & + main .jumbotron > *:nth-child(1) {
                        padding-top: 124px;
                    }
                }

                & + main {
                    padding-top: 0;

                    & .jumbotron > *:nth-child(1) {
                        padding-top: 82px;
                    }
                }
            }
        }

        &.bg-transparent {
            background-color: rgba(0,0,0,.15) !important;
            filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#15000000', endColorstr='#15000000');
        }
    }

    &.home {
        .page-header {
            margin: 0;
            padding: 0;
            border: 0;
        }
    }

    &.header-static {
        #main-container {
            padding-top: 0;
        }
    }

    #main-container {
        background-color: $main-bg;

        main {
            background-color: $main-bg;
            /* Below css added to fix Page Title h1 tag getting hidden behind top Nav on mobile devices */
            @media (max-width: $screen-md-max) {
                /*top: 60px;*/ /*734 - Section - checkbox not working */
                margin-top:60px;
                position: relative;
            }
        }
    }

    &.pagelayout-fixed {
        #main-container {
            margin-left: auto;
            margin-right: auto;
            max-width: $container-lg;

            .navbar {
                &.navbar-fixed-top {
                    margin-left: auto;
                    margin-right: auto;
                    max-width: $container-lg;
                }
            }
        }
    }
}
