﻿.highlight {
    width: 100%;
    display: block;
    text-align: center;
    margin-bottom: 41px;
    padding: 0 percentage(51/345);

    &.right-alignment {
        text-align: right;
    }

    &.left-alignment {
        text-align: left;
    }

    &:last-child {
        margin-bottom: 0;
    }

    .thumbnail {
        width: 55px;
        display: table;
        margin: 0 auto;

        a {
            display: table-cell;
            vertical-align: middle;
            text-align: center;
        }

        img {
            height: auto;
            max-width: 100%;
        }
    }

    a:hover {
        text-decoration: none;
    }

    h2 {
        color: $color-primary;
        font-family: $montreal-medium;
        text-transform: uppercase;
        font-size: 16px;
        margin: 18px 0 10px 0;
    }

    p {
        color: $text-color;
        font-family: $montreal-regular;
        font-size: 15px;
    }
}

@media (min-width: $screen-md-min) {
    .highlight {
        display: inline-block;
        margin-bottom: 14px;
        padding: 0 percentage(48/1300);
        vertical-align: top;

        .thumbnail {
            width: 66px;
        }

        h2 {
            color: $color-primary;
            font-family: $montreal-medium;
            text-transform: uppercase;
            font-size: 20px;
            margin: 18px 0 10px 0;
        }

        p {
            font-size: 16px;
        }
    }
}
