﻿/*Cookie Consent module CSS override*/
.header-static {
    .cc_banner.cc_container {
        background-color: $color-secondary-dark;
        font-size: $fonts-size-small;
        padding-left: 4vw;

        a {
            color: $color-secondary-lighter;
        }

        .cc_btn {
            border-radius: 0;
            border: 0;
            padding: 15px 17px;
            letter-spacing: 1px;
            width: 100%;
            font-weight: normal;
            font-family: $montreal-medium;
            margin-bottom: 15px;
            margin-right: 15px;
            background-color: $color-secondary;
            color: $color-white;
            font-size: 14px;

            &:focus,
            &:hover {
                background-color: $color-secondary-on;
            }

            @media (min-width: $screen-md-min) {
                padding: 12px 17px;
                width: auto;
            }
        }

        p.cc_message {
            font-family: $font-family-base;
            line-height: 1.625em !important;
        }

        a.cc_more_info {
            &:hover,
            &:active {
                text-decoration: underline;
            }
        }
    }
}

/* Onetrust Cookie styles overrite */
#onetrust-banner-sdk #onetrust-policy-text,
#onetrust-banner-sdk .ot-dpd-desc,
#onetrust-banner-sdk .ot-b-addl-desc,
#onetrust-banner-sdk #onetrust-policy-text,
#onetrust-banner-sdk,
#onetrust-pc-sdk,
#ot-sdk-cookie-policy,
#ot-sync-ntfy,
#onetrust-banner-sdk #onetrust-pc-btn-handler {
    font-size: $font-size-14mn-16mx;
}



#main-container ~ #onetrust-consent-sdk button#onetrust-accept-btn-handler,
#main-container ~ #onetrust-consent-sdk button.save-preference-btn-handler,
#main-container ~ #onetrust-consent-sdk button#accept-recommended-btn-handler {
    font-family: $montreal-medium !important;
    border-color: $color-primary !important;
    color: $color-white !important;
    font-size: $btn-fonts-size !important;
    background: linear-gradient(to bottom, $color-primary 50%, $color-dark-primary 50%);
    background-size: 100% 200%;
    background-position: 0 0;
    transition: background-position 0.2s ease-in-out;
    letter-spacing: 0 !important;
    font-weight: initial;
    padding: 13px 18px !important;

    &:hover,
    &:active {
        color: $color-white !important;
        border-color: $color-dark-primary !important;
        background-position: 0 100%;
    }
}

#main-container ~ #onetrust-consent-sdk h2#ot-pc-title,
#main-container ~ #onetrust-consent-sdk h3#ot-category-title {
    font-family: $montreal-bold !important;
}

#main-container ~ #onetrust-consent-sdk #ot-pc-desc,
#main-container ~ #onetrust-consent-sdk #onetrust-policy-text,
#main-container ~ #onetrust-consent-sdk p.ot-category-desc {
    font-family: $montreal-regular !important;
    color: $color-secondary-dark !important;
    font-size: 14px !important;
}

#main-container ~ #onetrust-consent-sdk p.ot-category-desc {
    font-size: .812em !important;
}

#main-container ~ #onetrust-consent-sdk button#onetrust-reject-all-handler {
    font-family: $montreal-medium !important;
    color: $color-secondary-dark !important;
    font-size: $btn-fonts-size !important;
    padding: 13px 18px !important;
    border: 0 none !important;
    background: linear-gradient(to bottom, $bg-btn-grey 50%, $bg-btn-dark-grey 50%);
    background-size: 100% 200%;
    background-position: 0 0;
    transition: background-position 0.2s ease-in-out;
    letter-spacing: 0 !important;

    &:hover,
    &:active {
        color: $color-secondary-dark !important;
        background-position: 0 100%;
    }
}

#main-container ~ #onetrust-consent-sdk #onetrust-pc-btn-handler.cookie-setting-link,
#main-container ~ #onetrust-consent-sdk a.privacy-notice-link,
#main-container ~ #onetrust-consent-sdk button.category-host-list-handler {
    font-family: $montreal-regular;
    color: $color-secondary-dark;
    font-size: 14px;
    text-decoration: underline;
    text-underline-offset: 3px;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    letter-spacing: 0 !important;

    &:hover,
    &:active {
        color: $color-primary;
        text-decoration: underline;
    }
}

#main-container ~ #onetrust-consent-sdk button.category-host-list-handler {
    font-size: .812em;
    border-bottom: 1px solid $color-secondary-dark;
    text-decoration: none;
    border-radius: 0;

    &:hover,
    &:active {
        text-decoration: none;
        border-bottom: 1px solid $color-primary;
    }
}

#main-container ~ #onetrust-consent-sdk .ot-always-active {
    color: $color-primary !important;
}

@media only screen and (max-width: 425px) {
    #onetrust-banner-sdk #onetrust-policy {
        margin-left: 0;
        margin-right: 2.5em;
    }

    #onetrust-consent-sdk #onetrust-accept-btn-handler {
        width: 100%;
    }
}

@media only screen and (min-width: 890px) {
    #onetrust-banner-sdk.ot-buttons-fw:not(.ot-iab-2) #onetrust-button-group {
        margin-top: 0;
    }

    #onetrust-banner-sdk.ot-buttons-fw:not(.ot-iab-2) #onetrust-button-group button {
        width: auto;
        margin-top: 0;
        margin-bottom: 3px;
    }

    #onetrust-banner-sdk.ot-buttons-fw:not(.ot-iab-2) #onetrust-button-group button:last-of-type {
        margin-bottom: 3px;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
    #onetrust-banner-sdk #onetrust-button-group {
        margin-right: 0;
    }
}

#onetrust-banner-sdk p,
#onetrust-pc-sdk p,
#ot-sdk-cookie-policy p {
    margin: 0 0 0.4em 0;
}
