header.page-header.banner-video {
    padding: 0;

    &.mar-bot-banner {
        @media (max-width: $screen-xs-max) {
            margin-bottom: 35px;
        }

        @media (min-width: $screen-sm-min) {
            margin-bottom: 60px;
        }

        @media (min-width: 1366px) {
            margin-bottom: 65px;
        }
    }

    &:before {
        content: none;
    }

    video.video-bg {
        position: relative;
    }

    .teaser-summary {
        position: absolute;
        top: 45px;
        -webkit-transform: none;
        transform: none;
        /*top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        border: 1px solid red;*/
        padding: 0 4vw;
        width: 100%;
        z-index: 199;

        @media (min-width: 768px) {
            /*top: 45px;
            -webkit-transform: none;
            transform: none;*/
            max-width: 90%;
        }

        @media (min-width: $screen-md-min) {
            /*max-width: calc(300px + 30vw);*/
            /*max-width:66.66667%;*/ /* bootstrap 8 columns */
            max-width: calc(70% + 4vw);
            padding: 0 0 0 4vw;
        }

        @media (min-width: $screen-lg-min) {
            max-width: calc(65% + 4vw);
            /*        top: 50%;
            transform: translateY(-50%);*/
        }

        @media (min-width: 1360px) {
            /*top: 50px;*/
            /* top: 50%;
            transform: translateY(-50%);*/
            max-width: calc(60% + 4vw);
        }

        @media (min-width: $screen-view-condition-min) {
            max-width: 910px;
            padding: 0;
            left: 50%;
            /*max-width: $screen-laptop-min;*/
            margin-left: calc(#{$screen-laptop-min}/ -2);
        }
        /*h1 {
            position: relative;
            bottom: auto;
            margin: 0 0 20px 0;
            line-height: 1.3;
            color: $color-white;
        }*/
        h1,
        h2 {
            
            
            font-family: $montreal-bold;
            text-transform: none;
            color: $color-white;
            
            @media (max-width: 413px) {
                font-size: 31px;
                line-height: 40px;
            }

            @media (min-width: 414px) and (max-width: $screen-xs-max) {
                font-size: 36px;
                line-height: 48px;
            }

            @media (min-width: $screen-sm-min) {
                font-size: 40px;
                line-height: 51px;
            }

            @media (min-width: 1350px) {
                font-size: 50px;
                line-height: 64px;
            }

            position: relative;
            bottom: auto;
            margin: 0 0 20px 0;
        }

        div,
        p {
            font-family: $montreal-medium;

            @media (max-width: $screen-sm-max) {
                font-size: 16px;
                line-height: 26px;
            }

            @media (min-width: $screen-md-min) {
                font-size: 17px;
                line-height: 28px;
            }

            @media (min-width: 1350px) {
                font-size: 21px;
                line-height: 34px;
            }

            color: $color-white;
        }

        p {
            margin-bottom: 25px;
        }

        .tagline {
            color: $color-white;
            font-size: 14px !important;
            font-family: $montreal-regular !important;
            margin-bottom: 20px;
            text-transform: uppercase;
            line-height: normal;
        }

        .btn {
            margin-top: 0px;
            /*min-width: 200px;
            min-height: 50px;*/
            @media (min-width: $screen-sm-min) {
                padding: 15px 20px;
                width: auto;
                margin-right: 20px;
            }
        }
    }

    .teaser-summary.light-theme {
        /*h1 {
            color: $color-primary;
        }*/
        h1,
        h2 {
            
            color: $color-secondary-dark;
        }

        div, p {
            color: $color-secondary-dark;
        }

        .tagline {
            color: $color-secondary-dark;
        }

        a:not(.btn) {
            color: $color-secondary-dark;
            text-decoration: underline;

            &:hover, &:active {
                color: $color-primary;
                text-decoration: underline;
            }
        }
    }

    .teaser-summary.dark-theme {

        .btn.btn-tertiary {
            color: $color-white;
            font-size: $btn-fonts-size;
            background: linear-gradient(to bottom, $bg-btn-grey-on-dark-bg 50%, $bg-btn-dark-grey-on-dark-bg 50%);
            background-size: 100% 200%; 
            background-position: 0 0; 
            transition: background-position 0.2s ease-in-out; 

            &:hover,
            &:active {
                color: $color-white;
                background-position: 0 100%; 
            }
        }

        h1, h2, div, p, .tagline {
            color: $color-white;
        }

        a.btn {
            color: $color-white;

            &.btn-tertiary {
                color: $color-white;
            }
        }

        a:not(.btn) {
            color: $color-white;
            text-decoration: underline;

            &:hover, &:active {
                color: $color-white;
                text-decoration: underline;
            }
        }
    }
}

header.page-header.bg-media.without-overlay:after {
    background-color: transparent !important;
}

header.page-header.bg-media.overlay-transparent:after {
    background-color: transparent !important;
}

header.page-header.bg-media.overlay:after {
    background-color: $color-secondary-dark;
    opacity: 0.1;
}

header.page-header.banner-video.cntVmAlign {
    .teaser-summary {
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}

header.page-header.banner-video.cntTopAlign {
    .teaser-summary {
        @media (max-width: 767px) {
            top: 45px;
            -webkit-transform: none;
            transform: none;
        }

        @media (min-width: 768px) and (max-width: 1199px) {
            top: 55px;
            -webkit-transform: none;
            transform: none;
        }

        @media (min-width: 1200px) {
            top: 65px;
            -webkit-transform: none;
            transform: none;
        }
    }
}

header.page-header.banner-video.Left-Top-Align {
    .teaser-summary {
        @media (max-width: 767px) {
            top: 45px;
            -webkit-transform: none;
            transform: none;
        }

        @media (min-width: 768px) and (max-width: 1199px) {
            top: 55px;
            -webkit-transform: none;
            transform: none;
        }

        @media (min-width: 1200px) {
            top: 65px;
            -webkit-transform: none;
            transform: none;
        }
    }
}

header.page-header.banner-video.Left-Ctr-Align {
    .teaser-summary {
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}

header.page-header.banner-video.Left-Btm-Align {
    .teaser-summary {
        top: auto;
        bottom: 50px;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;

        @media (max-width: 1199px) {
            bottom: 44px;
        }
    }
}


header.page-header.banner-video.Ctr-Top-Align {
    .teaser-summary {
        @media (max-width: 767px) {
            top: 45px;
            -webkit-transform: none;
            transform: none;
            left: 50%;
            transform: translateX(-50%);
            margin-left: auto;
        }

        @media (min-width: 768px) and (max-width: 1199px) {
            top: 55px;
            -webkit-transform: none;
            transform: none;
            left: 50%;
            transform: translateX(-50%);
            margin-left: auto;
        }

        @media (min-width: 1200px) {
            top: 65px;
            -webkit-transform: none;
            transform: none;
            left: 50%;
            transform: translateX(-50%);
            margin-left: auto;
        }
    }

    .teaser-summary * {
        text-align: center;
    }
}

header.page-header.banner-video.Ctr-Ctr-Align {
    .teaser-summary {
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        margin-left: auto;
    }

    .teaser-summary * {
        text-align: center;
    }
}


header.page-header.banner-video.Ctr-Btm-Align {
    .teaser-summary {
        top: auto;
        bottom: 50px;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        left: 50%;
        transform: translateX(-50%);
        margin-left: auto;

        @media (max-width: 1199px) {
            bottom: 44px;
        }
    }

    .teaser-summary * {
        text-align: center;
    }
}


.vertical-space-adjust {
    @media (max-width: 1199px) {
        margin-top: 30px !important;
        transition: all 0.5s ease;
    }

    @media (min-width: 1200px) {
        margin-top: 80px !important;
        transition: all 0.5s ease;
    }
}

#inner-nav-desktop {
    position: fixed;
    top: 85px;
    width: 100%;
    -webkit-transition: all .8s ease;
    -o-transition: all .8s ease;
    transition: all .8s ease;
}

#inner-nav-mobile {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    margin: 0;
    z-index: 2000;
    -webkit-transition: all .8s ease;
    -o-transition: all .8s ease;
    transition: all .8s ease;
}
