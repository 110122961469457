

@import "../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins";



@mixin backgroundHoverLighten($color, $percent, $important) {
    background-color: $color;
    @if $important == true {
      background-color: $color;
    }
    &:hover {
        background-color: lighten($color, $percent);
    }
}
