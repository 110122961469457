@import "../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/navbar";
.navbar {
    z-index: 16;
    text-align: center;
    margin-bottom: 0;
    padding: 13px 0;
    .header-fixed & {
        margin-bottom: 0;
    }
    @media all and (max-width: $screen-xs-max) {
        min-height: 30px;
        padding: 0 0 4px 0;
    }
    &-toggle {
        margin-top: 25px;
        margin-bottom: 25px;
        &:hover {
            background-color: $navbar-default-bg;
        }
        .icon-bar {
            background-color: $brand-text-color;
        }
        &-search {
            .is-searching & {
                margin-right: -15px;
                span.glyphicon {
                    &:before {
                        content: '\e014';
                    }
                }
            }
        }
        @media all and (max-width: $screen-xs-max) {
            float: left;
            margin: 15px 5px 15px 0;
        }
    }
    &-collapse {
        @media all and (max-width: $screen-xs-max) {
            float: left;
            width: 100%;
            //max-height: 400px;
        }
    }
    &-header {
        z-index: 1;
        position: relative;
        .is-searching & {
            @media all and (max-width: $screen-xs-max) {
                z-index: -1;
            }
        }
        @media all and (max-width: $screen-xs-max) {
            float: left;
            margin-left: 0 !important;
        }
    }
    .navbar-form {
        border: none;
        box-shadow: none;
    }
    &>.container {
        .navbar-brand {
            margin-left: 0;
        }
    }
    &-form {
        margin-bottom: 0;
        #mainNavbar.is-searching & {
            transition-delay: 0.2s;
            max-width: 200px;
            .lte-ie8 & {
                display: block;
                input {
                    padding-left: 50px;
                }
            }
            input {
                @media all and (max-width: $screen-xs-max) {
                    width: 100%;
                    padding: 26px 70px 26px 15px;
                }
            }
            @media all and (max-width: $screen-xs-max) {
                max-width: 2000px;
                left: 0;
                right: 0;
                margin-top: -15px;
            }
        }
        #mainNavbar & {
            margin: 0;
            max-width: 0;
            overflow: hidden;
            padding: 0;
            transition-delay: 0s;
            .lte-ie8 & {
                display: none;
            }
            input {
                padding: 15px;
                margin: 0px 0;
                color: $navbar-default-link-color;
                background-color: $color-secondary-light;
                border: none;
                .bg-primary &,
                .bg-secondary & {
                    color: $color-primary;
                    background-color: $navbar-default-link-color;
                }
                .bg-white & {
                    color: $color-black;
                    background-color: rgba(0, 0, 0, .1);
                }
                &:focus {
                    outline-width: 0;
                }
            }
        }
    }
    &-collapse.collapse {
        @media (min-width: $screen-xs-max) {
            display: inline-block !important;
        }
    }
    &-activity {
        z-index: 1;
        float: left;
        @media all and (max-width: $screen-xs-max) {
            float: right;
            margin: 4px 0;
            margin-right: 0px;
        }
        .navbar-nav {
            @media all and (max-width: $screen-xs-max) {
                margin: 7.5px 15px;
            }
            .dropdown-menu {
                @media all and (max-width: $screen-xs-max) {
                    min-width: 250px;
                }
            }
            .open {
                .dropdown-menu {
                    position: absolute;
                    top: 100%;
                    left: auto;
                    right: 0;
                    background-color: $navbar-dropdown-bg;
                    li {
                        float: none;
                    }
                }
            }
            li {
                @media all and (max-width: $screen-xs-max) {
                    //display: inline-block;
                    float: left;
                }
                max-width: 400px;
                &.navbar-activity-search {
                    @media all and (max-width: $screen-xs-max) {
                        position: inherit;
                    }
                }
                .navbar-form {
                    @media all and (max-width: $screen-xs-max) {
                        position: absolute;
                        right: 0;
                    }
                    input {
                        margin: 2px 0;
                    }
                }
            }
            .is-searching & {
                li {
                    max-width: 0;
                    transform: translateY(100%);
                    opacity: 0;
                    &.navbar-activity-search {
                        max-width: 400px;
                        transform: none;
                        opacity: 1;
                    }
                }
                .lte-ie8 & {
                    .navbar-toggle-search {
                        position: absolute;
                        top: 0;
                        right: 0;
                        float: none;
                        background-color: $color-secondary-dark;
                    }
                }
            }
        }
        &-search {
            form,
            a {
                display: inline-block;
                float: left;
            }
        }
    }
    &-right {
        float: right;
        @media (max-width: $screen-sm-max) {
            float: none;
        }
    }
    &-left {
        z-index: 1;
        float: left;
        @media (max-width: $screen-xs-max) {
            float: none;
        }
        .is-searching & {
            z-index: 0;
        }
    }
    &-left+&-center {
        display: inline-block;
        text-align: center;
        height: 100%;
        z-index: 0;
        @media all and (max-width: $screen-xs-max) {
            display: block;
            position: relative;
        }
    }
    &-left+&-right {
        .is-searching & {
            .navbar-toggle-search {
                margin-top: 10px;
                padding-bottom: 0px;
            }
        }
        .navbar-form {
            margin-top: 0px !important;
            input {
                @media (max-width: $screen-xs-max) {
                    padding: 20px 70px 20px 15px !important;
                    box-sizing: border-box;
                    //margin-top: -30px !important;
                }
            }
        }
    }
    &-center {
        display: block;
        //position: relative;
        text-align: center;
        @media all and (max-width: $screen-xs-max) {
            //text-align: left;
        }
        .navbar-header {
            display: inline-block;
            float: none;
            .navbar-brand {
                @media all and (max-width: $screen-xs-max) {
                    margin-bottom: 0;
                }
            }
        }
    }
    &-right {
        &>.navbar-collapse {
            float: left;
        }
    }
    &-brand {
        padding: 0;
        height: auto;
        &:hover {
            .bg-white & {
                color: $color-black;
            }
        }
        &.has-tagline {
            margin: 0;
            //margin-top: -15px;
            @media all and (max-width: $screen-sm-max) {
                margin-top: 0;
            }
        }
        @media all and (min-width: $screen-md-min) {
            margin-right: 0px;
        }
        @media all and (max-width: $screen-sm-max) {
            margin-right: 0;
            margin-top: 10px;
        }
        @media all and (max-width: $screen-xs-max) {
            margin: 16px 0 10px 0;
            &.has-tagline {
                margin: 10px 0 0 0;
            }
        }
        .logo {
            text-align: left;
            svg,
            img {
                display: block;
            }
            .tagline {
                margin-top: 0px;
                text-align: left;
                .bg-transparent & {
                    color: $navbar-bg;
                }
            }
        }
        &:hover {
            img,
            svg {
                path {
                    fill: $navbar-default-brand-hover-color !important;
                    .bg-white & {
                        fill: #000 !important;
                    }
                }
            }
        }
        img,
        svg {
            display: inline-block;
            .bg-transparent & {
                path {
                    fill: $navbar-bg;
                }
            }
        }
    }
    &-nav {
        float: none;
        .lte-ie8 & {
            float: none !important;
            display: inline-block;
        }
        &>li {
            &>a {
                border: none;
                .bg-white & {
                    color: $color-black !important;
                }
            }
        }
        li {
            &.open {
                .dropdown-menu {
                    @media all and (max-width: $screen-xs-max) {
                        color: lighten($color-secondary, 20%);
                        background-color: rgba(0, 0, 0, .2);
                        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#20000000, endColorstr=#20000000);
                        .bg-primary &,
                        .bg-secondary &,
                        .bg-dark &,
                        .bg-media & {
                            color: $navbar-default-link-color;
                        }
                        .bg-white & {
                            color: $color-black;
                        }
                    }
                    .dropdown-header,
                    a {
                        padding-left: 0px;
                        &.btn {
                            padding-left: 12px;
                        }
                    }
                }
                .mega-dropdown-menu {
                    top: 50px;
                }
            }
            .dropdown-menu {
                li {
                    &>a {
                        @media all and (max-width: $screen-xs-max) {
                            color: $navbar-default-link-color;
                        }
                    }
                }
            }
        }
    }
    &-default {
        .navbar-collapse {
            .navbar-nav {
                .open {
                    .dropdown-menu {
                        &>li {
                            &>a {
                                @media (max-width: $screen-xs-max) {
                                    color: $navbar-default-link-color;
                                    &:hover {
                                        color: $navbar-default-link-hover-color;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .navbar-nav {
            &>.open {
                &>a,
                &>a:focus,
                &>a:hover {
                    background-color: rgba(0, 0, 0, 0.4);
                    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr='#40000000', endColorstr='#40000000');
                    color: $navbar-default-link-hover-color;
                }
                .dropdown-menu {
                    &>li {
                        &.disabled {
                            a {
                                color: $navbar-default-disabled-color;
                                &:hover {
                                    color: $navbar-default-disabled-color;
                                }
                            }
                        }
                        &.active {
                            a {
                                color: $brand-primary;
                                background-color: $navbar-default-bg;
                            }
                        }
                        &>a {
                            color: $text-color;
                            &:hover {
                                color: $text-color;
                            }
                            @media (max-width: $screen-sm-max) {
                                color: $text-color;
                            }
                            &.active {
                                color: $navbar-default-link-color;
                            }
                        }
                        &.divider {
                            background-color: $text-color;
                        }
                    }
                }
            }
        }
        .navbar-toggle {
            &:hover,
            &:focus {
                background-color: $navbar-default-bg;
            }
            .icon-bar {
                background-color: $navbar-toggle-icon-bar-color;
                .bg-white & {
                    background-color: $color-black;
                }
            }
        }
    }
    &.navbar-default {
        border-color: $navbar-default-bg;
    }
}
