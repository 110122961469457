#content,
.main-footer {
    opacity: 1;
    transition: opacity 0.5s ease;
}

.sideNavIsOpen {
    #content,
    .main-footer {
        opacity: 0;
    }
}

.main-header {
    z-index: 1040;
    background: #fff;

    .pagemode-edit & {
        z-index: 2;
    }

    &.affix {
        position: relative !important;
    }

    .right-container {
        display: flex;
        flex-wrap: wrap;
    }

    .big-padding {
        padding: 0;
    }
}

.desktop-header {
    height: calc(100vh - 60px);
    width: 100vw;
    background-color: $color-white;
    overflow: hidden;
    
    position: fixed;
    left: -105%;
    top: 60px;
    transition: left 0.5s ease;
    -webkit-backface-visibility: hidden;

    .sideNavIsOpen & {
        overflow: auto;
        left: 0;
    }

    &.sub-nav-opened {
        overflow: hidden;
    }

    .main-search,
    .logo {
        display: none;
    }

    .mh__nav-wrapper {
        position: relative;
        transition: left 1s ease;
        left: 0;

        @media (min-width: $screen-lg-min) {
            position: static;
            transition: none;
        }
    }

    &.sub-nav-opened {
        .mh__nav-wrapper {
            transform: translateX(-7px);
            left: -100%;
        }
    }
}

/*6381 - There should be a gray overlay above page when search bar is activated - start */
header.show-search:after {
    background: $color-secondary-dark;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: -2;
    content: "";
    opacity: 0.7;
    display: block;
    top: 0;
    transition: top 0.5s ease;
}
/*6381 - There should be a gray overlay above page when search bar is activated - end */

@media (min-width: $screen-lg-min) {
    .desktop-header {
        height: auto;
        position: static;
        overflow: visible;
        width: auto;

        .language-link,
        .main-search,
        .logo {
            display: block;
        }

        .main-search {
            /*margin-top: 32px;*/
            margin-top: 20px;

            .affix & {
                margin-top: 20px;
            }
        }

       
        .language-link {
            
            margin-top: 25px;

            .affix & {
                margin-top: 25px;
            }
        }

        .logo, svg.a-wsp-logo.hidden-xs.hidden-sm.hidden-md {
            height: 40px;
            /*height: 98px;*/
            /*height: 70px;*/
        }



        .st0 {
            clip-path: none;
        }
    }

    .main-header {
        .big-padding {
            /*padding: 0 3.472222vw 0 4.861111vw;*/ /* 6315 */
            background-color: $color-white;
            border-bottom: 1px solid $color-secondary-light;
            padding-left: 4vw;
            padding-right: 4vw;
            /*@media (min-width: $screen-view-condition) {
                 border-bottom:0 none;
                 }*/
        }

        .right-container {
            display: block;
            padding: 0;
        }

        &.affix-top.scroll-up .container-fluid {
            top: 0;
            position: fixed;
            width: 100%;
        }

        &.affix.scroll-up .container-fluid {
            top: 0;

            @media (min-width: $screen-view-condition) {
                /*left: 50%;
                margin-left: calc(#{$screen-laptop-min}/-2) !important;*/
                /*display: none;*/ /* top nav hidden on scroll up for 1500px and higher */
            }
        }

        &.affix.scroll-up .secondary-nav:before {
            /*display: none;*/
        }

        &.affix-top {
            height: 85px;

            .logo {
                height: 40px;

                svg {
                    height: 40px;
                }
            }
        }

        &.affix {
            /*height: 99px;*/
            height: 85px;

            .logo {
                height: 40px;

                svg {
                    height: 40px;
                }
            }

            .container-fluid {
                width: 100%;
                position: fixed;
                top: -100%;
                transition: top 0.5s ease;

                .pagemode-edit & {
                    position: static;
                }
            }

            .pull-right {
                padding: 21.5px 0 0;
            }

            .right-container {
                padding: 0;
            }
        }
    }
}

@media (min-width: $screen-view-condition) {
    /*6381 - There should be a gray overlay above page when search bar is activated - start */
    .show-search .desktop-header:before {
        background: #fff;
        position: absolute;
        width: 100%;
        height: 100%;
        content: "";
        z-index: -1;
        left: 0;
    }
    /*6381 - There should be a gray overlay above page when search bar is activated - end */

    .desktop-header {
        .language-link {
            /*margin-top: 32px;*/
            
            /*margin-top: 19px;*/
            /*margin-top: 24px;*/

            .affix & {
                /* margin-top: 22px;*/
                /*margin-top: 19px;*/
                margin-top: 24px;
            }
        }

        .main-search {
            /*margin-top: 32px;*/
            margin-top: 24px;
            

            .affix & {
                margin-top: 24px;
            }
        }
    }

    .main-header {
        /*&.affix.scroll-up .container-fluid:before {
            background: #fff;
            height: 100%;
            max-height: 80px;
            position: fixed;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
        }*/
        .big-padding:before {
            border-bottom: 1px solid $color-secondary-light;
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
        }

        &.affix.scroll-up .big-padding:before, &.affix-top.scroll-up .big-padding:before {
            border-bottom: 0 none;
        }

        &.affix.scroll-up .big-padding, &.affix-top.scroll-up .big-padding {
            padding-left: 4vw !important;
            padding-right: 4vw !important;
            max-width: 100%;
            /*border-bottom: 0 none;*/
            .desktop-header {
                width: $screen-laptop-min;
                margin: 0 auto;
            }
        }
    }
    /*.desktop-header *:not(svg, i,p,h3) {
        font-size: 14px !important;
    }*/
}
//Banner and Page title

.page-header + .section {
    .page-title {
        margin-bottom: 20px;

        + article {
            margin-top: 0;
        }
    }
}

.section + .header {
    + .section {
        article:first-of-type {
            margin-top: 0;
        }
    }
}

.header {
    position: relative;
    /*min-height: 70px;*/
    div.header-text {
        padding-left: 4vw;

        @media (min-width: $screen-view-condition-min) {
            padding-left: 0;
            max-width: $screen-laptop-min;
            margin-left: auto;
            margin-right: auto;
        }

        h1 {
            margin-top: 20px;
            position: relative;
            /*text-transform: uppercase;
            color: $color-primary;*/
            @extend .new__typo__01; //Who we are, Services, Project (one), Sector (one), Job Opportunities, WSP Logo, 404 page
        }

        @media (max-width: $screen-lg-min) {
            bottom: calc(3em - 70px);
        }
    }
}

header {
    &.page-header {
        position: relative;
        overflow: hidden;
        padding-left: 4vw;
        padding-right: 4vw;
        width: 100%;
        max-width: 100%;
        margin: 0;

        @media (min-width: $screen-view-condition-min) {
            padding-right: 0;
            padding-left: 0;
        }

        h1 {
            margin-bottom: 0;
            /*text-transform: uppercase;
            color: $color-primary;*/
            @extend .new__typo__01; //Sectors
        }

        .page-title {
            margin-top: 90px;
            /*text-transform: uppercase;
            color: $color-primary;*/
            @extend .new__typo__01; //Global Team, Cookie Policy
            font-family: $montreal-bold;
        }

        &.no-image {
            padding-top: 3.5%;
        }

        &.bg-media {
            height: 0;
            background-size: cover;
            padding-top: 29%; /*30%, 31.6%*/
            background-position: center center;

            &:before {
                display: none;
            }

            &:after {
                content: "";
                
                background-color: transparent;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
                z-index: 1;
            }
            /*@media (min-width: $screen-md-min) {
                background-size: 100% auto;
                background-position: center top;
            }*/
            h1 {
                bottom: calc((24px + 1.25vw) * -1);
                position: absolute;
                top: auto;
                margin-bottom: 0;
                float: none;
                width: auto;
            }
        }

        &.banner-video {
            video::-webkit-media-controls-start-playback-button {
                display: none;
            }

            video {
                height: 100%;
                top: 50%;
                left: 50%;
                position: absolute;
                transform: translate(-50%, -50%);

                @media (min-width: $screen-md-min) {
                    height: auto;
                    width: 100%;
                }
            }
        }

        .col-md-9 {
            @media (min-width: $screen-md-min) {
                padding-left: 0;
            }
        }

        &.pad-all-0 {
            padding: 0;
        }
    }
}

.page-summary {
    color: $text-color; /* 6209 - HTML Headings and general css */
    @extend .new__typo__25; /* 6209 - HTML Headings and general css */
    
    /*@extend .new__typo__11;*/ //Who we are, Our story, Our offices, Global Team, News, What we do, Project (one), Careers, Sector (one), Contact us, Cookie policy, 404 Page
    p {
        color: $text-color; /* 6209 - HTML Headings and general css */
        @extend .new__typo__25; /* 6209 - HTML Headings and general css */
        
        /*@extend .new__typo__11;*/ //Who we are, Our story, Our offices, Global Team, News, What we do, Project (one), Careers, Sector (one), Job Opportunities
    }

    a {
        
        color: $color-secondary-on; /* 6209 - HTML Headings and general css */
        text-decoration: underline;
    }
}

-page-title {
    
    
    @extend .new__typo__01; //What we stand for, Our story, Our offices, News, What we do, Careers, Contact us
}

/*.main-header-bot-bdr {
    display: none;

    @media (min-width: $screen-view-condition-min) {
        display: block;
        min-height: 100px;
        width: 100%;
        position: absolute;
        z-index: -1;
        border-bottom: 1px solid $color-secondary-light;
        margin-top:-1px;
    }

    @media (min-width: $screen-4kview-condition-min) {
        min-height: 120px;
    }
}*/
.mar-tp-0 {
    margin-top: 0 !important;
}

.mar-tp-5 {
    margin-top: 5px !important;
}

.mar-tp-10 {
    margin-top: 10px;
}

.mar-tp-15 {
    margin-top: 15px !important;
}

.mar-tp-25 {
    margin-top: 25px;
}

.mar-btm-25 {
    margin-bottom: 25px;
}

.mar-btm-15 {
    margin-bottom: 15px;
}

.mar-btm-10 {
    margin-bottom: 10px;
}

.m-b-3 {
    @media (max-width: $screen-sm-max) {
        margin-bottom: 40px !important;
    }
}

.global-box-sum-txt {
    @media (min-width: 1200px) and (max-width: 1499px) {
        padding-top:5px;
        font-size: 14px !important;
    }

    @media (min-width: 1500px) {
        font-size: 16px !important;
    }
}
/* Hero Banner WSP 20, WSP 18  start */

.parallax-block {
    width: 100%;
    height: 100%;
    position: relative;
    opacity: 0;
}

img.img-media-banner {
    position: absolute;
    width: 100%;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
}

img.img-media-banner-mob {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: auto;
    transition: all 0.5s ease;
}

header.page-header.bg-media.desktop {
    padding-top: 0;

    @media (min-width: 1010px) {
        min-height: 425px;
    }

    @media (min-width: 1200px) {
        min-height: 385px;
    }

    @media (min-width: 1250px) {
        min-height: 410px;
    }

    @media (min-width: 1300px) {
        min-height: 425px;
    }

    @media (min-width: 1350px) {
        /*min-height: 500px;*/
        min-height: 445px;
    }

    @media (min-width: 1400px) {
        min-height: 474px;
    }

    @media (min-width: 1500px) {
        min-height: 500px;
    }

    @media (min-width: 1600px) {
        min-height: 534px;
    }

    @media (min-width: 1650px) {
        min-height: 565px;
    }

    @media (min-width: 1700px) {
        min-height: 580px;
    }

    @media (min-width: 1800px) {
        min-height: 615px;
    }

    @media (min-width: 1900px) {
        /*min-height: 703px;*/
        min-height: 659px;
    }
}

header.page-header.bg-media.desktop.compact715 {
    padding-top: 0;

    @media (min-width: 1010px) {
        min-height: 425px;
    }

    @media (min-width: 1200px) {
        min-height: 363px;
    }

    @media (min-width: 1250px) {
        min-height: 383px;
    }

    @media (min-width: 1300px) {
        min-height: 403px;
    }

    @media (min-width: 1350px) {
        min-height: 418px;
    }

    @media (min-width: 1400px) {
        min-height: 433px;
    }

    @media (min-width: 1500px) {
        min-height: 473px;
    }

    @media (min-width: 1600px) {
        min-height: 508px;
    }

    @media (min-width: 1700px) {
        min-height: 543px;
    }

    @media (min-width: 1800px) {
        min-height: 583px;
    }

    @media (min-width: 1900px) {
        min-height: 623px;
    }
}

header.page-header.bg-media.desktop.compact703 {
    padding-top: 0;

    @media (min-width: 1010px) {
        min-height: 425px;
    }

    @media (min-width: 1200px) {
        min-height: 358px;
    }

    @media (min-width: 1250px) {
        min-height: 376px;
    }

    @media (min-width: 1300px) {
        min-height: 395px;
    }

    @media (min-width: 1350px) {
        min-height: 410px;
    }

    @media (min-width: 1360px) {
        min-height: 410px;
    }

    @media (min-width: 1400px) {
        min-height: 425px; /* 432*/
    }

    @media (min-width: 1440px) {
        min-height: 435px;
    }

    @media (min-width: 1500px) {
        min-height: 465px;
    }

    @media (min-width: 1600px) {
        min-height: 496px;
    }

    @media (min-width: 1700px) {
        min-height: 538px;
    }

    @media (min-width: 1800px) {
        min-height: 574px;
    }

    @media (min-width: 1900px) {
        min-height: 612px;
    }

    &.video-file {
        @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
            /*min-height: 360px;*/
            min-height: 443px;
        }
    }
}

header.page-header.bg-media.desktop.compact845 {
    padding-top: 0;

    @media (min-width: 992px) {
        min-height: 540px;
    }

    @media (min-width: 1050px) {
        min-height: 540px;
    }

    @media (min-width: 1200px) {
        min-height: 443px;
    }

    @media (min-width: 1250px) {
        min-height: 465px;
    }

    @media (min-width: 1280px) {
        min-height: 476px;
    }

    @media (min-width: 1300px) {
        min-height: 485px;
    }

    @media (min-width: 1350px) {
        min-height: 510px;
    }

    @media (min-width: 1360px) {
        min-height: 512px;
    }

    @media (min-width: 1400px) {
        min-height: 530px;
    }

    @media (min-width: 1440px) {
        min-height: 548px;
    }

    @media (min-width: 1500px) {
        min-height: 575px;
    }

    @media (min-width: 1600px) {
        min-height: 618px;
    }

    @media (min-width: 1700px) {
        min-height: 660px;
    }

    @media (min-width: 1800px) {
        min-height: 705px;
    }

    @media (min-width: 1900px) {
        min-height: 750px;
    }

    &.video-file {
        @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
            /*min-height: 360px;*/
            min-height: 443px;
        }
    }
}


header.page-header.bg-media.desktop.compact930 {
    padding-top: 0;

    @media (min-width: 992px) {
        min-height: 540px;
    }

    @media (min-width: 1050px) {
        min-height: 540px;
    }

    @media (min-width: 1200px) {
        min-height: 493px;
    }

    @media (min-width: 1250px) {
        min-height: 518px;
    }

    @media (min-width: 1280px) {
        min-height: 535px;
    }

    @media (min-width: 1300px) {
        min-height: 543px;
    }

    @media (min-width: 1350px) {
        min-height: 568px;
    }

    @media (min-width: 1360px) {
        min-height: 573px;
    }

    @media (min-width: 1400px) {
        min-height: 593px;
    }

    @media (min-width: 1440px) {
        min-height: 613px;
    }

    @media (min-width: 1500px) {
        min-height: 638px;
    }

    @media (min-width: 1600px) {
        min-height: 688px;
    }

    @media (min-width: 1700px) {
        min-height: 738px;
    }

    @media (min-width: 1800px) {
        min-height: 788px;
    }

    @media (min-width: 1900px) {
        min-height: 838px;
    }

    &.video-file {
        @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
            /*min-height: 360px;*/
            min-height: 443px;
        }
    }
}


.rebrand-bnr {
    /* Banner size 1366 x 768 */
    header.page-header.bg-media.desktop {
        padding-top: 0;

       
        @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
            min-height: 550px;
        }

        @media (min-width: 1200px) {
            min-height: 600px;
        }

        @media (min-width: 1280px) {
            min-height: 630px;
        }

        @media (min-width: 1300px) {
            min-height: 650px;
        }

        @media (min-width: 1350px) {
            min-height: 670px;
        }

        @media (min-width: 1360px) {
            min-height: 680px;
        }

        @media (min-width: 1400px) {
            min-height: 700px;
        }

        @media (min-width: 1440px) {
            min-height: 720px;
        }

        @media (min-width: 1500px) {
            min-height: 760px;
        }

        &.video-file {
            @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
                min-height: 550px;
            }

            /*@media (min-width: 1200px) {
                min-height: 600px;
            }

            @media (min-width: 1280px) {
                min-height: 630px;
            }

            @media (min-width: 1300px) {
                min-height: 650px;
            }

            @media (min-width: 1350px) {
                min-height: 670px;
            }

            @media (min-width: 1360px) {
                min-height: 680px;
            }

            @media (min-width: 1400px) {
                min-height: 700px;
            }

            @media (min-width: 1440px) {
                min-height: 720px;
            }

            @media (min-width: 1500px) {
                min-height: 760px;
            }*/
        }
    }
}


.transparent-layer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 625px;
    display: block;
    z-index: 100;
    opacity: 0;
    height: 100%;

    &.overlay-black {
        background-color: $color-secondary-dark;

        &:after {
            background-color: unset !important;
        }
    }

    &.overlay-white {
        background-color: $color-white;

        &:after {
            background-color: unset !important;
        }
    }

    &.overlay-none {
        background-color: transparent;

        &:after {
            background-color: unset !important;
        }
    }

    &.opacity-15 {
        opacity: 0.15
    }

    &.opacity-30 {
        opacity: 0.3
    }

    &.opacity-45 {
        opacity: 0.45
    }

    &.opacity-60 {
        opacity: 0.6
    }

    &.opacity-75 {
        opacity: 0.75
    }
}
/* Hero Banner WSP 20, WSP 18  end */
header.page-header .page-title .norm-text,
.header div.header-text h1 .norm-text {
    text-transform: none;
}

.pgBanner-Image {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    transition: all 0.5s ease;
}
/* this prevents mouseover on top navigation while page is loading / not completely loaded */
@media (min-width:1200px) {
    .pageIsLoading {
        pointer-events: none;
    }
}

.no-pointer {
    cursor: default !important
}

#mobile-header{margin-top:-1px;}