﻿.search-results {
    margin-bottom: 35px;

    &.search-results-news {
        margin-bottom: 0;
        display: none;

        @media (min-width: $screen-md-min) {
            display: block;
        }
    }

    &.search-results-news .sr__title-wrapper {
        margin-top: 0;
        margin-bottom: 35px;

        @media (min-width: $screen-md-min) {
            margin-bottom: 0;
        }
    }

    .sr__title-wrapper {
        border-bottom: 1px solid #ccc;
        /*margin-bottom: 65px;
        padding: 25px 0;
        margin-top: 40px;*/

        margin-bottom: 15px;
        padding: 20px 0;
        margin-top: 25px;
    }

    .sr__title {
        text-transform: uppercase;
        color: $color-secondary-dark;
        @extend .new__typo__06; //News
        margin: 0;
        line-height: 1.365;

        span {
            color: $color-primary;
        }
    }

    .sr__title-number {
        color: $color-primary;
        padding-left: 3px;
    }
    /* .coveo-facet-column .CoveoFacet {
        margin-top: 35px !important;
    }*/

    .sr-result-cross {
        padding: 1px 5px;
        background: $color-secondary;
        width: 20px;
        height: 20px;
        color: $color-white !important;
        border: 0;
        cursor: pointer;
        margin-left: 5px;
        margin-right: 5px;
    }

    a#cross {
        position: relative;
        text-decoration: none;
        margin-right: 32px;

        &:hover, &:focus {
            text-decoration: none;
        }

        .sr-result-cross {
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj4KICA8ZyBpZD0iYnRuX2NoZWNrYm94X2Nsb3NlIiBkYXRhLW5hbWU9ImJ0biBjaGVja2JveCBjbG9zZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE1OTIgLTI5MjIuMTQ3KSI+CiAgICA8ZyBpZD0iUmVjdGFuZ2xlXzUyMiIgZGF0YS1uYW1lPSJSZWN0YW5nbGUgNTIyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxNTkyIDI5MjIuMTQ3KSIgZmlsbD0iI0UwMkYyOCIgc3Ryb2tlPSIjRTAyRjI4IiBzdHJva2Utd2lkdGg9IjEiPgogICAgICA8cmVjdCB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHN0cm9rZT0ibm9uZSIvPgogICAgICA8cmVjdCB4PSIwLjUiIHk9IjAuNSIgd2lkdGg9IjE5IiBoZWlnaHQ9IjE5IiBmaWxsPSJub25lIi8+CiAgICA8L2c+CiAgICA8bGluZSBpZD0iTGlnbmVfMjA1IiBkYXRhLW5hbWU9IkxpZ25lIDIwNSIgeDE9IjgiIHkyPSI4IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxNTk4IDI5MjguNSkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIxIi8+CiAgICA8bGluZSBpZD0iTGlnbmVfMjA2IiBkYXRhLW5hbWU9IkxpZ25lIDIwNiIgeDI9IjgiIHkyPSI4IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxNTk4IDI5MjguNSkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIxIi8+CiAgPC9nPgo8L3N2Zz4K");
            background-repeat: no-repeat;
            width: 20px;
            display: inline-block;
            height: 20px;
            padding: 0;
            position: absolute;
            border: 0;
            cursor: pointer;
        }
    }
}

#news-search-result-render-uid {
    .col-md-2 {
        @media (min-width: $screen-md-min) {
            margin-top: -63px;
        }
    }
}

.job-search-results {
    .sr__title-number {
        color: $color-primary;
    }
}
/* OVERWRITE COVEO FACET */
.CoveoSearchInterface {
    .CoveoLogo {
        display: none;
    }

    .CoveoFacet {
        border: 0;
        margin: 0;

        &.coveo-facet-collapsed {
            background-color: #f2f2f2 !important;

            .coveo-facet-header {
                border: none !important;
            }
        }
    }

    .coveo-facet-value-label,
    .coveo-facet-values,
    .coveo-facet-value {
        padding: 0;
    }

    .coveo-facet-values {
        padding: 0 0 0 30px;
    }

    .coveo-facet-header {
        background-color: transparent;
        border: 1px solid #537898;
        border-radius: 0;
        color: #537898;
        font-family: $montreal-medium;
        padding: 12px 20px 12px 30px;

        .coveo-facet-collapsed & {
            border-color: $color-secondary-light;
            background-color: $color-secondary-light;
        }
    }

    .coveo-facet-footer {
        margin: 0 30px;
    }
}
/*.coveo-facet-container > .CoveoFacet {
    margin: 0 0 35px 0;

    @media (min-width: $screen-md-min) {
        margin: 0 0 70px 0;

        .news-listing-wrapper & {
            max-width: 100%;
        }
    }

    .sr__btn--all {
        background-color: $color-primary;
        border: 0;
        color: $color-white;
        width: 100%;
        padding: 18px 0;
        font-family: $montreal-medium;
        font-size: 14px;
        display: block;
    }

    .coveo-facet-value-caption {
        color: $color-secondary-dark;
        font-family: $montreal-medium;
        font-size: 14px;
        opacity: 0.5;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
        text-align: center;
    }

    .coveo-facet-value-label {
        border: 0;
        color: $color-white;
        width: 100%;
        padding: 18px 0;
        font-family: $montreal-medium;
        background-color: rgba(228, 228, 230, 0.4);
        border-bottom: 1px solid #d0d0d0;
    }

    .coveo-selected .coveo-facet-value-label {
        background-color: rgba(228, 228, 230, 0.8);
    }

    .coveo-facet-value-checkbox,
    .coveo-facet-value-count {
        display: none;
    }
}*/

.coveo-facet-container + h2 {
    margin-top: 60px;
}

.coveo-facet-column > .CoveoFacet {

    .sr__btn--all {
        padding: 0;
        border: 0;
        background: transparent;
        text-align: left;
    }

    .sr__btn--all,
    .coveo-facet-value-label-wrapper {
        font-size: 14px;
        font-family: $montreal-medium;
        color: rgba(83, 120, 152, 0.8);
        text-transform: uppercase;
        line-height: 1;
        position: relative;
        font-weight: 100;
        display: block;

        &:hover,
        &:focus {
            color: rgb(83, 120, 152);
        }
    }

    .coveo-facet-value.coveo-selected .coveo-facet-value-label-wrapper {
        color: rgb(83, 120, 152);
    }

    .coveo-facet-value-label-wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -ms-grid-row-align: center;
        align-items: center;
    }

    .coveo-facet-value {
        padding: 30px 0 0 0;

        &:last-child {
            padding-bottom: 30px;
        }

        &:hover,
        &:focus,
        &.coveo-selected {
            background: transparent;
        }

        &.coveo-selected {
            .coveo-facet-value-checkbox {
                background-color: rgb(83, 120, 152);

                svg {
                    .coveo-hook-svg {
                        fill: #fff;
                    }
                }

                span {
                    width: 9px;
                    height: 5px;
                    position: absolute;
                    top: 4px;
                    left: 4px;
                    border: 2px solid #fcfff4;
                    border-top: none;
                    border-right: none;
                    background: $color-secondary;
                    opacity: 1;
                    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
                    -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                    display: block;
                }

                &:hover {
                    svg {
                        .coveo-hook-svg {
                            fill: #fff;
                        }
                    }
                }
            }
        }

        .coveo-facet-value-checkbox {
            border-color: #537898;
            border-radius: 0;
            min-width: 18px;
            top: 0;

            span {
                display: none;
            }


            svg {
                bottom: -1px;
            }
        }

        &.coveo-with-hover:hover:not(.coveo-facet-search-button):not(.coveo-excluded):not(.coveo-facet-value-will-exclude):not(.coveo-has-childs-selected) .coveo-facet-value-checkbox svg {
            bottom: -1px;
        }
    }

    .coveo-facet-value-count {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;

        &:before {
            content: "(";
        }

        &:after {
            content: ")";
        }
    }

    .coveo-facet-search-button {
        .coveo-facet-value-checkbox span {
            display: inline-block;
        }
    }
}

.CoveoSearchInterface .container-fluid .coveo-search-section {
    margin: 0px;
    padding: 0px 15px;

    .CoveoOmnibox .magic-box-input {


        input {
            color: $color-secondary-dark;
            text-indent: 12px;

            &::placeholder {
                color: #6C6E74
            }
        }
    }
}

.CoveoSearchInterface .coveo-tab-section {
    background-color: transparent;
    text-align: left;
    border-bottom: none;
}

.CoveoSearchInterface .CoveoTab {
    @extend .btn;
    display: inline-block;
    padding: 0;
    height: auto;
    margin: 0;
    padding: 17px 25px 18px;
    color: #a1a2a4;
    background-color: #f4f4f5;
    text-transform: none;
    border-bottom: 1px solid #d5d5d5;
    margin-right: -5px;

    > * {
        line-height: 1.3;
    }

    > p {
        color: #a1a2a4;
    }

    &:hover {
        color: #a1a2a4;
        background: #e8e8e8;
    }

    &.coveo-selected:hover,
    &.coveo-selected {
        p {
            color: $color-white;
        }

        background: $color-primary;
        border-bottom: 1px solid $color-primary;
        padding-bottom: 18px;
    }

    .no-icon {
        display: none;
    }

    p {
        font-family: $montreal-medium;
    }
}

.CoveoFacet.CoveoFacetButton {
    margin-bottom: 60px;

    .coveo-facet-header-settings {
        top: 6px;
    }

    .coveo-facet-values {
        padding: 0;
    }

    .coveo-facet-header {
        padding: 0;
        border: 0;

        .coveo-facet-header-title {
            @extend h2;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .coveo-facet-header-eraser {
        top: 4px;
    }

    .coveo-facet-header-wait-animation {
        margin-top: 8px;
    }

    .coveo-facet-footer {
        display: none;
    }

    .coveo-facet-value-label {
        @extend .btn;
        margin: 0;
        padding: 19px 25px 19px;
        width: 100%;


        @media (min-width: $screen-md-min) {
            text-align: left;
        }
    }

    .coveo-facet-value .coveo-facet-value-checkbox {
        display: none;
    }

    .coveo-facet-value {
        width: 100%;
        background: #f4f4f5;
        text-align: center;
        border-bottom: 1px solid #d5d5d5;

        &:not(.coveo-selected):hover {
            background: #e8e8e8;

            .coveo-facet-value-label {
                color: #a1a2a4;
            }
        }

        &.coveo-selected {
            background: $color-primary;
            border-bottom-color: $color-primary;

            .coveo-facet-value-label {
                color: #fff;
            }
        }
    }

    .coveo-facet-value-count {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;

        &:before {
            content: "(";
        }

        &:after {
            content: ")";
        }
    }
}

.col-md-9 {
    .CoveoFacet.CoveoFacetButton {
        margin-bottom: 0;

        .coveo-facet-values {
            @extend .clearfix;
            max-width: none;
            padding: 0;
            margin-bottom: 60px;

            @media (min-width: $screen-md-min) {
                margin-bottom: 0;
            }
        }

        li {
            float: left;

            @media (min-width: $screen-md-min) {
                width: auto;
            }
        }

        .coveo-facet-header {
            display: none;
        }
    }

    .coveo-bottomSpace {
        display: none;
    }
}

.coveo-facet-search-results .coveo-facet-value {

    .coveo-facet-value-checkbox {
        border-color: $color-secondary;
        border-radius: 0;
        width: 18px;
        height: 18px;
        top: 0;
    }

    &.coveo-current,
    &.coveo-selected {
        .coveo-facet-value-checkbox {
            background-color: rgb(83, 120, 152);

            span {
                width: 9px;
                height: 5px;
                position: absolute;
                top: 4px;
                left: 4px;
                border: 2px solid #fcfff4;
                border-top: none;
                border-right: none;
                background: $color-secondary;
                opacity: 1;
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
                display: block;
            }
        }
    }
}

.coveo-facet-search-results {
    overflow-y: auto;
    border-radius: 0;
    padding-bottom: 0;
}

.coveo-facet-search-selectable {
    line-height: 18px;
    padding: 5px 10px;
    margin-bottom: 5px;
}

.coveo-facet-search {
    margin: 30px 0 30px 30px;
    padding: 4px 0;
    border-radius: 0;
}

.coveo-bottomSpace {
    margin-bottom: 10px;
}

.coveo-facet-less {
    padding: 8px 0 27px;
}

.coveo-facet-more {
    padding: 10px 0 25px;
}
/* OVERWRITE COVEO STRUCTURE */
#main-search-result-render-uid .container-fluid {
    padding: 0 4vw;
}
/* OVERWRITE COVEO RESULTS */
.coveo-results-header {
    display: none;
}

.CoveoResultList {
    margin-top: 0;

    .CoveoResult:not(.coveo-card-layout) {
        padding: 32px 0px;

        .ml__item {
            padding: 0;
        }

        @media (min-width: $screen-md-min) {
            padding: 36px 0px;
        }

        &:first-child {
            padding-top: 0;
        }
    }

    .CoveoResultList:not(.coveo-card-layout) > div > div:nth-child(1) {
        padding-top: 0;
    }

    .coveo-suptitle {
        font-size: 16px;
        font-family: $montreal-medium;
        color: $color-secondary-dark;
        line-height: 1.4;
        margin: 0 0 17px 0;
    }

    .CoveoResult:not(.coveo-card-layout) {
        border-bottom: 1px solid #cccccc;
        margin: 0;

        &:last-child {
            border-bottom: 0;
        }

        .coveo-result-frame {
            padding: 0;
        }

        .no-more-results & {
            &:last-child {
                border-bottom: 0;
            }
        }
    }

    .CoveoResultLink,
    a.CoveoResultLink,
    .CoveoResult:not(.coveo-card-layout) a.CoveoResultLink {
        font-size: 20px;
        font-family: $montreal-regular;
        color: $color-primary !important;
        text-transform: uppercase;
        line-height: 1.65;
        margin: 0;

        &:hover,
        &:focus {
            opacity: 0.5;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
            text-decoration: none !important;
        }

        div,
        p {
            text-transform: none;
        }
    }

    .CoveoResult:not(.coveo-card-layout) .coveo-title .CoveoResultLink {
        display: block;
    }

    .coveo-result-cell {
        margin-bottom: 40px;
        display: block;
    }

    .coveo-result-cell span,
    .coveo-result-cell p,
    .coveo-result-cell {
        font-size: calc(13.5px + .3vw);
        line-height: 1.3;
        font-family: $montreal-medium;
        color: rgb(30, 37, 43);
    }

    .coveo-result-cell span {
        display: block;
        margin-top: 20px;
    }

    .coveo-result-cell span:empty {
        display: none;
    }

    .coveo-result-cell a {
        font-size: calc(13.5px + .3vw);
        line-height: 1.625;
        font-family: $montreal-medium;
    }

    .coveo-result-frame .coveo-result-cell,
    .CoveoResult:not(.coveo-card-layout).coveo-result-frame .coveo-result-cell {
        line-height: 1.625;
    }

    .coveo-result-frame .coveo-result-row,
    .CoveoResult:not(.coveo-card-layout).coveo-result-frame .coveo-result-row {
        margin: 0;
    }
}

.CoveoSearchbox {
    .CoveoSearchButton {
        height: 59px;
    }

    .CoveoOmnibox.magic-box {


        .magic-box-clear,
        .magic-box-input {
            height: 58px;
            background-color: $color-white;


            .magic-box-icon {
                line-height: 58px;
            }

            > input {
                @extend .typo__25;
                height: 58px;
                padding: 17px 0;
                text-indent: 12px;
            }
        }
    }
}

.CoveoSearchInterface hr {
    margin: 70px 0;
}

.coveo-results-header {
    margin-bottom: 75px;
}

.no-facets .CoveoResultList {
    .coveo-card-layout.CoveoResult {
        @media (min-width: $screen-md-min) {
            width: calc(100% / 2);
        }

        @media (min-width: $screen-md-min) {
            width: calc(100% / 3);
        }
    }
}

.CoveoResultList {
    .coveo-card-layout-container {
        @extend .row;
        display: block;
        flex-wrap: initial;

        > div {
            display: none;
        }

        > .coveo-card-layout.CoveoResult {
            display: block;
        }
    }

    .CoveoResult h2.typo__07 {
        text-transform: none;
    }

    .coveo-card-layout.CoveoResult {
        position: static;
        width: 100%;
        margin: 0;
        border: none;
        border-radius: 0;
        padding: 0 15px;
        flex-grow: initial;
        float: left;

        @media (min-width: $screen-md-min) {
            width: calc(100% / 2);

            &:nth-child(2n + 1) {
                clear: left;
            }
        }

        .coveo-result-row,
        .coveo-result-frame {
            display: block;
            padding: 0;
        }

        .coveo-result-cell {
            display: block !important;

            &.case-study * {
                @extend .typo__06;
                margin-bottom: 10px;
                display: block;
            }

            h2 * {
                @extend .typo__07;
                line-height: 1.625;
            }


            div.typo__09 {
                line-height: 1.666;
                margin-bottom: 15px;
                text-transform: initial;

                * {
                    @extend .typo__09;
                    line-height: 1.666;
                    margin-bottom: 15px;
                    text-transform: initial;
                }
            }
        }
    }
}

.load-more-results {
    background-color: $color-secondary-light;
    font-size: 14px;
    color: $color-secondary-on;
    border: none;
    font-family: $montreal-medium;
    padding: 14px 30px;
    margin-top: 25px;
    text-align: center;
    width: 100%;
    display: none;

    .pagemode-edit & {
        display: block;
    }

    @media (min-width: $screen-sm-min) {
        width: auto;
    }

    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }

    i {
        padding-top: 5px;
        vertical-align: top;
        display: inline-block;
        font-size: 10px;
        margin-left: 8px;
    }
}


.CoveoSearchInterface .coveo-facet-column {
    padding: 0 15px;
    width: 100%;

    @media (min-width: $screen-md-min) {
        width: 25%;
    }
}


.thought-infos {
    font-family: $montreal-regular;
    font-size: 16px;

    i {
        display: inline-block;
        width: 15px;
        margin-right: 8px;
        text-align: center;
    }
}

.tag-infos {
    line-height: 1.625;
    font-size: calc(13.5px + .3vw);
    margin-bottom: 40px;
}

.margin-bottom0 {
    margin-bottom: 0 !important;
}

/*.coveo-result-row {
    .img-responsive {
        transition: all 0.3s ease;
    }

    &:hover {
        .img-responsive {
            transform: scale(1.1);
        }
    }
}*/

#coveoC606340E {
    .filter-by {
        visibility: hidden !important;
    }

    .wsp-forms .form-control {
        background-color: #fff !important;
        color: black !important;
    }

    .message-me-section {
        background: #f2f2f2 !important;
    }

    .search-results .width-100 {
        width: 100% !important;
        padding-left: 53px;
        padding-right: 0px;
        background: #F2F2F2 0% 0% no-repeat padding-box;
    }

    .search-box-pretext {
        margin-top: 50px;
    }

    .refine-header {
        font-size: 18.5px;
        font-family: $montreal-regular;
    }

    .coveo-search-section {
        background-color: #f2f2f2;
        margin-left: 0px;
        margin-top: 0px;
        padding: 64px 10px 64px 10px;
        position: relative;
        flex-flow: column;
        align-items: flext-start;
        margin: 25px 0;

        @media (min-width: $screen-md-min) {
            padding-left: 0px;
        }
    }

    .coveo-for-sitecore-search-box-container {
        width: 100%;
    }

    .coveo-search-background {
        background-color: #f2f2f2;
        position: absolute;
        left: -50vw;
        right: -50vw;
        height: 100%;
        display: none;
        top: 0;

        @media (min-width: $screen-md-min) {
            display: block;
        }
    }

    .magic-box-underlay {
        background: white;
    }

    .magic-box-clear .magic-box-icon {
        background: white;
    }

    .magic-box-input {
        background-color: white;
    }

    .magic-box-input input {
        text-indent: 30px;
        color: $color-secondary-medium-gray;
    }

    .CoveoSearchButton {
        .magic-box .magic-box-clear {
            background-color: $color-white !important;
        }

        background: white;
    }

    .magic-box .magic-box-clear {
        background-color: $color-white !important;
    }


    .coveo-facet-column {
        padding-left: 0px;

        .coveo-facet-values {
            padding-left: 0px;
        }
    }

    .coveo-facet-header-title {
        color: $color-secondary-dark;
        font-size: 16px;
    }

    .coveo-search-box-title {
        position: relative;
        padding-bottom: 26px;
        flex: 1;
        width: 100%;

        p {
            color: $color-secondary-dark;
            font-family: $montreal-medium;
            font-size: 14px;
            margin-bottom: 0px;
        }
    }
}

.CoveoSearchInterface {
    .coveo-pager-list-item, coveo-results-per-page-list-item {
        background-color: $color-secondary-light;
        border: none;
        padding: 5px 10px;

        &.coveo-active {
            background-color: $color-dark-red;

            .coveo-pager-list-item-text, .coveo-results-per-page-list-item-text {
                color: white;
            }
        }

        &:hover {
            background-color: $color-dark-red;
            text-decoration: none;

            .coveo-pager-list-item-text, .coveo-results-per-page-list-item-text {
                color: white;
                text-decoration: none;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        &.coveo-pager-next, &.coveo-pager-previous {
            padding: 4px 8px;
            vertical-align: top;

            &:hover {
                background-color: $color-dark-red;
                color: white;

                .coveo-pager-next-icon-svg, .coveo-pager-previous-icon-svg {
                    color: white;
                }
            }
        }

        .coveo-pager-list-item-text, .coveo-results-per-page-list-item {
            font-size: 14px;
            color: $color-secondary-on;
            font-family: $montreal-medium;
        }
    }
}

.CoveoSearchInterface[data-pipeline="projects-coveo5"], .CoveoSearchInterface[data-pipeline="sectors-coveo5"] {
    .search-results {
        .coveo-results-column {
            .CoveoResultList {
                .CoveoResult {
                    padding: 0 15px 30px 15px;
                }

                .CoveoResultLink.ml__item {
                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            .CoveoPager {
                margin-top: 15px;
                margin-left: -5px;
            }
        }
    }
}

/* Find an Expert breadcrumb shift fix */
.section.section-full > .container-fluid > .row.no-margin > .col-md-12.no-padding > .wsp-forms {
    padding-right: 4vw;
    padding-left: 4vw;

    @media (min-width: $screen-view-condition-min) {
        padding-left: 0 !important;
        padding-right: 0 !important;
        max-width: $screen-laptop-min;
        margin: auto !important;
    }

    .big-padding {
        padding-right: 0;
        padding-left: 0;
    }
}

/* Featured results */
#job-search-result-title-div {
    display: none;
}

.job-srch-res-container {
    margin-bottom: 55px;
}

a.job-srch-res-box {
    padding: 28px 25px;
    display: block;
    background: $color-secondary-lightest;
    border-bottom: 1px solid $color-secondary-light-gray;
    text-decoration: none;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;

    h2 {
        margin-bottom: 10px;
        text-transform: none;
    }

    p {
        margin: 0px;
    }
}
/* 197 - Mobile experience issues - filtering */
@media (max-width:991px) {
    .CoveoSearchInterface .coveo-facet-column {
        width: 100% !important;
    }
}

.CoveoSearchbox .CoveoSearchButton {
    border-bottom: #d5d5d5 solid 1px;
}

.coveo-dropdown-header-wrapper {
    display: none !important;
}

@media (max-width: 480px) {
    .CoveoSearchInterface .coveo-results-column {
        padding: auto !important;
    }

    .CoveoSearchInterface .coveo-tab-section {
        background-color: transparent !important;
    }
}

.CoveoSearchInterface .CoveoTab {
    width: auto !important;
}

.coveo-small-tabs .CoveoTab.coveo-selected p, .coveo-small-tabs .CoveoTab.coveo-selected:hover p {
    border-bottom: none !important;
}

/* Trello 222 , 229 - Profile UI changes*/
.CoveoResultList .CoveoResult h2.typo__07 span.coveo-p-title {
    font-size: 14px;
    color: $color-secondary-dark;
    line-height: 21px;
    font-family: $montreal-regular;
}

/* Trello 387 - Job revamp */
.no-results-container {
    h2 {
        font-size: 24px;
        padding: 0;
        margin: 0;
        border: 0;
        font-family: $montreal-medium;
        color: $color-secondary-dark;
        margin-top: 20px;
        margin-bottom: 5px;
    }

    p {
        font-size: 14px;
        padding: 0;
        margin: 0;
        border: 0;
        font-family: $montreal-regular;
        color: $color-secondary-dark;
    }
}
/* Rebrand - Overwrite Coveo filter section colors - start */
.coveo-facet-column > .CoveoFacet .coveo-facet-value .coveo-facet-value-checkbox {
    border-color: $color-primary;
}

.coveo-facet-column > .CoveoFacet .coveo-facet-value-label-wrapper, .coveo-facet-column > .CoveoFacet .sr__btn--all {
    color: $color-secondary-dark;
    text-transform: none;
    font-size: 16px;
}

.coveo-facet-column > .CoveoFacet .coveo-facet-value-label-wrapper:focus, .coveo-facet-column > .CoveoFacet .coveo-facet-value-label-wrapper:hover, .coveo-facet-column > .CoveoFacet .sr__btn--all:focus, .coveo-facet-column > .CoveoFacet .sr__btn--all:hover {
    color: $color-primary;
}

.coveo-facet-column > .CoveoFacet .coveo-facet-value.coveo-selected .coveo-facet-value-label-wrapper {
    color: $color-secondary-dark;
}

.coveo-facet-column > .CoveoFacet .coveo-facet-value.coveo-selected .coveo-facet-value-checkbox {
    background-color: $color-primary;
}

.CoveoSearchInterface .coveo-facet-values {
    padding: 0;
}

.CoveoSearchInterface .coveo-facet-header {
    background-color: $color-secondary-lightest;
    border: 1px solid $color-secondary-lightest;
    font-size: 16px;
    color: $color-secondary-dark;
}

.coveo-facet-header-title {
    font-size: 16px !important;
    color: $color-secondary-dark !important;
}

.coveo-facet-header-operator, .coveo-facet-settings-more-svg, .coveo-sort-icon-descending-svg, .CoveoSort.coveo-ascending .coveo-sort-icon-ascending-svg, .coveo-facet-less-icon-svg, .coveo-facet-more-icon-svg {
    color: $color-secondary-dark !important;
}

.coveo-facet-value.coveo-with-hover:hover:not(.coveo-facet-search-button):not(.coveo-excluded):not(.coveo-facet-value-will-exclude):not(.coveo-has-childs-selected) .coveo-facet-value-checkbox svg .coveo-hook-svg {
    fill: $color-white;
}

/* Rebrand - Overwrite Coveo filter section colors - end */