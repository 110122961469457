﻿.news-details {
    p {
        color: $color-primary;
        @extend .new__typo__11; //News page
    }

    time {
        text-transform: uppercase;
        /*color: $color-primary;**/
        @extend .new__typo__22; //News page
        color: $color-secondary-dark;
        font-size: 14px;
        font-family: $montreal-medium;
    }
}

.news-text {
    color: $color-secondary-dark;
    @extend .new__typo__03; //News page
}