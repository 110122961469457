﻿.share-social {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;

    .ss__list {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .ss__item {
        display: inline-block;
        margin: 0 0 10px 0;
        padding: 0;

        @media (min-width: $screen-md-min) {
            margin-bottom: 0;
        }
    }

    .ss__link {
        align-items: center;
        background-color: $color-primary;
        border: 0;
        border-radius: 50%;
        color: $color-white;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-size: 26px;
        height: 65px;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin: 0 9px;
        width: 65px;

        &:hover,
        &:focus {
            background-color: $color-dark-primary;
            text-decoration: none;
        }

        &:before {
            font-family: font-awesome;
        }
    }

    .ss__facebook:before {
        content: "\f09a";
    }

    .ss__linkedin:before {
        content: "\f0e1";
    }
    /*.ss__twitter:before {
        content: "\f099";
    }*/

    .ss__twitter::before {
        font-family: "Font Awesome 6 Brands";
        font-weight: 400;
        content: "\e61b";
    }


    .ss__skype:before {
        content: "\f17e";
    }

    .ss__google-plus:before {
        content: "\f0d5";
    }

    .ss__email:before {
        content: "\f0e0";
    }
}


.social-media-top {
    
    list-style: none;
    margin-bottom: 0;
    margin-left: 0;
    display: flex;
    clear: both;

    @media (min-width: $screen-md-min) {
        margin: 0 0 0 -15px;
        clear: none;
        padding-right: 0;      
    }

    & > *,
    .mf__item {

        @media (min-width: $screen-md-min) {
            &:last-child {
                margin-right: 0;
            }
        }

        a {
            color: $color-secondary;
        }
    }

    .fa-size {
        font-size: 1.688em;
    }
}
