﻿ul.contact-info-text {
    margin: 0;
    padding: 0;

    li {
        margin: 0;
        padding: 0;
        list-style-type: none;
        letter-spacing: 0.5px;

        &:before {
            content: none;
            display: inline-block;
            width: 10px;
            background: $color-secondary-dark;
            height: 2px;
            margin-right: 30px;
            vertical-align: middle;

            @media (min-width: $screen-md-min) {
                content: "";
            }
        }
    }
}

.contact-info-text {
    @extend .typo__13;
    padding: 0;
    margin-bottom: 20px;

    @media (min-width: $screen-md-min) {
        margin-bottom: 0;
    }
}

a.contact-info-btn {
    margin-top: 30px;
}
