@import "../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs";

.breadcrumb {
    padding: 0;
    /*padding-left: 4vw;
        padding-top: 15px;
    position: absolute;*/
    position: relative;
    width: 100%;
    z-index: 100;

    ol {
        & > li {
            font-size: $breadcrumb-font-size;
            font-weight: $breadcrumb-font-weight;

            .ie9 &, .lte-ie8 {
                margin-right: 1px;
            }

            @media all and (max-width: $screen-xs-max) {
                font-size: floor($breadcrumb-font-size*.8);
            }

            & + li {
                &:before {
                    color: $brand-text-color;

                    .bg-primary &, .bg-secondary &, .bg-dark &, .bg-media & {
                        color: $brand-text-color;
                    }

                    .bg-white & {
                        color: $color-black;
                    }

                    @media all and (max-width: $screen-xs-max) {
                        padding: 0px;
                    }
                }
            }

            a {
                color: $breadcrumb-color;

                .bg-primary &, .bg-secondary &, .bg-dark &, .bg-media & {
                    color: $brand-text-color;
                }

                .bg-white & {
                    color: $color-black;
                }
            }

            &.active {
                font-size: $breadcrumb-active-font-size;
                /*font-weight: $breadcrumb-active-font-weight;*/
                font-weight: normal;

                @media all and (max-width: $screen-xs-max) {
                    font-size: floor($breadcrumb-active-font-size*.8);
                }

                .bg-primary &, .bg-secondary &, .bg-dark &, .bg-media & {
                    color: $brand-text-color;
                }

                .bg-white & {
                    color: $color-black;
                }

                &:before {
                    font-weight: normal;
                    /*font-weight: $breadcrumb-font-weight;*/
                    font-size: $breadcrumb-font-size;
                }
            }
        }
    }
}
