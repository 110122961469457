@import "../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/input-groups";
@import "../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/forms";
@import "../../../bower_components/Nexio.WSP.theme/sass/WSP/_variables.scss";

input {
    line-height: normal;

    &[type="text"],
    &[type="password"] {
        padding: 0 0px;

        &:focus {
            padding: 0 10px;
        }

        &[readonly] {
            padding: 0;
        }
    }

    &:focus {
        box-shadow: none;
        background-color: $input-bg-focus;
    }

    .input-group &[type="text"] {
        padding-left: 10px;
    }

    .checkbox {
        label {
            vertical-align: middle;
            font-size: $font-size-base
        }
    }



    label > &[type="checkbox"] {
        border: none;
        margin-top: 0px;
        vertical-align: middle;
        display: inline-block;
        margin: 0 0px;
        height: 15px;
        width: 15px;
        visibility: hidden;

        &:checked {
            &:before {
                border-width: 5px;
                background-color: $brand-text-color;
                box-sizing: border-box;
                margin-top: 4px;
            }
        }

        &:before {
            width: 15px;
            height: 15px;
            display: block;
            position: absolute;
            content: ' ';
            background-color: $gray-light;
            border: 1px solid $text-color;
            top: 0px;
            left: 0;
            visibility: visible;
            margin-top: 6px;

            .bg-primary &,
            .bg-secondary &,
            .bg-dark &,
            .bg-media & {
                border-color: $text-color;
            }
        }
    }

    &[type="file"] {
        display: none;
        position: relative;
        width: 120px;
        height: 40px;
        cursor: pointer;

        & + label {
            @extend .btn-default;
            text-align: center;
            font-weight: $btn-font-weight;
            padding: 10px 16px;
            border: 2px solid $color-secondary-dark;
            transition: all 0.2s ease;
            cursor: pointer;
            position: relative;
            display: inline-block;

            &:after {
                content: "\e022";
                font-family: 'Glyphicons Halflings';
                font-style: normal;
                font-weight: 400;
                line-height: 1.3;
                -webkit-font-smoothing: antialiased;
                margin-left: 10px;
                vertical-align: middle;
            }
        }
    }

    &::-webkit-input-placeholder {
        color: lighten($text-color, 30);
    }

    &:-moz-placeholder {
        color: lighten($text-color, 30);
    }

    &::-moz-placeholder {
        color: lighten($text-color, 30);
    }

    &:-ms-input-placeholder {
        color: lighten($text-color, 30);
    }

    .bg-primary &,
    .bg-secondary &,
    .bg-dark &,
    .bg-media & {
        &::-webkit-input-placeholder {
            color: darken($text-color, 30);
        }

        &:-moz-placeholder {
            color: darken($text-color, 30);
        }

        &::-moz-placeholder {
            color: darken($text-color, 30);
        }

        &:-ms-input-placeholder {
            color: darken($text-color, 30);
        }
    }
}

label {
    font-weight: $input-label-font-weight;
    font-size: $input-label-font-size;
    margin-bottom: 0;
}

.form-control {
    border-width: 0;
    border-bottom-width: 1px;
    background-color: transparent;
    padding: 0;
    height: 42px;
    margin: 0px 0;
    line-height: normal;
    box-shadow: none !important;
    color: $color-secondary-light;
    border-radius: 0;

    .form-inline & {
        padding: 10px;
    }

    .bg-primary &,
    .bg-secondary &,
    .bg-dark &,
    .bg-media & {
        color: $brand-text-color;

        &:focus {
            color: $text-color;
        }
    }

    .bg-white & {
        color: $color-black;
    }

    &:focus,
    &:active {
        box-shadow: none !important;
        padding-left: 10px;
    }

    .has-success & {
        border-color: $brand-success;
    }

    .has-warning & {
        border-color: $brand-warning;
    }

    .has-error & {
        border-color: $brand-danger;
    }

    &::-webkit-input-placeholder {
        color: lighten($text-color, 30);
    }

    &:-moz-placeholder {
        color: lighten($text-color, 30);
    }

    &::-moz-placeholder {
        color: lighten($text-color, 30);
    }

    &:-ms-input-placeholder {
        color: lighten($text-color, 30);
    }

    .bg-primary &,
    .bg-secondary &,
    .bg-dark &,
    .bg-media & {
        &::-webkit-input-placeholder {
            color: darken($text-color, 30);
        }

        &:-moz-placeholder {
            color: darken($text-color, 30);
        }

        &::-moz-placeholder {
            color: darken($text-color, 30);
        }

        &:-ms-input-placeholder {
            color: darken($text-color, 30);
        }
    }
}

.pressRelLeft .form-control {
    background-color: #f4f4f5;
}

.input-group-addon {
    border-width: 0;

    .has-success & {
        color: $brand-success;
        background-color: lighten($brand-success, 45%);
        border-color: $brand-success;
    }

    .has-warning & {
        color: $brand-warning;
        background-color: lighten($brand-warning, 45%);
        border-color: $brand-warning;
    }

    .has-error & {
        color: $brand-danger;
        background-color: lighten($brand-danger, 45%);
        border-color: $brand-danger;
    }
}

.help-block {
    margin-top: 0px;

    .bg-primary &,
    .bg-secondary &,
    .bg-dark &,
    .bg-media & {
        color: $brand-text-color;
    }

    .bg-white & {
        color: $color-black;
    }
}

.form-control-feedback,
.help-block {
    .has-success & {
        color: $brand-success;
    }

    .has-warning & {
        color: $brand-warning;
    }

    .has-error & {
        color: $brand-danger;
    }
}

select {
    padding: 10px 0;

    &.form-control {
        padding: 10px 0;
    }

    .bg-primary &,
    .bg-secondary &,
    .bg-dark &,
    .bg-media & {
        &:focus {
            background-color: $input-bg-focus;
        }
    }
}

textarea {
    &.form-control {
        border-width: 1px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;

        &:focus {
            background-color: $input-bg-focus;
        }
    }
}

form {
    &.form-signin,
    &.form-register {
        max-width: 600px;
        margin: 0 auto;

        hr {
            margin: 20px 0;
        }
    }
}

.contact-us-form-section {
    form {
        label {
            font-family: $montreal-medium !important;
            color: $text-color;
            line-height: 1.3;
            display: block;
            margin-bottom: 24px;
            font-size: $font-size-16mn-20mx;
        }
    }
}

.hiddenDataField {
    display: none !important;
}

.requestForProposal .formbreadcrumb {
    margin-top: 30px;
}

.requestForProposal .contact-us-form-section form h2 {
    font-size: 21px;
}

.wsp-recaptcha-section .field-validation-error {
    position: relative;
}

.email-consent-section .privacy-text, .receiveupdates-consent-section .receiveupdates-text {
    padding-left: 0;
    /*width: 94% !important;*/ /*commented this as it was causing text to show on new line*/
    font-size: 12px !important;

    &.rfp-privacy-text {
        padding-top: 10px;
    }

    &.required-field {
        padding-top: 10px;

        @media (max-width: 767px) {
            padding-left: 10px;
        }

        .control-label {
            &:before {
                content: '';
            }
        }
    }
}

.email-consent-section .privacy-text span, .receiveupdates-consent-section .receiveupdates-text span {
    margin-top: 10px !important;
}

.email-consent-section .privacy-text span, .email-consent-section .privacy-text a, .receiveupdates-consent-section .receiveupdates-text span {
    font-size: 12px !important;
    font-family: $montreal-medium !important;
    padding: 0;
}

.receiveupdates-consent-section label input {
    top: 0 !important;
}

.message-me-section .email-consent-section .privacy-text span {
    display: inline;
}

.consent-checkbox-section {
    width: 6% !important;

    @media(max-width: 550px) {
        width: 10% !important;
    }
    /*fix for showing proper gap between checkbox and text in mobile view*/
}


.consent-checkbox-section + .privacy-text {
    width: 85% !important;

    @media(max-width: 550px) {
        width: 80% !important;
    }
    /*fix for showing proper gap between checkbox and text in mobile view*/
}

.col-xs-1.consent-checkbox-section span.field-validation-error {
    position: absolute;
    margin-top: -35px !important;
}

.col-xs-1.consent-checkbox-section span.field-validation-error > * {
    position: inherit;
    margin-top: 0;
}

.person-contact-forms .requestForProposal.modal {
    overflow: auto;
}

.requestForProposal {
    .modal-dialog {
        width: unset !important;


        @media(min-width: 768px) {
            min-width: 450px;
            width: 600px;
        }

        @media(min-width: 992px) {
            width: 680px;
        }
    }

    .modal-body {
        padding: 50px 90px 50px;
    }

    .modal-body .row {
        @media(max-width:991px) {
            padding: 30px;
        }
    }

    form {
        h2 {
            font-size: 21px;
            color: $text-color;
            font-family: $montreal-medium !important;
        }
    }

    input.btn[type="submit"] {
        width: 189px;
        height: 50px;
        margin-right: 20px;
    }

    .send-a-message-row {
        .hide {
            display: none;
        }
    }

    .js-contact-list-form-area {
        @media(min-width: 768px) {
            width: 600px;
        }

        @media(min-width: 992px) {
            width: 680px;
        }
    }

    .js-contacts-container {
        display: flex;
        justify-content: space-around;

        .contact-card-row {
            display: flex;
            padding: 0 15px;
            justify-content: center;
            max-width: 880px;
            flex-wrap: wrap;

            .contact-card-column {
                padding: 0 15px;
                max-width: 315px;
                width: 315px;
            }

            &.max-layout {
                @media(min-width: 1400px) {
                    max-width: 1000px;
                }

                .contact-card-column {
                    width: 210px;
                    padding: 0 30px;

                    @media(min-width: 1400px) {
                        width: 240px;
                    }

                    .position {
                        margin-bottom: 0;
                    }

                    .image {
                        max-width: 150px;
                    }
                }
            }

            .contact-card-item {
                display: flex;
                flex-direction: column;
                align-items: center;

                .image {
                    max-width: 200px;
                    width: 100%;
                    display: block;
                    margin: 0 auto 30px;
                    border-radius: 100%;
                    overflow: hidden;
                }
            }

            .contact-card-info, .contact-info {
                margin-top: 10px;
                text-align: center;
                align-content: stretch;
                display: flex;
                flex-direction: column;
                width: 100%;

                .name {
                    display: block;
                }

                .position {
                }

                .contact-country {
                    font-size: 14px;
                }

                .update-form {
                    width: 100%;
                }
            }
        }
    }

    &.send-a-message-nopopup {
        /*.contact-card .image img {
           max-height: 225px;
        }
*/
        .js-contacts-container {
            justify-content: flex-start;
        }
    }

    .file-upload-text p {
        font-family: $montreal-medium !important;
        font-size: 12px;
    }

    .required-fields-text {
        font-family: $montreal-medium !important;
        color: $text-color;
        line-height: 1.625;
        display: block;
        margin-bottom: 24px;
        font-size: 12px !important;
        position: relative; /*position relative added to have proper gap between captcha box and checkbox row*/
        right: 0;
        text-align: right; /* aligned text to the right of Div container*/
    }

    .wsp-recaptcha-section .col-sm-6 {
        padding: 0;
    }

    .wsp-forms .custom-file-upload label {
        color: #898C8F !important;
    }

    .selectpicker + .ui-selectmenu-button {
        .ui-selectmenu-icon.fa-chevron-down {
            /*color: #898C8F !important;*/
            width: 15.707px;
            height: 8.561px;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNS43MDciIGhlaWdodD0iOC41NjEiIHZpZXdCb3g9IjAgMCAxNS43MDcgOC41NjEiPgogIDxwYXRoIGlkPSJpY24tYXJyb3ctbWVkaXVtLWJsdWUtZG93biIgZD0iTTcuNSwxNSwwLDcuNSwzLjEyNSw0LjM3NSw3LjUsMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC4zNTQgNy44NTQpIHJvdGF0ZSgtOTApIiBmaWxsPSJub25lIiBzdHJva2U9IiNFMDJGMjgiIHN0cm9rZS13aWR0aD0iMSIvPgo8L3N2Zz4K");

            &:before {
                content: '';
            }
        }

        &.chosen {
            .ui-selectmenu-icon.fa-chevron-down {
                color: $text-color !important;
            }
        }
    }
}

.popup-link-wrap {
    display: flex;
    min-width: 50%;


    @media(max-width:767px) {
        margin-bottom: 15px;
    }
}
/* Trello #119 - INC0128069 */
/*.container-fluid.ft-component.ft-structural.ft-three-column-4-4-4 .col-sm-4 .popup-link-wrap {
    display: none;
}*/
.anchor-nav-container.scroll-up {
    .flex-anchor-box {
        .popup-link-wrap {
            a.sam-anchor {
                border-top: 0 !important;
                border-bottom: 0 !important;
            }
        }
    }
}
/* Fixes background color of button inside an image */
.bg-secondary.ft-component.ft-structural.ft-content-wrapper .col-sm-6 .popup-link-wrap a {
    color: #fff;
    background-color: $color-dark-red;
    border: 0;

    &:hover {
        background-color: #d43530;
    }
}

.container-fluid.ft-component.ft-structural.ft-two-column-9-3 .col-md-3 .contact-card + .popup-link-wrap a, .big-padding-right.col-md-3 .popup-link-wrap a {
    background-color: $color-secondary;
    color: #fff !important;

    &:hover {
        background-color: $color-secondary;
    }
}
