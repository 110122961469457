﻿.media-list {
    list-style: none;
    padding: 0;
    margin: 0;

    .ml__item {
        width: 100%;
        padding: 70px 0;
        border-bottom: 1px solid $color-secondary-lighter;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        @media (max-width: 550px) {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
        }

        @media (min-width: 551px) {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
        }

        &:last-child {
            border-bottom: 0;
        }
    }

    .ml__item.event__record {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .ml__left {
        margin-bottom: 20px;

        @media (max-width: 550px) {
            height: auto !important;
            margin-right: 15px;
            /*max-width: 300px;*/
        }

        @media (min-width: 551px) {
            margin-right: 20px;
            margin-bottom: 0;
            width: 40%;
            min-width: 40%;
            height: auto !important;
        }

        @media (min-width: $screen-md-min) {
            margin-right: 20px;
            margin-bottom: 0;
            width: 50%;
            min-width: 50%;
            height: auto !important;
        }

        img {
            display: block;
            width: 100%;
        }
    }



    .ml__right {
        @media (min-width: 551px) {
            width: calc(59.99% - 20px);
            min-width: calc(59.99% - 20px);
        }

        @media (min-width: $screen-md-min) {
            width: calc(49.99% - 20px);
            min-width: calc(49.99% - 20px);
        }
    }

    .ml__left.prf {

        @media (min-width: 551px) {
            width: 16%;
            min-width: 16%;
        }

        @media (min-width: $screen-md-min) {
            width: 20%;
            min-width: 20%;
        }
    }

    .ml__left.prf + .ml__right {

        @media (min-width: 551px) {
            width: calc(83.99% - 20px);
            min-width: calc(83.99% - 20px);
        }

        @media (min-width: $screen-md-min) {
            width: calc(79.99% - 20px);
            min-width: calc(79.99% - 20px);
        }
    }

    .ml__link {
        color: $color-primary;

        h3 {
            text-transform: uppercase;
            color: $color-primary;
            @extend .new__typo__07; //News
        }
    }

    .ml__date {
        margin-bottom: 5px;
        color: $color-primary;
        @extend .new__typo__06; //News
    }

    .ml__text {
        color: $color-secondary-dark;
        @extend .new__typo__09; //News
    }
}

/* INC021244404  Coveo events page - mobile layout needs review  www.wsp.com/en-GL/events */
@media (max-width: 550px) {
    #events-search-results-render-uid {
        .media-list {
            a.ml__item {
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                -ms-flex-direction: row;
                        flex-direction: row;
            }
        }
    }
}

/* Trello 222 , 229 - Profile UI changes*/
@media (min-width: 551px) {
    div.coveo-result-row a[href*="/profiles/"] div.thumbnail.ml__left, div.coveo-result-row a[href*="/profiler/"] div.thumbnail.ml__left {
        width: 18%;
        min-width: 18%;
    }

    div.coveo-result-row a[href*="/profiles/"] div.thumbnail.ml__left + .ml__right, div.coveo-result-row a[href*="/profiler/"] div.thumbnail.ml__left + .ml__right {
        width: calc(79.99% - 20px);
        min-width: calc(79.99% - 20px);
    }
}