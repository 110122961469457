.owl-wrapper {
    z-index: 1;
}

.owl-prev .glyphicon,
.owl-next .glyphicon {
    background: transparent;
    right: 0;
}

.owl-carousel .project-list,
.owl-carousel .owl-item {
    padding: 0 !important;

    .date {
        @extend .new__typo__22; //Homepage
        text-transform: uppercase;
        
        color: $color-secondary-dark;
        display: block;
        margin: 35px 0 17px 0;
        font-family: $montreal-medium;
        font-size: 14px;
    }

    h2 {
        
        
        
        @extend .new__typo__29; //Homepage, What we do, Project (one), Careers

        &:hover, &:active {
            color: $color-secondary-dark-gray;
        }
    }

    p {
        color: $color-secondary-dark;
        @extend .new__typo__09; //Homepage, What we do, Porject (one), Careers
    }
}

.owl-pagination {
    counter-reset: slides-num; /* Initialize counter. */
    position: relative;
    top: -40px;
    right: 85px;
    margin: -4px 0 0 !important;
    left: auto;

    @media (min-width: $screen-md-min) {
        top: 0;
    }

    &:after {
        content: counter( slides-num ); /* get total number of items - more info on http://www.sitepoint.com/a-little-known-way-to-replace-some-scripts-with-css-counters/ */
        display: inline-block;
        font-family: $montreal-medium;
        vertical-align: middle;
        padding-left: 0px;
        font-weight: normal;
        font-size: $fonts-size-small;
    }
}

.owl-page {
    display: inline-block;
    counter-increment: slides-num; /* Increment counter */
    margin-right: 2px !important;

    span {
        display: none;
    }

    &.active {
        &:before {
            content: counter(slides-num) " /"; /* Use the same counter to get current item. */
            display: inline-block;
            vertical-align: middle;
            font-family: $montreal-medium;
            position: relative;
            font-size: $fonts-size-small;
            left: auto;
            top: auto;
        }
    }
}

.related-project {
    margin: 75px 0; //70px
    @media (max-width: $screen-sm-max) {
        margin: 55px 0;
    }

    @media (min-width: $screen-view-condition-min) {
        margin-top: 75px !important;
        margin-bottom: 70px !important;
    }

    .row {
        margin-bottom: 34px !important;
    }

    h2 {
        margin-bottom: 18px;
    }


    .footer-bloc.services-sectors-slider.marT20 {
        @media (max-width: $screen-sm-max) {
            margin-bottom: 35px;
        }

        @media (min-width: $screen-md-min) and (max-width: 1499px) {
            margin-bottom: 55px;
        }
    }


    @media (max-width: $screen-sm-max) {
        .footer-bloc.services-sectors-slider.marT20 {
            margin-bottom: 35px;
        }
    }
}

.latest-news,
.feature-project {
    margin: 66px 0; //70px
    @media (max-width: $screen-sm-max) {
        margin: 64px 0;
    }

    @media (min-width: $screen-view-condition-min) {
        margin-top: 70px !important;
        margin-bottom: 70px !important;
    }

    h2 {
        
        @extend .new__typo__02;
    }

    p {
        color: $color-secondary-dark;
        @extend .new__typo__09;
    }

    @media (min-width: $screen-sm-min) {
        .featured-nav-position {
            margin-top: 50px;
        }
    }
}

.featured-publications {
    h2 {
        
        @extend .new__typo__02;
    }
}

.latest-news,
.feature-project {
    h2 {
        margin-bottom: 0;
    }

    .row {
        margin-bottom: 40px !important;
    }
}

.latest-news-btn {
    margin-bottom: 0;
}

.feature-project {
    h2 {
        @media (max-width: 319px) {
            margin-bottom: 13px;
        }

        @media (min-width: 320px) {
            margin-bottom: 13px;
        }

        @media (min-width: 480px) {
            margin-bottom: 11px;
        }

        @media (min-width: 768px) {
            margin-bottom: 10px;
        }

        @media (min-width: 992px) {
            margin-bottom: 8px;
        }

        @media (min-width: 1024px) {
            margin-bottom: 0;
        }

        @media (min-width: 1250px) {
            margin-bottom: 6px;
        }

        @media (min-width: 1300px) {
            margin-bottom: 12px;
        }

        @media (min-width: 1366px) {
            margin-bottom: 5px;
        }

        @media (min-width: 1430px) {
            margin-bottom: 0;
        }

        @media (min-width: 1500px) {
            margin-bottom: 0;
        }

        @media (min-width: 1900px) {
            margin-bottom: -5px;
        }
    }
}

.feature-project-title {
    
    @extend .new__typo__02;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.owl-carousel .owl-item {
    .featured-publication {
        .publication-image,
        .publication-content {
            float: left;
            width: 100%;
        }

        @media (min-width: $screen-sm-min) {
            .publication-image {
                width: percentage(2/3);
            }

            .publication-content {
                padding-left: 20px;
                width: percentage(1/3);
            }
        }

        img {
            display: block;
            height: auto;
            width: 100%;
        }

        .top-text {
            
            @extend .new__typo__06;
            color: $color-secondary-dark;
            font-size: 14px;
            line-height: 1.4;
            margin-bottom: 25px;
            margin-top: 28px;
            text-transform: uppercase;

            @media (min-width: $screen-sm-min) {
                margin-top: -0.3em;
            }
        }

        h3 {
            
            @extend .new__typo__29;
            line-height: 1.3;
            margin-bottom: 15px;
            text-transform: none;

            @media (min-width: $screen-sm-min) {
                margin-top: -0.3em;
                margin-bottom: 28px;
            }
        }

        .top-text + h3 {
            margin-top: 0;

            @media (min-width: $screen-sm-min) {
                margin-top: 0;
            }
        }

        .text {
            @extend .new__typo__09;
            line-height: 1.4;
            margin-bottom: 20px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            max-height: 8.125em;

            > * {
                display: inline;
                line-height: 1.625;
            }

            @media (min-width: $screen-sm-min) {
                margin-bottom: 23px;
            }
        }

        table {
            font-family: $montreal-regular;
            margin-bottom: 25px;

            td {
                padding: 2px 0;
                font-size: $font-size-16mn-18mx;
                line-height: 1.625;
                vertical-align: middle;

                &:first-child {
                    text-align: center;
                    padding-right: 12px;
                }
            }

            tr.more-info {
                a {
                    font-size: $font-size-16mn-18mx;
                    color: $color-secondary-dark;
                    line-height: 1.625;
                    text-decoration: none;

                    &:hover,
                    &:focus {
                        color: $color-secondary-dark;
                        text-decoration: none;
                    }
                }

                img.svg-icon {
                    width: auto !important;
                    margin-top: 0;
                }
            }

            tr:hover .svg-icon {
                filter: invert(34%) sepia(91%) saturate(5120%) hue-rotate(358deg) brightness(95%) contrast(92%);
            }

            i.fa {
                position: relative;
                font-size: fluid(16px, 19px);
            }
        }
    }
}

.services-sectors-slider {
    div {
        //text-transform: uppercase;
        color: $color-secondary-dark;
        @extend .new__typo__06; //Project (one)
    }
}

.col-custom-full {
    @media (max-width: 359px) {
        width: 100%;
    }
}
/*  INC020880524 - Conflict between Sitecore and Maglr for Owl Carousel */
.spread.spread_v11 .element.slideshow .owl-carousel div:nth-child(2),
.spread.spread_v11 .element.slideshow .owl-carousel div:nth-child(3) {
    display: none;
}
