.multi-list-view {
    h2 {
        color: $color-secondary-dark;
        @extend .new__typo__12; //Project (one)
        margin: 0 0 calc(17px + 1.4vw);
    }

    .mlv__title {
        margin-top: 15px;
        /*text-transform: uppercase;
        color: $color-primary;*/
        @extend .new__typo__12; //Services
    }

    .mlv__list {
        list-style: none;
        margin: 0 0 0 -15px;
        padding: 0;
    }

    .mlv__item {
        /*margin-bottom: 20px;*/
        margin-bottom: 28px;
        padding-top: 0;
        padding-bottom: 0;

        > div {
            display: table;
            width: 100%;
            /* min-height: 72px;
            height: 72px;*/
            min-height: 62px;
            height: 62px;
        }
    }

    .mlv__link {
        position: relative;
        /* @extend .new__typo__18;*/ //What we do, Services, Project (one), Job Opportunities
        letter-spacing: 0;
        font-family: $montreal-medium;
        /*font-size: 14px;*/
        font-size: 16px;
        line-height: 1.3;
        text-transform: none;
        color: $color-secondary-dark;
        display: table-cell;
        vertical-align: middle;
        border-bottom: 1px solid $color-primary;
        padding: 5px 40px 5px 20px;
        height: 100%;
        /*-webkit-transition: ease-in-out 0.3s;
        -o-transition: ease-in-out 0.3s;
        -moz-transition: ease-in-out 0.3s;
        transition: ease-in-out 0.3s;*/
        /*-webkit-transition: color 0.2s ease-in-out, background 0.4s ease-in-out;
        -o-transition: color 0.2s ease-in-out, background 0.4s ease-in-out;
        -moz-transition: color 0.2s ease-in-out, background 0.4s ease-in-out;
        transition: color 0.2s ease-in-out, background 0.4s ease-in-out;*/
        text-transform: none;
        text-decoration: none;
        /*line-height: 1.5;*/
        /*background: linear-gradient(to left, $bg-btn-grey 50%, $color-primary 50%);
        background-size: 200% 100%;
        background-position: right top;*/

        background: linear-gradient(to bottom, $bg-btn-grey 50%, $color-primary 50%);
        background-size: 100% calc(200% + 10px);
        background-position: 0 0;
        transition: color 0.2s ease-in-out, background-position 0.2s ease-in-out;

        @media (min-width: $screen-lg-min) {
            padding-right: 60px;
        }

        &:hover, &:active {
            color: $color-white;
            /*background-color: transparent;
            box-shadow: inset 100vw 0 0 0 $color-primary;*/


            background-position: 0 100%;

            &:before {
                background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4LjU2MSIgaGVpZ2h0PSIxNS43MDciIHZpZXdCb3g9IjAgMCA4LjU2MSAxNS43MDciPgogIDxwYXRoIGlkPSJpY24tYXJyb3ctbWVkaXVtLXdoaXRlLXJpZ2h0IiBkPSJNNy41LDE1LDAsNy41LDMuMTI1LDQuMzc1LDcuNSwwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3Ljg1NCAxNS4zNTQpIHJvdGF0ZSgxODApIiBmaWxsPSJub25lIiBzdHJva2U9IiNmNmY2ZjYiIHN0cm9rZS13aWR0aD0iMSIvPgo8L3N2Zz4K"); /* White arrow image for hover */
            }
        }

        &:before {
            /*@extend .icomoon;
            content: '\e903';*/
            content: '';
            display: inline-block;
            height: 16px;
            width: 9px;
            font-size: 6px;
            position: absolute;
            right: 25px;
            top: 50%;
            margin-top: -6px;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4LjU2MSIgaGVpZ2h0PSIxNS43MDciIHZpZXdCb3g9IjAgMCA4LjU2MSAxNS43MDciPgogIDxwYXRoIGlkPSJpY24tYXJyb3ctbWVkaXVtLXJlZC1yaWdodCIgZD0iTTcuNSwxNSwwLDcuNSwzLjEyNSw0LjM3NSw3LjUsMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNy44NTQgMTUuMzU0KSByb3RhdGUoMTgwKSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZTAyZjI4IiBzdHJva2Utd2lkdGg9IjEiLz4KPC9zdmc+Cg==");

            @media (min-width: $screen-lg-min) {
                right: 20px;
            }
        }
    }
}

.multi-list-view.Services {
    @media (min-width: $screen-md-min) {
        padding-right: 4vw;
    }

    &.full-width {
        @media (min-width: $screen-md-min) {
            padding-right: 0;
        }
    }


    .mlv__list {
        
        margin: 20px 0 0 -15px; //60px 0 0 -15px
        &.full-width {
            margin: 0 -15px;
            max-width: none;
        }
    }
   


    .mlv__item {
        padding-right: 0;

        @media (min-width: $screen-md-min) {
            padding-right: 15px;
        }
    }

    &.Related {

        .mlv__item {
            padding-right: 0;

            @media (min-width: $screen-lg-min) {
                padding-right: 15px;
            }

            text-transform: uppercase;
            color: $color-secondary-dark;
            @extend .new__typo__18;
        }

        h2 {
            color: $color-secondary-dark;
            @extend .new__typo__12;

            .orange-title & {
                @extend .new__typo__02;
                
            }
        }
    }
}

.multi-list-view.Sectors {
    @media (min-width: $screen-md-min) {
        padding-right: 4vw;
    }

    &.full-width {
        @media (min-width: $screen-md-min) {
            padding-right: 0;
        }
    }

    .mlv__list {
        max-width: 900px;
        margin: 20px 0 0 -15px; //60px 0 0 -15px
    }

    .mlv__item {
        padding-right: 0;

        @media (min-width: $screen-md-min) {
            padding-right: 15px;
        }
    }
    

    &.Related {
        .mlv__item {
            text-transform: uppercase;
            color: $color-secondary-dark;
            @extend .new__typo__18;
        }

        h2 {
            color: $color-secondary-dark;
            @extend .new__typo__12;
        }
    }
}



.multi-list-view.Servicev2 {
    @media (min-width: $screen-md-min) {
        padding-right: 4vw;
    }

    &.full-width {
        @media (min-width: $screen-md-min) {
            padding-right: 0;
        }
    }

    .mlv__list {
        
        margin: 20px 0 0 -15px; //60px 0 0 -15px
    }

    .mlv__item {
        padding-right: 0;

        @media (min-width: $screen-md-min) {
            padding-right: 15px;
        }
    }
    

    &.Related {
        .mlv__item {
            text-transform: uppercase;
            color: $color-secondary-dark;
            @extend .new__typo__18;
        }

        h2 {
            color: $color-secondary-dark;
            @extend .new__typo__12;
        }
    }
}





.publications-title {
    
    @extend .new__typo__02;
}

.publications-content,
.sector-content {
    margin-left: -15px;
    margin-right: -15px;

    .services-sectors-slider-title {
        h2 {
            color: $color-secondary-dark;
            @extend .new__typo__12;
        }
    }

    p, div {
        width: 100%;
        color: $color-secondary-dark;
        @extend .new__typo__09;
        line-height: 1.4;
    }

    &-title {
        color: $color-secondary-dark;
        @extend .new__typo__12;
    }

    .date {
        display: block;
        margin: 26px 0 15px 0;
        text-transform: uppercase !important;
        color: $color-secondary-dark;
        font-family: $montreal-medium;
        font-size: 16px;
    }



    .more-info {
        td {
            vertical-align: middle;
        }

        td:first-child {
            padding-right: 10px;
        }

        .svg-icon {
            margin-top: 5px;
            vertical-align: top;
        }

        a {
            font-size: $font-size-16mn-18mx;
        
            &:hover {
                color: $color-secondary-dark;

            }
        }
            

        svg {
            margin-top: 4px;
        }

        tr:hover .svg-icon {
            filter: invert(34%) sepia(91%) saturate(5120%) hue-rotate(358deg) brightness(95%) contrast(92%);
        }
    }
}

.publications-content {
    .date {
        text-transform: initial;
    }

    .thumbnail a > img {
        max-width: none;
        width: 100%;
        height: auto;
    }
}

.services-sectors-slider-title {
    h2 {
        color: $color-secondary-dark;
        @extend .new__typo__12; 
    }
}

.service-list + .btn {
    margin-top: 35px;

    &-title {
        color: $color-secondary-dark;
        @extend .new__typo__12;
    }
}

.service-list-title {
    color: $color-secondary-dark;
    @extend .new__typo__12;
}

/****** code for the sticky buttons on the Careers section ******/
.teaser-job {
    margin-top: 20px;

    > div {
        display: table;
        width: 100%;
    }

    .mlv__link {
        /*@extend .new__typo__18;*/ /*Careers*/
        
        /*min-height: 0;*/
        /*border-bottom: 2px solid $color-primary;*/
        color: $color-white;
        /*background: $color-primary;*/
        padding: 15px 40px 15px 20px;
        background: linear-gradient(to left, $color-primary 50%, $color-dark-primary 50%);
        background-size: 200% 100%;
        background-position: right top;

        @media (min-width: $screen-md-min) {
            padding: 15px 50px 15px 20px;
        }

        &:hover, &:active {
            /*background-color: $color-dark-primary;
            border-bottom: 2px solid $color-dark-primary;*/
            /*color: $color-white;
            background-color: transparent;*/
            
            background-position: left top;
        }

        &:before {
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4LjU2MSIgaGVpZ2h0PSIxNS43MDciIHZpZXdCb3g9IjAgMCA4LjU2MSAxNS43MDciPgogIDxwYXRoIGlkPSJpY24tYXJyb3ctbWVkaXVtLXdoaXRlLXJpZ2h0IiBkPSJNNy41LDE1LDAsNy41LDMuMTI1LDQuMzc1LDcuNSwwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3Ljg1NCAxNS4zNTQpIHJvdGF0ZSgxODApIiBmaWxsPSJub25lIiBzdHJva2U9IiNmNmY2ZjYiIHN0cm9rZS13aWR0aD0iMSIvPgo8L3N2Zz4K"); /* White arrow image for hover */
        }

        &:after,
        &:before {
            right: 20px;
            /*@media (min-width: $screen-lg-min) {
                right: 20px;
            }*/
        }
    }
}

.sticky-div {
    width: 100%;
    pointer-events: none;
    z-index: 100;

    .col-md-6 {
        pointer-events: auto;
    }

    .col-md-6 + .col-md-6 {
        padding-left: 15px;
        padding-right: 0;
    }

    @media (min-width: $screen-xs-min) {
        width: calc(100vw - 8vw);
    }

    @media (min-width: $screen-view-condition-min) {
        width: calc(#{$screen-laptop-min} + 30px);
    }

    .child {
        pointer-events: auto;
    }
}


.tag-list {
    list-style: none;
    margin: 0;
    padding: 0;

    .tag-list-item {
        float: left;
        margin-right: 20px;
        font-size: 14px;
        font-family: $montreal-medium;
        margin-bottom: 23px;
        //color: $color-secondary-on;
        cursor: default;

        a {
            color: $color-secondary-dark;

            &:hover {
                color: $color-primary;
                text-decoration: none;
            }
        }

        span {
            display: inline-block;
            margin-right: 2px;
        }
        /*&:hover {
            color: $color-secondary-on-hover;
        }*/
        @media (min-width: $screen-xs-min) {
            margin-bottom: 13px;
        }
    }
}
