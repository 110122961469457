﻿button.rounded-play-button {
    vertical-align: middle;
    display: inline-block;
    text-decoration: none;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    line-height: 1;
    border: 0;
    background-color: $color-secondary;
    border-radius: 50%;
    height: 58px;
    left: 0%;
    margin: auto;
    padding: 0 0 0 4px;
    position: absolute;
    right: 0%;
    top: calc( 50% - 62px);
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
    width: 58px;
    z-index: 99999999999;

    @media (min-width: $screen-md-min) {
        height: 78px;
        top: calc( 50% - 72px);
        width: 78px;
    }

    &:hover,
    &:focus {
        background-color: #3d5f7b;
        border: 10px solid #3d5f7b;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;

        @media (min-width: $screen-md-min) {
            border-width: 20px;
        }

        svg {
            -webkit-transform-origin: center center;
            transform-origin: center center;
            -webkit-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;
            -webkit-transform: rotate(-180deg) rotateY(180deg);
            transform: rotate(-180deg) rotateY(180deg);
        }

        path {
            fill: $color-white;
        }
    }

    svg {
        margin-top: 2px;
        height: 20px;
        width: 17px;
    }

    path {
        fill: $color-white;
    }
}
