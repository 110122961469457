﻿.language-selector {
    display: none;

    @media (min-width: $screen-lg-min) {
        display: block;
    }

    .pagemode-edit & {
        overflow-y: scroll;
        height: 63px;
    }

    .ls__link {
        font-size: 11px;
        font-family: $montreal-regular;
        color: rgb(30, 37, 43);
        text-transform: uppercase;
        line-height: 3.4;
    }
}
