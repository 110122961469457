.fixheight img:not(.image-no-fixheight > img) {
    @media (min-width: $screen-md-min) {
        min-height: auto;
    }
}

.container-alignment {
    position: relative;
    z-index: 1;
    min-height: 50vh;
    overflow: hidden;

    @media (min-width: $screen-md-min) {
        min-height: 80vh;
    }

    .teaser-bg {
        background-position: right center;
        background-repeat: no-repeat;
        background-size: 100%;
        min-height: 50vh;
        width: 100%;
        z-index: 1;
        margin-bottom: calc(35px + 2.1vw);
        position: relative;
        transition: background-size 0.3s;

        &:after {
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(255, 255, 255, 0.15);
            width: 100%;
            height: 100%;
        }

        @media (min-width: $screen-md-min) {
            background-position: center;
            position: absolute;
            top: 0;
            height: 100%;
            min-height: 70vh;
            margin-bottom: 0;

            &:after {
                content: "";
            }
        }

        .camp-mob-only {
            display: none;
        }
    }

    .teaser-alignment {
        padding: 0 4vw;
        z-index: 2;

        @media (min-width: $screen-md-min) {
            width: 42vw;
            padding: calc(4vw + 15px);
            display: table;
            position: absolute;
            box-sizing: content-box;
        }

        @media (min-width: $screen-lg-min) {
            width: 39vw;
            padding: calc(4vw + 15px);
            display: table;
            position: absolute;
            box-sizing: content-box;
        }

        @media (min-width: $screen-view-condition-min) {
            width: 28vw;
            padding: calc(1vw + 15px);
            display: table;
            position: absolute;
            box-sizing: content-box;
        }

        .content {
            display: table-cell;
        }

        &.top-alignment .content {
            vertical-align: top;
        }

        &.bottom-alignment .content {
            vertical-align: bottom;
        }

        &.middle-alignment {
            vertical-align: middle;

            @media (min-width: $screen-md-min) {
                -webkit-transform: translate(0%, -50%);
                transform: translate(0%, -50%);
                top: 50%;
            }
        }

        &.left-alignment {
            left: 0;

            @media (min-width: $screen-md-min) {
                position: absolute;
            }
        }

        &.right-alignment {
            right: 0;

            @media (min-width: $screen-md-min) {
                position: absolute;
            }
        }

        &.center-alignment {
            margin: 0;
            top: 50%;
            left: 50%;

            @media (max-width: $screen-md-min) {
                left: 0px;
            }

            @media (min-width: $screen-md-min) {
                position: absolute;
                margin-right: -50%;
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }
        }
    }
}


.fixheight {
    .thumbnail {
        margin-bottom: 0;
    }
}

/* 6261 - Review Margins and Paddings for Component which has text, description, button on banner */
.txt-img-box {
    margin-top: 25px;
    margin-bottom: 25px;
}

.txt-img-box .container-alignment {
    min-height: 650px;
    overflow: hidden;

    &.h-small {
        min-height: clamp(300px, 40vw, 400px);
    }

    &.h-medium {
        min-height: clamp(430px, 40vw, 650px);
    }

    &.h-large {
        min-height: clamp(500px, 40vw, 800px);
    }

    .teaser-alignment {
        position: absolute;

        &.middle-alignment {
            -webkit-transform: translate(0,-50%);
            -ms-transform: translate(0,-50%);
            transform: translate(0,-50%);
            top: 50%;
        }

        &.right-alignment {
            @media (max-width:$screen-sm-max) {
                padding: 0 4vw;
            }
        }

        .content {
            h2 {
                font-size: $font-size-31mn-39mx-h3;
                margin-bottom: clamp(28px, 2.5vw, 32px);
            }

            p {
                margin-bottom: clamp(22px, 2.5vw, 30px);

                &:empty {
                    display: none;
                }
            }

            a {
                margin: 0;
            }

            a:not(.btn) {
                text-decoration: underline;

                &:hover, &:active {
                    text-decoration: underline;
                }
            }

            a.btn + a.btn {
                @media (max-width:$screen-sm-max) {
                    margin-top: 20px !important;
                }
                /*@media (min-width:$screen-lg-min) {
                    margin-left: 20px !important;
                }*/
            }

            @media (min-width:$screen-md-min) {
                a.btn:first-of-type {
                    margin-right: 20px !important;
                    margin-bottom: 20px !important;
                    float: left;
                }
            }

            p:has(> a) {
                margin-bottom: 0;
            }

            div:not(:has(p)) {
                margin-bottom: clamp(22px,2.5vw,30px);
            }

            &::after {
                content: "";
                display: block;
                clear: both;
            }
        }

        &.left-alignment {
            position: absolute;
            z-index: 10;
            padding: 0 20px 0 60px;
            top: 50%;

            @media (max-width:$screen-sm-max) {
                padding: 0 4vw;
            }
        }
    }

    .teaser-bg {
        min-height: inherit;
        margin-bottom: 0;
        margin-top: 0;
        position: absolute;
        z-index: -1;

        @media (max-width:$screen-sm-max) {
            &.bg-mob-img-left {
                background-position: left !important;
            }

            &.bg-mob-img-center {
                background-position: center !important;
            }

            &.bg-mob-img-right {
                background-position: right !important;
            }
        }
    }
}

.txt-img-box h2.redttl {
    font-size: calc(.18779vw + 14px);
}



@media screen and (min-width:$screen-view-condition-min) {
    .txt-img-box .container-alignment .teaser-alignment.left-alignment {
        position: absolute;
        z-index: 10;
        padding: 0 20px 0 0;
        top: 50%;
        left: 50%;
        margin-left: calc(#{$screen-laptop-min}/-2);
    }

    .txt-img-box .container-alignment .teaser-alignment.right-alignment {
        position: absolute;
        z-index: 10;
        padding: 0 0 0 20px;
        top: 50%;
        right: 50%;
        margin-right: calc(#{$screen-laptop-min}/-2);
    }

    .txt-img-box h2.redttl {
        font-size: calc(.18779vw + 14px);
    }
}
/* 587 - Alignable Text on Background Image - Teaser theme not working -- start */
.txt-img-box.txt-theme {
    .container-alignment {
        .teaser-alignment {


            &.light-theme {

                h1,
                h2 {
                    color: $color-secondary-dark;
                }

                div,
                p {
                    color: $color-secondary-dark;
                }
            }

            &.dark-theme {

                a:not(.btn) {
                    color: $color-white;
                    text-decoration: underline;

                    &:hover, &:active {
                        color: $color-white;
                        text-decoration: underline;
                    }
                }

                .btn.btn-tertiary {
                    color: $color-secondary-dark;
                    font-size: $btn-fonts-size;
                    background: linear-gradient(to bottom, $bg-btn-grey 50%, $bg-btn-dark-grey 50%);
                    background-size: 100% 200%;
                    background-position: 0 0;
                    transition: background-position 0.2s ease-in-out;

                    &:hover,
                    &:active {
                        color: $color-secondary-dark;
                        background-position: 0 100%;
                    }
                }

                h1, h2, div, p {
                    color: $color-white;
                }

                a.btn {
                    color: $color-white;

                    &.btn-tertiary {
                        color: initial;
                    }
                }
            }
        }
    }
}
/* 587 - Alignable Text on Background Image - Teaser theme not working -- end */
/* 175 - offices project css starts here */
.campaign-theme {
    .txt-img-box.txt-theme {
        margin-top: 0;
        margin-bottom: 0;

        @media (min-width: $screen-md-min) and (max-width:$screen-md-max) {
            margin-bottom: 20px;
        }

        .container-alignment {


            .teaser-bg {
                min-height: auto;



                .camp-mob-only {
                    display: none !important;
                }
            }

            @media (max-width:$screen-sm-max) {
                /* min-height: auto;

                .teaser-bg {
                    min-height: auto;
                    background: none !important;

                    .camp-mob-only {
                        display: block !important;
                    }
                }*/


                min-height: 250px;

                .teaser-bg {
                    min-height: 250px;

                    .camp-mob-only {
                        display: block !important;
                    }
                }
            }


            @media (max-width:500px) {
                min-height: 220px;

                .teaser-bg {
                    min-height: 220px;
                }
            }


            @media (min-width: $screen-md-min) and (max-width:$screen-md-max) {
                min-height: 280px;
            }

            @media (min-width: $screen-lg-min) {
                min-height: 330px;
            }

            @media (min-width: 1500px) {
                min-height: 390px;
            }

            .teaser-alignment {
                @media (min-width: $screen-md-min) and (max-width:$screen-md-max) {
                    width: 48vw;
                }

                h2 {
                    font-family: $montreal-bold;
                    font-size: calc(.73vw + 16px); /* = 30px*/

                    color: $color-secondary-dark;
                    line-height: 1.2;
                }

                p {
                    font-family: $montreal-regular;
                    font-size: $font-size-16mn-20mx;
                }

                .btn {
                    margin-bottom: 15px;
                }

                &.light-theme {

                    h1,
                    h2 {
                        color: $color-secondary-dark;
                    }

                    div,
                    p {
                        color: $color-secondary-dark;
                    }
                }

                &.dark-theme {


                    .btn.btn-tertiary {
                        color: $color-white;
                        background: linear-gradient(to bottom, $bg-btn-grey-on-dark-bg 50%, $bg-btn-dark-grey-on-dark-bg 50%);
                        background-size: 100% 200%;
                        background-position: 0 0;
                        transition: background-position 0.2s ease-in-out;

                        &:hover,
                        &:active {
                            background-position: 0 100%;
                        }
                    }

                    h1, h2, div, p {
                        color: $color-white;
                    }

                    a.btn {
                        color: $color-white;

                        &.btn-tertiary {
                            color: initial;
                        }
                    }
                }
            }
        }
    }
}
/* 175 - offices project css ends here */
/* 418 - Banner component upgrades - start */
.txt-img-box {
    .overlay-style {
        .teaser-bg {

            &.overlay-black {
                /*box-shadow: inset 0 0 0 50vw rgba(20, 18, 18, 0.15);*/

                &.opacity-15 {
                    box-shadow: inset 0 0 0 50vw rgba(20, 18, 18, 0.15);
                }

                &.opacity-30 {
                    box-shadow: inset 0 0 0 50vw rgba(20, 18, 18, 0.3);
                }

                &.opacity-45 {
                    box-shadow: inset 0 0 0 50vw rgba(20, 18, 18, 0.45);
                }

                &.opacity-60 {
                    box-shadow: inset 0 0 0 50vw rgba(20, 18, 18, 0.6);
                }

                &.opacity-75 {
                    box-shadow: inset 0 0 0 50vw rgba(20, 18, 18, 0.75);
                }

                &:after {
                    background-color: unset !important;
                }
            }

            &.overlay-white {
                &.opacity-15 {
                    box-shadow: inset 0 0 0 50vw rgba(255, 255, 255, 0.15);
                }

                &.opacity-30 {
                    box-shadow: inset 0 0 0 50vw rgba(255, 255, 255, 0.3);
                }

                &.opacity-45 {
                    box-shadow: inset 0 0 0 50vw rgba(255, 255, 255, 0.45);
                }

                &.opacity-60 {
                    box-shadow: inset 0 0 0 50vw rgba(255, 255, 255, 0.6);
                }

                &.opacity-75 {
                    box-shadow: inset 0 0 0 50vw rgba(255, 255, 255, 0.75);
                }

                &:after {
                    background-color: unset !important;
                }
            }

            &.overlay-none {
                background-color: transparent;

                &:after {
                    background-color: unset !important;
                }
            }
        }
    }
}
/* 418 - Banner component upgrades - end */
