﻿.logo {
    font-size: 0;
    height: auto;
    width: 100%;
    max-width: 64px;
    float: left;
    /*margin: 18px 0 10px 0;*/
    margin: 18px 0 14px 0;

    @media (max-width: $screen-md-max) {

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            /* IE10+ CSS */
            height: 30px;
        }
    }

    .st0 {
        fill: #F05044 !important;
    }


    @media (min-width: $screen-lg-min) {
        float: left;
        width: percentage(80/1320);
        margin: 0 35px 0 0; /* margin: 0 4.375vw 0 0; - arrow alignment issue on laptop 1366x768 in en-US for Investors and Contact us */ /* 9Jan2020 3.375vw to 2vw */ /* 8Feb2020  margin: 0 2vw 0 0; */
        max-width: 80px;
        padding: 26px 0 58px 0; /*padding: 42px 0 22px 0;*/ /* 36px 0 22px 0; */
        .affix & {
            /*padding: 26px 0 12px 0;*/
            padding: 26px 0 58px 0;
        }
    }
}

.lt-preview {
    div {
        color: $color-secondary-dark;
        @extend .new__typo__12; //WSP Logo
    }
}

.lt-info {
    .lt-type {
        div {
            color: $color-secondary-dark;
            @extend .new__typo__12; //WSP Logo
        }

        color: $color-secondary-dark;
        @extend .new__typo__06; //WSP Logo
    }

    .lt-size {
        div {
            color: $color-secondary-dark;
            @extend .new__typo__12; //WSP Logo
        }

        color: $color-secondary-dark;
        @extend .new__typo__06; //WSP Logo
    }

    .lt-link {
        div {
            color: $color-secondary-dark;
            @extend .new__typo__12; //WSP Logo
        }

        color: $color-secondary-dark;
        @extend .new__typo__06; //WSP Logo
    }
}
