.owl-carousel {
    display: block;
    opacity: 0;
    width: 100%;

    .owl-stage-outer {
        display: none;
        overflow: hidden;
        position: relative;
    }

    &.owl-loaded {
        opacity: 1;

        .owl-stage-outer {
            display: block;
        }
    }

    .owl-item {
        padding: 20px;
    }
}
