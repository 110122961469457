.blockquote {
    margin: 0;

    &.row {
        margin: 48px 0;

        @media (min-width: $screen-md-min) {
            margin: 64px 0;
        }
    }

    .blockquote-bloc {
        padding: 52px 0;
        border-width: 1px 0; /* Top and bottom borders only */
        border-style: solid;
        border-color: $color-quote-medium-gray;
        display: table;

        .quote-text {
            color: $color-secondary-dark;
            font-family: $montreal-medium;
            font-size: $font-size-25mn-39mx-quote;
            line-height: 1.2288;
            padding-left: 0;
            display: inline-block;
            padding-right: 0;


            &:before {
                content: open-quote;
                display: inline;
            }

            &:after {
                content: close-quote;
                display: inline;
            }
        }
        /* 186 - Fix spacing on quote component */
        .quote-text:lang(fr) {
            &:before {
                margin-right: 9px;
            }

            &:after {
                margin-left: 9px;
            }
        }

        .author {
            color: $color-secondary-dark;
            text-align: left;
            padding-right: 0;
            padding-left: 0 !important;
            margin-top: 36px;
            line-height: 1.3;
            font-size: $font-size-16mn-20mx;

            .author-name {
                display: block;
                font-family: $montreal-bold;
                line-height: 1.4;
                font-size: 16px;
            }

            .author-location {
                display: block;
                font-family: $montreal-regular;
                line-height: 1.4;
                font-size: 16px;
            }
        }
    }
}

@media (min-width: $screen-view-condition-min) {

    .medium-margin-children blockquote.blockquote {
        padding-right: 0;
        padding-left: 0;
        width: $screen-laptop-min;
        margin-left: auto;
        margin-right: auto;
    }

    .col-xs-12.col-md-9.big-padding-left blockquote.blockquote {
        width: auto;
    }
}
