﻿.call-to-actions-box {
    display: table;
    width: 100%;
    border-top: 1px solid #e7e7e7;

    &:last-child {
        border-bottom: 1px solid #e7e7e7;
    }

    @media (min-width: $screen-md-min) {
        width: 100%;
        border: 1px solid #e7e7e7;
    }

    .fixheight {
        padding: 50px 0;

        @media (min-width: $screen-sm-min) {
            display: table-cell;
            padding: 85px percentage(104px / 703px);
            vertical-align: middle;
        }

        .title {
            color: $color-primary;
            @extend .new__typo__12; //Spontaneous applications
            max-width: percentage(280px / 345px);
            margin-bottom: 30px;
        }


        .title-big {
            color: $color-primary;
            line-height: 1.2;
            @extend .new__typo__02; //Spontaneous applications
            max-width: percentage(280px / 345px);
            margin-bottom: 45px;
        }

        p {
            max-width: percentage(280px / 345px);
            @extend .new__typo__03; //Spontaneous applications
            line-height: 1.66;
            margin-bottom: 26px;
        }
    }

    .btn {
        @media (min-width: $screen-md-min) {
            width: 100%;
            white-space: nowrap;
            padding: 12px 0;
        }
    }
}
