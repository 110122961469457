.logo {

    &-image {
        width: 180px;

        @media all and (max-width: $screen-sm-max) {
            width: 110px;
        }
    }

    .tagline {
        display: block;

        @media (max-width: $screen-xs-max) {
            display: none;
        }

        .navbar-center .navbar-brand & {
            text-align: center;
        }

        .bg-primary &, .bg-secondary &, .bg-transparent & {
            color: $logo-tagline-color;
        }

        .bg-white & {
            color: $color-black;
        }
    }

    img {
        height: 40px; /* 50px original value*/
        width: auto !important;
        max-width: 215px;
        margin-top: 0px;

        .has-tagline & {
            height: 35px;
            width: auto !important;
            max-width: 150px;
            margin-top: 0px; 

            @media (max-width: $screen-xs-max) {
                margin-top: 5px;
            }
        }

        @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
            height: 35px;
            width: auto !important;
            max-width: 150px;
        }
        @media (min-width: $screen-md-max) and (max-width: 1400px) {
            height: 35px; /* 38px */
            width: auto !important;
            max-width: 150px;
        }
        @media all and (max-width: $screen-sm-max) {
            height: 29px; /*35 original value */
            width: auto !important;
            max-width: 150px;

            .has-tagline & {
                height: 25px;
                width: auto !important;
                max-width: 110px;
            }
        }
    }
}
