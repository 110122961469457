﻿.thumbnail {
    margin-bottom: 30px;
    max-width: 100%;

    .caption {
        margin: 0 auto;

        a {
            &.img-link {
                font-family: $montreal-regular;
                text-decoration: none;
                line-height: 1.4;
                
                font-size: $font-size-20mn-25mx-h5;
                

                &:hover, &:active {
                    text-decoration: underline;
                    color: $color-primary;
                }
            }
        }

        p {
            &.img-txt {
                font-size: 14px;
                font-family: $montreal-medium;
                text-transform: uppercase;

                @media (min-width: $screen-lg-min) {
                    font-size: 16px;
                }
            }
        }
    }


    + .caption {
        margin: 0 auto;
        margin-bottom: 30px;
        max-width: 100%;

        a {
            &.img-link {
                font-family: $montreal-regular;
                text-decoration: none;
                line-height: 1.4;
                
                font-size: $font-size-20mn-25mx-h5;
                /*@media (max-width: $screen-md-max) {
                    font-size: calc(15px + 1vw);
                }*/

                &:hover, &:active {
                    text-decoration: underline;
                    color: $color-primary;
                }
            }

            &.img-link-bold {
                font-family: $montreal-bold;
                text-decoration: none;
                line-height: 1.3;
                font-size: $font-size-20mn-25mx-h5;

                &:hover, &:active {
                    text-decoration: none;
                    color: $color-secondary-medium-gray;
                }
            }
        }

        p {
            &.img-txt {
                font-size: 14px;
                font-family: $montreal-medium;
                text-transform: uppercase;

                @media (min-width: $screen-lg-min) {
                    font-size: 16px;
                }
            }
        }
    }

    &.full-width {
        img {
            width: 100%;
        }

        .caption {
            margin: 0 4vw;
        }
    }
}
/* Global hover effect on thumbnail image // start */
* {
    > .thumbnail:has(a) {
        position: relative;
        display: block;
        
        overflow: hidden;
        width: 100%;

        > a {
            display: block;
            overflow: hidden;

            img {
                
                width: 100%;
                height: auto;
                display: block;
                transition: opacity 0.5s ease; 
            }
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            width: 150%; /* Extend the flare to cover more area */
            height: 150%;
            background: radial-gradient( farthest-corner at 100% 100%, /* Start from bottom-right corner */
            rgba(255, 55, 47, 1) 0%, /* Semi-transparent red */
            rgba(255, 55, 47, 0) 50% /* Fade to transparent */
            );
            opacity: 0; /* Start with the flare being invisible */
            z-index: 1;
            pointer-events: none;
            transition: opacity 0.5s ease, background 0.5s ease; /* Smooth transition for flare opacity and background */
        }
        

        &:hover:not(:has(.caption))::after { /* Exclude effect if .caption exists */
            opacity: 1; /* Make the flare fully visible on hover */
        }
    }

    &:hover {
        > .thumbnail a img {
            /*transform: scale(1.1);*/
            /*opacity: 0.8;*/ /* Slight fade on the image when hovered */
            opacity: 1;
        }
    }
}
/* Global hover effect on thumbnail image // end */