﻿/*
Used desktop mode only
Used when clic on .country-language-selector button
related to country-language-selector.js.
*/

.overlayIsActive {
    @media (min-width: $screen-lg-min) {
        &:before {
            /*background: rgba(0, 0, 0, 0.7);*/
            background: $color-secondary-dark;
            opacity: 0.7;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            /*z-index: 4;*/
            z-index: 201;
            /*-webkit-transition: opacity 1s ease-out;
            -o-transition: opacity 1s ease-out;
            -moz-transition: opacity 1s ease-out;
            transition: opacity 1s ease-out;*/
        }
    }
}
