.tabs {
  &-nav {
    position: relative;
    li {
      & > a {
        padding-top: 40px;
        padding-bottom: 40px;
        opacity: 0.6;
        border: 1px solid $gray;
        margin-left: -1px;
        color: $text-color;
      }
      &:hover {
        opacity: 1;
        & > a {
          color: $brand-primary;
          border: 1px solid $gray;
        }
      }
      &.active {
        & > a {
          color: $brand-primary;
          opacity: 1;
        }

      }
    }
  }
}
