﻿
.hamburger-button {

    @media (min-width: $screen-lg-min) {
        display: none;
    }

    &.isActive svg {
        g:last-child,
        g:first-child {
            /*-webkit-transform: translate(0, 0);
            transform: translate(0, 0);*/
        }
    }

    .hb__btn {
        vertical-align: middle;
        display: inline-block;
        text-decoration: none;
        white-space: nowrap;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: pointer;
        border: 0;
        border-radius: 0;
        background-color: transparent;
        line-height: 0;
        padding: 20px 0 21px;

        &:hover,
        &:focus,
        &:active {
            /*svg path {
                fill: rgb(255, 67, 55);
            }*/
        }
    }

    svg {
        width: 24px;
        height: 18px;
        padding-left:3px;
        padding-right:2px;

        /*g {
            -webkit-transition: all 0.4s ease-in-out;
            transition: all 0.4s ease-in-out;
        }

        g:first-child {
            -webkit-transform: translate(0, 7px);
            transform: translate(0, 7px);
        }

        g:last-child {
            -webkit-transform: translate(0, -7px);
            transform: translate(0, -7px);
        }*/
    }
}
