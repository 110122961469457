.section, section {
    .home & {
        margin: 0px 0;
        padding: 50px 0;
    }

    main > & {
        &:first-child {
            padding-top: 30px;
        }
    }

    &.section-full {
        width: 100%;

        .container-fluid, .container.full-width {
            @media (min-width: $screen-view-condition-min) {
                max-width: $screen-laptop-min;
            }
        }

        .container.full-width {
            padding: 0;
        }
    }

    &.bg-dark, &.bg-white, &.bg-media, &.bg-primary {
        .jumbotron {
            background-color: transparent;
        }
    }

    .flex {
        @media (min-width: $screen-md-min) {
            display: flex;
        }
    }

    .flex-btn {

        @media(max-width:$screen-sm-max) {
            .btn-tertiary {
                margin-top: 15px;
            }
        }

        @media (min-width: $screen-md-min) {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
    }

    .flex-btn-right {

        @media(max-width:$screen-sm-max) {
            .btn-tertiary {
                margin-top: 15px;
            }
        }

        @media (min-width: $screen-md-min) {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }

    &.contact-bg {
        /*background-color: #333D47;*/
        background-color: $color-white;
        /*padding: 50px 0;*/
        padding: 0;
        padding-top: clamp(50px, 5vw, 80px);
        padding-bottom: clamp(50px, 5vw, 80px);

        @media (min-width:$screen-md-min) and (max-width:$screen-md-max) {
            margin-bottom: 30px;
        }

        h3, p {
            color: $text-color;
        }



        h3 {
            font-size: $font-size-39mn-48mx-h2;
            line-height: 1.1;
            padding-bottom: 15px;
        }

        p {
            display: none;
            font-family: $montreal-regular;
            font-size: $font-size-16mn-20mx;
            margin-top: 10px;
            margin-bottom: 0;

            @media(max-width:$screen-sm-max) {
                margin-bottom: 20px;
            }
        }

        a.btn {
            letter-spacing: 0;
        }
    }
}

[data-show-more] {
    position: relative;
    overflow: hidden;

    &.transitioning {
        transition: height 0.5s ease-out;
    }

    .inner {
        p:first-child,
        div:first-child {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
        }
    }


    &.open {
        -webkit-line-clamp: unset;

        .inner {
            p:first-child,
            div:first-child {
                -webkit-line-clamp: unset;
            }
        }
    }

    + div {
        .read-less,
        .read-more {
            float: right;
        }

        .read-less {
            display: none;
        }
    }
}
/* 6318 - Website display in different screen resolutions */

.section.section-full.bod {

    @media (min-width: $screen-view-condition-min) {
        max-width: $screen-laptop-min;
        margin-left: auto;
        margin-right: auto;
    }
}

.section-full .container-fluid.full-width {
    max-width: 100%;
}


.tablelayout.flex-order-image {
    margin-bottom: 15px !important;
}

.tablelayout.flex-order-image .teaser-summary {
    margin-top: 35px;
}

.tablelayout.flex-order-image .teaser-summary .btn {
    margin-top: 35px;
}


@media (min-width: $screen-md-min) {
    .w-75p {
        width: 75%;
        display: block;
    }
}

@media (max-width: $screen-sm-max) {
    .rev-order-on-mob {
        .row:first-child {
            display: flex;
            display: -ms-flex;
            flex-direction: column;
            /* 2 column 9-3 full width - start */
            .col-md-9 {
                order: 2;
            }

            .col-md-3 {
                order: 1;
            }
            /* 2 column 9-3 full width - end */
            /* 2 column 6-6 full width - start */
            .col-md-6:nth-child(1) {
                order: 2;
            }

            .col-md-6:nth-child(2) {
                order: 1;
            }
            /* 2 column 6-6 full width - end */
            /* 2column6-6 - start */
            .col-sm-6:nth-child(1) {
                order: 2;
            }

            .col-sm-6:nth-child(2) {
                order: 1;
            }
            /* 2column6-6 - end */
        }
    }
}
