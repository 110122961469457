﻿.wsp-forms {

    &.generic {
        label {
            margin-bottom: 1em;
            font-family: $montreal-medium !important;
            color: $color-secondary-dark;
            line-height: 1.625;
            display: block;
            font-size: 14px;
        }

        label.control-label {
            margin-bottom: 1em !important;
            font-family: $montreal-medium !important;
            color: $color-secondary-dark !important;
            line-height: 1.625 !important;
            display: block !important;
            font-size: 14px !important;
        }

        select, #categories-button {
            border-bottom: 1px solid $color-dark-red !important;
            background-color: $color-white;
            /*border-bottom: 1px solid $dropdown-bottom-line-normal;
            background-color: $color-secondary-lightest;
            box-shadow: 0px 0px 1px lightgray;*/
        }

        select {
            -webkit-appearance: revert;
            -moz-appearance: revert;
            appearance: revert;
            outline: unset !important;
            box-shadow: unset !important;
            border-bottom: 1px solid $color-dark-red !important;
            background: $color-secondary-lightest !important;
            background-image: unset !important;
            flex: 1;
            padding: 0 1em !important;
            color: $color-secondary-dark !important;
            cursor: pointer;
        }
        /* Remove IE arrow */
        select::-ms-expand {
            display: unset !important;
        }

        input.checkbox-list[type="checkbox"] {
            width: 15px;
            height: 15px;
            display: inline;
            content: ' ';
            background-color: #f2f2f2;
            border: 1px solid $color-secondary-dark;
            /*top: -2px;*/
            visibility: visible;
            margin-top: 0;
            margin-bottom: 0;
            margin-right: 20px;
        }

        label > input.checkbox-list[type="checkbox"]:before {
            visibility: hidden;
        }

        .checkbox-container {
            @media (min-width:$screen-md-min) {
                column-count: 2;
            }

            label.main-label {
                column-span: all;
            }

            label:not(.main-label) {
                display: flex;
                align-items: flex-start;

                input.checkbox-list[type=checkbox] {
                    margin-top: 4px;
                }
            }
        }

        .required-fields-text {
            font-family: $montreal-medium !important;
            color: $text-color;
            line-height: 1.625;
            display: block;
            margin-bottom: 24px;
            font-size: 12px !important;
            position: relative; /*position relative added to have proper gap between captcha box and checkbox row*/
            right: 0;
            text-align: right; /* aligned text to the right of Div container*/
        }
    }



    .form-control {
        background-color: $color-secondary-lightest;
        border-top: 0;
        border-right: 0;
        border-left: 0;
        border-bottom: 1px solid $dropdown-bottom-line-normal;
        @extend .new__typo__08; //Contact-us
        color: rgba(51, 62, 72, 0.502) !important;
        line-height: 2.357;
        margin-bottom: 37px;
        font-size: 14px;

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #898C8F;
            opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #898C8F;
        }

        &::-ms-input-placeholder { /* Microsoft Edge */
            color: #898C8F;
        }
    }

    .form-group {
        label {
            @extend .new__typo__09; //Contact-us
            color: rgb(30, 37, 43);
            line-height: 1.625;
            display: block;
            margin-bottom: 24px;
        }

        &.required-field .control-label:before,
        &.required-field .checkbox-right:before,
        &.required-field .checkbox:after {
            color: $color-primary;
            margin-right: 5px;
        }

        &.required-field .control-label:before, &.required-field .checkbox-right:before {
            content: '*';
        }
    }

    .form-submit-border {
        .btn.btn-default {
            background-color: $color-secondary;
            padding: 12px 30px;
            color: $color-white;
        }
    }

    .halfAvailableWidth.form-group {
        margin: 0 20px 0 0;
        width: calc(50% - 10px);
    }

    .halfAvailableWidth.form-group + .halfAvailableWidth.form-group {
        margin: 0 0 0 0;
    }

    .has-error .form-control-feedback, .has-error .help-block {
        color: $color-primary;
        margin-top: -30px;
        margin-bottom: 40px;

        .newsletter-form & {
            margin-top: 10px;
            margin-bottom: 0;
        }
    }

    .has-error .help-block, .has-error .control-label, .has-error .radio, .has-error .checkbox, .has-error .radio-inline, .has-error .checkbox-inline, .has-error.radio label, .has-error.checkbox label, .has-error.radio-inline label, .has-error.checkbox-inline label {
        color: $color-primary;
    }

    .field-validation-error {
        font-family: $montreal-regular;
    }

    .list-group-item-danger {
        border: 0;
        background: transparent;
        color: $color-primary;
    }

    .validation-summary-errors .list-group {
        border: 1px solid $color-primary;
    }

    .newsletter-form {
        position: relative;

        ::-webkit-input-placeholder {
            color: #f2f2f2 !important;
        }

        :-ms-input-placeholder {
            color: #f2f2f2 !important;
        }

        ::-moz-placeholder {
            color: #f2f2f2 !important;
            opacity: 1;
        }

        :-moz-placeholder {
            color: #f2f2f2 !important;
            opacity: 1;
        }

        .form-group {
            @media (min-width: $screen-md-min) {
                margin-bottom: 0;
            }

            .col-narrow & {
                margin-bottom: 0;
            }
        }

        span.scWebEditInput,
        label {
            display: none;
        }

        legend {
            border: none;
            margin-bottom: 7px;
            font-family: $montreal-medium;
            font-size: fluid(18px, 20px);
            color: $text-color;

            .col-narrow & {
                font-family: $montreal-bold;
                font-size: fluid(13px, 16px);
            }

            @media (min-width: $screen-md-min) {
                margin-bottom: 27px;
            }
        }

        p {
            font-family: $montreal-regular;
            font-size: fluid(15px, 18px);
            letter-spacing: 0.65px;
            margin-bottom: 20px;
            color: $text-color;

            @media (min-width: $screen-md-min) {
                letter-spacing: 0.15px;
                margin-bottom: 30px;
            }
        }

        .validation-summary-errors {
            display: none;
        }



        .row {
            @media (min-width: $screen-md-min) {
                float: left;
                width: calc(100% - 145px);

                .col-narrow & {
                    width: auto;
                }
            }

            > div:first-of-type {
                width: 100%;

                @media (min-width: $screen-md-min) {
                    padding-right: 0;

                    .col-narrow & {
                        padding-right: 15px;
                    }
                }
            }

            .c {
                background-color: #fff !important;
                color: $color-secondary-dark;
                font-size: 13px;
                padding: 0 20px;
                height: 53px;

                .col-narrow & {
                    background-color: $color-secondary-lightest !important;
                    margin-bottom: 0;
                }

                @media (min-width: $screen-md-min) {
                    font-size: 14.0042px;
                    height: 60px;
                    padding: 0 38px;
                    margin-bottom: 0;
                }
            }
        }

        .form-submit-border {
            width: 100%;
            padding-left: 15px;
            padding-right: 15px;

            .col-narrow & {
                float: left;
            }

            .btn {
                color: $color-white;
                padding: 16px 25px;
                margin-right: 0;
                background: $color-primary;
                font-size: 12px;

                .col-narrow & {
                    width: 100%;
                }

                @media (min-width: $screen-md-min) {
                    padding: 20px 25px;
                    font-size: 14px;
                }

                &:hover,
                &:focus {
                    background-color: $color-dark-primary;
                }
            }

            @media (min-width: $screen-md-min) {
                position: absolute;
                width: 135px;
                right: 0;
                padding: 0;

                .col-narrow & {
                    position: static;
                    width: 100%;
                    padding: 0 15px;
                }
            }
        }
    }

    .g-recaptcha {
        margin-bottom: 37px;
    }

    .contact-us-modal a,
    .js-form-container a {
        display: block;
        margin-top: 37px;
        margin-bottom: 37px;

        @media (min-width: $screen-md-min) {
            float: right;
            margin-top: -32px;
            margin-bottom: 0;
        }
    }

    .contact-us-modal {
        .form-title {
            color: $color-dark-red;
            font-size: 14px !important;
            font-family: $montreal-medium !important;
            text-transform: uppercase;
        }

        .modal-body {
            text-align: center !important;
        }
    }

    .js-contact-list-form {
        padding-bottom: 40px;
        padding-left: 15px;

        @media(min-width:992px) {
            padding-left: 0px;
        }

        label {
            display: block;
            font-family: $montreal-regular;
            font-size: 16px;
            margin-bottom: 22px;
        }

        .col-md-4 {
            margin-top: 20px;

            &:first-child {
                margin-top: 0;
            }

            @media (min-width: $screen-md-min) {
                margin-top: 0;
            }
        }
    }



    hr + .spinner {
        display: none;
        text-align: center;
        padding: 0 0 30px 0;
        font-size: 30px;
        color: #c2c2c2;
    }

    .js-form-container .modal-close {
        display: none;
    }


    div.form-group.has-error {
        position: relative;

        &:after {
            color: $color-primary;
            content: "X";
            position: absolute;
            right: 20px;
            top: calc(1.625em + 50px);
            transform: translateY(-50%);
            font-weight: 700;

            .newsletter-form & {
                content: "";
            }
        }
    }

    .has-success .control-label {
        color: $text-color;
    }

    .has-success .form-control:focus {
        border-color: #66afe9;
    }

    .validation-summary-errors {
        display: none;
    }

    .checkbox-left {
        display: inline-block;
        width: 15px;
        margin-right: 10px;

        span.field-validation-error {
            min-width: 100px !important;
            margin-top: 0px !important;
            float: left;
        }
    }

    .checkbox-right {
        display: inline-block;
        width: calc(100% - 30px);
        font-size: $font-size-base !important;
        font-family: $montreal-medium !important;

        a {
            font-size: $font-size-base !important;
            font-family: $montreal-medium !important;
        }
    }




    span.field-validation-error {
        margin-top: -30px !important;
        margin-bottom: 20px;
        font-size: $font-size-base !important;
        font-family: $montreal-medium !important;
        color: $color-dark-red !important;
        white-space: nowrap;
        display: block; /*fix to align error text below input field*/
    }

    span.field-validation-error > * {
        color: inherit;
        font-size: inherit;
        margin-top: inherit;
        font-family: inherit !important;
        line-height: inherit;
    }



    .wsp-recaptcha-section span.field-validation-error {
        margin-top: 0px !important;
    }

    .fxt-captcha.input-validation-error + span.field-validation-error {
        margin-top: 0 !important;
    }
    /* fix for showing error message below the captcha box */

    .requiredfield-section {
        position: relative;
        right: 0;
        margin: auto;
        display: inline-block;
        float: right;

        p {
            font-size: $font-size-base !important;
            font-family: $montreal-medium !important;
        }
    }


    .tab-list-rendering {
        max-width: 980px;
        margin-top: 59px;

        .container {
            padding-left: 0 !important;

            p {
                font-family: $montreal-bold !important;
                font-size: 21px !important;
                /*font-weight: medium;*/

                &.tab-list-title {
                    margin-bottom: 36px;
                    color: $color-secondary-dark;
                    padding: 0;
                }
            }

            .tab-list {
                margin-left: 0;

                .btn.btn-primary {
                    font-family: $montreal-medium;
                }

                div:last-child {
                    margin-right: 0;
                }
            }

            .tab {
                margin-right: 25px;
                max-width: 290px;
            }
        }

        a {
            max-width: 296px !important;
            width: 100%;
            height: 50px !important;
            background: #F2F2F2 0% 0% no-repeat padding-box;
            font-size: 14px;
            color: #898C8F;
            line-height: 24px;
            border-bottom: 1px solid grey;

            &:hover {
                background: $color-dark-red 0% 0% no-repeat padding-box;
                color: $color-white;
                max-width: 296px !important;
                height: 50px !important;
                border-bottom: 0px;
            }
        }

        .btn-primary {
            border-bottom: 0px;
            background-color: $color-dark-red;
            color: $color-white;
            white-space: nowrap;
        }
    }

    .requestForProposal {
        padding: 0 4vw;

        @media(min-width:1200px) {
            padding: unset;
        }

        .formbreadcrumb {
            .darkcrumb {
                color: $color-secondary-dark;
            }
        }

        .padded-left {
            padding-left: 0;

            @media (max-width: $screen-md-min) {
                .tab-list-rendering .container {
                    padding-right: 2em;

                    .row.tab-list {
                        max-width: 100%;

                        .tab {
                            margin-right: 0px;
                            max-width: none;

                            a {
                                max-width: none !important;
                            }
                        }
                    }
                }
            }
        }

        .padded-top {
            padding-top: 4em;
        }

        .box {
            margin-top: 2em;
            float: none;
        }
        // Dropdown styling
        .dropdown-div {
            background-color: #F2F2F2;
            padding-top: 4em;
            padding-left: 0;
            padding-bottom: 3em;
            position: relative;

            .grey-background {
                background-color: #F2F2F2;
                width: 200vw;
                display: none;
                position: absolute;
                top: 0px;
                left: -25vw;
                bottom: 0px;

                @media (min-width: $screen-md-min) {
                    display: block;
                }
            }

            option {
                background-color: $color-secondary-lightest;

                &:hover {
                    background-color: $color-dark-red;
                }
            }
        }

        label {
            margin-bottom: 1em;
            font-family: $montreal-medium !important;
            color: $color-secondary-dark;
            line-height: 1.625;
            display: block;
            font-size: 14px;
        }

        label.control-label {
            margin-bottom: 1em !important;
            font-family: $montreal-medium !important !important;
            color: $color-secondary-dark !important;
            line-height: 1.625 !important;
            display: block !important;
            font-size: 14px !important;
        }
        // End of Dropdown styling
        .text-color-black {
            color: black;
        }

        .text-color-grey {
            color: $color-secondary-medium-gray;
        }

        .text-color-red {
            color: $color-dark-red;
        }

        .background-white {
            background-color: $color-white;
        }



        .information {
            font-size: 18.5px;
            font-family: $montreal-regular !important;
        }

        .rfpForm {
            width: 100%;

            @media(min-width: 768px) {
                width: 100%; /*80%*/
            }


            @media (min-width: $screen-md-min) {
                width: 66.66667%;
            }
        }

        .hidden {
            display: initial !important;
        }

        .hide {
            display: none;
        }



        select, #categories-button {
            border-bottom: 1px solid $color-dark-red !important;
            background-color: $color-white;
            /*border-bottom: 1px solid $dropdown-bottom-line-normal;
            background-color: $color-secondary-lightest;
            box-shadow: 0px 0px 1px lightgray;*/
        }

        select {
            -webkit-appearance: revert;
            -moz-appearance: revert;
            appearance: revert;
            outline: unset !important;
            box-shadow: unset !important;
            border-bottom: 1px solid $color-dark-red !important;
            background: $color-secondary-lightest !important;
            background-image: unset !important;
            flex: 1;
            padding: 0 1em !important;
            color: $color-secondary-dark !important;
            cursor: pointer;
        }
        /* Remove IE arrow */
        select::-ms-expand {
            display: unset !important;
        }

        .ui-menu-item {
            background-color: $color-secondary-lightest !important;
        }

        .contact-us-form-section {
        }
    }



    input[type=file] {
        position: static;
        width: 49%;

        .padded-left {
            padding-left: 1em;
        }
    }

    .custom-file-upload {
        background-color: $color-secondary-lightest;
        border-bottom: 1px solid $dropdown-bottom-line-normal !important;
        display: inline-block;
        cursor: pointer;
        width: calc(50% - 30px) !important;
        padding-top: 10px;
        padding-left: 20px;
        margin-right: 15px;
        margin-left: 15px;
        margin-bottom: 20px;

        label {
            padding: 10px 0 15px;
            margin-bottom: 0 !important;
            color: #898C8F !important;
            font-size: 14px;

            &:after {
                content: "\f0c6";
                width: 20px;
                position: absolute;
                right: 20px;
                font-size: 18px;
                color: #999;
                font-family: 'FontAwesome';
                -moz-transform: scale(-1, 1);
                -webkit-transform: scale(-1, 1);
                -o-transform: scale(-1, 1);
                -ms-transform: scale(-1, 1);
                transform: scale(-1, 1);
            }
        }
        /* 383 - RFP form validation for file uploads*/
        input[type=file] {
            visibility: hidden;
            position: absolute !important;
            display: inline;
            height: 0;
        }
    }

    .margin-right-upload {
        margin-right: 20px !important;
    }

    .number-of-employees {
        background: $color-secondary-lightest url(/-/media/contact-us/arrowdown.png) no-repeat right !important;
        border-bottom: 1px solid $dropdown-bottom-line-normal !important;
        padding: 2px 0px 0px 20px !important;
        color: black;

        option {
            background-color: $color-secondary-lightest;

            &:hover {
                background-color: $color-dark-red;
            }
        }
    }


    .font-montreal-medium {
        font-family: $montreal-medium !important;
    }


    .font-12 {
        font-size: 12px !important;
    }


    .font-14 {
        font-size: 14px !important;
    }

    .hiddenEmail {
        /*    display: none;*/
    }

    .hrSection {
        hr {
            margin-top: 0px;
            margin-bottom: 3em;
        }
    }

    .contact-us-form-section {
        form {
            label {
                font-family: $montreal-medium !important;
                color: $color-secondary-dark;
                line-height: 1.3;
                display: block;
                margin-bottom: 24px;
                font-size: $font-size-16mn-20mx;
            }

            .checkbox-left {
                display: inline-block;
                width: 15px;
                margin-right: 10px;
            }

            .checkbox-right {
                display: inline-block;
                width: calc(100% - 30px);
            }
        }
    }

    select {
        &.form-control {
            padding: 10px 0 10px 15px !important;
        }
    }
}

.selectpicker {


    + .ui-selectmenu-button {
        display: block;
        position: relative;
        width: 100%;
        font-family: $montreal-medium;
        font-size: 16px;
        background-color: $color-secondary-lightest;
        border-bottom: 1px solid $dropdown-bottom-line-normal;
        border-radius: 0;
        color: $color-secondary-medium-gray;

        &.chosen {
            color: $color-secondary-dark;
            border-bottom: 1px solid $color-primary;
        }

        span.ui-selectmenu-text {
            padding: 20px 45px 19px 30px;
        }

        .ui-selectmenu-icon {
            color: $color-dark-primary;
            text-indent: 0;
            background: none;
            width: 11px;
            right: 30px;
            font-size: 11px;
            height: 12px;
            margin-top: -6px;
            transition: all 0.4s ease;
        }

        &.ui-selectmenu-button-open {
            .ui-selectmenu-icon {
                transform: rotate(180deg);
            }

            background-color: $color-secondary-light-gray;
            color: $color-secondary-dark;
            border-bottom: 1px solid $color-primary;
        }
    }
}

.ui-selectmenu-open {
    &.ui-selectmenu-menu .ui-menu {
        border-radius: 0;
        box-shadow: 0 5px 8px 0 rgba(50,50,50,0.4);
    }

    .ui-menu .ui-menu-item {
        padding: 0;
        background-color: $color-secondary-lightest;
        font-family: $montreal-medium;
        font-size: 16px;
        background: $color-secondary-lightest 0% 0% no-repeat padding-box;

        div {
            padding: 10px 20px;
            background: transparent;

            &.ui-state-active,
            &:hover {
                color: $color-white;
                background: $color-primary;
            }
        }

        &:hover div {
            color: $color-white;
            background: $color-primary;
        }
    }

    .ui-state-active, .ui-widget-content .ui-state-active,
    .ui-widget-header .ui-state-active {
        border: none;
    }
}

.find-an-expert {
    .country, .telephone {
        font-size: 14px;
        font-family: $montreal-medium !important;
        margin-bottom: 15px;
        word-break: break-all;
    }

    .telephone {
        font-weight: 300;

        a {
            font-family: $montreal-medium !important;
            color: black !important;
            font-size: 14px !important;
        }

        img {
            width: 16px;
            height: 17px;
            display: inline;
            vertical-align: bottom;
            margin-right: 5px !important;
        }
    }




    .country img {
        width: 16px;
        height: 17px;
        display: inline;
        margin-right: 5px !important;
    }


    .person-photo {
        max-height: 150px;
        border-radius: 100%;
    }

    .fae-button {
        /*width: 189px !important;
        height: 50px !important;*/
        font-family: $montreal-medium !important;
    }

    .fae-button::after {
        content: '' !important;
    }

    .fae-button.message-me {
        /*  margin-left: 25px;
        color: #fff !important;*/
        font-size: 14px !important;
        text-decoration: none !important;
        margin-right: 15px;
        /*line-height: 1em;
        background-color: $color-dark-secondary;*/
    }

    .fae-button.btn-profile {
        /*  margin-left: 25px;*/
        color: #fff !important;
        font-size: 14px !important;
        text-decoration: none !important;
        line-height: 1em;
    }

    .btn-ghost.fae-button {
        color: $color-secondary !important;
    }

    .btn-ghost.fae-button:hover {
        color: #fff !important;
    }
}

.message-me-section {
    display: block;
    padding: 25px 40px;
    border-top: 1px solid $color-secondary-lightest;
    margin-top: 55px;
    position: relative;
}

.message-me-section.hide {
    display: none;
}

.message-me-section span {
    font-family: $montreal-medium !important;
}

.message-me-section span.person {
    font-family: $montreal-medium !important;
    display: inline-block !important;
    text-transform: uppercase !important;
    color: $color-dark-red !important;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-size: 14px !important;
}

.message-me-section span.label {
    font-family: $montreal-medium !important;
    display: inline-block !important;
    text-transform: uppercase;
    font-size: 14px !important;
}

.message-me-section form .email-field {
    display: none;
}

.message-me-section form .halfAvailableWidth.form-group {
    margin: 0 20px 0 0;
    width: calc(50% - 15px);
    display: inline-block;
}

.message-me-section form label {
    font-family: $montreal-medium !important;
    color: $color-secondary-dark;
    line-height: 1.3;
    display: block;
    margin-bottom: 24px;
    font-size: $font-size-16mn-20mx;
}

.message-me-section .modal-close {
    background-color: $color-primary;
    border: 0;
    height: 50px;
    line-height: 14px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
}

.message-me-section .modal-close svg {
    height: 14px;
    -webkit-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
    width: 14px;
    fill: $color-white;
}

.message-me-modal .hide {
    display: none !important;
}

#coveoC606340E .coveo-search-button-svg {
    width: 18px;
    height: 18px;
    color: $color-dark-red !important;
}

form .consent-checkbox-section {

    input[type="checkbox"] {
        width: 15px;
        height: 15px;
        display: block;
        position: absolute;
        content: ' ';
        background-color: $gray-light;
        border: 1px solid $text-color;
        top: 8px;
        visibility: visible;
        margin-top: 6px;

        &:checked {
            border-width: 5px;
            background-color: $brand-text-color;
            box-sizing: border-box;
            margin-top: 4px;
        }

        &:before {
            visibility: hidden;
            display: none;

            .bg-primary &,
            .bg-secondary &,
            .bg-dark &,
            .bg-media & {
                border-color: $text-color;
            }
        }
    }
}

.contact-us-form-section form label {
    font-family: $montreal-medium !important;
    color: $color-secondary-dark;
    line-height: 1.3;
    display: block;
    margin-bottom: 24px;
    font-size: $font-size-16mn-20mx;
}


.modal .modal-close {
    background-color: $color-primary;
    border: 0;
    height: 50px;
    line-height: 14px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
}

.modal .modal-close:hover, .modal .modal-close:focus {
    background-color: $color-dark-primary;
}

.modal .modal-close:hover svg, .modal .modal-close:focus svg {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.modal .modal-close svg {
    height: 14px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    width: 14px;
}

.modal .modal-close svg path {
    fill: $color-white;
}


.modal-close .mdiv {
    height: 25px;
    width: 1px;
    margin: 10px 22px;
    background-color: $color-white;
    transform: rotate(45deg);
    Z-index: 1;
}

.modal-close .md {
    height: 25px;
    width: 1px;
    background-color: $color-white;
    transform: rotate(90deg);
    Z-index: 2;
}

#coveo779f353c {
    &.CoveoResultList {
        .wsp-expert-name {
            h2 {
                margin: 0px !important;
                font-size: 21px;
            }

            .position {
                font-size: 14px;
            }
        }
    }
}

.pd-l-0 {
    padding-left: 0px !important;
}

.pd-r-0 {
    padding-right: 0px !important;
}

.mr-n-30 {
    margin-right: -30px;
}
/* 540-Spacer component without colours*/
hr.hr-no-border {
    border: 0 none !important;
}
