.section, .ft-content-wrapper {
    &.bg-secondary {
        background-color: $color-secondary-lightest !important;
        color: $color-secondary-dark;
        overflow: hidden;
    }

    &.secondary-white {
        @extend .clearfix;
        background: $color-secondary-lightest; /* Old browsers */
        @media (max-width: 359px) {
            background: linear-gradient(to bottom,$color-secondary-lightest 0,$color-secondary-lightest 205px, #fff 0); /*260px*/
        }

        @media (min-width: 360px) {
            background: linear-gradient(to bottom,$color-secondary-lightest 0,$color-secondary-lightest 215px, #fff 0); /*271px*/
        }

        @media (min-width: 414px) {
            background: linear-gradient(to bottom,$color-secondary-lightest 0,$color-secondary-lightest 225px, #fff 0); /*281px*/
        }

        @media (min-width: 480px) {
            background: linear-gradient(to bottom,$color-secondary-lightest 0,$color-secondary-lightest 236px, #fff 0); /*292px*/
        }

        @media (min-width: 560px) {
            background: linear-gradient(to bottom,$color-secondary-lightest 0,$color-secondary-lightest 253px, #fff 0);
        }

        @media (min-width: 730px) {
            background: linear-gradient(to bottom,$color-secondary-lightest 0,$color-secondary-lightest 283px, #fff 0);
        }

        @media (min-width: 768px) {
            background: linear-gradient(to bottom,$color-secondary-lightest 0,$color-secondary-lightest 217px, #fff 0); /*274px*/
        }

        @media (min-width: 850px) {
            background: linear-gradient(to bottom,$color-secondary-lightest 0,$color-secondary-lightest 226px, #fff 0); /*274px*/
        }

        @media (min-width: 992px) {
            background: linear-gradient(to bottom,$color-secondary-lightest 0,$color-secondary-lightest 224px, #fff 0); /*247px*/
        }

        @media (min-width: 1024px) {
            background: linear-gradient(to bottom,$color-secondary-lightest 0,$color-secondary-lightest 214px, #fff 0);
        }

        @media (min-width: 1250px) {
            background: linear-gradient(to bottom,$color-secondary-lightest 0,$color-secondary-lightest 228px, #fff 0);
        }

        @media (min-width: 1300px) {
            background: linear-gradient(to bottom,$color-secondary-lightest 0,$color-secondary-lightest 236px, #fff 0);
        }

        @media (min-width: 1500px) {
            background: linear-gradient(to bottom,$color-secondary-lightest 0,$color-secondary-lightest 243px, #fff 0);
        }
    }

    &.bg-black-white {
        @extend .clearfix;
        background: $color-secondary-dark; /* Old browsers */
        @media (max-width: 359px) {
            background: linear-gradient(to bottom,$color-secondary-dark 0,$color-secondary-dark 205px, #fff 0); /*260px*/
        }

        @media (min-width: 360px) {
            background: linear-gradient(to bottom,$color-secondary-dark 0,$color-secondary-dark 205px, #fff 0); /*271px*/
        }

        @media (min-width: 414px) {
            background: linear-gradient(to bottom,$color-secondary-dark 0,$color-secondary-dark 225px, #fff 0); /*281px*/
        }

        @media (min-width: 480px) {
            background: linear-gradient(to bottom,$color-secondary-dark 0,$color-secondary-dark 236px, #fff 0); /*292px*/
        }

        @media (min-width: 560px) {
            background: linear-gradient(to bottom,$color-secondary-dark 0,$color-secondary-dark 253px, #fff 0);
        }

        @media (min-width: 730px) {
            background: linear-gradient(to bottom,$color-secondary-dark 0,$color-secondary-dark 283px, #fff 0);
        }

        @media (min-width: 768px) {
            background: linear-gradient(to bottom,$color-secondary-dark 0,$color-secondary-dark 217px, #fff 0); /*274px*/
        }

        @media (min-width: 850px) {
            background: linear-gradient(to bottom,$color-secondary-dark 0,$color-secondary-dark 226px, #fff 0); /*274px*/
        }

        @media (min-width: 992px) {
            background: linear-gradient(to bottom,$color-secondary-dark 0,$color-secondary-dark 224px, #fff 0); /*247px*/
        }

        @media (min-width: 1024px) {
            background: linear-gradient(to bottom,$color-secondary-dark 0,$color-secondary-dark 214px, #fff 0);
        }

        @media (min-width: 1250px) {
            background: linear-gradient(to bottom,$color-secondary-dark 0,$color-secondary-dark 228px, #fff 0);
        }

        @media (min-width: 1300px) {
            background: linear-gradient(to bottom,$color-secondary-dark 0,$color-secondary-dark 236px, #fff 0);
        }

        @media (min-width: 1500px) {
            background: linear-gradient(to bottom,$color-secondary-dark 0,$color-secondary-dark 243px, #fff 0);
        }
    }


    &.bg-white {
        .feature-project {
            margin-top: 0;
        }
    }
}


.section {
    padding: 0;

    .footer-bloc {
        padding: 0 4vw;

        p {
            margin-bottom: 0;
        }

        .btn {
            margin-top: 0;
        }

        .typo--h3 {
            padding-bottom: 15px;
        }
    }


    .news-slider {
        padding-bottom: 8px;

        @media (min-width: $screen-md-min) {
            padding-bottom: calc(20px + .1vw);
        }

        &-title {
            color: $color-secondary-dark;
            @extend .new__typo__12;
        }
    }

    .services-sectors-slider {
        /*padding-bottom: 8px;

        @media (min-width: $screen-md-min) {
            padding-bottom: calc(20px + .1vw);
        }*/
        &-title {
            color: $color-secondary-dark;
            @extend .new__typo__12;
        }
    }
}


/* If carousel has sub-text then add 60px more to background grey/black color - start */

.section:has(.sub-text), .ft-content-wrapper:has(.sub-text) {


    &.secondary-white {
        @extend .clearfix;
        background: $color-secondary-lightest; /* Old browsers */
        @media (max-width: 359px) {
            background: linear-gradient(to bottom,$color-secondary-lightest 0,$color-secondary-lightest 265px, #fff 0); /*260px*/
        }

        @media (min-width: 360px) {
            background: linear-gradient(to bottom,$color-secondary-lightest 0,$color-secondary-lightest 275px, #fff 0); /*271px*/
        }

        @media (min-width: 414px) {
            background: linear-gradient(to bottom,$color-secondary-lightest 0,$color-secondary-lightest 285px, #fff 0); /*281px*/
        }

        @media (min-width: 480px) {
            background: linear-gradient(to bottom,$color-secondary-lightest 0,$color-secondary-lightest 296px, #fff 0); /*292px*/
        }

        @media (min-width: 560px) {
            background: linear-gradient(to bottom,$color-secondary-lightest 0,$color-secondary-lightest 313px, #fff 0);
        }

        @media (min-width: 730px) {
            background: linear-gradient(to bottom,$color-secondary-lightest 0,$color-secondary-lightest 343px, #fff 0);
        }

        @media (min-width: 768px) {
            background: linear-gradient(to bottom,$color-secondary-lightest 0,$color-secondary-lightest 277px, #fff 0); /*274px*/
        }

        @media (min-width: 850px) {
            background: linear-gradient(to bottom,$color-secondary-lightest 0,$color-secondary-lightest 286px, #fff 0); /*274px*/
        }

        @media (min-width: 992px) {
            background: linear-gradient(to bottom,$color-secondary-lightest 0,$color-secondary-lightest 284px, #fff 0); /*247px*/
        }

        @media (min-width: 1024px) {
            background: linear-gradient(to bottom,$color-secondary-lightest 0,$color-secondary-lightest 274px, #fff 0);
        }

        @media (min-width: 1250px) {
            background: linear-gradient(to bottom,$color-secondary-lightest 0,$color-secondary-lightest 288px, #fff 0);
        }

        @media (min-width: 1300px) {
            background: linear-gradient(to bottom,$color-secondary-lightest 0,$color-secondary-lightest 296px, #fff 0);
        }

        @media (min-width: 1500px) {
            background: linear-gradient(to bottom,$color-secondary-lightest 0,$color-secondary-lightest 303px, #fff 0);
        }
    }

    &.bg-black-white {
        @extend .clearfix;
        background: $color-secondary-dark; /* Old browsers */
        @media (max-width: 359px) {
            background: linear-gradient(to bottom,$color-secondary-dark 0,$color-secondary-dark 265px, #fff 0); /*260px*/
        }

        @media (min-width: 360px) {
            background: linear-gradient(to bottom,$color-secondary-dark 0,$color-secondary-dark 265px, #fff 0); /*271px*/
        }

        @media (min-width: 414px) {
            background: linear-gradient(to bottom,$color-secondary-dark 0,$color-secondary-dark 285px, #fff 0); /*281px*/
        }

        @media (min-width: 480px) {
            background: linear-gradient(to bottom,$color-secondary-dark 0,$color-secondary-dark 296px, #fff 0); /*292px*/
        }

        @media (min-width: 560px) {
            background: linear-gradient(to bottom,$color-secondary-dark 0,$color-secondary-dark 313px, #fff 0);
        }

        @media (min-width: 730px) {
            background: linear-gradient(to bottom,$color-secondary-dark 0,$color-secondary-dark 343px, #fff 0);
        }

        @media (min-width: 768px) {
            background: linear-gradient(to bottom,$color-secondary-dark 0,$color-secondary-dark 277px, #fff 0); /*274px*/
        }

        @media (min-width: 850px) {
            background: linear-gradient(to bottom,$color-secondary-dark 0,$color-secondary-dark 286px, #fff 0); /*274px*/
        }

        @media (min-width: 992px) {
            background: linear-gradient(to bottom,$color-secondary-dark 0,$color-secondary-dark 284px, #fff 0); /*247px*/
        }

        @media (min-width: 1024px) {
            background: linear-gradient(to bottom,$color-secondary-dark 0,$color-secondary-dark 274px, #fff 0);
        }

        @media (min-width: 1250px) {
            background: linear-gradient(to bottom,$color-secondary-dark 0,$color-secondary-dark 288px, #fff 0);
        }

        @media (min-width: 1300px) {
            background: linear-gradient(to bottom,$color-secondary-dark 0,$color-secondary-dark 296px, #fff 0);
        }

        @media (min-width: 1500px) {
            background: linear-gradient(to bottom,$color-secondary-dark 0,$color-secondary-dark 303px, #fff 0);
        }
    }
}

/* If carousel has sub-text then add 60px more to background grey/black color - end */
