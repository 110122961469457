.job-search-field {
    margin-left: -8px;
    margin-right: -8px;

    select {
        display: none;
    }

    .jsf__main-button {
        text-align: left;
        padding: 0 30px;
        text-align: left;
        border: none;
        background: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        z-index: 2;

        &:after,
        &:before {
            content: '';
            display: block;
            width: 10px;
            height: 2px;
            background: $color-primary;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            -webkit-transition: -webkit-transform .2s ease-in-out;
            transition: -webkit-transform .2s ease-in-out;
            transition: transform .2s ease-in-out;
            transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
            position: absolute;
            top: 30px;
            right: 35px;
            z-index: 100;
        }

        &:before {
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
            right: 28px;
        }

        &:hover,
        &:focus {
            color: white;
            background-color: $color-primary;
            outline: none;

            &:after,
            &:before {
                background: white;
            }

            .jsf__value {
                color: $color-white;
            }
        }

        &.isOpen {
            &:before {
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                -webkit-transition: -webkit-transform .5s ease-in-out;
                transition: -webkit-transform .5s ease-in-out;
                transition: transform .5s ease-in-out;
                transition: transform .5s ease-in-out, -webkit-transform .5s ease-in-out;
            }

            &:after {
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
                -webkit-transition: -webkit-transform .5s ease-in-out;
                transition: -webkit-transform .5s ease-in-out;
                transition: transform .5s ease-in-out;
                transition: transform .5s ease-in-out, -webkit-transform .5s ease-in-out;
            }

            .jsf__value {
                color: $color-white;
            }
        }

        .jsf__value {
            color: $color-secondary-dark;
            @extend .new__typo__08; //Job Opportunities
        }
    }

    .jsf__secondary-button {
        @extend .new__typo__08;
        border: none;
        width: 100%;
        text-align: left;
        background: #d5d5d5;
        padding: 20px 20px;

        &:hover,
        &:focus {
            color: $color-white;
            background: $color-primary;
        }
    }

    .jsf__list {
        z-index: 3;
        width: 100%;
        list-style: none;
        position: absolute;
        margin-left: -20px;
        top: 60px;
        overflow-y: scroll;
        height: 0;
        transition: height 0.4s ease-in;

        &.open {
            height: 366px;
        }
    }

    .jsf__item {
        padding: 0;
        margin: 0;
        overflow: hidden;
        /*&.isOpen {
            max-height: 500px;
            -webkit-transition: max-height 2s ease-in;
            transition: max-height 2s ease-in;
        }*/
        &.selected {
            .jsf__secondary-button {
                color: white;
                background: $color-primary;
            }
        }
    }

    .jsf_col {
        color: $color-secondary-dark;
        @extend .new__typo__09; //Job Opportunities
        width: 100%;
        padding: 0 8px;
        display: inline-block;
        vertical-align: top;

        label {
            @extend .new__typo__09; //Job Opportunities
        }

        @media (min-width: $screen-md-min) {
            width: calc(27% - 1px);
        }

        input,
        select,
        .jsf_countrylist {
            position: relative;
            height: 60px;
            margin: 15px 0 35px;
            background: $color-secondary-lightest;
            border: none;
            cursor: pointer;
            border-bottom: 2px solid $color-primary;
            padding: 15px 20px;
            width: 100%;
            color: #333c48;
            @extend .new__typo__08; //Job Opportunities
            @media (min-width: $screen-md-min) {
                margin: 25px 0 0;
            }

            &:focus {
                //color: #a6a9ac;
                @extend .new__typo__08;
                border-bottom: 2px solid #d5d5d5;
                outline-width: 0;
            }
        }
    }

    .jsf_lastcol {
        width: 100%;
        display: inline-block;
        padding: 0 8px;
        vertical-align: bottom;

        @media (min-width: $screen-md-min) {
            width: 18%;
        }

        button {
            color: white;
            @extend .new__typo__08; //job Opportunities
            background: $color-primary;
            border-bottom: 2px solid $color-primary;
            width: 100%;
            padding: 20px;
            border: none;

            &:hover,
            &:focus {
                background-color: $color-dark-primary;
            }
        }
    }
}

.job-search-results {
    /*background: $color-secondary-lightest;*/
    padding: 25px 0;
    margin-left: -4vw;
    margin-right: -4vw;
    text-transform: uppercase;
    @extend .new__typo__08; //Job Opportunities
    border-bottom: 1px solid #ccc;
    margin-bottom: 65px;
    /*padding: 20px 30px;*/
    /*padding:20px 0;*/
    margin-top: 40px;


    @media (min-width: $screen-md-min) {
        margin-left: 0;
        margin-right: 0;
    }
}

.jobs-table {
    display: block;
    overflow: hidden;

    thead,
    tbody {
        display: block;
    }
}

.joblisting {
    h2 {
        color: $color-secondary-dark;
        @extend .new__typo__12; //Job opportunities
    }

    hr {
        margin-left: -4vw;
        margin-right: -4vw;

        @media (min-width: $screen-md-min) {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .job-result-title {
        color: $color-secondary-dark;
        @extend .new__typo__12; //Job opportunities
        margin-bottom: 20px;
        display: none;

        th {
            display: block;
            float: left;
        }

        @media (min-width: $screen-md-min) {
            display: block;
        }
    }

    .job-listing {
        padding: 20px 0;
        /*border-bottom: 1px solid #a6a9ac;*/
        border-bottom: 1px solid #ccc;
        font-size: $fonts-size-small;
        display: block;

        td {
            display: block;

            @media (min-width: $screen-md-min) {
                float: left;
            }
        }

        .jl_title {
            /*text-transform: uppercase;
            color: $color-secondary;*/
            a {
                text-transform: uppercase;
                color: $color-secondary-on;
                @extend .new__typo__06; //Job opportunities
            }

            @media (min-width: $screen-md-min) {
                padding: 0 15px;
            }
        }

        .jl_location,
        .jl_date {
            color: $color-secondary-dark;
            @extend .new__typo__13; //Job opportunities
            @media (min-width: $screen-md-min) {
                padding: 0 15px;
            }
        }

        .jl_date {
            @media (min-width: $screen-md-min) {
                padding: 0 15px;
            }
        }
    }
}

.pagination {
    margin-bottom: 25px;

    @media (min-width: $screen-md-min) {
        margin-bottom: 40px;
        text-align: right;
    }

    .pagelist:nth-child(3) {

        @media (min-width: $screen-xs-min) {
            display: inline-block;
        }
    }

    .pagelist:nth-child(4) {

        @media (min-width: $screen-sm-min) {
            display: inline-block;
        }
    }

    .pagelist:nth-child(5),
    .pagelist:nth-child(6) {

        @media (min-width: $screen-md-min) {
            display: inline-block;
        }
    }

    .pagelist,
    .pagesuspend {
        display: inline-block;

        input {
            border: 1px solid $color-secondary;
            background: $color-secondary;
            font-size: $fonts-size-small;
            font-family: $montreal-medium;
            color: white;
            padding: 10px 15px;
            height: 46px;
            width: 46px;
            margin: 0 2px;
            -webkit-transition: background 0.5s ease-in-out;
            transition: background 0.5s ease-in-out;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            justify-content: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;

            &:hover,
            &:focus {
                background: $color-secondary-on;
                -webkit-transition: background 0.5s ease-in-out;
                transition: background 0.5s ease-in-out;
            }
        }

        &.current input {
            background: white;
            border: 1px solid $color-secondary;
            color: $color-secondary;
        }
    }

    .pagesuspend {
        padding: 10px 15px;
        color: $color-secondary;
        vertical-align: bottom;
        height: 48px;
    }
}

.pagination > * {
    margin-bottom: 15px;

    @media (min-width: $screen-md-min) {
        margin-bottom: 0;
    }
}

.pagenext,
.pageprev {
    border: none;
    background: $color-secondary;
    color: white;
    padding: 10px 15px;
    height: 46px;
    width: 46px;
    vertical-align: bottom;

    &:hover,
    &:focus {
        background: $color-dark-secondary;
        -webkit-transition: background 0.5s ease-in-out;
        transition: background 0.5s ease-in-out;
    }

    span {
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=1)";

        &:after,
        &:before {
            content: '';
            display: block;
            opacity: 1;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
            width: 10px;
            height: 1px;
            background: white;
            -webkit-transform: rotate(-50deg);
            transform: rotate(-50deg);
            position: relative;
            top: -5px;
            left: 2px;
        }

        &:before {
            -webkit-transform: rotate(50deg);
            transform: rotate(50deg);
            top: 4px;
        }
    }
}

.pagenext {
    span {
        &:after {
            -webkit-transform: rotate(50deg);
            transform: rotate(50deg);
            top: -5px;
        }

        &:before {
            -webkit-transform: rotate(-50deg);
            transform: rotate(-50deg);
            top: 4px;
        }
    }
}
