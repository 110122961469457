div.scLooseFrameZone {
    overflow-y: visible !important;
}

//  general
.U--relative {
    position: relative !important;
}

//  margin / padding
@mixin generate-spacing-classes($space-amounts: ()) {
    @each $space in $space-amounts {
        .U--m#{nth($space, 3)}--#{nth($space, 1)} {
            margin-#{nth($space, 2)}: #{nth($space, 1)}px !important;
        }

        .U--m--#{nth($space, 1)} {
            margin: #{nth($space, 1)}px !important;
        }

        .U--p#{nth($space, 3)}--#{nth($space, 1)} {
            padding-#{nth($space, 2)}: #{nth($space, 1)}px !important;
        }

        .U--p--#{nth($space, 1)} {
            padding: #{nth($space, 1)}px !important;
        }
    }
}

@include generate-spacing-classes((0 left l, 10 left l, 20 left l, 40 left l, 70 left l));

@include generate-spacing-classes((0 right r, 10 right r, 20 right r, 40 right r, 70 right r));

@include generate-spacing-classes((0 bottom b, 10 bottom b, 20 bottom b, 40 bottom b, 70 bottom b));

@include generate-spacing-classes((0 top t, 10 top t, 20 top t, 40 top t, 70 top t));

.U--pl--4vw {
    padding-left: 4vw !important;
}

.U--pr--4vw {
    padding-right: 4vw !important;
}

//  flexbox
.U--flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
}

.U--flex-justify-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
}

.U--flex-justify-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
}

.U--flex-justify-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
}

.U--flex-align-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    -ms-grid-row-align: center !important;
    align-items: center !important;
}

.U--flex-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
}

.U--order-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
}

.U--w-100 {
    width: 100% !important;
}

// display
.U--block {
    display: block !important;
}