﻿.CoveoSearchInterface button.filter-by {
    background-color: #ff4337;
    background-repeat: no-repeat;
    background-position: center right 20px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgd2lkdGg9IjE0LjVweCIgaGVpZ2h0PSI3LjVweCI+PGc+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiAgc3Ryb2tlPSJyZ2IoMjU1LCAyNTUsIDI1NSkiIHN0cm9rZS13aWR0aD0iMXB4IiBzdHJva2UtbGluZWNhcD0iYnV0dCIgc3Ryb2tlLWxpbmVqb2luPSJtaXRlciIgZmlsbD0ibm9uZSIgZD0iTTEyLjk5OSwxLjMxOSBMNi43NDgsNi4wMzEgTDAuNDk4LDEuMzE5ICIvPjwvZz48cGF0aCBmaWxsPSJub25lIiBzdHJva2U9InJnYigyNTUsIDI1NSwgMjU1KSIgc3Ryb2tlLXdpZHRoPSIxcHgiIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBkPSJNMTIuOTk5LDEuMzE5IEw2Ljc0OCw2LjAzMSBMMC40OTgsMS4zMTkgIi8+PC9zdmc+');
    border: 0;
    color: #fff;
    width: 100%;
    padding: 18px 0;
    font-family: $montreal-medium;
    font-size: 14px;
    font-weight: 100;
    display: block;
    margin-bottom: 40px;

    @media (min-width: $screen-md-min) {
        display: none;
    }

    &.arrowIsDown {
        background-image: url('data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgd2lkdGg9IjEzLjVweCIgaGVpZ2h0PSI3LjVweCI+PGcgPjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgIHN0cm9rZT0icmdiKDI1NSwgMjU1LCAyNTUpIiBzdHJva2Utd2lkdGg9IjFweCIgc3Ryb2tlLWxpbmVjYXA9ImJ1dHQiIHN0cm9rZS1saW5lam9pbj0ibWl0ZXIiIGZpbGw9Im5vbmUiIGQ9Ik0wLjUwMSw0Ljc4NCBMNi41MDIsMC41MDAgTDEyLjUwMiw0Ljc4NCAiLz48L2c+PHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSJyZ2IoMjU1LCAyNTUsIDI1NSkiIHN0cm9rZS13aWR0aD0iMXB4IiBzdHJva2UtbGluZWNhcD0iYnV0dCIgc3Ryb2tlLWxpbmVqb2luPSJtaXRlciIgZD0iTTAuNTAxLDQuNzg0IEw2LjUwMiwwLjUwMCBMMTIuNTAyLDQuNzg0ICIvPjwvc3ZnPg==');
    }
}
