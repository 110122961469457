﻿

.mn__list {
    margin: 0;
    padding: 0 4vw;
    list-style: none;

    a {
        display: block;
        font-size: 18px;
        color: $color-primary;
        font-family: $montreal-medium;
        padding: 30px 0;
        width: 100%;
        text-decoration: none;


        @media (max-width: $screen-md-max) {
            font-family: $montreal-regular;
            font-size: 16px;
            padding: 18px 0;
            padding-left: 2px;
            padding-right: 2px;
            color: $color-dark-red;

            &.mn__link.level-0 {
                font-family: $montreal-medium;
                font-size: 20px;

                &.targetIsShown, &:focus, &.active {
                    text-decoration: none;
                }

                &:hover {
                    text-decoration: underline;
                }
            }

            &.targetIsShown, &.active, &:focus, &:hover {
                text-decoration: none;
            }
        }
    }
}

.mn__item {
    border-bottom: 1px solid $color-secondary-light;


    @media (max-width: $screen-md-max) {

        .arw_rgt:after {
            content: "";
            position: absolute;
            right: 4vw;
            box-sizing: border-box;
            width: 20px;
            text-align: center;
            text-rendering: auto;
            font-family: font-awesome;
            font-size: 22px;
            cursor: pointer;
            z-index: 100;
            line-height: 0.7;
            height: 20px;
        }

        .arw_dwn:after {
            content: "\f107";
            position: absolute;
            right: 4vw;
            box-sizing: border-box;
            width: 20px;
            text-align: center;
            text-rendering: auto;
            font-family: font-awesome;
            font-size: 22px;
            cursor: pointer;
            z-index: 100;
            line-height: 0.7;
            height: 20px;
        }

        .arw_up:after {
            content: "\f106";
            position: absolute;
            right: 4vw;
            box-sizing: border-box;
            width: 20px;
            text-align: center;
            text-rendering: auto;
            font-family: font-awesome;
            font-size: 22px;
            cursor: pointer;
            z-index: 100;
            line-height: 0.7;
            height: 20px;
        }
    }



    .sub-nav {
        ul {
            padding: 0;
            list-style: none;
        }
    }

    li[class*=col-] {
        position: static;
    }

    > .sub-nav {
        &.owl-loaded {
            margin: 0;
        }

        .owl-stage-outer {
            @extend .clearfix;
            overflow: hidden;
        }

        .owl-prev,
        .owl-next {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);

            button {
                border: 0;
                background-color: transparent;
            }
        }

        .owl-next {
            left: auto;
            right: 0;
            transform: translateY(-50%) rotate(180deg);
        }

        .owl-item {
            float: left;

            li {
                padding: 0;
                width: 100%;
            }
        }

        a {
            color: $text-color;
            text-transform: none;
            padding-top: 0;
            position: relative;
            font-size: 14px;

            @media (max-width: $screen-md-max) {
                font-size: 16px;

                &:hover {
                    color: $color-primary;
                }

                &:hover:after {
                    color: $color-secondary-dark;
                }
            }

            &.subnav-trigger:after {
                position: absolute;
                color: $text-color;
                content: "\f105";
                font-size: 22px;
                right: 5px;
                top: 2px;
                text-rendering: auto;
                font-family: font-awesome;
                z-index: 100;
                line-height: 0.7;
                height: 20px;
            }
        }
    }
}

nav.main-nav .mn__item.level-0:last-child {
    @media (max-width: $screen-md-max) {
        border-bottom: none;
    }
}

.sub-nav.level-0 {
    @media (max-width: $screen-xs-max) {
        margin-left: 5vw;
    }

    @media(min-width:$screen-sm-min) and (max-width: $screen-sm-max) {
        margin-left: 3vw;
    }

    @media (min-width:$screen-md-min) and (max-width: $screen-md-max) {
        margin-left: 2vw;
    }
}

.sub-nav.level-1.mobile-sub {
    position: absolute;
    top: 0;
    height: calc(100vh - 60px) !important;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #fff;
    right: 0;
    width: 0;
    transition: all 1s ease;

    .row.level-2 {
        list-style: none;
        position: absolute;
        top: 0;
        right: 0;
        padding-left: 0;
        width: 100vw;
        height: calc(100vh - 60px) !important;

        > .top-category-link,
        > .back-link {
            display: block;
        }
    }

    .row.level-3 {
        margin-left: 35px;

        a.level-3 {
            font-family: $montreal-medium;
        }
    }

    .back-link a {
        display: block;
        width: 100%;
        position: relative;
        font-size: 16px;
        color: $color-secondary-dark;
        font-family: $montreal-medium;
        text-transform: lowercase;
        padding: 25px 0 25px 25px;

        &:before {
            /*@extend .icomoon;
                content: "\e902";
                font-size: 15px;*/
            content: "\f104";
            position: absolute;
            left: 0;
            top: 19px;
            font-family: font-awesome;
            font-size: 22px;
            color: $color-secondary-dark;
        }

        &::first-letter {
            text-transform: uppercase;
        }
    }
}

.sub-nav {
    .top-category-link a {
        /*font-size: 18px;
        text-transform: uppercase;*/
        font-size: 14px;
        color: $color-secondary-dark;
        display: block;
        width: 100%;
        padding-left: 25px;
        font-family: $montreal-bold;

        @media (max-width: $screen-md-max) {
            font-size: 16px;
        }
    }
}

.mobile-opened + .sub-nav.level-1.mobile-sub {
    width: 100vw;
    right: -100vw;
}

.mobile-nav {
    position: fixed;
    width: 100%;
    font-size: 0;
    padding: 0 4vw;
    border-bottom: 1px solid $color-secondary-light;
    z-index: 3;
    background-color: #fff;
}

.hamburger-button {
    float: right;
}

.menu-mob-close {
    display: none;
}

.sideNavIsOpen .menu-mob-open {
    display: none;
}

.sideNavIsOpen .menu-mob-close {
    display: block;
}

.sub-nav {
    .top-category-link,
    .back-link {
        display: none;
    }
}

ul.level-2 {
    li.level-2 {
        padding: 0 4vw;
    }
}

.main-nav {
    .separator {
        display: none;
    }

    .title {
        display: block;
        padding-bottom: 30px;
        font-size: 16px;
        text-transform: uppercase;
    }
}





@media (min-width: $screen-lg-min) {

    li.has-border {
        border-left: 1px solid $color-secondary-lighter;
    }

    ul.level-2 {
        li.level-2 {
            padding: 0 15px;
        }
    }

    .main-nav {
        .title {
            display: inline;
            padding-bottom: 0;
        }
    }

    .mobile-nav {
        display: none;
    }

    .nav-container {
        display: flex;
        justify-content: space-between;
    }

    .main-nav {
        /*flex-basis: 48%;*/
        flex-shrink: 1;
        /*border:1px solid red;*/
    }

    .right-container {
        /*flex-basis: 52%;*/
        flex-shrink: 0;
        /*border:1px solid red;*/
    }

    .overflow-visible > .sub-nav {
        overflow: visible !important;
    }

    .mn__list {
        padding: 0;
        display: flex;
        height: 100%;

        a {
            font-size: 14px;
            position: relative;
            /*padding: 39px 0 40px;*/
            /*padding: 30px 0 33px;*/
            /*padding: 33px 0 35px;*/
            /*padding: 28px 0 35px;*/
            /*padding: 30px 10px 33px;*/
            /*padding: 31.5px 0 31.5px;*/
            padding: 32px 0 32px;
            width: auto;
            border-bottom: 0;
            height: 100%;
            text-decoration: none;
            /*text-align:center;*/
            &:before {
                content: "";
                background-color: $color-dark-red;
                bottom: -1px;
                display: block;
                height: 5px;
                position: absolute;
                width: 0;
                left: 0;
                transition: width 0.5s ease;
            }

            &.mn__link.level-0 {
                padding-left: 10px;
                padding-right: 10px;
            }

            &.targetIsShown,
            &:focus,
            &.active,
            &:hover {
                text-decoration: none;

                &:before {
                    width: 100%;
                }
            }
            /*.affix & {
               
                padding: 33px 0 35px;
            }*/
        }

        .mn__item {
            
            margin-right: 5px;
            text-align: center;
            border-bottom: 0;
            height: 100%;

            &:last-child {
                margin-right: 0;
            }

            .sub-nav {
                position: absolute;
                top: 100%;
                left: 0;
                width: 100vw;
                overflow: hidden;
                height: 0;
                transition: height 0.5s ease-out; /* Adds a transition effect for height */

                &.transitioning {
                    /*transition: height .2s linear;*/
                    transition: height .5s ease-out;
                }

                ul {
                    list-style: none;
                }

                &.isHidden {
                    /*height: 0 !important;*/
                    height: 0;
                     /* Ensures content is hidden when height is 0 */
                    /*transition: height 0.5s ease-in-out;*/ /* Adds a transition effect for height */
                }
            }

            > .sub-nav {
                padding: 0 4.861111vw;
                background-color: #f4f4f5;
                color: $color-primary;

                > ul > li {
                    display: table;

                    &.back-link,
                    &.top-category-link {
                        display: none;
                    }

                    > a {
                        display: table-cell;
                        height: 90px;
                        vertical-align: middle;
                        text-align: center;
                        color: $color-primary;
                        padding: 10px 0;
                        width: 100%;
                    }
                }
            }
        }
    }

    .sub-nav.level-1 {
        background-color: #fff;
        padding: 0 4.861111vw;
    }

    .main-nav {

        ul.level-2 {
            font-family: $montreal-medium;
            padding-bottom: 42px;

            .title {
                font-size: 14px;
                text-transform: uppercase;
            }

            .separator {
                display: inline-block;
                margin: 0 12px;
            }

            li {
                color: $text-color;
                text-align: left;
            }

            li.level-2 {
                margin-top: 37px;

                a {
                    padding: 0;
                    display: inline-block;
                    color: $link-color;
                    text-transform: none;

                    &:hover {
                        color: darken($link-color, 30%);
                    }

                    &:before {
                        display: none;
                    }
                }
            }
        }

        div.level-2 {
            margin-top: 18px;
        }

        li.level-3 {
            margin-top: 27px;
        }
    }

    li.level-2 {
        &.col-lg-10 {
            li.level-3 {
                width: percentage(2/10);
            }
        }

        &.col-lg-8 {
            li.level-3 {
                width: percentage(2/8);
            }
        }

        &.col-lg-6 {
            li.level-3 {
                width: percentage(2/6);
            }
        }

        &.col-lg-4 {
            li.level-3 {
                width: percentage(2/4);
            }
        }

        &.col-lg-2 {
            li.level-3 {
                width: 100%;
            }
        }
    }

    .affix {
        .mn__item > .active + .sub-nav {
            
            top: 101%;
            /*height: auto;*/ /*29-Jul Megamenu optimization */
            /*height: auto !important;*/
            /*box-shadow: 0 0 20px 0 rgba(50,50,50,.75);*/
            z-index: 10;
        }

        .mn__item .sub-nav {
            top: 101%;
        }
    }

    div.sub-nav.level-0 li.col-lg-2.level-1 {
        width: 14.28%;
    }
}


@media (min-width: $screen-lg-min) and (max-width:1499px) {
    .main-nav {
        flex-basis: 50%;
        /*border:1px solid green;*/
        height: inherit;
    }

    .right-container {
        flex-basis: 50%;
        /*border:1px solid green;*/
        height: inherit;
    }
}


.anchor-nav-container {
    display: none;
    margin: 0;
}


@media (min-width: $screen-view-condition-min) {


    .affix {
        .mn__item > .active + .sub-nav {
            
            top: 101.15%;
            /*height: auto;*/ /* 29-Jul disabled this line as it was showing subnav for secondary menu on scroll up*/
            /*height: auto !important;*/
            /*box-shadow: 0 0 20px 0 rgba(50,50,50,.75);*/
            z-index: 10;
        }

        .mn__item .sub-nav {
            top: 101.15%;
        }
    }
}

@media (min-width: $screen-md-min) {
    .anchor-nav-wrapper {
        font-size: 0;
        margin: 0 -10px; /* Old Value margin: 0 -20px;*/
        transition: top .5s ease;


        .affix & {
            background-color: #fff;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            padding: 0 4vw;
            margin: 0;
            /*box-shadow: 0 5px 8px 0 rgba(50,50,50,0.4);*/
            box-shadow: 0 0 1px 0 rgba(50,50,50,0.5);

            @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }

        .scroll-up.affix & {
            top: 79px;
        }

        a {
            display: inline-block;
            font-family: $montreal-medium;
            text-transform: none;
            font-size: 14px;
            padding: 15px 12px;
            transition: all .3s ease-in-out;

            &:hover {
                color: $color-secondary-dark;
                text-decoration: none;
            }

            &:active, &:focus {
                color: $color-secondary;
                text-decoration: none;
            }

            &.btn-secondary:focus {
                color: $color-white;
            }
        }

        .col-md-8 a {
            padding: 15px 19px 15px 5px;
        }
    }

    .big-padding-left .anchor-nav-wrapper .col-md-8 {
    }

    .big-padding-left .anchor-nav-wrapper .col-md-8 a {
        padding: 15px 19px 15px 15px;
    }

    .col-md-9 .big-padding-left > div.flex-anchor-box, .col-md-9 .big-padding > div.flex-anchor-box {
        margin: 0 20px 0 5px;
    }

    .big-padding-left .anchor-nav-wrapper .col-md-4.flex-anchor-box {
        display: flex !important;
    }

    .big-padding-left .anchor-nav-wrapper .col-md-4 button.sam-anchor, .big-padding .anchor-nav-wrapper .col-md-4 button.sam-anchor {
        color: $color-secondary;
        border: 0;
        border-left: 1px solid #D9D9D9;
        border-right: 1px solid #D9D9D9;
        margin-bottom: 0;
        background: transparent;

        &:hover {
            background-color: #f2f2f2;
        }
    }

    .big-padding .col-md-4.flex-anchor-box a.sam-anchor {
        border: 0;
        border-left: 1px solid #D9D9D9;
        border-right: 1px solid #D9D9D9;
        background: transparent;
        color: $color-secondary;
    }

    .anchor-nav-container {
        overflow: hidden;
        border-bottom: 1px solid #cccccc;
        border-top: 1px solid #cccccc;
        display: block;

        &.affix {
            /*z-index: 1000;*/
            z-index: 1040;
            border: 0;
            height: 0;
            margin: 0;
        }
    }
}

@media (min-width: $screen-lg-min) {
    .anchor-nav-wrapper {

        .scroll-up.affix & {
            top: 0;
            /*min-height: 85px;*/
            min-height: 88px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }

        .big-padding {

            .scroll-up.affix & {
                flex: 1;
            }
        }
    }
}

@media (min-width: $screen-view-condition-min) {
    .mn__list {
        a {
            /*padding: 30px 0 35px;*/
            /*padding: 25px 0 35px;*/
            /*padding: 30px 0 30px;*/
            padding: 30.5px 0 30.5px;
            text-decoration: none;
        }
    }

    .main-nav {
        /*flex-basis: 52%;*/
        flex-basis: 50%;
        flex-shrink: 1;

        .mn__item {
            .sub-nav {
                ul {
                    max-width: $screen-laptop-min + 30;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }

    .right-container {
        /*flex-basis: 48%;*/
        flex-basis: 50%;
        flex-shrink: 0;
    }

    .anchor-nav-wrapper {
        font-size: 0;
        margin: 0 -20px;
        transition: top .5s ease;


        .affix & {
            background-color: #fff;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            padding: 0 4vw;
            margin: 0;
            /*box-shadow: 0 5px 8px 0 rgba(50,50,50,0.4);*/
            box-shadow: 0 0 1px 0 rgba(50,50,50,0.5);
        }
        /*.scroll-up.affix & {
            top: 0;
        }*/
        /*a {
            display: inline-block;
            font-family: $montreal-medium;
            text-transform:  none;
            font-size: 14px;
            padding: 20px;
        }*/
    }


    .anchor-nav-container {
        overflow: hidden;
        border-bottom: 1px solid #cccccc;
        border-top: 1px solid #cccccc;
        display: block;

        &.affix {
            /*z-index: 1000;*/
            z-index: 1040;
            border: 0;
            height: 0;
            margin: 0;
        }
    }
}

.scroller_anchor {
    height: 0px;
    margin: 0;
    padding: 0;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}

li.dropdown.mobile-anchor {
    list-style: none;
    display: none;
    
    border-bottom: 1px solid #D9D9D9;
    border-top: 1px solid #D9D9D9;
    margin-top: 45px;
    margin-bottom: 35px;

    &.fix-to-top {
        background-color: #fff;
        position: fixed;
        top: 59px;
        left: 0;
        width: 100%;
        padding: 0 4vw;
        margin: 0;
        z-index: 2000;
    }

    svg {
        display: inline;
        float: right;
        margin-top: 6px;
        margin-right: 5px;
    }



    .arw-anchor-up-svg {
        display: none;
    }

    .arw-anchor-dwn-svg {
        
    }

    .arw-anchor-dwn {
        fill: none;
        stroke: $color-secondary;
        stroke-width: 1.5px;
    }

    .arw-anchor-up {
        fill: none;
        stroke: $color-secondary;
        stroke-width: 1.5px;
    }

    &.open {

        svg {
            display: inline;
            float: right;
            margin-top: 6px;
            margin-right: 5px;
        }

        .arw-anchor-dwn-svg {
            display: none;
        }

        .arw-anchor-up-svg {
            
        }
    }

    a {
        text-decoration: none;
        display: inline-block;
        font-family: $montreal-medium;
        font-size: 14px;
        color: $color-secondary;
        background-color: none;


        &.dropdown-toggle {
            background-color: transparent;
            padding: 15px 0;
            display: block;

            span#varAchTxt {
                font-family: $montreal-regular;
            }
        }
    }

    ul.dropdown-menu {
        padding: 10px 0;
        list-style: none;
        margin: 0;
        margin-top: 1px;
        background-color: #F2F2F2;
        width: 100%;
        box-shadow: none;
        border: 0 none;
        border-bottom: 1px solid #D9D9D9;

        li {

            a {
                height: 40px;
                display: table-cell;
                vertical-align: middle;
                font-family: $montreal-medium;
                text-transform: none;
                color: $color-secondary;
                white-space: normal;

                &.activeLink {
                    color: $color-secondary-dark;
                }


                &:hover {
                    color: $color-secondary-dark;
                }
            }
        }
    }

    @media (max-width: $screen-sm-max) {
        display: block;
    }
}

.fix-to-top ul.dropdown-menu li a {
    padding: 0 4vw;
}


span.arwToglBox {
    @media (max-width: $screen-md-max) {
        position: absolute;
        width: 30px;
        height: 70px;
        overflow: hidden;
        margin-top: 0;
        right: 4vw;
        z-index: 1000;
        cursor: pointer;
    }

    .secondary-nav & {
        margin-top: 0;
        height: 55px;
    }
}


.inner-nav-box {
    position: relative;
    height: 86px;
    background: $color-secondary-lightest; 
    z-index: 1020;

    .inner-nav-div {
        display: flex;
        flex-direction: row;
    }

    .inner-nav-div-left { 
        align-items: center;
        display: flex;

        h4 {
            a {
                color: $color-secondary-dark;
                font-family: $montreal-regular;
                text-transform: capitalize;
                font-size: clamp(14px, calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320))), 16px);
                text-decoration: none;

                &:hover {
                    color: $color-primary;
                    text-decoration: none;
                }
            }
        }
    }

    .inner-nav-div-right {
        align-self: right;
        margin-left: auto;

        .main-nav a.mn__link.level-0 {
            color: $color-secondary-dark;
            font-family: $montreal-regular;
            text-transform: capitalize;
            font-size: clamp(14px, calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320))), 16px);

            &:hover {
                color: $color-primary;
            }

            &.active {
                color: $color-primary !important;
            }
        }
    }
}

/* inner nav - mobile start */

/*the container must be positioned relative:*/
.custom-select-inner-mobile {
    position: relative;
}

.custom-select-inner-mobile select {
    display: none; /*hide original SELECT element:*/
}



/*style the arrow inside the select element:*/
.select-selected:after {
    position: absolute;
    content: "";
    right: 0;
    width: 20px;
    height: 20px;
    line-height: .7;
    margin-right: 4vw;
    margin-top: 3px;
    content: "\f107";
    box-sizing: border-box;
    text-align: center;
    text-rendering: auto;
    font-family: font-awesome;
    font-size: 22px;
    cursor: pointer;
    z-index: 210;
    -webkit-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    -moz-transition: all .2s ease-out;
    transition: all .2s ease-out;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
    content: "\f106";
}

/*style the items (options), including the selected item:*/
.select-items div, .select-selected {
    color: $color-secondary-dark;
    padding: 10px 0;
    cursor: pointer;
    user-select: none;
    font-family: $montreal-regular;
    font-size: 16px;
}

.select-selected {
    background-color: $color-secondary-lightest;
    font-family: $montreal-bold;
}

.select-items div {
    padding-left: 4vw;
    padding-right: 4vw;
}

.select-selected {
    padding: 17px 0;
    font-size: 16px;
    border: 1px solid transparent;
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
}
/*style items (options):*/
.select-items {
    position: absolute;
    background-color: $color-secondary-lightest;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 199;
    padding-top: 10px;
    padding-bottom: 10px;
}

/*hide the items when the select box is closed:*/
.select-hide {
    display: none;
}

.select-items div:hover, .same-as-selected {
    background-color: $color-secondary-medium-gray;
}

/* inner nav - mobile end */

.flex-anchor-box {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    justify-content: flex-end;

    @media (min-width: $screen-view-condition-min) {

        .col-md-8 {
            padding-left: 0;
        }

        .col-md-4 {
            padding-right: 0;
        }
    }

    @media (min-width: $screen-md-min) {
        /* this code causes anchor elements to vertically center align in flexbox */
        .col-md-8 {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }
    }

    @media (max-width: $screen-xs-max) {
        flex-direction: column;
    }
}

.col-md-4.flex-anchor-box {
    display: flex !important;
}

.flex-anchor-btn {
    flex: 1;
}
