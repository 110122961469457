﻿
.multi-tab-content {
    padding-right: 0;



    @media (max-width:$screen-xs-max) {
        width: 100%;
        margin-bottom: 25px;
    }

    .multi-tab-bg {
        position: relative;
        min-height: 412px;
        /*background-position: bottom right;*/
        background-position: top right;
        /*background-size: 100% auto;*/
        background-color: #f3f3f3;
        background-size: cover;
        display: none;
        transition: all .3s ease-in-out;
        height: 100%;

        &.active {
            animation: animateshowhide .8s ease-in-out;
            display: block;

            @keyframes animateshowhide {
                0% {
                    opacity: 0.2;
                }

                100% {
                    opacity: 1;
                }
            }

            @media (max-width:$screen-sm-max) {
                display: flex;

                .multi-tab-content-text-box {
                    @media (max-width:$screen-xs-max) {
                        width: 93%;
                    }
                }
            }
        }

        @media (max-width: $screen-sm-max) {
            min-height: 450px;
        }

        @media (max-width: $screen-xs-max) {
            min-height: 316px;
        }
    }

    .multi-tab-content-text-box {
        min-height: 100%;
        position: relative;



        @media (max-width:$screen-sm-max) {
            padding-left: 4vw;
            padding-right: 4vw;
        }

        @media (min-width:$screen-md-min) and (max-width:1499px) { /*over 1500px left value is calculated inside component script */
            padding-left: 4vw;
        }
    }

    .teaser-summary {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        min-width: 300px;
        /*width: 60%;*/
        max-width: 434px;


        .mar-t-b-0 {
            margin-top: 0;
            margin-bottom: 0;
        }

        h2 {
            font-size: calc(.54vw + 16px)
        }

        p {
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            margin-bottom: 0;

            &:empty {
                display: none;
            }
        }
        /*@media (max-width:$screen-xs-max) {
            padding-right: 8vw;
            min-width: 100%;
        }

        @media (min-width:1500px) {
            padding-left: 5vw;
        }

        @media (min-width:1550px) {
            padding-left: 6vw;
        }

        @media (min-width:1600px) {
            padding-left: 8vw;
        }

        @media (min-width:1650px) {
            padding-left: 9vw;
        }

        @media (min-width:1700px) {
            padding-left: 10vw;
        }

        @media (min-width:1750px) {
            padding-left: 11vw;
        }

        @media (min-width:1800px) {
            padding-left: 12vw;
        }

        @media (min-width:1850px) {
            padding-left: 13vw;
        }

        @media (min-width:1900px) {
            padding-left: 14vw;
        }

        @media (min-width:1950px) {
            padding-left: 15vw;
        }

        @media (min-width:2000px) {
            padding-left: 16vw;
        }

        @media (min-width:2050px) {
            padding-left: 17vw;
        }

        @media (min-width:2100px) {
            padding-left: 18vw;
        }

        @media (min-width:2150px) {
            padding-left: 18vw;
        }

        @media (min-width:2200px) {
            padding-left: 19vw;
        }

        @media (min-width:2250px) {
            padding-left: 20vw;
        }

        @media (min-width:2300px) {
            padding-left: 21vw;
        }

        @media (min-width:2350px) {
            padding-left: 21vw;
        }

        @media (min-width:2400px) {
            padding-left: 22vw;
        }*/


        &.dark-theme {

            h3.title-h3, p, a.btn-text {
                color: $color-white;
                text-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
            }
        }
    }
}



.multi-tab-menu {
    padding-left: 0;

    .caption-m {
        padding-left: 25px;
        padding-right: 25px;

        @media (max-width:$screen-sm-max) {
            padding-left: 4vw;
            padding-right: 4vw;
            margin-left: 15px;
            margin-top: 40px;
        }
    }

    ul {
        list-style: none;
        padding: 0;
        width: 100%;
        margin-bottom: 0;

        @media (min-width:$screen-lg-min) {
            width: 321px;
        }

        li {
            border-bottom: 1px solid #e4e4e6;
            position: relative;

            span.icn-arrow-small-red {
                background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1LjUiIGhlaWdodD0iMTAuOTkiIHZpZXdCb3g9IjAgMCA4LjU2MSAxNS43MDciPgogIDxwYXRoIGlkPSJpY24tYXJyb3ctbWVkaXVtLWNoYXJjb2FsLWRhcmstcmlnaHQiIGQ9Ik03LjUsMTUsMCw3LjUsMy4xMjUsNC4zNzUsNy41LDAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDcuODU0IDE1LjM1NCkgcm90YXRlKDE4MCkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI0ZGMzcyRiIgc3Ryb2tlLXdpZHRoPSIxIi8+Cjwvc3ZnPgo=);
                width: 8px;
                height: 12px;
                background-repeat: no-repeat;
                /*top: 27px;*/
                position: absolute;
                right: 25px;
                top: 54%;
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
            }
            /*@media (max-width:$screen-sm-max) {
                    &:last-child {
                        margin-bottom: 30px;
                    }
                }*/

            @media (min-width:$screen-md-min) {

                span.icn-arrow-small-red {
                    right: 25px;
                    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1LjUiIGhlaWdodD0iMTAuOTkiIHZpZXdCb3g9IjAgMCA4LjU2MSAxNS43MDciPgogIDxwYXRoIGlkPSJpY24tYXJyb3ctbWVkaXVtLWNoYXJjb2FsLWRhcmstcmlnaHQiIGQ9Ik03LjUsMTUsMCw3LjUsMy4xMjUsNC4zNzUsNy41LDAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDcuODU0IDE1LjM1NCkgcm90YXRlKDE4MCkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZmZmZiIgc3Ryb2tlLXdpZHRoPSIxIi8+Cjwvc3ZnPgo=);
                }



                &:last-child {
                    border-bottom: 1px solid $color-white;
                }
                /*span.icn-arrow-small-red {
                    opacity: 0;
                }*/
            }

            a {
                /*padding: 21px 25px;*/
                padding: 21px 40px 21px 25px;
                font-size: 14px;
                color: $color-secondary-dark;
                display: block;
                text-decoration: none;
                transition: all .3s ease-in-out;

                &:hover {
                    background-color: $color-dark-red;
                    color: $color-white;

                    @media (max-width:$screen-md-min) {
                        background-color: $color-white;
                        color: $color-secondary-dark;
                    }
                }

                @media (max-width:$screen-sm-max) {
                    padding-left: 4vw;
                    padding-right: 4vw;
                    margin-left: 15px;
                    /*margin-right: 15px;*/
                }
            }
        }

        @media (max-width:$screen-sm-max) {
            width: 100%;
        }
    }
}
