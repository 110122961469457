$grid-float-breakpoint: $screen-sm-min;

@import "../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/grid";

$grid-gutter-width: 30;

[class*="col-xl-"] {
  position: relative;
  min-height: 1px;
  padding-left:  (30px / 2);
  padding-right: (30px / 2);
}

@media (min-width: 1400px) {
  @include make-grid(xl);
}

.container {
  position: relative;
  width: 100%;
  max-width: 1170px;
  @media (max-width: $screen-lg-min) {
    width: 100%;
    max-width: 1170px;
  }
  @media (max-width: $screen-xs-max) {
    width: 100%;
    max-width: 1170px;
  }
}


// Custom implementation of block grid here

.row {
  &.no-gutters {
    padding: 0;
    & > [class^="col-"],
    & > [class*=" col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }

  /* Support equal height columns */
  &.row-col-eq {
      margin-bottom: $line-height-computed;
  }
}
