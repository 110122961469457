@import "../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/buttons";

.btn, a.btn {
    font-weight: $btn-font-weight;
    white-space: normal;
    overflow: hidden;
    position: relative;

    &[disabled] {
        &:hover, &:focus {
            color: currentColor !important;
        }
    }

    &-hollow {
        border: 2px solid $text-color;
    }

    /*&-default, */&-hollow {
        border-width: 2px;
        background-color: transparent;
        border-radius: $btn-border-radius-base;

        .bg-primary &, .bg-secondary &, .bg-dark &, .bg-media & {
            border-color: $brand-text-color !important;
            color: $brand-text-color !important;

            &:hover {
                background-color: $brand-text-color;
                color: $text-color !important;
            }
        }

        .bg-white & {
            border-color: $color-black !important;
            color: $color-black !important;

            &:hover {
                background-color: $color-black;
                color: $color-white !important;
            }
        }

        &:hover {
            background-color: $color-secondary-dark;
            color: $brand-text-color;
        }
    }

    &-lg {
        @media all and (max-width: $screen-xs-max) {
            font-size: floor($font-size-large*.8);
        }
    }

    &-xl {
        font-size: floor($font-size-large*1.1);
        padding: 15px 35px;

        @media all and (max-width: $screen-xs-max) {
            font-size: $font-size-large;
        }
    }

    &-social-icon {
        position: relative;

        span {
            float: right;
            padding-left: 0.9em;
        }

        &.btn {
            &-facebook {
                @include backgroundHoverLighten(#3b5998, 25%, true);

                span, i {
                    color: #FFF;
                }
            }

            &-twitter {
                @include backgroundHoverLighten(#00aced, 25%, true);

                span, i {
                    color: #FFF;
                }
            }

            &-youtube {
                @include backgroundHoverLighten(#bb0000, 25%, true);

                span, i {
                    color: #FFF;
                }
            }

            &-instagram {
                @include backgroundHoverLighten(#125688, 25%, true);

                span, i {
                    color: #FFF;
                }
            }

            &-google-plus {
                @include backgroundHoverLighten(#dd4b39, 25%, true);

                span, i {
                    color: #FFF;
                }
            }

            &-linkedin {
                @include backgroundHoverLighten(#007bb6, 25%, true);

                span, i {
                    color: #FFF;
                }
            }
        }

        .nav & {
            background-color: transparent !important;
            color: $navbar-social-icon-color;

            i {
                color: $navbar-social-icon-color;

                .bg-white & {
                    color: #000;
                }
            }
        }
    }

    &-primary {
        .bg-primary & {
            background-color: rgba(0,0,0,.4);
        }
    }
}

button {
    &.close {
        border: 2px solid $text-color;
        background-color: transparent;
    }
}

.ripple-effect {
    position: absolute;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    background: white;
    animation: ripple-animation 1.5s;

    .btn-primary & {
        background-color: #ff6666;
    }

    .btn-ghost &,
    .btn-secondary & {
        background-color: $color-secondary;
    }
}


@keyframes ripple-animation {
    from {
        transform: scale(1);
        opacity: 0.4;
    }

    to {
        transform: scale(100);
        opacity: 0;
    }
}
