// Custom Breakpoint
$screen-xl-min: 1600px;

//Colors
$color-primary: #E02F28 !default;
$color-dark-primary: #CA2A24 !default;
/*$color-dark-primary: #C82B25 !default;*/

/*below : the 2 next colours were inverted :*/


$color-secondary: $color-primary !default;
$color-secondary-on: #3d5f7a !default;
$color-secondary-on-hover: #bec9d4 !default;
$color-dark-secondary: #3d5f7b !default;
$color-secondary-pale: #a2b5c6;
$color-secondary-light: #e4e4e6 !default;

$color-secondary-medium-gray-on-dark-bg: #D0D0D0 !default;
$color-secondary-medium-gray: #5B5959 !default; /*#787271*/
$color-secondary-light-gray: #E8E7E7 !default;
$color-secondary-lighter: #c2c2c2 !default;
$color-secondary-lightest: #F3F3F3 !default;
$color-secondary-lightest-80: #434141 !default;
$color-secondary-dark: #141212 !default;
$color-secondary-dark-gray: #727171 !default;
$color-white: #fff !default;
$color-black: #000 !default;
$color-dummy: #c532df;
$color-high-contrast: #FF5733;
$color-navigation-dark: #141212 !default;
$color-select-menu-hover-active: #2C2A2A !default;
$color-select-menu-hover-active-on: $color-secondary-lightest-80 !default;
$color-pagination-dark: #2C2A2A !default;
$brand-success: #d5d5d5;
$brand-primary: $color-primary !default;
$bg_secondary: $color-secondary-lightest !default; // bg color footer bloc
$breadcrumb-light: #898C8F;
$breadcrumb-dark: $color-secondary-dark;
$color-quote-medium-gray: #B9B8B8 !default;

$divider-grey-line-on-normal-bg: #e5e5e5 !default;
$divider-grey-line-on-dark-bg: #727171 !default;

$dropdown-bottom-line-normal: #A1A0A0 !default;
$dropdown-bottom-line-hover: $color-primary !default;

$font-size-49mn-61mx-h1: clamp(48.828px, calc(48.828px + (61.03 - 48.828) * ((100vw - 320px) / (1920 - 320))), 61.03px);
$font-size-39mn-48mx-h2: clamp(39.06px, calc(39.06px + (48.83 - 39.06) * ((100vw - 320px) / (1920 - 320))), 48.83px);
$font-size-31mn-39mx-h3: clamp(31.25px, calc(31.25px + (39.06 - 31.25) * ((100vw - 320px) / (1920 - 320))), 39.06px);
$font-size-25mn-31mx-h4: clamp(25px, calc(25px + (31.25 - 25) * ((100vw - 320px) / (1920 - 320))), 31.25px);
$font-size-20mn-25mx-h5: clamp(20px, calc(20px + (25 - 20) * ((100vw - 320px) / (1920 - 320))), 25px);


$font-size-14mn-16mx: clamp(14px, calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320))), 16px);
$font-size-16mn-18mx: clamp(16px, calc(16px + (18.5 - 16) * ((100vw - 320px) / (1920 - 320))), 18.5px);
$font-size-16mn-20mx: clamp(16px, calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320))), 20px);
$font-size-16mn-32mx: clamp(16px, calc(16px + (32 - 16) * ((100vw - 320px) / (1920 - 320))), 32px);
$font-size-75mn-95mx: clamp(75px, calc(75px + (95.35 - 75) * ((100vw - 320px) / (1920 - 320))), 95.35px);
$font-size-55mn-75mx: clamp(55px, calc(55px + (75 - 55) * ((100vw - 320px) / (1920 - 320))), 75px);

$font-size-25mn-39mx-quote: clamp(25px, calc(25px + (39.06 - 25) * ((100vw - 320px) / (1920 - 320))), 39.06px);

//Scaffolding
$body-bg: $color-white !default;
$main-bg: $color-white !default;
$text-color: $color-secondary-dark !default;
$color-light-grey: #D2D0D0 !default;
$color-light-red: #f94a4c !default;
$color-dark-red: #FF372F !default;

$link-color: $color-secondary-dark !default;
$link-hover-color: $color-primary !default;
$brand-text-color: $color-primary !default;

$bg-dark-color: $color-secondary-dark !default;
$bg-btn-grey: #F4F3F3 !default;
$bg-btn-dark-grey: #E8E7E7 !default;
$bg-btn-grey-on-dark-bg: #342B2A !default;
$bg-btn-dark-grey-on-dark-bg: #4B4342 !default;

$footer-background-color: $color-white !default;
$footer-text-color: $text-color !default;

$owl-btn-border: #F3F3F3 !default;
$own-btn-hover-bg: #F3F3F3 !default;

$owl-btn-red-border: #E02F28 !default;

//Typography original fonts
$font-family-serif: 'PPNeueMontreal-Regular', sans-serif;
$font-family-sans-serif: "sans-serif" !default;
$font-family-base: 'PPNeueMontreal-Medium', sans-serif;

$montreal-bold: 'PPNeueMontreal-Bold', sans-serif;
$montreal-medium-italic: 'PPNeueMontreal-MediumItalic', sans-serif;
$montreal-medium: 'PPNeueMontreal-Medium', sans-serif;
$montreal-regular: 'PPNeueMontreal-Regular', sans-serif;

$fonts-size-base: 18px !default;
$fonts-size-xlarge: 44px !default;
$fonts-size-large: 28px !default;
$fonts-size-medium: 16px !default;
$fonts-size-small: 14px !default;
$fonts-size-h1: 60px !default;
$fonts-size-h2: 20px !default;
$fonts-size-h3: 14px !default;
$fonts-size-h4: 14px !default;

$btn-fonts-size: 16px !default;
$headings-font-weight: 800 !default;
$list-font-weight: normal !default;

$boxspacing: 30px;
