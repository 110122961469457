.breabcrumb, ol.breadcrumb {
    display: none;
    padding-left: 4vw;
    padding-right: 4vw;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    line-height: normal;
    margin-bottom: 0;
    text-transform: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    li {
        opacity: 1 !important;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        color: $color-primary;
        font-family: $montreal-regular;
        font-size: 12px;
        display: inline;

        &:before {
            content: '/';
            color: $breadcrumb-light !important;
            font-size: 14px;
            padding: 0 2px;
        }

        &:first-child {
            display: none;
        }

        &:nth-child(2):before {
            content: none;
        }

        &:last-child {
            text-transform: none;
            /*font-size: 12px;*/
            /*@extend .new__typo__26;*/
        }

        a {
            color: $breadcrumb-light;
            font-size: 12px;
            text-decoration: none;
            font-weight: normal;
            text-transform: none;

            &:hover {
                color: $breadcrumb-dark;
            }
        }
    }

    li.active {

        a {
            color: $breadcrumb-dark;
            font-size: 12px;
            font-weight: normal;
            pointer-events: none;

            &:hover {
                color: $breadcrumb-dark;
            }
        }
    }

    @media (min-width: $screen-sm-min) {
        display: block;
        padding-top: 30px !important;


        ul {
            margin-bottom: 0;
        }
    }

    @media (min-width: $screen-md-min) {
        width: 75%;
        padding-right: 0;
    }

    @media (min-width: $screen-view-condition-min) {
        left: 50%;
        max-width: $screen-laptop-min;
        margin-left: calc(#{$screen-laptop-min}/-2);
        padding-left: 0;
    }
}

/* Trello 404 - Breadcrumb spacing end */
.big-padding {
    .breabcrumb, ol.breadcrumb {
        @media (min-width: $screen-sm-min) {
            padding-left: 0;
        }

        @media (min-width: $screen-md-min) {
            padding-left: 0;
        }
    }
}
/* Trello 404 - Breadcrumb spacing end */

.header {
    .header-text {
        .breadcrumb {
            top: 15px;
        }
    }
}

.section + .header {
    ol.breadcrumb {
        top: calc(-60vh + 63px);
    }
}

.banner-video + .header {
    ol.breadcrumb {
        top: calc(-375px + 23px);

        @media (min-width: 1271) {
            top: calc(-29.5vw + 23px);
        }
    }
}
