@import "_variables.scss";
@import "../../../bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss";

// BELLOW: old typo
.typo--03 {
    color: $color-primary;
    @extend .new__typo__10; //Header
}

.bg-media.desktop {
    display: block;
}

.bg-media.mobile {
    display: none;
}

@media all and (max-width: $screen-sm-max) {
    .bg-media.desktop {
        display: none !important;
    }

    .bg-media.mobile {
        display: block !important;
    }
}
// the title should go up if it's parents (section...) are just after header.page-header
// very specific but we didn't have the choise for now
// 3 cases for H1:
//  - h1 in header without image (global.scss line 84)
//  - h1 in header with image    (header.scss line 63)
//  - h1 under header with image (just below)

header.page-header + section > div > div > div.col-md-9.big-padding-left > .under-hero {
    position: relative;
    top: calc((24px + 1.25vw) * -1);
    margin-bottom: -27px;
    float: left;
    width: 100%;
    margin-top: 0;
}

.under-hero {
    color: $color-primary;
    @extend .new__typo__01; //News page
}

.typo--h1,
h1 {
    font-family: $montreal-bold;
    color: $color-secondary-dark;
    /*font-size: $fonts-size-h1;
    font-size: calc(2.5vw + 12px);*/
    font-size: $font-size-49mn-61mx-h1;
    line-height: 1.2;
    font-weight: normal;
    z-index: 100 !important;
    word-break: break-word;
    /*@media (min-width: $screen-view-condition-min) {
        font-size: 50px;
    }*/
}

.typo--h2,
h2 {
    font-family: $montreal-bold;
    /*font-size: $fonts-size-h2;*/
    /*font-size: calc(17px + 0.21vw);*/
    font-size: $font-size-39mn-48mx-h2;
    color: $color-secondary-dark;
    font-weight: normal;
    line-height: 1.2;
    margin: 0 0 calc(16px + 0.5vw);
    word-break: break-word;

    .orange-title & {
        color: $color-secondary-dark;
        /*font-size: $font-size-25mn-31mx-h4;*/
        /*@extend .new__typo__02;*/
    }

    &.red-title {
        color: $color-secondary-dark;
    }

    &.title-h2 {
        font-family: $montreal-bold !important;
        color: $color-secondary-dark;
        font-size: 28px !important;
        /*font-size: $font-size-31mn-39mx-h3;*/
        line-height: 1.30 !important;

        @media (min-width:$screen-lg-min) {
            font-size: 36px !important;
            line-height: 1.30 !important;
            margin: 0 0 calc(20px + 0.5vw);
        }
    }
}

.typo--h3,
h3 {
    font-size: $fonts-size-h3;
    font-size: calc(13px + 0.07vw);
    line-height: 1.2;
    margin: 0 0 calc(16px + 0.5vw);
    color: $color-secondary-dark;
    font-family: $montreal-bold;
    font-weight: normal;
    word-break: break-word;

    .orange-title & {
    }

    &.title-h3 {
        font-family: $montreal-bold;
        font-size: 24px;
        line-height: 1.38em;
        color: $color-secondary-dark;
        text-decoration: none;
        text-transform: none;
        margin-top: 0;
        margin-bottom: 0;

        ~ p {
            margin-top: 10px;
            margin-bottom: 18px;
        }


        &.dark-title {
            color: $color-secondary-dark;
        }

        @media (min-width:$screen-lg-min) {
            font-size: 30px !important;
        }
    }
}



.typo--h4,
h4 {
    font-family: $montreal-bold;
    font-size: $fonts-size-h4;
    font-size: calc(13px + 0.07vw);
    line-height: 1.2;
    margin: 0;
    font-weight: normal;
    color: $color-secondary-dark;
    font-weight: normal;
    word-break: break-word;

    &.title-h4 {
        font-family: $montreal-bold;
        font-size: $font-size-20mn-25mx-h5;
        line-height: 1.30em;
        text-decoration: none;
        text-transform: none;
        margin-top: 0;
        margin-bottom: 0;
    }
}

article {
    margin: $boxspacing 0;

    ul {
        padding: 0;
        margin: 0 0 0 20px;
    }
}

.section:last-child article:last-of-type {
    margin-bottom: 0;
}

body {
    height: 100%;
    max-width: 100%;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    transition: none;

    &.modal-open {
        padding-right: 0 !important;
    }

    * {
        transition: none;
    }
}

#main-container {
    overflow-x: hidden;
}

.tablelayout {
    @media (min-width: $screen-md-min) {
        display: table;
        width: 100%;
    }

    > .row {
        @media (min-width: $screen-md-min) {
            display: table;
            width: 100%;
        }

        > [class*="col-"] {
            @media (min-width: $screen-md-min) {
                display: table-cell;
                vertical-align: middle;
                float: none;
            }

            .thumbnail {
                margin-bottom: 0;
            }
        }
    }
}

.legal-warning {
    color: $color-secondary-dark;
    @extend .new__typo__03;
}

aside {
    display: none;

    @media (min-width: $screen-md-min) {
        display: block;
    }
}

.orange-title {
   /* h2 {
        color: $color-secondary-dark;
        font-size: $fonts-size-large;
        font-size: fluid(20px, 28px);
        line-height: 1.2;
        font-family: $montreal-bold;
        margin: 0 0 calc(25px + 0.5vw);

        &.title-h2 {
            line-height: 1.30 !important;
        }
    }*/



    .campaign {
        .col-sm-4 {
            &:nth-child(3) .teaser-summary {
                margin-bottom: 0;
            }
        }

        .teaser-summary {
            margin-bottom: 40px;

            @media (min-width: $screen-md-min) {
                margin-bottom: 0;
            }

            h2 {
                margin-bottom: 10px;


                /*@extend .new__typo__01;*/
            }

            div {
                color: $color-secondary-dark;
                @extend .new__typo__03;
            }
        }
    }
}

.content-box {
    margin: 0 0 $boxspacing;

    .thumbnail {
        width: 100%;
        overflow: hidden;

        @media (min-width: $screen-md-min) {
            height: auto;
            width: auto;
        }

        img {
            height: 100%;
            max-width: none;

            @media (min-width: $screen-md-min) {
                height: auto;
                width: 100%;
                max-width: 100%;
            }
        }
    }
}

.orange {
    margin-top: 45px;
}

iframe {
    max-width: 100%;
}

.teaser-summary-hero {
    h1 {


        @extend .new__typo__01; //Homepage
    }

    div {
        color: $color-primary;
        @extend .new__typo__11; //Homepage
    }
}

.uppercase-blue-links {
    p,
    p > a {
        text-transform: uppercase;
        color: $color-secondary-on;
        @extend .new__typo__06;
    }
}

//IMPORTANT : Text-trimming on 3 lines

.text_2lines {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;

    * {
        display: inline !important;
    }
}

/*.text_2lines_no_ellipsis {
    overflow: hidden;
    display: block;*/ /* Ensure it's a block container */
    /*text-overflow: clip;*/ /* Prevent ellipses */

    /*max-height: calc(2 * clamp(60px, calc(60px + (110 - 60) * ((100vw - 320px) / (1920 - 320))), 110px));
    word-wrap: break-word;*/ /* Break long words */
/*}*/

.text_3lines {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;

    * {
        display: inline !important;
    }
}

.text_4lines {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;

    * {
        display: inline !important;
    }
}

.coveo-result-cell,
.sector-list,
.owl-stage-outer {
    .text_2lines {
        max-height: 3.1em;
    }

    .text_3lines {
        color: $text-color;
        @extend .new__typo__09;
        max-height: 5.4em;

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            /* IE10+ specific styles go here */
            max-height: 4.9em;
        }
    }

    .text_4lines {
        color: $text-color;
        @extend .new__typo__09;
        max-height: 5.4em;

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            /* IE10+ specific styles go here */
            max-height: 4.9em;
        }
    }
}

/* INC014714605 - Project Gallery component - issues with text on IE11 */
.text_3lines {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        position: relative;
        height: 4.85em; /* exactly three lines */
        max-height: 4.9em;
    }
}

.text_3lines:after {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        content: "";
        text-align: right;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 50%;
        height: 1.2em;
        background: linear-gradient( to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 70% );
    }
}

.text_4lines {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        position: relative;
        height: 4.85em; /* exactly three lines */
        max-height: 4.9em;
    }
}

.text_4lines:after {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        content: "";
        text-align: right;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 50%;
        height: 1.2em;
        background: linear-gradient( to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 70% );
    }
}

table.table-responsive {
    width: 100%;
    font-family: $montreal-regular;
    border: 0;

    @media (min-width: $screen-sm-min) {
        width: auto;
    }

    tr:first-child {
        display: none;

        @media (min-width: $screen-sm-min) {
            display: table-row;
            background-color: $color-primary;
            color: $color-white;
            font-size: 14px;
            font-family: $montreal-medium;
        }
    }

    tr:nth-child(odd):not(:first-child) {
        td {
            background-color: #fdd9d7;
        }
    }

    td {
        position: relative;
        display: block;
        padding: 10px 10px 10px 25%;

        &:before {
            display: block;
            width: 25%;
            position: absolute;
            content: attr(data-header);
            font-family: $montreal-medium;
            left: 0;
            top: 10px;
            padding: 0 10px;
            color: $color-primary;
        }

        &:last-child {
            margin-bottom: 10px;
        }

        @media (min-width: $screen-sm-min) {
            display: table-cell;
            border: 1px solid $color-primary;
            padding: 10px;

            &:before {
                display: none;
            }
        }
    }
}

.office-marker {
    width: 36px;
    height: 43px;
}

.sc-breadcrumb .sc-breadcrumb-item-path {
    height: auto !important;
    display: inline-block !important;
}

@media screen and (-ms-high-contrast: active) {
    .video-player .vp__video-controls {
        border-top: 1px solid #fff;
    }

    .st0,
    svg path {
        fill: #fff !important;
    }

    .vp__volume-bar {
        border: 1px solid #fff;

        &:after {
            border: 1px solid #fff;
        }
    }

    .secondary-white {
        background: none !important;
    }
}

.focusOnMyanchor:focus {
    display: block;
    padding: 0 4vw;
    position: static;
    width: 100%;
    height: auto;
    background-color: #fff;
}

.height30 {
    height: 30px;
}

.sub-text span {
    font-family: $montreal-regular;
    font-size: $font-size-16mn-20mx;
}

.sub-text {
    font-family: $montreal-regular;
    font-size: $font-size-16mn-20mx;
}

.carousel-custom-class {
    margin-bottom: 29px;
    position: absolute;
    right: 0;
    bottom: 0;
}

.no-pad-left-right {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

/* 6195 - Width of button not adjusted for all text to fit */
@media screen and (max-width: 1200px) {
    .cc_container .cc_btn {
        min-width: 120px;
        max-width: 180px;
        word-wrap: break-word;
    }
}

@media screen and (min-width: 1201px) {
    .cc_container .cc_btn {
        min-width: 120px;
        max-width: 240px;
        word-wrap: break-word;
    }
}

article .page-summary {
    margin-top: -10px !important;
}


.valign-top-110 {
    @media(max-width: 991px) {
        top: 110px !important;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
        top: 60px;
    }
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-7 {
    margin-bottom: 7px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mt-23 {
    margin-top: 23px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.expert-desc {
    font-size: 20px !important;
}

.expert-name {
    font-size: 21px !important;
    margin-bottom: 5px !important;

    @media(max-width: 991px) {
        font-size: 18px !important;
    }
}

.expert-profile {
    font-family: $montreal-medium !important;
    font-size: 14px !important;
    line-height: 1.5 !important;

    @media(max-width: 991px) {
        font-size: 13px !important;
    }
}

.block-grid-item .thumbnail .caption {
    margin-top: 26px;
}


/*Accessibility*/
#INDlangsCombo option[value='en-GB'], #INDlangsCombo option[value='en-CA'], #INDlangsCombo option[value='en-AU'], #INDlangsCombo option[value='en-ZA'], #INDlangsCombo option[value='es-MX'], #INDlangsCombo option[value='zh-CN'] {
    display: none;
}

#INDmenu-btn > svg {
    width: 26px !important;
    height: 26px !important;
}

.INDpositionRight #INDmenu-btn {
    right: 15px !important;
}

/* Homepage - start */
.caption-m {
    font-size: 14px !important;
    color: $color-secondary-dark !important;
    line-height: 21px;
    font-family: $montreal-regular !important;
    margin-bottom: 15px;
    text-transform: uppercase;
}

.date-m {
    font-size: 14px !important;
    color: #70777E !important;
    line-height: 21px;
    font-family: $montreal-regular !important;
    margin-bottom: 15px;
    text-transform: uppercase;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.white-card-box {
    padding: 25px 0;
    background-color: $color-white;

    @media (min-width:$screen-md-min) {
        padding: 25px 20px;
    }

    h4.title-h4 * {
        font-family: inherit;
        font-size: inherit;
        color: inherit;
        line-height: inherit;
        text-decoration: inherit;
        text-transform: inherit;
        margin-top: inherit;
        margin-bottom: inherit;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }
}

.cta-bigimage-box {
    display: flex;
    flex-direction: row;

    @media (max-width:$screen-sm-max) {
        flex-direction: column;
    }

    .content-box {
        align-self: center;

        .mar-t-b-0 {
            margin-top: 0;
            margin-bottom: 0;
        }

        p {
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;


            &:empty {
                display: none;
            }



            * {
                font-family: inherit;
                font-size: inherit;
                color: inherit;
                line-height: inherit;
                text-decoration: inherit;
                text-transform: inherit;
                margin-top: inherit;
                margin-bottom: inherit;
            }
        }
    }
}


.cta-horizontal {

    * {
        @media (max-width:$screen-xs-max) {
            padding-left: 0;
            padding-right: 0;
            margin-left: 0;
            margin-right: 0;
        }
    }

    img.img-responsive {
        height: 350px;
        object-fit: cover;

        @media (max-width:$screen-view-condition-min) {
            height: 320px;
        }

        @media (max-width:$screen-md-max) {
            height: 300px;
        }

        @media (max-width:$screen-sm-max) {
            height: 270px;
        }

        @media (max-width:$screen-xs-max) {
            height: 200px;
        }

        &.hover {
            -ms-transform: scale(1.1);
            transform: scale(1.1);
        }
    }

    .v-center div.col-xs-12 {

        @media (max-width:$screen-xs-max) {
            padding-left: 4vw;
            padding-right: 4vw;
        }
    }
}

.teaser-summary {
    h2.title-h2 {
        font-family: $montreal-bold;
        color: $color-secondary-dark;
        font-size: 28px !important;
        line-height: 1.30 !important;

        @media (min-width:$screen-lg-min) {
            font-size: 36px !important;
            line-height: 1.30 !important;
        }
    }
}


.big-padding-left-home {
    padding-left: 4vw;

    @media (min-width:$screen-view-condition-min) {
        padding-left: unset;
    }
}

.big-padding-right-home {
    padding-right: 4vw;
}

.news-title {

    @media (max-width:$screen-sm-max) {
        width: 100%;
    }

    color: inherit;
    /*width: 70%;*/
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

a.news-title {

    &:hover, &:active, &:focus, &.hover {
        color: $color-primary;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
    }
}

.home-blue-bg {
    background: linear-gradient(to bottom,#E8E7E7 0,#E8E7E7 13%,$color-white 0);
}

/*.home-gradient-bg {*/
    /*background: linear-gradient(to bottom,#ECF2F800 10%,#ECF2F8B3 50%,#E8E7E7 80%);*/

    /*.ft-two-column-6-6 > .row {
        @media (max-width:$screen-sm-max) {
            .big-padding-left-home {
                padding-left: 4vw;
                padding-right: 4vw;
            }

            .col-md-6 {
                width: 100% !important;
            }
        }

        @media (min-width:$screen-md-min) {
            display: flex;
        }
    }

    .ft-two-column-6-6 .row .col-md-6:nth-child(1) {
        @media (min-width:$screen-md-min) {
            padding-right: 0;
        }
    }

    .ft-two-column-6-6 .row .col-md-6:nth-child(2) {
        background: #E8EFF6;

        @media (max-width:$screen-sm-max) {
            .content-box {
                padding: 30px 4vw;
            }
        }

        @media (min-width:$screen-md-min) {

            .content-box {
                top: 50%;
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
                position: relative;
                padding-left: 15px;
            }
        }


        @media (min-width:1300px) {
            &:after {
                content: '';
                position: absolute;
                width: 100vw;
                height: 100%;
                background: #E8EFF6;
                z-index: 0;
                top: 0;
                left: 695px;
            }
        }

        .no-pad-r {
            padding-right: 0;
        }

        .no-pad-l {
            padding-left: 0;
        }
    }
}*/


.limit-2 {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.limit-3 {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.limit-3-imp {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3 !important;
    -webkit-box-orient: vertical;
}

.limit-4 {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
/* Hero banner nav - start */
.navBar-banner {
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 31%, rgba(255,255,255,0) 0%);
    position: absolute;
    width: 100%;
    z-index: 200;
    bottom: 0;


    .big-padding {
        padding-right: 0;
    }
}

.nav-banner {
    margin: 0 -10px;
    padding: 0 10px;
    list-style: none;
    display: flex;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */

    &::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
    }

    @media (max-width:$screen-md-max) {
        background: $color-white;
    }

    .container-banner {
    }

    li {
        background: $color-white;

        a.btn {
            color: $color-secondary-dark;
            padding: 0;
            padding-top: 20px;
            padding-bottom: 20px;
            box-shadow: none;
            text-align: left;
            margin-bottom: 0;
            letter-spacing: 0;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-family: $montreal-medium;

            @media (max-width:$screen-xs-max) {
                min-width: 233px;
                max-width: 233px;
            }

            @media (min-width:$screen-sm-min) {
                min-width: 233px;
                max-width: 233px;
            }


            &:before {
                content: "";
                background-color: $color-dark-red;
                top: -1px;
                display: block;
                height: 5px;
                position: absolute;
                width: 0;
                left: 0;
                transition: width .5s ease;
            }

            &:hover, &:active, &.active {
                color: $color-dark-red;
                box-shadow: none;


                &:before {
                    width: 100%;
                }
            }
        }


        &:last-child a {
            padding-right: 0;
            margin-right: 0;

            @media (max-width:$screen-xs-max) {
                padding-right: 25px;
            }
        }
    }
}

.relative {
    position: relative;
}

.bot-space {
    margin-bottom: 48px;

    @media (min-width:$screen-md-min) {
        margin-bottom: 64px;
    }
}

.top-space {
    margin-top: 48px;

    @media (min-width:$screen-md-min) {
        margin-top: 64px;
    }
}

.v-center {
    @media (min-width:$screen-md-min) {
        top: 50%;
        position: absolute;
        transform: translateY(-50%);
    }
}

.thumbnail.m-b {
    @media (min-width:$screen-md-min) {
        margin-bottom: 0;
    }
}

.bot-space {

    .teaser-summary {
        .col-sm-8.col-md-12 {

            p:empty {
                display: none;
            }
        }
    }
}


.para-line-height {
    line-height: 1.3;

    p:empty {
        display: none;
    }
}

/* .dyn-margin used on Homepage for What we do and Featured Projects carousel left text content box */
.dyn-margin {

    @media (max-width:1499px) {
        margin-left: 0 !important;
    }

    @media (min-width:1500px) {
        transition: all .3s linear;
        margin-left: 5vw;
    }

    @media (min-width:1550px) {
        margin-left: 6vw;
    }

    @media (min-width:1600px) {
        margin-left: 7vw;
    }

    @media (min-width:1650px) {
        margin-left: 8vw;
    }

    @media (min-width:1700px) {
        margin-left: 9vw;
    }

    @media (min-width:1750px) {
        margin-left: 10vw;
    }

    @media (min-width:1800px) {
        margin-left: 11vw;
    }

    @media (min-width:1850px) {
        margin-left: 12vw;
    }

    @media (min-width:1900px) {
        margin-left: 13vw;
    }

    @media (min-width:1925px) {
        margin-left: 14vw;
    }

    @media (min-width:1950px) {
        margin-left: 15vw;
    }

    @media (min-width:1975px) {
        margin-left: 15.5vw;
    }

    @media (min-width:2000px) {
        margin-left: 16vw;
    }

    @media (min-width:2050px) {
        margin-left: 16vw;
    }

    @media (min-width:2500px) {
        margin-left: 22vw;
    }
}

.dyn-margin-left {

    @media (max-width: $screen-sm-max) {
        margin-left: 0 !important;
        padding-left: 4vw;
        padding-right: 4vw;
    }

    @media (min-width: $screen-md-min) and (max-width: 1499px) {
        margin-left: 4vw !important;
    }




    @media (min-width:1500px) {
        transition: all .3s linear;
        margin-left: 5vw;
        left: 0;
    }

    @media (min-width:1550px) {
        margin-left: 6vw;
    }

    @media (min-width:1600px) {
        margin-left: 7vw;
    }

    @media (min-width:1650px) {
        margin-left: 8vw;
    }

    @media (min-width:1700px) {
        margin-left: 9vw;
    }

    @media (min-width:1750px) {
        margin-left: 10vw;
    }

    @media (min-width:1800px) {
        margin-left: 11vw;
    }

    @media (min-width:1850px) {
        margin-left: 12vw;
    }

    @media (min-width:1900px) {
        margin-left: 13vw;
    }

    @media (min-width:1925px) {
        margin-left: 14vw;
    }

    @media (min-width:1950px) {
        margin-left: 15vw;
    }

    @media (min-width:1975px) {
        margin-left: 15.5vw;
    }

    @media (min-width:2000px) {
        margin-left: 16vw;
    }

    @media (min-width:2050px) {
        margin-left: 16vw;
    }

    @media (min-width:2500px) {
        margin-left: 22vw;
    }
}


.dyn-margin-right {

    @media (max-width: $screen-sm-max) {
        margin-right: 0 !important;
        padding-left: 4vw;
        padding-right: 4vw;
    }

    @media (min-width: $screen-md-min) and (max-width: 1499px) {
        margin-right: 4vw !important;
    }


    @media (min-width:1500px) {
        transition: all .3s linear;
        margin-right: 5vw;
        right: 0;
    }

    @media (min-width:1550px) {
        margin-right: 6vw;
    }

    @media (min-width:1600px) {
        margin-right: 7vw;
    }

    @media (min-width:1650px) {
        margin-right: 8vw;
    }

    @media (min-width:1700px) {
        margin-right: 9vw;
    }

    @media (min-width:1750px) {
        margin-right: 10vw;
    }

    @media (min-width:1800px) {
        margin-right: 11vw;
    }

    @media (min-width:1850px) {
        margin-right: 12vw;
    }

    @media (min-width:1900px) {
        margin-right: 13vw;
    }

    @media (min-width:1925px) {
        margin-right: 14vw;
    }

    @media (min-width:1950px) {
        margin-right: 15vw;
    }

    @media (min-width:1975px) {
        margin-right: 15.5vw;
    }

    @media (min-width:2000px) {
        margin-right: 16vw;
    }

    @media (min-width:2050px) {
        margin-right: 16vw;
    }

    @media (min-width:2500px) {
        margin-right: 22vw;
    }
}
/* Hero banner nav - end */
/* Homepage - end */
@media (min-width: $screen-lg-min) {
    .pageIsLoading {
        pointer-events: none;
    }
}
