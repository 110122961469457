.mani-content {
    position: relative;
    max-height: 0;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    overflow: hidden;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;

    &.open {
        max-height: 10000px;
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        -webkit-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
    }

    .container-alignment .teaser-alignment {
        position: relative;
        -webkit-transform: none;
        transform: none;
        top: auto;
        left: auto;
    }

    .content, .content p {
        color: $color-primary;
        @extend .new__typo__05; //Mockup 1-03 (What we stand for)
        .btn {
            display: none;
        }
    }
}

.closecollapse {
    background-color: $color-primary;
    border-radius: 0;
    color: $color-white;
    height: 52px;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    width: 52px;
    z-index: 99999999999;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    border: 0;
    line-height: 14px;
    padding: 0;

    .closecollapse-svg {
        height: 14px;
        width: 14px;
        -webkit-transition: all .5s ease;
        -o-transition: all .5s ease;
        transition: all .5s ease;

        .svg-lines {
            fill: none;
            stroke: $color-white;
        }
    }

    path {
        fill: $color-white;
    }

    span {
        display: none;
    }

    &:hover,
    &:focus {
        background-color: $color-dark-primary;
        -webkit-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
        cursor: pointer;

        .closecollapse-svg {
            -webkit-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: rotate(90deg);
        }
    }
}
