.list {
  & > .disabled {
    & > a {
      color: $list-group-disabled-text-color;
      &:hover {
        cursor: not-allowed;
        background-color: transparent;
        background-image: none;
        text-decoration: none;
      }
    }
  }
  &-divided {
    li {
      padding: 20px 0;
      border-bottom: 1px solid $list-divided-border-color;
    }
  }
}

.dl {
  &-horizontal {
    dt {
      text-align: left;
    }
  }
}
