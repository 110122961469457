


@import "../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/pagination";
@import "../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/pager";


.pagination {
  display: block;
  text-align: center;
  & > li {
    display: inline-block;
    & > a, & > span {
      padding: 4px 10px;
      border-width: 2px;
      color: $text-color;
      .bg-primary &, .bg-secondary &, .bg-dark &, .bg-media & {
        color: $brand-text-color;
        &:hover {
          background-color: $brand-text-color;
          border-color: $brand-text-color;
          color: $brand-primary;
        }
      }

      .bg-white & {
        color: $color-black;
      }
    }
    &.active {
      & > a, & > span {
        font-weight: $active-list-font-weight;
        .bg-primary &, .bg-secondary &, .bg-dark &, .bg-media & {
          border-color: $brand-text-color;
          &:hover {
            background-color: transparent;
            color: $brand-text-color;
          }
        }

        .bg-white & {
          border-color: $color-black;
          &:hover {
            color: $color-black;
          }
        }
      }
    }
  }
}

.pager {
  a {
    .bg-primary &, .bg-secondary &, .bg-dark &, .bg-media & {
      color: $brand-primary;
      &:hover {
        color: $brand-primary;
      }
    }
  }
}
