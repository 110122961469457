#main-container > footer {
    padding-top: 65px; //60px
}

.wspFtLogo {
    display: none;
}

.main-footer {
    background-color: #f4f4f4;
    height: auto;
    position: relative;
    width: 100%;
    padding-right: 4vw;
    padding-bottom: 48px;
    padding-left: 4vw;
    /*margin-top: 30px;*/ //30px
    margin-top: 0px;

    @media (min-width: $screen-md-min) {
        padding-bottom: 65px;
    }

    @media (min-width: $screen-lg-min) {
        margin-top: 0px;
    }


    nav {
        @media (min-width: $screen-md-min) {
            width: 83.33333%;
            padding-left: 15px;
            float: right;
        }

        &:after {

            @media (min-width: $screen-md-min) {
                clear: both;
            }
        }
    }




    .copyright {
        font-size: 12px;
    }

    article {
        float: left;
        width: 100%;
        margin: calc(18px + 2.1vw) 0;

        @media (max-width: $screen-sm-max) {
            margin-top: 40px;
            margin-bottom: 0;
        }

        @media (min-width: $screen-md-min) {
            margin: 0 0 48px 0;
        }
    }

    .container-fluid {
        @media (min-width: $screen-md-min) {
            padding: 0;
        }
    }

    .mf__list {
        margin: 0;
        padding: 0;

        @media (min-width: $screen-md-min) {
            padding-left: 15px;
            padding-right: 15px;
        }

        &:nth-child(odd) {
            clear: both;

            @media (min-width: $screen-md-min) {
                clear: none;
            }
        }

        &:first-of-type {
            @media (min-width: $screen-md-min) {
                padding-left: 0;
            }


            .wspFtLogo {
                display: block;
                margin-bottom: 25px;

                @media (max-width: $screen-sm-max) {
                    display: none;
                }
            }

            li.mf__item {
                float: left;
                margin-right: 15px;

                i {
                    color: $color-secondary-dark;
                    font-size: 21px;
                    padding: 0;
                    display: inline-block;

                    &:hover,
                    &:focus, &:active {
                        color: $color-dark-red;
                    }
                }
            }
        }

        &:last-of-type {
            list-style: none;
            margin-top: 18px;
            margin-bottom: 0;
            margin-left: 0;
            display: flex;
            clear: both;

            @media (min-width: $screen-md-min) {
                position: absolute;
                top: 0;
                right: 0;
                margin: 0;
                clear: none;
                padding-right: 0;
                -webkit-box-pack: end;
                -ms-flex-pack: end;
                justify-content: flex-end;
            }

            & > *,
            .mf__item {
                margin: 0 25px 0 0;

                @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
                    margin: 0 15px 0 0;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                @media (min-width: $screen-lg-min) {
                    margin: 0 20px 0 0;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                a {
                    color: $color-primary;
                }

                a > span {
                    position: absolute;
                    width: 1px;
                    height: 1px;
                    padding: 0;
                    margin: -1px;
                    overflow: hidden;
                    clip: rect(0,0,0,0);
                    border: 0;
                }
            }
        }
    }

    .col-md-5col {
        @media (min-width: $screen-md-min) {
            position: relative;
            min-height: 1px;
            width: 20% !important;
            float: left;
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .mf__item {
        margin-top: 0;
        margin-bottom: 24px;
        padding: 0;

        @media (min-width: $screen-md-min) {
            margin-bottom: 9px;
        }

        &:first-child > a:not(.icon-link) {
            text-transform: none;
            word-break: break-word;
            color: $color-secondary-dark;
            font-family: $montreal-medium;
            font-size: 13px;
            margin-bottom: 20px;

            @media (min-width: $screen-md-min) {
                font-size: 14px;
            }


            &:hover, &:focus, &:active {
                color: $color-dark-red;
            }
        }
    }

    .mf__link {
        font-weight: normal;
        color: #898C8F;
        font-family: $montreal-medium;
        font-size: 12px;
        padding: 0;
        word-break: break-word;
        text-decoration: none;


        &:hover,
        &:focus {
            background: none;
            /*opacity: 0.5;*/
            color: $color-secondary-dark;
        }

        &:active {
            color: $color-secondary-dark;
        }
    }

    .fa-size {
        font-size: 1.688em;
    }

    svg path {
        /*fill: $color-primary;*/
        fill: $color-dark-red;
    }

    .instagram {
        width: 26px;
        height: 26px;
    }

    .linkedin {
        width: 23px;
        height: 22px;
    }

    .twitter {
        width: 26px;
        height: 22px;
    }

    .facebook {
        width: 10px;
        height: 24px;
    }


    &.bg-dark-footer {
        background-color: $color-navigation-dark;

        .mf__list {
            &:first-of-type {
                li.mf__item {
                    i {
                        color: $color-white;
                        text-decoration: none;

                        &:hover, &:focus, &:active {
                            color: $color-dark-red;
                            text-decoration: none;
                        }
                    }
                }
            }

            &:last-of-type {
                & > *, .mf__item {

                    a {
                        color: $color-primary;
                    }
                }
            }
        }



        .mf__item {
            /* margin-top: 0;
            margin-bottom: 24px;
            padding: 0;*/

            @media (min-width: $screen-md-min) {
                /*margin-bottom: 9px;*/
            }

            &:first-child > a:not(.icon-link) {
                word-break: break-word;
                color: $color-white;
                font-family: $montreal-bold;
                font-size: 16px;
                margin-bottom: 20px;
                -webkit-transition: all .3s ease-in-out;
                -o-transition: all .3s ease-in-out;
                -moz-transition: all .3s ease-in-out;
                transition: all .3s ease-in-out;

                @media (min-width: $screen-md-min) {
                    font-size: 14px;
                }


                &:hover, &:focus, &:active {
                    /*color: $color-dark-red;*/
                    text-decoration: underline;
                    text-underline-offset: 3px;
                }
            }
        }

        .mf__link {
            /*color: #898C8F;*/
            color: $color-white;
            font-family: $montreal-regular;
            font-size: 14px;
            padding: 0;
            word-break: break-word;
            text-decoration: none;
            -webkit-transition: all .3s ease-in-out;
            -o-transition: all .3s ease-in-out;
            -moz-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;


            &:hover, &:focus, &:active {
                /*opacity: 0.5;*/

                text-decoration: underline;
                text-underline-offset: 3px;

                &.icon-link {
                    text-decoration: none;
                    text-underline-offset: 0;
                }
            }
        }
    }
}

.main-footer-bottom {
    margin: 0;

    @media (min-width: $screen-md-min) {
        
        
        padding-top: 20px;
        border-top: 1px solid $divider-grey-line-on-normal-bg;
    }

    .mfb__copyright {
        color: $text-color;
        font-size: 12px;
        font-family: $montreal-regular;
        position: relative;
        
        @media (max-width: $screen-sm-max) {
            margin-top: 44px;
            display: block;
        }

        @media (min-width: $screen-md-min) {
            position: relative;
            float: left;
            font-size: 12px;
        }
    }

    .mfb__list {
        list-style-type: none;
        margin-left: 0;

        @media (min-width: $screen-md-min) {
            float: right;
            
        }
    }

    .mfb__item {
        padding: 0;
        margin-top: 0;
        margin-right: 0;
        margin-bottom: 24px;

        @media (min-width: $screen-md-min) {
            display: inline-block;
            margin-bottom: 0;
            
            margin-right: 15px;

            &.last-child {
                margin-right: 0;
            }
        }

        &:last-child {
            margin-bottom: 0;
            margin-right: 0;
        }
    }

    .mfb__link {
        

        padding: 0;
        font-family: $montreal-regular;
        text-decoration: none;
        font-weight: normal;
        font-size: 14px;
        color: $text-color;

        @media (min-width: $screen-md-min) {
            font-size: 12px;
        }

        &:hover,
        &:focus, &:active {
            background: none;
            
            color: #898C8F;
        }
    }

    .mfb__copyright-div {
        position: relative;
    }
    /*Styles for Dark background */
    .bg-dark-footer & {
        @media (min-width: $screen-md-min) {
            border-top: 1px solid $divider-grey-line-on-dark-bg;
        }

        .mfb__copyright {
            color: $color-white;
            font-family: $montreal-medium;
        }

        .mfb__link {
            color: $color-white;


            &:hover, &:focus, &:active {
                
                text-decoration: underline;
                text-underline-offset: 3px;
            }
        }
    }
}
/* 6314 - Footer padding removed after hiding links */
@media screen and (max-width: $screen-sm-max) {
    #main-container > footer {
        padding-top: 0;
        margin-top: 0; /*  734 - Section - checkbox not working */
        /*margin-top: 65px;*/
    }

    .hidden-mobile {
        display: none;
    }
}


.footer-box {
    position: relative;

    @media (min-width: $screen-view-condition-min) {
        max-width: $screen-laptop-min;
        left: 50%;
        margin-left: calc(#{$screen-laptop-min}/-2);


        .mf__list:last-of-type {
            top: 0;
            right: 0;
        }
    }
}

@font-face {
    font-family: 'Glyphicons Halflings';
    src: url('/fonts/glyphicons-halflings-regular.eot');
    src: url('/fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), url('/fonts/glyphicons-halflings-regular.woff2') format('woff2'), url('/fonts/glyphicons-halflings-regular.woff') format('woff'), url('/fonts/glyphicons-halflings-regular.ttf') format('truetype'), url('/fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
}

@font-face {
    font-family: 'font-awesome';
    src: url('/fonts/font-awesome.eot');
    src: url('/fonts/font-awesome.eot?#iefix') format('embedded-opentype'), url('/fonts/font-awesome.woff2') format('woff2'), url('/fonts/font-awesome.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


/*@font-face {
    font-family: 'gt-sectra-bold';
    src: url('/fonts/gt-sectra-bold.eot');
    src: url('/fonts/gt-sectra-bold.eot?#iefix') format('embedded-opentype'), url('/fonts/gt-sectra-bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'gt-sectra-medium';
    src: url('/fonts/gt-sectra-medium.eot');
    src: url('/fonts/gt-sectra-medium.eot?#iefix') format('embedded-opentype'), url('/fonts/gt-sectra-medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'gt-sectra-regular';
    src: url('/fonts/gt-sectra-regular.eot');
    src: url('/fonts/gt-sectra-regular.eot?#iefix') format('embedded-opentype'), url('/fonts/gt-sectra-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'styrene-bold';
    src: url('/fonts/styrene-bold.eot');
    src: url('/fonts/styrene-bold.eot?#iefix') format('embedded-opentype'), url('/fonts/styrene-bold.woff2') format('woff2'), url('/fonts/styrene-bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'styrene-medium';
    src: url('/fonts/styrene-medium.eot');
    src: url('/fonts/styrene-medium.eot?#iefix') format('embedded-opentype'), url('/fonts/styrene-medium.woff2') format('woff2'), url('/fonts/styrene-medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'styrene-medium-italic';
    src: url('/fonts/styrene-medium-italic.eot');
    src: url('/fonts/styrene-medium-italic.eot?#iefix') format('embedded-opentype'), url('/fonts/styrene-medium-italic.woff2') format('woff2'), url('/fonts/styrene-medium-italic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'styrene-regular';
    src: url('/fonts/styrene-regular.eot');
    src: url('/fonts/styrene-regular.eot?#iefix') format('embedded-opentype'), url('/fonts/styrene-regular.woff2') format('woff2'), url('/fonts/styrene-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
*/

@font-face {
    font-family: 'icomoon';
    src: url('/fonts/icomoon.eot?w1nynm');
    src: url('/fonts/icomoon.eot?w1nynm#iefix') format('embedded-opentype'), url('/fonts/icomoon.ttf?w1nynm') format('truetype'), url('/fonts/icomoon.woff?w1nynm') format('woff'), url('/fonts/icomoon.svg?w1nynm#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FontAwesome';
    src: url('/fonts/fontawesome-webfont.eot?v=4.4.0');
    src: url('/fonts/fontawesome-webfont.eot?#iefix&v=4.4.0') format('embedded-opentype'), url('/fonts/fontawesome-webfont.woff2?v=4.4.0') format('woff2'), url('/fonts/fontawesome-webfont.woff?v=4.4.0') format('woff'), url('/fonts/fontawesome-webfont.ttf?v=4.4.0') format('truetype'), url('/fonts/fontawesome-webfont.svg?v=4.4.0#fontawesomeregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
