

.panel-group {
  .panel {
    .bg-primary &, .bg-secondary &, .bg-dark &, .bg-media & {
      background-color: transparent;
      border: none;
      border-bottom: 1px solid $gray;
      box-shadow: none;
      .panel-heading, .panel-body, .panel-footer {
        background-color: transparent;
        border: none;
        color: $brand-text-color;
        padding-left: 0;
        padding-right: 0;
      }
      .panel-heading {
        border-bottom: 1px solid $gray;
        margin-bottom: -1px;
      }
    }
  }
}
