.key-number-group {
    /* width: 100%;*/
    /*display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;*/
    margin-bottom: -37px;

    h2 {
        width: 100%;
    }

    .key-number {
        
        margin-bottom: 64px;

        &:last-child{margin-bottom:24px;}

        @media (min-width: $screen-sm-min) {
            //width: calc(50% - 15px);
            //margin-left: 15px;
            /*display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            justify-content: flex-start;*/
            margin-bottom: calc(40px + 0.5vw);
            /*&:nth-of-type(2n+1) {
                margin-left: 0;
            }*/
        }

        @media (min-width: $screen-md-min) {
            //width: calc((100% / 4) - 20px);
            /*margin-left: 20px;

            &:nth-of-type(2n+1) {
                margin-left: 20px;
            }

            &:nth-of-type(3n+1) {
                margin-left: 20px;
            }*/
        }

        .keytitle {
            
            color: $color-secondary-dark;
            /*font-size: $font-size-16mn-20mx;*/
            font-size: 16px;
            //@extend .new__typo__13;
            font-family: $montreal-medium;
            border-top: 1px solid $color-dark-red;
            display: block;
            line-height: 1.4;
            /*font-weight: normal;
            line-height: 1.625;*/
            /*margin-bottom: 0;*/
            /*padding: calc(8px + 0.3vw) 0 calc(12px + 0.5vw);*/
            /*padding: 28px 0 8px;*/
            padding: 26px 0 0 0;
            margin-bottom: 4px;
        }

        .keynumber, .keynumberCMS {
            color: $color-dark-red;
            /*@extend .new__typo__14;*/
            word-break: break-all;
            /*font-size: $font-size-75mn-95mx;*/
            font-size: $font-size-55mn-75mx;
            line-height: 1.2;
            font-family: $montreal-medium;
        }
    }
}
