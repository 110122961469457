.bg {
    &-primary {
        background-color: $brand-primary !important;
        color: $brand-text-color !important;

        .blockquote .blockquote-bloc {
            border-top: 1px solid $color-white;
            border-bottom: 1px solid $color-white;
            margin-bottom: 40px;
        }
    }

    &-secondary {
        background-color: $color-secondary-lightest !important;
        color: $brand-text-color;
        margin-bottom: 70px;

        &-dark {
            color: $brand-text-color !important;
            background-color: $color-secondary-dark !important;

            p {
                opacity: 0.4;

                &.lead {
                    opacity: 1;
                }
            }
        }

        &-light {
            color: $brand-text-color;
            background-color: $color-secondary-light !important;
        }

        &-lighter {
            color: $brand-text-color;
            background-color: $color-secondary-lighter !important;
        }
    }


    &-secondary-grey {
        background-color: $color-secondary-lightest !important;
        color: $color-secondary-dark;

        .blockquote .blockquote-bloc {
            border-top: 1px solid #3d5f7b73;
            border-bottom: 1px solid #3d5f7b73;
        }
    }

    &-secondary-grey80 {
        background-color: $color-secondary-lightest-80 !important;
        color: $color-white;

        .blockquote .blockquote-bloc {
            border-top: 1px solid #3d5f7b73;
            border-bottom: 1px solid #3d5f7b73;
        }
    }



    &-white {
        background-color: $color-white;
        color: $color-black;
    }

    &-black {
        background-color: $color-black;
        color: $color-white;
    }

    &-dark {
        /*background-color: $color-secondary-dark;
        color: $brand-text-color;*/
        background-color: $color-secondary-dark;
        color: $color-white;

        * {
            color: $color-white;
        }

        .modal {
            h1, h2, h3, h4, h5, h5, h6 {
                color: initial !important
            }
        }


        .blockquote {
            padding-bottom: 40px;
        }
    }

    &-transparent {
        background-color: transparent !important;
        color: $brand-text-color;

        a, p, h1, h2, h3, h4, h5, h6, i {
            color: $brand-text-color !important;
        }

        .dropdown-menu, .mega-dropdown-menu {
            a, h1, h2, h3, h4, h5, h6, p, i {
                color: $text-color !important;
            }
        }
    }

    &-media {
        background-color: transparent;
        background-repeat: no-repeat;
        background-size: cover;
        color: $brand-text-color;
        position: relative;
        overflow: hidden;

        &:after {
            content: ' ';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: $bg-media-overlay-color;
            opacity: 0.6;
            z-index: 0;
        }

        & > * {
            z-index: 1;
            position: relative;
        }

        & > .video-bg {
            position: absolute;
            top: 0;
            left: 0;
            min-width: 100%;
            min-height: 100%;
            height: auto;
            width: auto;
            z-index: 0;
            max-width: 100vw;
        }
    }

    &-parallax {
        background-position: 50% 50% !important;
        background-size: cover;
        background-repeat: no-repeat;

        @media all and (min-width: $screen-lg-min) {
            background-position: center top !important;
            background-size: 100% auto !important;
            background-attachment: fixed;
        }
    }

    &-primary, &-secondary, &-media {
        color: $brand-text-color;

        & .dropdown-menu:not(.bg-primary):not(.bg-secondary):not(.bg-dark):not(.bg-media) {
            color: $text-color !important;

            .btn {
                &-default {
                    /*.btn-default*/
                    /* color: $text-color !important;
                    border-color: $text-color !important;*/
                }
            }
        }
    }
}

.pull {
    &-right {
        &-lg {
            @media all and (min-width: $screen-lg-min) {
                float: right;
            }
        }
    }

    &-left {
        &-lg {
            @media all and (min-width: $screen-lg-min) {
                float: left;
            }
        }
    }
}

.row {

    &-vertical-align {
        display: table;
        table-layout: fixed;
        width: 100%;

        [class^="col-"], [class*="col-"] {
            display: table-cell;
            vertical-align: middle;
            float: none;
        }
    }

    &-no-gutter {
        [class^="col-"], [class*="col-"] {
            padding: 0 !important;
        }
    }
}

.secondary-3-column {
    background-color: $color-secondary-light-gray;

    .blockquote .blockquote-bloc {
        border-top: 1px solid #3d5f7b73;
        border-bottom: 1px solid #3d5f7b73;
    }
}

.light-blue-bg {
    background-color: $color-secondary-medium-gray-on-dark-bg;

    .blockquote .blockquote-bloc {
        border-top: 1px solid #3d5f7b73;
        border-bottom: 1px solid #3d5f7b73;
    }

    .col-md-9.quote-text {
        width: 75% !important;
    }

    .blockquote-bloc {
        margin-top: 40px;
        margin-bottom: 40px;
    }
}
