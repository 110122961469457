﻿.events-container {
    h2.title {
        color: $color-primary;
        font-family: $montreal-medium-italic;
        font-size: 20px;

        @media (min-width: $screen-md-min) {
            font-size: 28px;
        }
    }

    .btn {
        min-width: 172px;
        margin: 0;
    }
}

.event-container {
    margin-bottom: 20px;
}

.event-date-col,
.event-desc-col {
    font-family: $montreal-medium;
    float: left;
    color: $text-color;
}

.event-date-col {
    background-color: $color-secondary-lightest;
    border-bottom: 2px solid $color-primary;
    text-align: center;
    width: 79px;
    min-height: 81px;
    padding: 13px 10px 17px;
    font-size: 24.4px;

    @media (min-width: $screen-md-min) {
        padding: 17px 10px 18px;
        font-size: 28px;
        width: 90px;
        min-height: 92px;
    }

    .month {
        display: block;
        font-size: 11.35px;
        text-transform: uppercase;
        margin-top: -2px;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (min-width: $screen-md-min) {
            font-size: 13px;
        }
    }

    .to-date {
        display: block;
        font-size: 13.89px;
        font-family: $montreal-regular;
        margin: 6px 0 5px 0;

        @media (min-width: $screen-md-min) {
            margin: 5px 0 8px 0;
            font-size: 16px;
        }
    }
}

.event-desc-col {
    padding: 0 0 0 15px;
    width: calc(100% - 79px);

    h2 {
        
        /*text-transform: none;
        color: $color-primary;*/
        font-size: 16px;
        /*letter-spacing: 0.1px;*/
        margin: -8px 0 6px 0;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        max-height: 3.25em;


        @media (min-width: $screen-md-min) {
            letter-spacing: -0.4px;
            margin: -9px 0 10px 0;
            font-size: 20px;
        }
    }

    .view-more {
        color: $link-color;
    }

    p {
        font-size: 13px;
        padding: 0 0 0 20px;
        font-family: $montreal-regular;
        line-height: 1.6;
        margin-bottom: 9px;

        &:last-child {
            margin-bottom: 0;
        }

        strong {
            font-family: $montreal-bold;
        }

        @media (min-width: $screen-md-min) {
            font-size: 16px;
            padding: 0 0 0 25px;
            line-height: 1.3;
            letter-spacing: 0.2px;
            margin-bottom: 17px;
        }

        &.date-time,
        &.location {
            position: relative;

            &:before {
                @extend .icomoon;
                position: absolute;
                left: 0;
                top: 5px;
                font-size: 12px;
                color: $color-primary;

                @media (min-width: $screen-md-min) {
                    top: 5px;
                    font-size: 15px;
                }
            }
        }

        &.date-time {
            &:before {
                content: "\e907";
            }
        }

        &.location {
            font-family: $montreal-bold;

            &:before {
                content: "\e904";
                top: 3px;

                @media (min-width: $screen-md-min) {
                    top: 0;
                    font-size: 15px;
                }
            }
        }

        &.description {
            line-height: 1.5;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            max-height: 4.5em;
            padding: 0;
        }
    }

    @media (min-width: $screen-md-min) {
        padding: 0 0 0 20px;
        width: calc(100% - 90px);
    }
}


.date-list {

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        @media (min-width: $screen-md-min) {
            margin: -7px 0 0 0;
        }
    }

    li {
        display: block;
        width: 100%;
    }

    .day {
        font-size: 16px;
        font-family: $montreal-medium;
        margin-bottom: 0;
    }

    .full-date {
        font-family: $montreal-medium;
        font-size: 22px;
        margin-bottom: 0;
        line-height: 1.2;
        margin-top: 0;
        text-transform: uppercase;

        @media (min-width: $screen-sm-min) {
            font-size: 28px;
        }
    }

    .time {
        position: relative;
        padding-left: 30px;
        font-size: 16px;
        margin-top: 7px;
        line-height: 1.3;
        margin-bottom: 19px;
        letter-spacing: 0.1px;

        strong {
            letter-spacing: 0.3px;
        }
    }

    .icon-time-filled {
        font-size: 15px;
        font-family: $montreal-medium;
        position: absolute;
        left: 0;
        top: 3px;
    }
}

.time .icon-time-filled,
.event-address .icon-marker,
.event-info .icon-info {
    color: $color-primary;
}


.event-address,
.event-info {
    font-size: 16px;
    font-family: $montreal-bold;
    line-height: 1.3;
    letter-spacing: 0.19px;
    padding-left: 24px;
    position: relative;

    i {
        position: absolute;
        top: 3px;
        left: 0;
        font-size: 15px;
    }
}

.event-info {
    margin-top: 20px;

    a {
        display: block;
        margin-top: 6px;

        &:last-child {
            margin-top: 0;
        }
    }

    i {
        top: 2px;
        left: 0;
    }

    @media (min-width: $screen-sm-min) {
        margin-top: -1px;
    }
}

[class$="margin-children"] {
    .map {
        .map-container {
            height: 0;
            min-height: 314px;
            padding-top: percentage(314/640);
        }
    }
}


.medium-margin-children {
    .map {
        margin-bottom: 70px;
    }
}

.small-margin-children {
    .map {
        margin-bottom: 40px;
    }
}

.big-margin-children {
    .map {
        margin-bottom: 100px;
    }
}
