.block-grid-item,
.project-list {
    margin-bottom: 0;
    color: $color-secondary-dark;
    @extend .new__typo__03;

    h2,
    h2 a {
        margin-bottom: 15px;
        text-transform: none;


        @extend .new__typo__29; //Homepage, What we do, Sectors, Sector (one)
        line-height: 1.3;
        color: $color-secondary-dark;

        &:hover, &:active {
            color: $color-secondary-dark-gray;
        }
    }

    p {
        color: $color-secondary-dark;
        @extend .new__typo__09; //Global Team, What we do, Sectors, Sector (one)
    }

    a:hover,
    a:focus {
        text-decoration: none;
    }

    .case-study {
        @extend .new__typo__06; //Homepage, What we do, Project (one), Sector (one)
        color: $color-secondary-dark;
        display: block;
        margin: 36px 0 18px 0;
        text-transform: uppercase;
        font-family: $montreal-medium;
        font-size: $font-size-14mn-16mx;
    }

    .thumbnail {
        @media (min-width: $screen-md-min) {
            //min-height: 207px;
        }
    }
}

.project-list {
    padding-left: 10px;
    padding-right: 10px;
}

.teaser-summary,
.teaser-alignment {
    .btn {
        margin-top: 30px;
        white-space: normal;
    }

    .btn + .btn {
        margin-top: 5px;

        @media (min-width: $screen-md-min) {
            margin-top: 30px;
        }
    }

    ol,
    ul {
        @extend .new__typo__03;
        padding: 0 15px 15px 40px;
        margin: 0;

        p {
            margin-bottom: 0;
            @extend .new__typo__03;
            line-height: 1.3;
        }
    }

    ul {
        list-style: none;
        padding: 0 15px 15px 50px;

        li {
            position: relative;

            &:before {
                content: "\2014";
                position: absolute;
                left: -30px;
            }
        }
    }

    h2 {
        color: $color-secondary-dark;
        //@extend .new__typo__02; //Who we are, What we stand for, Our story, Careers
        font-size: $font-size-31mn-39mx-h3;
        line-height: 1.2;
    }

    div {
        color: $color-secondary-dark;
        @extend .new__typo__03; //Homepage, Who we are, What we stand for, Project (one), Careers, Sector (one), WSP Logo, Cookie policy
        &.para-text {
            font-family: $montreal-regular;
            font-size: $font-size-16mn-20mx;
            line-height: 1.4;
            margin: 0 0 15px;

            &.f-25-20 {
                font-size: $font-size-20mn-25mx-h5;
            }

            a:not(.btn) {
                @extend .para-anchor-links;
            }

            h2 {
                &.quote-text {
                    font-family: $montreal-medium !important;
                    font-size: $font-size-25mn-39mx-quote !important;
                    line-height: 1.228;
                }
            }

            h2.quote-text + p > strong {
                font-family: $montreal-bold !important;
            }
        }
    }

    p {
        color: $color-secondary-dark;
        @extend .new__typo__03; //WSP Logo
        &.para-text {
            font-family: $montreal-regular;
            font-size: $font-size-16mn-20mx;
            line-height: 1.4;
            margin: 0 0 15px;

            &.f-25-20 {
                font-size: $font-size-20mn-25mx-h5;
            }

            a:not(.btn) {
                @extend .para-anchor-links;
            }
        }
    }
}

section:not(.orange-title) {
    .teaser-summary:not(.orange-title),
    .teaser-alignment {
        h2 {
            /* color: $color-secondary-dark;
            @extend .new__typo__12; //Homepage, Project (one), Sector (one), Cookie policy*/

            &.title-h2-48 {
                font-family: $montreal-bold;
                color: $color-secondary-dark;
                font-size: $font-size-39mn-48mx-h2;
                line-height: 1.1;
            }
        }
    }

    .teaser-summary.orange-title {
        h2 {


            &.title-h2-48 {
                font-family: $montreal-bold;
                color: $color-secondary-dark;
                font-size: $font-size-39mn-48mx-h2;
                line-height: 1.1;
            }
        }
    }
}

.col-md-6 + .col-md-6 {
    .thumbnail {
        margin-top: $boxspacing;

        @media (min-width: $screen-md-min) {
            margin-top: 0;
        }
    }
}

.flex-order-image {

    .row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;

        @media (min-width: $screen-md-min) {
            width: 100%;
            display: table;
        }
    }
    /* Trello 171 - Contact List Modal Popup Button - Modal width too small */
    .modal-body .row {
        display: inherit;
        -webkit-box-orient: unset;
        -webkit-box-direction: unset;
        -ms-flex-direction: unset;
        flex-direction: unset;

        @media (min-width: $screen-md-min) {
            width: auto;
            display: inherit;
        }
        /* Trello 171 - Contact List Modal Popup Button - Modal width too small */
    }

    .col-md-5.col-sm-12.no-padding-left,
    .col-md-6.col-md-offset-1.no-padding-left.fixheight.image-no-fixheight {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;

        @media (min-width: $screen-md-min) {
            -webkit-box-ordinal-group: 0;
            -ms-flex-order: 0;
            order: 0;
        }

        img {
            margin: 0 0 calc(35px + 2.1vw);

            @media (min-width: $screen-md-min) {
                margin: 0;
            }
        }
    }
}
/* WSP 67 - [USABILITY] To improve the paragraph + CTA component - START */

.teaser-summary.set-row-view {

    .btn {
        margin-top: 15px;
        margin-bottom: 30px;
    }

    @media (min-width: $screen-sm-min) {
        width: 100%;

        .thumbnail {
            width: 50%;
            float: left;
            margin-right: 25px;

            .col-sm-6 & {
                @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
                    width: 100%;
                    float: none;
                    margin-right: 0;
                }
            }

            &.img-right {
                float: right;
                margin-left: 25px;
                margin-right: 0;
            }
        }

        .btn {
            margin-top: 0;
        }
    }

    @media (min-width: $screen-md-min) {
        .btn {
            margin-top: 10px;
        }
    }

    @media (min-width: $screen-lg-min) {
        .thumbnail {
            width: 40%;
            float: left;
            margin-right: 45px;

            &.img-right {
                float: right;
                margin-left: 45px;
                margin-right: 0;
            }
        }
    }
}

/* WSP 67 - [USABILITY] To improve the paragraph + CTA component - END */
/* Trello 300 - Background Types / Classes - Start */
.ft-content-wrapper.orange-title {


    .person-list h2 {
        font-size: calc(.75117vw + 17.18px) !important;
        line-height: 1.2;
        margin: 0 0 calc(25px + .5vw);
    }
}
/* Trello 300 - Background Types / Classes - end */
.teaser-summary, .highlight, .feature-summary {

    &.ft-call-to-action-with-image {
        h3 {
            font-family: $montreal-bold;
            font-size: $font-size-20mn-25mx-h5;
            line-height: 1.2;
            color: $color-secondary-dark;
            margin-bottom: 28px;

            a:not(.btn) {
                background-color: transparent;
                font-size: 16px;
                font-family: PPNeueMontreal-Medium,sans-serif;
                line-height: 1.4;
                text-decoration: underline;
            }
        }

        p, .para-text {
            font-family: $montreal-regular;
            font-size: $font-size-16mn-20mx;
            line-height: 1.4;


            h2 {
                font-family: $montreal-bold;
                font-size: $font-size-20mn-25mx-h5;
                line-height: 1.2;
                color: $color-secondary-dark;
                margin-bottom: 28px;
            }
        }

        .para-text {
            p {
                margin-bottom: 0;
            }
        }

        .para-text + a.btn {
            margin-top: 24px;
        }


        &.set-row-view {
            h2 {
                font-family: $montreal-bold;
                font-size: $font-size-31mn-39mx-h3;
                line-height: 1.2;
                color: $color-secondary-dark;
                margin-bottom: clamp(21px, 4vw, 25px);
            }
        }

        &.set-column-view {
            h3 {
                font-size: $font-size-20mn-25mx-h5;
                margin-top: 36px;
            }
        }
    }
}
