.call-to-action {
    border-bottom: none;

    .content {
        display: table;
        width: 100%;

        .content-text {
            display: inline-block;
            vertical-align: middle;
            float: none;
            padding-left: 0;

            p {
                margin-bottom: 0;
            }
        }

        .content-btn {
            text-align: right;
            display: inline-block;
            vertical-align: middle;
            float: none;
            width: 100%;
            padding: 0;
            margin-top: 30px;

            @media (min-width: $screen-md-min) {
                width: 33.33333%;
                margin-top: 0;
            }

            .btn {
                border: 1px solid $color-secondary;
                padding: 12px !important;
                width: 100%;

                @media (min-width: $screen-md-min) {
                    padding: 6px 12px !important;
                    width: auto;
                }
            }
        }
    }

    &.bg-media {
        border: none;
        text-align: right;
        background-position: left center;
        background-size: cover;
        padding: calc(12px + 1.5vw);

        &:after {
            background-color: transparent;
        }

        .content {
            float: right;
            max-width: 50%;

            @media (min-width: $screen-md-min) {
                max-width: 45%;
            }

            h3 {
                color: $color-primary;
            }

            p {
                color: $color-primary;
                width: auto;
                margin-bottom: calc(12px + 4vw);
                font-size: calc(15px + 0.49vw);
                line-height: 1.4;
                font-family: $montreal-medium;

                @media (min-width: $screen-md-min) {
                    margin-bottom: calc(12px + 1.5vw);
                }
            }

            /*.btn {
                border: none;
                padding: 0;

                @media (min-width: $screen-md-min) {
                    padding: 6px 12px;
                    border: 1px solid $color-secondary;
                }
            }*/
        }
    }
}
