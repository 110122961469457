.off-canvas-sidebar {
  z-index: 1032;
  display: inline-block;
  &-left {
    position: fixed;
    left: 0;
    top: 300px;
    .show-sidebar-left &{
      transition: all 0.4s ease;
      left: 320px;
    }
  }
  &-right {
    position: fixed;
    right: 0;
    top: 300px;
    .show-sidebar-right &{
      transition: all 0.4s ease;
      right: 320px;
    }
  }
}

.sidebar {
  background-color: $sidebar-bg;
  transition: all 0.4s ease;
  margin-bottom: 30px;

  &-toggle {
    border-color: transparent;
    position: relative;
    margin-right: 15px;
    padding: 13px 10px;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: $brand-primary;
    background-image: none;
    border: 1px solid transparent;
    border-radius: $sidebar-toggle-border-radius;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    display: block;
    width: 100%;

    &.collapsed {
      border-bottom-left-radius: $sidebar-toggle-border-radius;
      border-bottom-right-radius: $sidebar-toggle-border-radius;
    }

    .text {
      color: $brand-text-color;
      position: absolute;
      left: 40px;
      top: 11px;
    }

    .icon-bar {
      display: block;
      width: 22px;
      height: 2px;
      border-radius: 1px;
      background-color: $brand-text-color;
      & + .icon-bar {
        margin-top: 4px;
      }
    }
  }

  &-collapse {
    display: block;

    @media (max-width: $screen-xs-max) {
      display: none;
    }
  }

  .bg-primary &, .bg-secondary &, .bg-dark &, .bg-media & {
    background-color: transparent;
    color: $brand-text-color;
  }

  &.sidebar-fixed {
    &-left, &-right {
      height: 100%;
      position: fixed;
      top: 0;
      width: 320px;
      z-index: 1032;
      overflow-y: auto;
    }
    &-left {
      left: -320px;
      .show-sidebar-left & {
        left: 0;
      }
    }
    &-right {
      right: -320px;
      .show-sidebar-right & {
        right: 0;
      }
    }
  }

  .show-sidebar-left &.sidebar-fixed-left {
    left: 0;
  }
  .show-sidebar-right &.sidebar-fixed-right {
    right: 0;
  }

  &.sidebar-right {
    right: 0;
    left: auto;
  }

  .nav {
    li {
      >a {
        padding-left: 0;
        color: $sidebar-nav-item-color;
        background-color: $sidebar-nav-item-bg;
        font-weight: $sidebar-nav-item-font-weight;
        .bg-primary &, .bg-secondary &, .bg-dark &, .bg-media & {
            color: $brand-text-color;
        }
        &:hover {
          padding-left: 10px;
        }
      }
      &.active {
        >a  {
          color: $sidebar-nav-item-color-active;
          font-weight: $sidebar-nav-item-font-weight-active;
          .bg-primary &, .bg-secondary &, .bg-dark &, .bg-media & {
            color: $brand-text-color;
          }
          &:hover {
            background-color: $sidebar-nav-item-bg;
          }
          &:focus {
            color: $brand-text-color;
          }
        }
      }

      &.open {
        & > .nav {
          max-height: 9999px;
        }
      }
    }
    .nav {
      border-left: 1px solid $sidebar-nav-item-border-color-active;
      padding-left: 15px;
      max-height: 0;
      overflow: hidden;
    }
  }
}
