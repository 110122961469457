﻿@import "_variables.scss";

// ONLY TYPOGRAPHY PROPERTIES
// NO MARGIN, NO PADDING, NO DISPLAY, NO BACKGROUND, NO ANIMATION..., NOTHING WHO IS NOT TEXT RELATIVE
// CREATE, DON'T UPDATE

.typo__01 {
    color: $color-secondary-dark;
    font-family: $montreal-medium;
    text-transform: uppercase;
    font-size: fluid(32px, 60px);
}

.typo__02 {
    color: $color-secondary-dark;
    font-family: $montreal-medium-italic;
    font-size: $font-size-25mn-31mx-h4;
}

.typo__03 {
    color: $color-secondary-dark;
    font-family: $montreal-regular;
    line-height: 1.3;
    font-size: $font-size-16mn-20mx;
}

.typo__04 {
    color: $color-primary;
    font-family: $montreal-medium-italic;
    text-transform: uppercase;
    font-size: fluid(20px, 40px);
}

.typo__05 {
    color: $color-primary;
    font-family: $montreal-medium-italic;
    font-size: fluid(15px, 18px);
}

.typo__06 {
    color: $color-primary;
    font-family: $montreal-medium;
    font-size: fluid(13px, 14px);
}

.typo__07 {
    font-family: $montreal-bold;
    font-size: $font-size-20mn-25mx-h5;
}

.typo__08 {
    color: $color-secondary-on;
    font-family: $montreal-medium;
    font-size: fluid(12px, 14px);
}

.typo__09 p,
.typo__09 {
    color: $color-secondary-dark;
    font-family: $montreal-regular;
    /*font-size: fluid(13px, 16px);*/
    font-size: $font-size-16mn-20mx;
    line-height: 1.4;
}

.typo__10 {
    color: $color-primary;
    font-family: $montreal-bold;
    font-size: fluid(20px, 20px);
    text-transform: uppercase;
}

.typo__11 p,
.typo__11 {
    color: $color-primary;
    font-family: $montreal-regular;
    font-size: $font-size-25mn-31mx-h4;
}

.typo__12 {
    font-family: $montreal-medium;
    font-size: fluid(13px, 14px);
}

.typo__13 {
    color: $color-secondary-dark;
    font-family: $montreal-medium;
    text-transform: uppercase;
    font-size: fluid(13px, 14px);
}

.typo__14 {
    color: $color-secondary-on;
    font-family: $montreal-medium;
    text-transform: uppercase;
    font-size: fluid(13px, 14px);
}

.typo__15 {
    color: $color-secondary-dark;
    font-family: $montreal-medium;
    font-size: fluid(18px, 20px);
}

.typo__16 {
    color: $color-primary;
    font-family: $montreal-bold;
    text-transform: uppercase;
    font-size: fluid(13px, 14px);
}

.typo__17 {
    color: $color-primary;
    font-family: $montreal-medium;
    text-transform: uppercase;
    font-size: fluid(30px, 44px);
}

.typo__18 {
    color: $color-secondary-dark;
    font-family: $montreal-bold;
    text-transform: uppercase;
    font-size: fluid(12px, 12px);
}

.typo__19 {
    color: $color-secondary-dark;
    font-family: $montreal-regular;
    font-size: $font-size-25mn-31mx-h4;
}

.typo__20 {
    color: $color-secondary-dark;
    font-family: $montreal-regular;
    font-size: fluid(15px, 16px);
    font-weight: bold;
}

.typo__21 {
    color: $color-secondary-dark;
    font-family: $montreal-regular;
    font-size: fluid(15px, 16px);
}

.typo__22 {
    color: #fff;
    font-family: $montreal-regular;
    font-size: fluid(10px, 14px);
}

.typo__23 {
    color: $color-secondary-dark;
    font-family: $montreal-medium;
    text-transform: uppercase;
    font-size: fluid(13px, 13px);
}

.typo__24 {
    color: $color-secondary-dark;
    font-family: $montreal-bold;
    font-size: fluid(13px, 14px);
}

.typo__25 {
    color: #333e48;
    font-family: $montreal-regular;
    font-size: fluid(13px, 14px);
}

.typo__26 {
    color: #fff;
    font-family: $montreal-medium;
    font-size: fluid(12px, 12px);
}

.typo__27 {
    color: $color-secondary;
    font-family: $montreal-medium;
    text-transform: uppercase;
    font-size: fluid(14px, 14px);
}

.typo__28 {
    color: $color-primary;
    font-family: $montreal-medium;
    text-transform: uppercase;
    font-size: fluid(14px, 14px);
}

.typo__29 {
    color: $color-primary;
    font-family: $montreal-medium;
    text-transform: uppercase;
    font-size: fluid(14px, 14px);
}

.typo__30 {
    color: $color-secondary-on;
    font-family: $montreal-medium;
    font-size: fluid(12px, 12px);
}

.typo__31 {
    color: $color-secondary-dark;
    font-family: $montreal-medium;
    font-size: fluid(12px, 14px);
}

.typo__32 {
    color: #a6a9ac;
    font-family: $montreal-medium;
    font-size: fluid(12px, 14px);
}

.typo__33 {
    color: $color-secondary-dark;
    font-family: $montreal-bold;
    font-size: fluid(12px, 12px);
}

.typo__34 {
    color: $color-primary;
    font-family: $montreal-bold;
    font-size: fluid(12px, 12px);
}

.typo__35 {
    color: $color-secondary-on;
    font-family: $montreal-regular;
    font-size: fluid(15px, 18px);
}

.typo__36 {
    color: #fff;
    font-family: $montreal-medium;
    font-size: fluid(11px, 16px);
}

.typo__37 {
    color: $color-secondary-dark;
    font-family: $montreal-regular;
    font-size: fluid(15px, 18px);
}

.typo__38 {
    color: $color-primary;
    font-family: $montreal-bold;
    text-transform: uppercase;
    font-size: fluid(13px, 13px);
}

.typo__39 {
    color: $color-secondary-on;
    font-family: $montreal-medium;
    text-transform: uppercase;
    font-size: fluid(10px, 11px);
}

.typo__40 {
    color: #fff;
    font-family: $montreal-medium;
    font-size: fluid(12px, 14px);
}

.typo__41 {
    color: $color-secondary-dark;
    font-family: $montreal-medium;
    font-size: fluid(13px, 16px);
}

.typo__42 {
    color: #fff;
    font-family: $montreal-medium;
    font-size: fluid(12px, 13px);
}

.typo__43 {
    color: $color-primary;
    font-family: $montreal-bold;
    font-size: fluid(13px, 14px);
}

.typo__44 {
    color: $color-primary;
    font-family: $montreal-medium;
    text-transform: uppercase;
    font-size: fluid(18px, 20px);
}

.typo__45 {
    color: $color-secondary-on;
    font-family: $montreal-medium;
    font-size: fluid(12px, 13px);
    text-transform: uppercase;
}

.typo__46 {
    color: $color-primary;
    font-family: $montreal-medium;
    font-style: italic;
    font-size: $font-size-25mn-31mx-h4;
    line-height: 1.2;
}

.typo__47 {
    color: $color-primary;
    font-family: $montreal-bold;
    font-size: fluid(12px, 12px);
    text-transform: uppercase;
}

.new__typo__01 {
    font-family: $montreal-bold;
    /*font-size: fluid(32px, 60px);*/
    /*font-size: calc(2.5vw + 12px);*/
    word-break: break-word;
    font-size: $font-size-49mn-61mx-h1;
    /*@media (min-width: $screen-view-condition-min) {
        font-size: 50px;
    }*/
}

.new__typo__02 {
    font-family: $montreal-bold;
    font-size: $font-size-25mn-31mx-h4;
}

.new__typo__03 {
    font-family: $montreal-regular;
    font-size: $font-size-16mn-20mx;
}

.new__typo__04 {
    font-family: $montreal-medium-italic;
    font-size: fluid(20px, 40px);
}

.new__typo__05 {
    font-family: $montreal-medium-italic;
    font-size: fluid(15px, 18px);
}

.new__typo__06 {
    font-family: $montreal-medium;
    font-size: fluid(13px, 14px);
}

.new__typo__07 {
    font-family: $montreal-medium;
    font-size: $font-size-20mn-25mx-h5;
}

.new__typo__08 {
    font-family: $montreal-medium;
    font-size: fluid(12px, 14px);
}

.new__typo__09 {
    font-family: $montreal-regular;
    font-size: $font-size-16mn-20mx;
}

.new__typo__10 {
    font-family: $montreal-bold;
    font-size: fluid(20px, 20px);
}

.new__typo__11 {
    font-family: $montreal-regular;
    font-size: $font-size-25mn-31mx-h4;
}

.new__typo__12 {
    font-family: $montreal-bold;
    /*font-size: fluid(18px, 20px);*/
    font-size: $font-size-25mn-31mx-h4;
}

.new__typo__13 {
    font-family: $montreal-bold;
    font-size: fluid(13px, 14px);
}

.new__typo__14 {
    font-family: $montreal-medium;
    font-size: fluid(30px, 44px);
}

.new__typo__15 {
    font-family: $montreal-medium;
    font-size: fluid(12px, 12px);
}

.new__typo__16 {
    font-family: $montreal-bold;
    font-size: fluid(15px, 16px);
}

.new__typo__17 {
    font-family: $montreal-regular;
    font-size: fluid(15px, 16px);
}

.new__typo__18 {
    font-family: $montreal-medium;
    font-size: fluid(12px, 13px);
}

.new__typo__19 {
    font-family: $montreal-bold;
    font-size: fluid(12px, 12px);
}

.new__typo__20 {
    font-family: $montreal-medium;
    font-size: fluid(11px, 16px);
}

.new__typo__21 {
    font-family: $montreal-regular;
    font-size: fluid(15px, 18px);
}

.new__typo__22 {
    font-family: $montreal-bold;
    font-size: fluid(13px, 13px);
}

.new__typo__23 {
    font-family: $montreal-medium;
    font-size: fluid(10px, 11px);
}

.new__typo__24 {
    font-family: $montreal-medium;
    font-size: fluid(12px, 16px);
}

.new__typo__25 {
    font-family: $montreal-medium;
    font-size: calc(.52vw + 14px);
}
/* 6209 - HTML Headings and general css */

.new__typo__26 {
    font-family: $montreal-medium;
    font-size: 12px;
}

.new__typo__27 {
    font-family: $montreal-medium;
    font-size: 14px;
}

.new__typo__28 {
    font-family: $montreal-regular;
    font-size: 14px;
}

.typo__24_20 {
    color: $color-secondary-dark;
    font-family: $montreal-medium;
    font-size: calc(18px + .31vw);
    letter-spacing: 0px;
}

.typo_20_16 {
    color: $color-secondary-dark;
    font-family: $montreal-regular;
    font-size: calc(16px + .31vw);
    letter-spacing: 0px;
    text-transform: none;
}


.new__typo__29 {
    font-family: $montreal-bold;
    font-size: $font-size-20mn-25mx-h5;
}
