

@import "../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/dropdowns";


.dropdown, .dropup {
  &-header {
      font-size: $dropdown-header-font-size;
      color: $dropdown-header-color;

      .bg-white & {
        color: $color-black;
        font-weight: $dropdown-header-font-weight;
      }

      @media (max-width: $screen-sm-max) {
        color: $dropdown-header-color;
        //font-weight: bold;
        .bg-primary &, .bg-secondary &, .bg-media & {
           color: $brand-text-color;
           font-weight: $dropdown-header-font-weight;
        }
      }
  }

  &-toggle {
    margin-bottom: 0 !important;
    &:focus {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
    }
  }

  &.open {
    .dropdown-toggle {
      background-color: rgba(0,0,0,0.2);
      filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#20000000', endColorstr='#20000000');
    }
    & > a {
      & > .caret {
        transform: rotate(180deg);
        -ms-transform: rotatet(180deg);
      }
    }
  }

  &-menu {
    border-radius: $dropdown-menu-border-radius;
    .bg-primary &, .bg-secondary &, .bg-dark &, .bg-media & {
      color: $text-color;
    }
    & > .active {
      & > a, & > a:hover, & > a:active, & > a:focus {
        color: $active-list-text-color;
        background-color: transparent;
        font-weight: $active-list-font-weight;
      }
    }
    li {
      a {
        padding: 3px 20px;
        color: $text-color;

        &:hover {
          text-decoration: underline;
          background-color: transparent;
        }
      }
    }
  }
}

.caret {
  margin-left: 10px;
}
