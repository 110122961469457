@import "_variables.scss";

$grid-gutter-width: 0;

.container-fluid {
    padding: 0;
}

.big-padding {
    padding-right: 4vw;
    padding-left: 4vw;

    @media (min-width: $screen-view-condition-min) {
        padding-left: 0 !important;
        padding-right: 0 !important;
        max-width: $screen-laptop-min;
        margin: auto !important;
    }

    .big-padding {
        padding-right: 0;
        padding-left: 0;
    }
}

.big-padding-left {
    padding-left: calc(4vw + 15px);
    padding-right: calc(4vw + 15px);

    @media (min-width: $screen-lg-min) {
        padding-left: calc(4vw + 15px);
        padding-right: 0;
    }

    @media (min-width: $screen-view-condition-min) {
        padding-left: 1vw;
        padding-right: 0;
    }
}

.big-padding-right {
    padding-left: calc(4vw + 15px);
    padding-right: calc(4vw + 15px);

    @media (min-width: $screen-md-min) {
        padding-left: 15px;
    }

    @media (min-width: $screen-view-condition-min) {
        padding-left: 0;
        padding-right: 1vw;
    }
}

.reg-padding {
    padding-left: 15px;
    padding-right: 15px;
}

.reg-padding-left {
    padding-left: 15px !important;
}

.reg-padding-right {
    padding-left: 15px;
}

.no-padding {
    padding-left: 0;
    padding-right: 0;
}

.no-padding-right {
    @media (min-width: $screen-md-min) {
        padding-right: 0;
    }
}

.no-padding-left {
    @media (min-width: $screen-md-min) {
        padding-left: 0;
    }
}

.no-margin {
    margin-left: 0;
    margin-right: 0;
}

.no-margin-right {
    margin-right: 0;
}

.big-margin-children {
    > *:not(.modal) {
        margin-top: 100px;
        margin-bottom: 100px;

        &:first-child:not(link) {
            margin-top: 0 !important;

            .bg-secondary &,
            .secondary-white & {
                margin-top: 100px !important;
            }
        }
    }
}

.medium-margin-children {
    > *:not(.modal) {
        margin-top: 70px;
        margin-bottom: 70px;

        @media (max-width: $screen-xs-min) {
            margin-top: 45px;
            margin-bottom: 35px;
        }

        @media (min-width: $screen-xs-min) {
            margin-top: 45px;
            margin-bottom: 35px;
        }

        @media (min-width: $screen-sm-min) {
            margin-top: 45px;
            margin-bottom: 35px;
        }

        @media (min-width: $screen-md-min) {
            margin-top: 65px;
            margin-bottom: 45px;
        }

        @media (min-width: $screen-lg-min) {
            margin-top: 75px;
            margin-bottom: 56px;
        }

        @media (min-width: $screen-view-condition-min) {
            margin-top: 75px;
        }

        &:first-child:not(link) {
            margin-top: 0 !important;

            .bg-secondary &,
            .secondary-white & {
                margin-top: 70px !important;

                @media (max-width: $screen-xs-min) {
                    margin-top: 25px;
                }

                @media (min-width: $screen-xs-min) {
                    margin-top: 25px;
                }

                @media (min-width: $screen-sm-min) {
                    margin-top: 35px;
                }

                @media (min-width: $screen-md-min) {
                    margin-top: 45px;
                }

                @media (min-width: $screen-lg-min) {
                    margin-top: 55px;
                }

                @media (min-width: $screen-view-condition-min) {
                    margin-top: 55px;
                }
            }
        }
    }

    h1.page-title {
        margin-bottom: 35px;
        margin-top: 45px;
    }

    &:has(.set-column-view) > :not(.modal) {
        margin-top: clamp(40px, 4vw, 56px); /* 40px for mobile, 56px for desktop, scales in between */
    }
}


.small-margin-children {
    > *:not(.modal) {
        margin-top: 40px;
        margin-bottom: 40px;

        &:first-child:not(link) {
            margin-top: 0 !important;

            .bg-secondary &,
            .secondary-white &,
            .bg-primary &, .secondary-3-column &, .bg-white &, .bg-dark &, .bg-secondary-grey80 &, .bg-black-white &, .light-blue-bg & {
                margin-top: 40px !important;
            }
        }
    }
}

.no-margin-left {
    margin-left: 0;
}

.fullimg .thumbnail {
    margin-left: -4vw;
    margin-right: -4vw;
    padding: 0;

    @media (min-width: $screen-md-min) {
        margin-left: 0;
        margin-right: 0;
    }
}

#insight-theme-coveo-srch {
    margin-top: 0 !important;
}

/* contact us page form - full width layout form elements left spacing updated */
.js-contacts-container .big-padding-left.js-form-col {
    @media (min-width: $screen-view-condition-min) {
        padding-left: calc(4vw + 15px);
    }
}

$grid-gutter-width: 0;

.container-fluid {
    padding: 0;
}

.big-padding {
    padding-right: 4vw;
    padding-left: 4vw;

    @media (min-width: $screen-view-condition-min) {
        padding-left: 0 !important;
        padding-right: 0 !important;
        max-width: $screen-laptop-min;
        margin: auto !important;
    }

    .big-padding {
        padding-right: 0;
        padding-left: 0;
    }
}

.big-padding-left {
    padding-left: calc(4vw + 15px);
    padding-right: calc(4vw + 15px);

    @media (min-width: $screen-lg-min) {
        padding-left: calc(4vw + 15px);
        padding-right: 0;
    }

    @media (min-width: $screen-view-condition-min) {
        padding-left: 1vw;
        padding-right: 0;
    }
}

.big-padding-right {
    padding-left: calc(4vw + 15px);
    padding-right: calc(4vw + 15px);

    @media (min-width: $screen-md-min) {
        padding-left: 15px;
    }

    @media (min-width: $screen-view-condition-min) {
        padding-left: 0;
        padding-right: 1vw;
    }
}

.reg-padding {
    padding-left: 15px;
    padding-right: 15px;
}

.reg-padding-left {
    padding-left: 15px !important;
}

.reg-padding-right {
    padding-left: 15px;
}

.no-padding {
    padding-left: 0;
    padding-right: 0;
}

.no-padding-right {
    @media (min-width: $screen-md-min) {
        padding-right: 0;
    }
}

.no-padding-left {
    @media (min-width: $screen-md-min) {
        padding-left: 0;
    }
}

.no-margin {
    margin-left: 0;
    margin-right: 0;
}

.no-margin-right {
    margin-right: 0;
}

.big-margin-children {
    > *:not(.modal) {
        margin-top: 100px;
        margin-bottom: 100px;

        &:first-child:not(link) {
            margin-top: 0 !important;

            .bg-secondary &,
            .secondary-white & {
                margin-top: 100px !important;
            }
        }
    }
}

.medium-margin-children {
    > *:not(.modal) {
        margin-top: 70px;
        margin-bottom: 70px;

        @media (max-width: $screen-xs-min) {
            margin-top: 45px;
            margin-bottom: 35px;
        }

        @media (min-width: $screen-xs-min) {
            margin-top: 45px;
            margin-bottom: 35px;
        }

        @media (min-width: $screen-sm-min) {
            margin-top: 45px;
            margin-bottom: 35px;
        }

        @media (min-width: $screen-md-min) {
            margin-top: 65px;
            margin-bottom: 45px;
        }

        @media (min-width: $screen-lg-min) {
            margin-top: 75px;
            margin-bottom: 55px;
        }

        @media (min-width: $screen-view-condition-min) {
            margin-top: 75px;
        }

        &:first-child:not(link) {
            margin-top: 0 !important;

            .bg-secondary &,
            .secondary-white & {
                margin-top: 70px !important;

                @media (max-width: $screen-xs-min) {
                    margin-top: 25px;
                }

                @media (min-width: $screen-xs-min) {
                    margin-top: 25px;
                }

                @media (min-width: $screen-sm-min) {
                    margin-top: 35px;
                }

                @media (min-width: $screen-md-min) {
                    margin-top: 45px;
                }

                @media (min-width: $screen-lg-min) {
                    margin-top: 55px;
                }

                @media (min-width: $screen-view-condition-min) {
                    margin-top: 55px;
                }
            }
        }
    }

    h1.page-title {
        margin-bottom: 35px;
        margin-top: 45px;
    }
}

.small-margin-children {
    > *:not(.modal) {
        margin-top: 40px;
        margin-bottom: 40px;

        &:first-child:not(link) {
            margin-top: 0 !important;

            .bg-secondary &,
            .secondary-white & {
                margin-top: 40px !important;
            }
        }
    }
}

.no-margin-left {
    margin-left: 0;
}

.fullimg .thumbnail {
    margin-left: -4vw;
    margin-right: -4vw;
    padding: 0;

    @media (min-width: $screen-md-min) {
        margin-left: 0;
        margin-right: 0;
    }
}

#insight-theme-coveo-srch {
    margin-top: 0 !important;
}

/* contact us page form - full width layout form elements left spacing updated */
.js-contacts-container .big-padding-left.js-form-col {
    @media (min-width: $screen-view-condition-min) {
        padding-left: calc(4vw + 15px);
    }
}


/* added below css from SC8 grid.scss file that is needed for displaying instagram images on this page prod.cd.wsp.com/sv-SE/karriar/att-jobba-pa-wsp */
/*  WSP-144 Instagram embed feed - Sprint 43 */
.follow-btn {
    margin-right: 0 !important;
    margin-bottom: 40px !important;
}

.instafeed {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin-left: 15px;
    margin-right: 15px;
    place-items: start;

    @media (min-width: 442px) and (max-width: $screen-sm-max) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 25px;
    }

    @media (min-width: $screen-md-min) {
        &.rec2 {
            grid-template-columns: 1fr 1fr;
            grid-gap: 35px;
        }

        &.rec3 {
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 35px;
        }

        &.rec4 {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }

        &.rec5 {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        }

        &.rec6 {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        }
    }

    & > * {
        border: 1px solid rgba(128, 128, 128, 0.1);
        width: 100%;
    }
}

.instafeed a {
    overflow: hidden;
    position: relative;

    &:before {
        content: "";
        display: inline-block;
        width: 1px;
        height: 0;
        padding-bottom: 100%;
    }

    .insta-image {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        top: 0;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }

    .insta-image:hover {
        -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
    }
}

/* IE11 Support for Flexbox instead of Grid that is used for all other browsers above */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .instafeed {
        display: -ms-flexbox;
        flex-wrap: wrap;

        a {
            width: 48.8%;
            margin: 0.4em;
        }

        @media (min-width: 442px) and (max-width: $screen-sm-max) {
            a {
                width: 32.2%;
            }
        }

        @media (min-width: $screen-md-min) {
            &.rec2 {
                a {
                    width: 48.8%;
                }
            }

            &.rec3 {
                a {
                    width: 32.2%;
                }
            }

            &.rec4 {
                a {
                    width: 24%;
                }
            }

            &.rec5 {
                a {
                    width: 19%;
                }
            }

            &.rec6 {
                a {
                    width: 15.6%;
                }
            }
        }
    }
}
/*  WSP-144 Instagram embed feed - Sprint 43 */
