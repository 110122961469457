html {
  &.show-sidebar-left, &.show-sidebar-right {
    overflow-x: hidden;
  }
}

body {
  transition: all 0.4s ease;
  position: relative;
  left: 0;
  right: 0;

  * {
    transition: all 0.256s ease;
  }

  &.bg-media {
    overflow: auto;
  }

  &:before {
    content: ' ';
    display: none;
    background-color: #000;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1031;
    transition: all 0.4s ease;
    pointer-events: none;
  }

  .show-sidebar-left &, .show-sidebar-right & {
    &:before {
      display: block;
      opacity: 0.4;
      pointer-events: all;
    }
  }
}

hr {
  margin: 0px 0;
}

a {
  .bg-primary &, .bg-dark &, .bg-media & {
    color: $brand-text-color;
  }
  &[role="button"] {
    &:hover {
      text-decoration: none !important;
    }
  }
}

table, .table {
  & > tbody {
    & > tr {
      & > td,  & > th {
        vertical-align: middle;
      }
    }
  }
}

.open {
  & > a >.caret {
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
  }
}

.component-example {
  padding: 20px 0 15px 0;

  &:first-child {
    margin-top: 10px;
    border-top: 1px solid $hr-border;
  }

  & > hr {
    margin-top: 70px;
  }
}

.wow {
  visibility: hidden;
  opacity: 0;
  transition-delay: 0.2s;

  &-shown {
    opacity: 1;
  }
}

.maps {
  position: relative;
  min-height: 300px;
}

.code {
  width:100%;
  height:100%;
  min-height:300px;
}
