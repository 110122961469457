.nav-tabs {
    border: none;
    padding-left: 4vw;
    margin-left: 0;

    &.nopadding {
        padding-left: 0;
    }

    li {
        font-family: $montreal-medium;
        font-size: $fonts-size-small;
        background: $color-secondary-lightest;
        border-bottom: 1px solid #d5d5d5;
        padding: 0;
        opacity: 0.5;

        &:hover,
        &:focus {
            background: $color-secondary-lightest;
            border-bottom: 1px solid $color-secondary-lightest;
            opacity: 1;

            a {
                background: $color-secondary-lightest;
                border: none;
                color: $color-secondary-dark;
                opacity: 0.5;
                font-weight: normal;
            }
        }

        &.active {
            color: $color-white;
            background: $color-primary;
            border-bottom: 1px solid $color-primary;
            opacity: 1;

            &:hover,
            &:focus {
                background: $color-primary;
                border-bottom: 1px solid $color-primary;

                a,
                &:focus {
                    background: $color-primary;
                    border: none;
                    color: $color-white;
                }
            }

            a,
            a:focus {
                color: $color-white;
                background: $color-primary;
                border: none;
                opacity: 1;
                font-weight: normal;
            }
        }

        &:before {
            content: none;
        }

        a,
        a:focus {
            font-family: $montreal-medium;
            font-size: $fonts-size-small;
            color: $color-secondary-dark;
            background: $color-secondary-lightest;
            border: none;
            padding: 20px 25px;
            opacity: 0.5;
            font-weight: normal;
        }
    }
}

.tab-content {
    &.sector-list, &.service-list {
        border-top: 1px solid $color-secondary-light;
        margin: calc(40px + 2vw) 0 0;
        padding: 60px 0 0 0;

        @media (min-width: $screen-md-min) {
            padding: 60px 0 30px;
        }

        .tab-pane {
            margin-left: 0;
            margin-right: 0;

            .block-grid-md-3 {
                margin-left: -15px;
                margin-right: -15px;
            }
        }
    }

    &.sector-list {
        .typo__15 {
            padding-left: 15px;
        }
    }

    &.service-alpha {
        padding: 15px 0 30px;
    }

    .tab-pane {
        .tablist {
            .mlv__list {
                margin: 0;
            }
        }
    }

    .teaser-summary {
        margin: 0 15px 40px 15px;
    }
}

.service-content {
    margin-top: 40px;
}
