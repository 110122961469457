

.page-header {
    border-bottom: none;
    margin-top: 0;
    margin-bottom: 80px;
    position: relative;
    padding-bottom: 0; /* Breadcrumb */
    & > .container:first-child {
        padding-top: 80px;

        @media all and (max-width: $screen-xs-max) {
            padding-top: 30px;
        }
    }


    &.bg-primary, &.bg-dark, &.bg-media {
        h1 {
        }
    }

    h1 {
    }

    @media all and (max-width: $screen-xs-max) {
        margin-bottom: 20px;
    }

    &.bg-media {
        position: relative;

        &:before {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 80px;
            content: ' ';
            background: -moz-linear-gradient(top, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%);
            background: -webkit-linear-gradient(top, rgba(0,0,0,0.3) 0%,rgba(0,0,0,0) 100%);
            background: linear-gradient(to bottom, rgba(0,0,0,0.3) 0%,rgba(0,0,0,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#CC000000', endColorstr='#00000000',GradientType=0 );
        }
    }



    body.home & {
        margin-bottom: 60px;
    }

    &.bg-media {
        min-height: 425px;
        transition: all 200ms ease;

        @media (max-width: 991px) {
            min-height: 540px;
        }

        & > .jumbotron {
            background-color: transparent;
        }

        .breadcrumb {
            & > li {
                opacity: 0.6;

                &.active {
                    color: $brand-text-color;
                    opacity: 1;

                    &:before {
                        opacity: 0.6;
                    }
                }

                & > a {
                    color: $brand-text-color;
                }

                & + li:before {
                    color: $brand-text-color;
                }
            }
        }
    }


    &.bg-media.m-h-375 {

        @media (max-width: 991px) {
            min-height: 375px; /* WSP-127 What we do - section hero banner on mobile and tablet*/
        }
    }

    &.bg-media.m-h-281 {

        @media (max-width: 991px) {
            min-height: 281px; /* WSP-127 What we do - section hero banner on mobile and tablet*/
        }
    }

    &.bg-media.m-h-TD {

        @media (min-width: 992px) and (max-width:1199px) {
            min-height: 375px !important; /* WSP-127 What we do - section hero banner from tablet resolution till lower desktop resolution - banner height*/
        }
    }
}


.hero-banner.header {
    header.page-header {
        padding-top: 0px;

        h1.page-title {
            text-transform: none;
            font-size: 50px;
            margin-top: 62px;
            padding-bottom: 13px;
            bottom: auto;
        }
    }

    .page-header.bg-media {
        min-height: 415px;
    }

    .page-summary p {
        font-size: 18px;
    }
}
