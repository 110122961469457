﻿/*press release classes */
.pressCont {
    margin-bottom: 40px;

    .press {
        border-top: 1px solid #e7e7e7;
        background: #fafafa;
        padding: 20px 15px 15px;

        h4 {
            font-size: 16px;
            margin-bottom: 15px;
            color: #292929;
            font-weight: bold;
        }

        p {
            font-size: 16px;
            color: #414141;
        }

        .date {
            font-size: 15px;
            color: #b0b0b0;
            max-width: 135px;
        }
    }

    .form-control {
        width: 100%;
    }
}

.paddignT {
    padding-top: 20px;
}
/*press release classes */

.stockInfo {
    .tabs ul {
        margin: 35px 0 30px;
        padding: 0;

        li {
            list-style: none;
            display: table-cell;
            margin: 0;
            float: left;
            margin-bottom: 5px;

            a {
                padding: 15px 2px;
                color: $color-secondary-medium-gray;
                background: $color-secondary-lightest;
                font-size: 13px;
                display: block;
                width: auto;
                text-align: center;
                text-decoration: none;

                &.active {
                    color: $color-white;
                    background: $color-primary;
                }

                &:hover {
                    color: $color-secondary-medium-gray;
                    background: $color-secondary-light-gray;
                    text-decoration: none;
                }
            }
        }
    }

    .nav-tabs {
        padding-left: 0;

        li {
            width: 33%;
            text-align: center;

            a,
            a:focus {
                padding: 10px 25px;
            }

            &.active {
                a,
                a:focus {
                    background: $color-secondary-dark;
                }
            }
        }
    }
}

.stockInfo .nav-tabs li.active,
.nav-tabs li.active:focus,
.nav-tabs li.active:hover {
    border-bottom: 1px solid $color-secondary-dark;
    background: $color-secondary-dark;
}

.tab-content {
    margin-top: 25px;
}

.corporate .tabs ul li a {
    padding: 15px 2px;
    margin-right: 5px;
    text-align: center;
    width: 204px;

    @media (max-width: $screen-xs-min) {
        width: 92vw;
    }

    @media (min-width: $screen-xs-min) {
        width: 92vw;
    }

    @media (min-width: $screen-sm-min) {
        width: 217px;
    }

    @media (min-width: $screen-md-min) {
        width: 217px;
    }

    @media (min-width: $screen-lg-min) {
        width: 200px;
    }

    @media (min-width: $screen-view-condition-min) {
        width: 215px;
    }
}

.stockTabs .tabs ul li a {
    @media (min-width: $screen-lg-min) {
        width: 260px;
    }
}

.greyColor {
    color: #645c63;
}

.blackColor {
    color: #000;
}

.drips strong {
    margin-bottom: 20px;
    display: block;
}

.mergerCont {
    margin-top: 30px;

    hr {
        margin-top: -30px;
        margin-bottom: 5px;
    }
}

.merger h2 {
    margin: 0 0 5px;
}

.mergerList {
    padding: 20px 0 5px;
    border-bottom: 1px solid #e4e4e6;
}

.faq .panel {
    border-bottom: 1px solid #d9d9d6;
    border-top: 0;
    border-left: 0;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
    border-right: 0;
}

.faq .panel-group .panel:first-child {
    border-top: 1px solid #d9d9d6;
}

.faq .panel-group .panel-heading + .panel-collapse > .panel-body {
    border-top: 1px solid #fff;
}

.faq .panel-group .panel + .panel {
    margin-top: 0;
}

.faq .panel-heading {
    padding: 0;
    font-weight: bold;
}

.faq .panel-title {
    color: $text-color;
}

.faq .panel-heading[role="tab"]:active,
.faq .panel-heading[role="tab"]:focus,
.faq .panel-heading[role="tab"]:hover {
    background-color: #eeebea;
    color: $text-color;
}

.faq .panel-heading a {
    padding: 15px 40px 15px 20px;
    display: block;
}

.faq .panel-heading a span {
    float: right;
    font-size: 26px;
    color: $color-secondary;
    margin-right: -20px;
}

.faq .panel-heading a:not(.collapsed) span:before {
    content: "\f106";
}

.faq .panel-heading a:not(.collapsed) {
    border-bottom: 1px solid #d9d9d6;
    background-color: #eeebea;
}

.faq .panel-heading a.collapsed span:before {
    font: "FontAwesome";
    content: "\f107";
}

.faq .panel-body div.well {
    border: 0 none;
    padding: 0;
    font-size: 20px;
}

.faq .panel-body div.well p {
    padding-left: 5px;
    padding-right: 5px;
}

.faq .panel-body div.well p a,
.faq .panel-body div.well p a:hover {
    color: $color-secondary;
    text-decoration: none;
}

.faq .panel-body {
    padding: 10px 40px 15px 20px;
}
/* 566 - Accordion component - title in side well h4 to be hidden / start */
.faq .panel-body h4 {
    display: none;
}
/* 566 - Accordion component - title in side well h4 to be hidden / end */
/* 327 - List item formatting - start */
.panel-body {
    p {
        font-family: $montreal-regular;
        font-size: $font-size-16mn-20mx;
    }

    ul {
        font-family: $montreal-regular;
        font-size: $font-size-16mn-20mx;
        list-style: none;
        padding: 0 15px 15px 50px;

        li {
            position: relative;

            &:before {
                content: "\2014";
                position: absolute;
                left: -30px;
            }
        }
    }
}
/* 327 - List item formatting - end */

.corporate {
    .form-control,
    .form-group,
    label {
        margin-bottom: 5px;
    }

    hr {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    h2 {
        margin-bottom: 15px;
        color: #353434;
    }
}

.contact-info .location {
    margin-bottom: 8px;
    font-weight: bold;
}

.annualReports {
    margin-bottom: 50px;
}

.boardDirTable {
    border: 1px solid #fff;
}

.boardDirTable thead th {
    color: $color-primary;
    font-style: italic;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
}

.boardDirTable > thead > tr > th,
.boardDirTable > tbody > tr > td {
    border: 2px solid #ddd;
    border-bottom-width: 0px;
}

.boardDirTable tr td {
    vertical-align: middle !important;
    text-align: center;
    color: #b4b1b1;
    font-style: italic;
}

.boardDirTable tr td:first-child {
    font-size: 35px;
    line-height: 38px;
    text-align: left;
    color: #000;
}

.boardDirTable tr td:first-child span {
    font-style: italic;
    display: block;
}

.boardDirTable tr:first-child td {
    border-top: 1px solid #fff;
}

.boardDirTable tr td:first-child,
.boardDirTable tr th:first-child {
    border-left: 1px solid #fff;
}

.boardDirTable tr:last-child td {
    border-bottom: 1px solid #fff;
}

.boardDirTable tr td:last-child,
.boardDirTable tr th:last-child {
    border-right: 1px solid #fff;
}

.shareInfo {
    padding: 25px 0;
    text-align: center;

    h5 {
        color: #37637d;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: normal;
    }

    i {
        font-size: 50px;
    }
}

.rightColumn {
    border-left: 1px solid #f0f0f0;
    padding: 35px 15px 50px;
}

.investor .table tr th,
.investor .table tr td {
    padding: 20px 0;
    border-bottom: 1px solid #d9d9d6;
    font-weight: 100;
}

.investor .table tbody tr:hover {
    background: #f0f0f0;
}

.investor .table thead th {
    color: $color-secondary-medium-gray;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 100;
}

.investor .table tbody td {
    color: $text-color;
    font-size: 15px;
    font-weight: 100;
}

.grayCont {
    padding: 0 5px;

    @media (min-width: $screen-view-condition-min) {
        padding: 0;
    }

    &:after {
        display: block;
        clear: both;
        content: "";
    }

    .col-md-3,
    .col-md-4 {
        padding: 0 20px 0 0;

        &:last-child {
            padding: 0;
        }
    }
}

.event-container {
    margin-bottom: 60px;
}

.featureContent {
    height: 365px;
    background: #d9d9d6;
    position: relative;

    .teaser-summary {
        position: absolute;
        top: 50%;
        left: 40px;
        margin-top: -60px;

        small {
            font-size: 12px;
            color: #ff0c0c;
            text-transform: uppercase;
        }
    }
}

.featureAmount {
    hr {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .amount-summary {
        border-top: 1px solid #ff0c0c;
        padding: 20px 0;

        small {
            font-size: 12px;
            color: #ff0c0c;
            text-transform: uppercase;
        }

        h2 {
            font-size: 32px;
            text-transform: uppercase;
            line-height: 28px;
            color: #ff0c0c;
            margin: 20px 0;
        }

        ul {
            padding: 0 0 10px;
            margin: 0;

            li {
                list-style: none;
                display: inline-block;
                min-width: 50px;
                font-weight: bold;
            }
        }
    }
}

.contractInvest {
    font-weight: bold;
}

.wspinvestordivider {
    margin: 50px 0.4vw 0;
    border-top-color: #d9d9d6;
}

header.page-header .page-title {
    margin-top: 60px;

    @media (max-width: $screen-xs-min) {
        margin-bottom: 25px;
    }

    @media (min-width: $screen-xs-min) {
        margin-bottom: 25px;
    }

    @media (min-width: $screen-sm-min) {
        margin-bottom: 35px;
    }

    @media (min-width: $screen-md-min) {
        margin-bottom: 35px;
    }

    @media (min-width: $screen-lg-min) {
        margin-bottom: 45px;
    }

    @media (min-width: $screen-view-condition-min) {
        max-width: $screen-laptop-min;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 45px;
    }
}

.page-summary {
    margin-top: 0 !important;
    line-height: 1.62; /*WSP-139*/
    margin-bottom: 15px; /*WSP-139*/
}

.header header.page-header div.page-summary {
    @media (min-width: $screen-view-condition-min) {
        max-width: $screen-laptop-min;
        margin-left: auto;
        margin-right: auto;
    }
}

.annualreportttl {
    padding-left: calc(3vw + 15px);
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: 0;
    box-shadow: none;
    border: 0 !important;
    background: #eeebea;
    background-image: none;
    flex: 1;
    padding: 0 1em;
    color: $text-color;
    cursor: pointer;
    /* Remove IE arrow */
    &::-ms-expand {
        display: none;
    }
}
/* Custom Select */
.custom-select {
    position: relative;
    display: flex;
    width: 20em;
    height: 3em;
    line-height: 18px;
    background: #2c3e50;
    overflow: hidden;
    border-radius: 0.25em;
    border-bottom: 1px solid $color-primary;
    border-color: transparent transparent $color-primary transparent;
    border-radius: 0;
    margin-bottom: 0;
    /* Arrow */
    &:after {
        position: absolute;
        border-style: solid;
        border-width: 1px 1px 0 0;
        content: "";
        display: inline-block;
        height: 7px;
        right: 17px;
        vertical-align: top;
        width: 7px;
        top: 17px;
        transform: rotate(135deg);
        color: $color-primary;
    }

    select option {
        font-family: $montreal-medium;
        font-size: 14px;
    }
}

.teaser-summary a.btn btn-secondary {
    width: 40%;
}

.pad0 {
    padding: 0;
}

.irtableicon {
    img {
        display: inline;
        margin-left: 10px;
    }

    a {
        padding-left: 25px;
    }
}

.wspanalystdivider {
    margin: 30px 0.4vw;
    border-top-color: #d9d9d6;
    width: 74%;
}

.analyswspanalystdividert .table > thead > tr > th {
    border-bottom: 1px solid #ddd;
    color: $color-secondary-medium-gray;
    font-size: 16px;
    font-weight: normal;
    font-family: $montreal-medium !important;
}

.analyst .table td {
    color: $text-color;
    font-size: 15px;
}

.investor1 {
    margin-top: 30px;
    margin-bottom: 30px !important;
}

.investor1 .table {
    width: 100%;
}

.investor1 .table tr th,
.investor1 .table tr td {
    padding: 20px 0;
    border-bottom: 1px solid #d9d9d6;
}

.investor1 .table tbody tr:hover {
    background: #f0f0f0;
}

.investor1 .table thead th {
    color: $color-secondary-medium-gray;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: normal;
}

.investor1 .table tbody td {
    color: $text-color;
    font-size: 15px;
}

h2.h2ttl {
    color: $text-color;
    margin: 0;
}

.btn.btn-secondary.orgcolor {
    background-color: $color-primary;
    margin-top: 15px;
}

.btn.btn-secondary.orgcolor:hover {
    background-color: #f11e16;
    margin-top: 15px;
}

.shareinfo p {
    font-size: 16px;
}

select,
select.form-control {
    padding: 10px;
}

.resp-container {
    position: relative;
    overflow: hidden;
    padding-top: 90%;
}

.resp-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.modal.pressmodal .modal-body {
    padding: 0 40px 20px 40px;
}

.pressmodal {
    button {
        &.close {
            border: 0 none;
            background-color: #3d5f7b;
            position: absolute;
            right: 0;
            top: 2px;
            padding: 3px 8px;

            span {
                color: #fff;
            }
        }
    }
}

.grayBox,
.grayBox1 {
    border-bottom: 1px solid #ff0c0c;
    background: #f2f2f2;
    padding: 25px;
    position: relative;
    min-height: 155px;

    small {
        /* padding-bottom: 5px; */
        font-size: 12px;
        margin-bottom: 10px;
        display: block;
        color: $text-color;
    }

    h4 {
        font-size: 18px;
        text-transform: uppercase;
        line-height: 25px;
    }

    .download {
        position: absolute;
        right: 15px;
        top: 10px;
    }
}

.grayBox1 {
    border-bottom: 1px solid $color-secondary;
    min-height: 178px;

    h4 {
        text-transform: none;
    }

    .text_3lines_inv_press {
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            height: 4.1em; /* exactly three lines */
            max-height: 4.1em;
        }
    }

    .text_3lines_inv_press:after {
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            background: linear-gradient( to right, rgba(242, 242, 242, 0), rgba(242, 242, 242, 1) 70% );
        }
    }

    &:hover {
        background: #d9d9d6;
        cursor: pointer;

        .text_3lines_inv_press:after {
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                background: linear-gradient( to right, rgba(217, 217, 214, 0), rgba(217, 217, 214, 1) 70% );
            }
        }
    }
}

.posrel {
    position: relative;
}

/* Investor Navigation media queries start */
@media screen and (min-width: $screen-lg-min) {
    .Ir-Nav {
        padding-right: 4px;
    }

    .Ir-Nav ul.mn__list.level-0 > li.mn__item.level-0 a.mn__link.level-0.targetIsHidden {
        color: $text-color;
    }

    .Ir-Nav ul.mn__list.level-0 > li.mn__item.level-0 a.mn__link.level-0.targetIsShown {
        color: $color-secondary-on;
    }

    .Ir-Nav ul.mn__list.level-0 > li.mn__item.level-0 a.mn__link.level-0 {
        color: $text-color;
        font-family: $montreal-regular;
        font-size: 14px;
        text-transform: none;
        font-weight: normal;
        padding-top: 30px 0 33px;

        &:before {
            content: "";
            background-color: $color-secondary-on;
            bottom: -1px;
            display: block;
            height: 5px;
            position: absolute;
            width: 0;
            transition: width 0.5s ease;
        }

        &.targetIsShown,
        &:focus,
        &.active,
        &:hover {
            color: $color-secondary-on;
            text-decoration: none;

            &:before {
                width: 100%;
            }
        }
    }

    .Ir-Nav .main-nav ul.mn__list.level-0 > li.mn__item.level-0 a.mn__link.level-0[href*="inv"] + div.sub-nav.level-0
    ul.row.level-1
    a.level-1 {
        color: $color-secondary-on;
    }

    .Ir-Nav
    .main-nav
    ul.mn__list.level-0 > li.mn__item.level-0
    a.mn__link.level-0[href*="inv"] + div.sub-nav.level-0
    ul.row.level-1
    a.level-1:before {
        background-color: $color-secondary-on;
    }

    .Ir-Nav .row.level-3 li:nth-child(9) {
        clear: both;
    }
}
/* Investor Navigation media queries end */
a.ir_link,
a.ir_link:hover,
a.ir_link:focus {
    color: $color-secondary-on;
    text-decoration: none;
}

.table.div_dates,
.analyst table.table {
    font-family: $montreal-medium;
    font-size: 12px;
    font-weight: 500;
}

.table.div_dates th,
.analyst table.table th {
    color: $color-secondary-medium-gray;
    text-transform: uppercase;
    font-weight: 500;
}

.analyst table.table th {
    padding-left: 0;
    font-size: 12px;
}

.analyst table.table td {
    padding-left: 0;
    font-size: 13px;
    padding-right: 20px;
}
/* stock information common css start */
.pure-g *,
.stockInfo *,
.qmod-pricehistory-table-wrap *,
.qtool * {
    font-family: $montreal-medium;
    font-weight: 500 !important;
}

.pure-g .fa,
.stockInfo .fa,
.qmod-pricehistory-table-wrap .fa,
.qtool .fa {
    font-family: FontAwesome;
    font-weight: normal;
}

.title-pricehistory {
    font-weight: normal;
}

.qmod-pricehistory .qmod-btn.qmod-darkblue {
    background-color: $color-dark-primary !important;
}
/* stock information common css end */
.pad-left0 {
    padding-left: 0;
}

#fileListing a,
#fileListing a:focus,
#fileListing a:hover {
    color: $text-color;
    text-decoration: none;
}

.shareinfo h2.h2ttl {
    font-family: $montreal-bold;
    font-size: calc(0.75117vw + 12.18px);
}

.header div.header-text h1.page-title {
    margin-top: 45px;
    margin-bottom: 35px;
    position: relative;
    /* 6209 - HTML Headings and general css */
    color: $color-secondary-dark;
}

section h1.page-title {
    margin-top: 45px;
    margin-bottom: 35px;
    position: relative;


    &.project-page-title {
        text-transform: none !important;
        margin-top: -15px !important;
    }
}

.fl-box {
    @media (min-width: $screen-view-condition-min) {
        left: 50%;
        margin-left: calc(#{$screen-laptop-min}/ -2) !important;
        padding-left: 0;
        padding-right: 0;
    }
}

.col-xs-12.col-md-9.big-padding-left .fl-box {
    @media (min-width: $screen-view-condition-min) {
        left: auto;
        margin-left: auto !important;
        padding-left: 0;
        padding-right: 0;
    }
}

.analyst {
    margin-top: 0;
}

.latest-news {
    h2 {
        margin-bottom: 20px;
    }

    &.marTB35 &-btn {
        bottom: 20px;
    }

    .grayBox:hover {
        background: #d9d9d6;
    }

    a {
        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
}

.fincl-years h2 {
    margin: 50px 0 30px 0;
}

header.page-header .page-title1 {
    margin-top: 60px;
    font-size: calc(2.5vw + 12px) !important;
    color: $color-secondary-dark;
    line-height: 1.3;
    word-break: break-word;

    @media (max-width: $screen-view-condition-min) {
        padding-right: 0;
        padding-left: 0;
    }
}

hr.pressrls {
    margin: 58px 4vw;
}

.martsharebtn {
    margin-top: 30px;
}

@media (min-width: 768px) {
    .employeelist .el-container .el-item {
        padding-bottom: 0;
    }

    .jl_location.irtableicon a.relLinkhref {
        margin-left: 0;
        padding-left: 0;
    }

    .jl_location.irtableicon a.relLinkhref img.relLinkImg {
        margin-right: 25px;
        margin-left: 0;
    }
}

@media (max-width: 768px) {
    header.page-header .page-title1 {
        margin-top: 60px;
    }

    hr.pressrls {
        margin: 28px 4vw;
    }

    .martsharebtn {
        margin-top: 0;
    }
}

.pressrlsdiv hr {
    margin-top: 25px;
    margin-bottom: 25px;
}

.pressreleaselft h3 {
    font-size: calc(16px + 0.07vw);
}

.subscribewrapper {
    position: relative;
    padding-bottom: 70.25% !important;
    padding-top: 25px;
    height: 0;
}

.subscribewrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.modal-header .close {
    margin-top: 0 !important;
    position: absolute;
    right: 0;
    top: 0;
    background-color: #3d5f7b;
    border: 1px solid #3d5f7b;
    color: #fff;
}

p.irorangettl {
    color: $color-primary;
    font-family: $montreal-medium-italic;
    font-size: calc(0.75117vw + 17.18px);
}

.clear {
    clear: both;
}

.investor .table {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
}

.no-pad-left-right {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

h2.orangettl {
    margin-bottom: 0;
}

hr.ir-home {
    margin-top: 35px;
    margin-bottom: 20px;
}

button[data-target="#subscribeModal"] {
    margin-top: 30px;
}

@media screen and (min-width: 1250px) {
    .subscribeM.contact-us-modal .modal-body {
        padding: 20px 50px 100px;
    }

    span.qmod-last {
        font-size: 3.5em !important;
    }
}
/* Stock information chart left/right space added */
.qmod-chart > .qmod-chart-plot {
    padding-left: 40px;
    padding-right: 40px;
}

h2.redttl {
}

.investor .jl_title.quarterhideshow {
    padding-left: 20px;
}

.margin-top0 {
    margin-top: 0 !important;
}

.margin-bottom20 {
    margin-bottom: 20px !important;
}

.black-color {
    color: #000000 !important;
}

@media screen and (min-width: 992px) {
    .el-item-height a.btn.btn-secondary {
        margin-bottom: -4px;
    }

    .mtop12p {
        padding-top: 12%;
    }

    .IrHomeBnr .container-alignment {
        min-height: 400px;
        overflow: hidden;
    }

    .IrHomeBnr .container-alignment .teaser-bg {
        margin-bottom: 0;
        margin-top: 0;
        min-height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .IrHomeBnr .container-alignment .teaser-alignment.left-alignment {
        position: absolute;
        z-index: 10;
        padding: 0 20px 0 60px;
        top: 50%;
    }

    .IrHomeBnr h2.redttl {
        font-size: calc(0.18779vw + 14px);
    }

    hr.ir-home {
        margin-top: 50px;
        margin-bottom: 40px;
    }

    .qmod-investment-calculator .qmod-popup-wrapper .qmod-popup {
        max-width: 80%;
        width: 80%;
    }
}

@media screen and (max-width: 991px) {
    .marB80 {
        margin-bottom: 80px;
    }

    .bod {
        padding-left: 4%;
        padding-right: 4%;
    }

    .el-item-content.fixheight.el-item-height {
        padding-top: 15px;
    }

    .feat-content .btn.btn-secondary {
        width: 100%;
    }

    .grayCont,
    .grayCont .col-md-3,
    .grayCont .col-md-4 {
        padding: 0;
    }

    .grayBox,
    .grayBox1 {
        margin-bottom: 20px;
        min-height: auto;
    }

    .author-image {
        width: 27.11364%;
        margin: 0 5% 0 0;
    }

    .author-description {
        width: 61.88636%;
    }

    .employeelist .el-container .el-item .fixheight {
        margin-top: 30px;
    }

    .custom-select {
        width: 100%;
    }

    .investor .table {
        word-break: break-all;
    }

    .multi-list-view.Services.Related .mlv__item {
        padding-right: 15px;
    }

    .shareinfo {
        padding: 0 15px;
    }

    .analyst table.table {
        word-break: break-all;
    }

    #fileListing .jl_date {
        min-width: 80px;
        padding-left: 5px;
    }

    .pressR.custom-select {
        margin-bottom: 20px;
    }

    .IrHomeBnr .container-alignment {
        min-height: 300px;
        overflow: hidden;
    }

    .IrHomeBnr .container-alignment .teaser-bg {
        min-height: 300px;
        margin-bottom: 0;
        margin-top: 0;
    }

    .IrHomeBnr .container-alignment .teaser-alignment.left-alignment {
        width: 100%;
        display: table;
        position: relative;
        z-index: 10;
        padding: 0;
        margin-top: 25px;
    }
}

@media screen and (max-width: 600px) {
    .IrHomeBnr .container-alignment {
        min-height: 250px;
        overflow: hidden;
    }

    .IrHomeBnr .container-alignment .teaser-bg {
        min-height: 250px;
    }
}

@media screen and (max-width: 500px) {
    .IrHomeBnr .container-alignment {
        min-height: 220px;
        overflow: hidden;
    }

    .IrHomeBnr .container-alignment .teaser-bg {
        min-height: 220px;
    }

    .IrHomeBnr .container-alignment .teaser-alignment.left-alignment {
        margin-top: 15px;
    }
}

@media screen and (max-width: 400px) {
    .IrHomeBnr .container-alignment {
        min-height: 180px;
        overflow: hidden;
    }

    .IrHomeBnr .container-alignment .teaser-bg {
        min-height: 180px;
    }
}

header.page-header.bg-media:after {
    background-color: transparent;
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    span.qmod-last {
        font-size: 2.5em !important;
    }
}

article.investor,
article.ArticleCss {
    margin: 0;
}

.marTB35 {
    margin-top: 35px;
    margin-bottom: 35px;
}

.marT35 {
    margin-top: 35px !important;
}

h2.pressrelSubTxt {
    color: $color-black !important;
    text-transform: none;
    font-size: calc(13px + 0.07vw);
    line-height: 1.2;
    font-family: $montreal-medium;
    font-weight: 400;
    word-break: break-word;
    font-style: normal;
}

.martop50 {
    margin-top: 50px;
}

.ir-quicklinks {
    clear: both;
    margin-top: 50px !important;

    ul {
        list-style-type: none;
        padding: 0;
        font-size: calc(0.75117vw + 10px);

        li {
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }

            a {
                text-decoration: none;
                margin-left: 10px;
                font-family: $montreal-medium;
                color: $color-dark-secondary;
                font-size: 15px;

                &:hover {
                    text-decoration: underline;
                    color: #243848;
                }
            }
        }
    }

    p {
        font-size: $font-size-16mn-20mx;
        margin: 0 0 10px !important;
    }
}

div.medium-margin-children > hr ~ p {
    font-size: $font-size-16mn-20mx;
    margin: 0 0 10px !important;
}

.marTm100 {
    margin-top: -100px;

    @media (max-width: $screen-xs-min) {
        margin-top: -25px;
    }

    @media (min-width: $screen-xs-min) {
        margin-top: -25px;
    }

    @media (min-width: $screen-sm-min) {
        margin-top: -35px;
    }

    @media (min-width: $screen-md-min) {
        margin-top: -45px;
    }

    @media (min-width: $screen-lg-min) {
        margin-top: -55px;
    }

    @media (min-width: $screen-view-condition-min) {
        margin-top: -55px;
    }
}

.marBm100 {
    margin-bottom: -100px;
}

.eventsHome .ir-quicklinks,
.eventsHome .ir-people,
.eventsHome a p.description {
    display: none;
}

.eventsHome .eventsHrow div a p.date-time,
.eventsHome .eventsHrow div a p.location {
    color: $text-color;
}

.eventsHome .eventsHrow div a {
    text-decoration: none;
}

.marT20 {
    margin-top: 20px;
}

.eventsAddlInfo {
    font-size: 16px;
}

.investoreventclass {
    margin-top: -30px !important;
    margin-bottom: 40px !important;
}

div[id^="file-"] table {
    margin-bottom: 80px !important;
}
/* Request - 6168 start */
.pos-mT0 {
    position: absolute;
    margin-top: 0 !important;
}

.project-info.pos-mT0 ul li {
    margin-right: 60px;
}

.marT40 {
    margin-top: 40px;
}

@media screen and (max-width: 991px) {
    .pos-mT0 {
        position: relative;
        margin-bottom: 0 !important;
    }
}
/* Request - 6168 end */
/* Request - 6269 start */
@media screen and (max-width: 767px) {
    .investor .table thead th,
    .paginationNav span,
    .irtableicon a {
        font-size: 12px;
    }

    .relLinkCol {
        font-size: 10px !important;
        width: 55px !important;
    }

    .jl_location.irtableicon a {
        float: right;
        margin-right: 15px;
    }

    .irtableicon img {
        margin-left: 0;
    }
}
/* Request - 6269 end */
div[id^="stockBox"] {
    @media (min-width: $screen-view-condition-min) {
        width: $screen-laptop-min;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: $screen-view-condition-min) {

    .qtool.detailedQuote .padLR20.qmod-box-container {
        padding-right: 0;
        padding-left: 0;
        width: $screen-laptop-min;
        margin-left: auto;
        margin-right: auto;
        clear: both;
    }
}
/*.desktop-header *:not(svg,i,p,h3,.sub-nav-box) {*/
.main-nav a.mn__link.level-0, .secondary-nav .Ir-Nav ul.mn__list.level-0 > li.mn__item.level-0 a.mn__link.level-0, .secondary-nav a.sn__link, .secondary-nav a.ln_sub-link, .country-language-selector {
    @media (min-width: $screen-lg-min) and (max-width: 1499px) {
        font-size: 14px;
    }

    @media (min-width: 1500px) {
        font-size: 16px;
    }
}

.country-language-selector {
    h3,
    p {
        @media (min-width: $screen-lg-min) and (max-width: 1499px) {
            font-size: 13px;
        }

        @media (min-width: 1500px) {
            font-size: 16px;
        }
    }
}
/* Megamenu */
.mn__list .mn__item > .sub-nav-box {
    background: $color-white;
}

.sub-nav--box-row {
    text-align: left;
    color: $color-secondary-dark;
    text-decoration: none;
    display: flex;

    @media (min-width: $screen-view-condition-min) {
        padding-left: 0;
        padding-right: 0;
        width: 1396px;
        max-width: 1396px;
        margin: auto !important;
    }

    .top-menu-close-btn {
        background-color: $color-white;
        border: 0;
        height: 25px;
        line-height: 14px;
        padding: 0;
        position: absolute;
        right: 20px;
        top: 0;
        width: 25px;
        margin-top: 35px;
        z-index: 300;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            right: 0;
        }

        &:hover svg {
            -webkit-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: rotate(90deg);

            .menu-close-svg-lines {
                stroke: $color-primary;
            }
        }

        svg {
            width: 18px;
            height: 18px;
            -webkit-transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
            transition: all 0.5s ease;

            .menu-close-svg-lines {
                fill: none;
                stroke: $color-secondary-dark;
                stroke-width: 1.5px;
            }
        }
    }

    .category-link {
        color: $color-secondary-medium-gray;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 25px;
        text-transform: none;

        a {
            color: $color-secondary-medium-gray;
            font-size: 14px;
            line-height: 21px;
            text-align: left;
            text-transform: lowercase;
            display: inline;

            &:hover {
                color: $color-primary;
            }

            @media (min-width: $screen-view-condition-min) {
                font-size: 16px;
            }
        }

        &::first-letter {
            text-transform: uppercase;
        }

        @media (min-width: $screen-view-condition-min) {
            font-size: 16px;
        }
    }

    a {
        padding: 0;
        text-decoration: none;

        &:before {
            content: "";
            background-color: none;
            bottom: auto;
            display: none;
            height: 5px;
            position: absolute;
            width: 0;
            transition: width 0.5s ease;
        }

        &.targetIsShown,
        &:focus,
        &.active,
        &:hover {
            text-decoration: none;

            &:before {
                width: 100%;
            }
        }

        .affix & {
            padding: 0;
        }
    }

    .sub-nav--box-1 {
        border-right: 1px solid $color-secondary-light-gray;
        padding-top: 35px;
        padding-right: 25px;
        padding-bottom: 55px;
        padding-left: 5px;

        .cta-box {
            h2 {
                font-size: 18px !important;
                line-height: 1.3;
                margin-bottom: 15px;

                &:empty {
                    display: none;
                }

                @media (min-width: $screen-view-condition-min) {
                    font-size: 20px !important;
                }
            }

            p {
                font-family: $montreal-regular;
                font-size: 16px !important;
                margin-bottom: 0;

                &:empty {
                    display: none;
                }
            }

            a {
                /*new 660 */
                margin-top: 25px;
                white-space: normal;
                font-weight: normal;
                padding: 15px 20px;
                width: auto;
                margin-bottom: 0;
                margin-right: 0;
                display: inline-block;
                font-family: $montreal-medium;
                letter-spacing: 0;
                text-transform: none;
                color: $color-secondary-dark;


                &:hover {
                    /*new 660 */

                    color: $color-white;
                }

                &.btn-text {
                    color: $color-secondary-dark;
                    background-color: transparent;
                    font-size: $btn-fonts-size;
                    font-family: $montreal-medium;
                    padding: 0;
                    text-align: left;
                    width: auto;
                    letter-spacing: 0;

                    span.icn-arrow-small-blue {
                        margin-left: 7px;
                        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3LjA4OCIgaGVpZ2h0PSIxMi4wNTUiIHZpZXdCb3g9IjAgMCA3LjA4OCAxMi4wNTUiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOm5vbmU7c3Ryb2tlOiNFMDJGMjg7c3Ryb2tlLXdpZHRoOjEuNXB4O308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iYSIgZD0iTTUuNSwxMC45OTQsMCw1LjUsMi4yOTEsMy4yMDcsNS41LDAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDYuMDI4IDExLjUyNCkgcm90YXRlKDE4MCkiLz48L3N2Zz4=");
                        width: 8px;
                        height: 12px;
                        background-repeat: no-repeat;
                        display: inline-block;
                        top: 1px;
                        position: relative;
                        -webkit-transition: all 0.3s ease-in-out;
                        -o-transition: all 0.3s ease-in-out;
                        -moz-transition: all 0.3s ease-in-out;
                        transition: all 0.3s ease-in-out;
                    }

                    span.icn-arrow-small-red {
                        margin-left: 7px;
                        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3LjA4OCIgaGVpZ2h0PSIxMi4wNTUiIHZpZXdCb3g9IjAgMCA3LjA4OCAxMi4wNTUiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOm5vbmU7c3Ryb2tlOiNFMDJGMjg7c3Ryb2tlLXdpZHRoOjEuNXB4O308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iYSIgZD0iTTUuNSwxMC45OTQsMCw1LjUsMi4yOTEsMy4yMDcsNS41LDAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDYuMDI4IDExLjUyNCkgcm90YXRlKDE4MCkiLz48L3N2Zz4=");
                        width: 8px;
                        height: 12px;
                        background-repeat: no-repeat;
                        display: inline-block;
                        top: 2px;
                        position: relative;
                        -webkit-transition: all 0.3s ease-in-out;
                        -o-transition: all 0.3s ease-in-out;
                        -moz-transition: all 0.3s ease-in-out;
                        transition: all 0.3s ease-in-out;
                    }

                    &:hover,
                    &:active {
                        background-color: transparent;
                        color: $color-secondary-dark;

                        span.icn-arrow-small-blue, span.icn-arrow-small-red {
                            margin-left: 11px; /* move arrow to right on hover, active */
                        }
                    }

                    &:active {
                        box-shadow: none;
                    }

                    .btn-space & {
                        &:hover,
                        &:active {
                            span.icn-arrow-small-blue {
                                margin-left: 7px;
                            }
                        }
                    }
                }
            }
        }

        .ssp-box {
            hr {
                margin: 25px auto !important;
            }

            p.title {
                text-transform: uppercase;
                font-size: 12px;
                color: $text-color;
                font-family: $montreal-medium;
                margin-bottom: 15px;
                display: block;
            }

            ul {
                list-style: none;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                li {
                    width: 33.33333%;
                    text-align: left;

                    a {
                        text-transform: none;
                        font-size: 14px;
                        color: $text-color;
                        font-family: $montreal-medium;
                        word-break: break-word;

                        &:hover {
                            color: $color-primary;
                        }
                    }
                }
            }
        }

        .social-box {
            hr {
                margin: 25px auto !important;
            }

            p.title {
                text-transform: uppercase;
                font-size: 12px;
                color: $text-color;
                font-family: $montreal-medium;
                margin-bottom: 15px;
                display: block;
            }

            ul {
                list-style: none;
                display: flex;
                flex-wrap: wrap;

                li {
                    margin: 0 20px 0 0;
                    text-align: left;

                    a {
                        padding: 0;

                        .fa {
                            color: $text-color;
                            font-size: 21px;
                            padding: 0;
                            display: inline-block;

                            &:hover {
                                color: $color-primary;
                            }
                        }

                        span {
                            position: absolute;
                            width: 1px;
                            height: 1px;
                            padding: 0;
                            margin: -1px;
                            overflow: hidden;
                            clip: rect(0, 0, 0, 0);
                            border: 0;
                        }
                    }
                }
            }

            a {
                text-transform: none;
                font-size: 14px;
                color: $text-color;
                font-family: $montreal-medium;

                &:hover {
                    color: $color-primary;
                }
            }
        }
    }

    .sub-nav--box-2 {
        padding: 35px 25px 55px 25px;
        width: 28%;

        @media (min-width: $screen-view-condition-min) {
            width: 27%;
        }

        .subMenuOpen {
            pointer-events: none;
        }

        li[class*="col-"] {
            padding-left: 0;
            padding-right: 0;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        ul.sub-category-link li:first-child {
            margin-top: 0;
        }

        ul.sub-category-link li:last-child {
            margin-bottom: 0;
        }

        li {
            a {
                font-family: $montreal-medium;
                font-size: 14px;
                font-weight: normal;
                letter-spacing: 0;
                color: $color-secondary-dark;
                text-align: left;
                display: inline;

                &.hasSubitems {
                    display: block;
                    padding-right: 35px;
                }

                &.hasSubitems:after {
                    position: absolute;
                    content: "";
                    width: 7px;
                    height: 12.055px;
                    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3LjA4OCIgaGVpZ2h0PSIxMi4wNTUiIHZpZXdCb3g9IjAgMCA3LjA4OCAxMi4wNTUiPjxkZWZzPjxzdHlsZT4uYS1pY24tYXJ3LW5ybWwtYmxja3tmaWxsOm5vbmU7c3Ryb2tlOiMxZTI1MmI7c3Ryb2tlLXdpZHRoOjEuNXB4O308L3N0eWxlPjwvZGVmcz48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjUzIDAuNTMpIj48cGF0aCBjbGFzcz0iYS1pY24tYXJ3LW5ybWwtYmxjayIgZD0iTS0xODYxLjQwNiw2NTMxLjUyM2wtNS41LTUuNSwyLjI5MS0yLjI5MSwzLjIwNy0zLjIwNyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE4NjEuNDA2IDY1MzEuNTIzKSByb3RhdGUoMTgwKSIvPjwvZz48L3N2Zz4=");
                    z-index: 100;
                    right: 7px;
                    top: 5px;
                    -webkit-transition: all 0.2s ease-out;
                    -o-transition: all 0.2s ease-out;
                    -moz-transition: all 0.2s ease-out;
                    transition: all 0.2s ease-out;
                }

                &.act-link.hasSubitems:after {
                    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3LjA4OCIgaGVpZ2h0PSIxMi4wNTUiIHZpZXdCb3g9IjAgMCA3LjA4OCAxMi4wNTUiPjxkZWZzPjxzdHlsZT4uYS1pY24tYXJ3LWhvdmVyLXJlZHtmaWxsOm5vbmU7c3Ryb2tlOiNmZjM3MmY7c3Ryb2tlLXdpZHRoOjEuNXB4O308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iYS1pY24tYXJ3LWhvdmVyLXJlZCIgZD0iTS0xODYxLjQwNiw2NTMxLjUyM2wtNS41LTUuNSwyLjI5MS0yLjI5MSwzLjIwNy0zLjIwNyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE4NjAuODc2IDY1MzIuMDU0KSByb3RhdGUoMTgwKSIvPjwvc3ZnPg==");
                }

                &.act-link.hasSubitems {
                    color: $color-primary;
                }

                &.active,
                &:hover {
                    text-decoration: none;
                    color: $color-primary;

                    &.hasSubitems:after {
                        background-image: url("data:image/svg+xml;base64,CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iNy4wODgiIGhlaWdodD0iMTIuMDU1IiB2aWV3Qm94PSIwIDAgNy4wODggMTIuMDU1Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4uYS1pY24tYXJ3LWhvdmVyLXJlZHtmaWxsOm5vbmU7c3Ryb2tlOiNFMDJGMjg7c3Ryb2tlLXdpZHRoOjEuNXB4O308L3N0eWxlPgogIDwvZGVmcz4KICA8cGF0aCBjbGFzcz0iYS1pY24tYXJ3LWhvdmVyLXJlZCIgZD0iTS0xODYxLjQwNiw2NTMxLjUyM2wtNS41LTUuNSwyLjI5MS0yLjI5MSwzLjIwNy0zLjIwNyIKICAgIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xODYwLjg3NiA2NTMyLjA1NCkgcm90YXRlKDE4MCkiLz4KPC9zdmc+Cg==");
                    }
                }

                &:hover {
                    &.hasSubitems:after {
                        right: 0;

                        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                            right: -1px;
                        }
                    }
                }

                .secondary-nav & {
                    &.act-link.hasSubitems:after {
                        /*background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3LjA4OCIgaGVpZ2h0PSIxMi4wNTUiIHZpZXdCb3g9IjAgMCA3LjA4OCAxMi4wNTUiPjxkZWZzPjxzdHlsZT4uYS1pY24tYXJ3LWhvdmVyLWJsdXtmaWxsOm5vbmU7c3Ryb2tlOiM1Mzc4OTg7c3Ryb2tlLXdpZHRoOjEuNXB4O308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iYS1pY24tYXJ3LWhvdmVyLWJsdSIgZD0iTS0xODYxLjQwNiw2NTMxLjUyM2wtNS41LTUuNSwyLjI5MS0yLjI5MSwzLjIwNy0zLjIwNyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE4NjAuODc2IDY1MzIuMDU0KSByb3RhdGUoMTgwKSIvPjwvc3ZnPg==");
                        */
                        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3LjA4OCIgaGVpZ2h0PSIxMi4wNTUiIHZpZXdCb3g9IjAgMCA3LjA4OCAxMi4wNTUiPjxkZWZzPjxzdHlsZT4uYS1pY24tYXJ3LWhvdmVyLXJlZHtmaWxsOm5vbmU7c3Ryb2tlOiNmZjM3MmY7c3Ryb2tlLXdpZHRoOjEuNXB4O308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iYS1pY24tYXJ3LWhvdmVyLXJlZCIgZD0iTS0xODYxLjQwNiw2NTMxLjUyM2wtNS41LTUuNSwyLjI5MS0yLjI5MSwzLjIwNy0zLjIwNyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE4NjAuODc2IDY1MzIuMDU0KSByb3RhdGUoMTgwKSIvPjwvc3ZnPg==);
                    }

                    &.act-link.hasSubitems {
                        color: $color-primary;
                    }

                    &.active,
                    &:hover {
                        color: $color-primary;

                        &.hasSubitems:after {
                            right: 0;
                            background-image: url("data:image/svg+xml;base64,CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iNy4wODgiIGhlaWdodD0iMTIuMDU1IiB2aWV3Qm94PSIwIDAgNy4wODggMTIuMDU1Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4uYS1pY24tYXJ3LWhvdmVyLXJlZHtmaWxsOm5vbmU7c3Ryb2tlOiNFMDJGMjg7c3Ryb2tlLXdpZHRoOjEuNXB4O308L3N0eWxlPgogIDwvZGVmcz4KICA8cGF0aCBjbGFzcz0iYS1pY24tYXJ3LWhvdmVyLXJlZCIgZD0iTS0xODYxLjQwNiw2NTMxLjUyM2wtNS41LTUuNSwyLjI5MS0yLjI5MSwzLjIwNy0zLjIwNyIKICAgIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xODYwLjg3NiA2NTMyLjA1NCkgcm90YXRlKDE4MCkiLz4KPC9zdmc+Cg==");
                        }
                    }

                    &:hover {
                        &.hasSubitems:after {
                            right: 0;
                        }
                    }
                }

                @media (min-width: $screen-view-condition-min) {
                    font-size: 16px;
                }
            }
        }
    }

    .sub-nav--box-3 {
        border-left: 1px solid $color-secondary-light-gray;
        padding-top: 35px;
        padding-left: 25px;
        padding-bottom: 55px;
        width: 47%;

        @media (min-width: $screen-view-condition-min) {
            width: 48%;
        }

        .addDwnEffect {
            -webkit-transition: all 1s ease-in-out;
            -moz-transition: all 1s ease-in-out;
            -ms-transition: all 1s ease-in-out;
            -o-transition: all 1s ease-in-out;
            transition: all 1s ease-in-out;
        }

        ul {
            column-count: 2;
            column-fill: auto;
            flex-flow: column wrap;
            margin-top: -12px;

            &.rec4 {
                height: 230px;
            }

            &.rec6 {
                height: 270px;
            }

            &.rec8 {
                height: 350px;
            }

            &.rec10 {
                height: 410px;
            }

            &.rec20 {
                height: 450px;
            }

            &.recXL {
                height: 650px;
            }
        }

        li[class*="col-"] {
            padding-left: 0;
            padding-right: 0;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        ul.sub-category-link {
            margin-left: 0;
            margin-right: 0;
        }

        ul.sub-category-link li:last-child {
            margin-bottom: 0;
        }

        li {
            a {
                font-family: $montreal-medium;
                font-size: 14px;
                font-weight: normal;
                letter-spacing: 0;
                color: $color-secondary-dark;
                text-align: left;
                display: inline;

                &:focus,
                &.active,
                &:hover {
                    text-decoration: none;
                    color: $color-primary;
                }

                .secondary-nav & {
                    &:focus,
                    &.active,
                    &:hover {
                        color: $color-primary;
                    }
                }

                @media (min-width: $screen-view-condition-min) {
                    font-size: 16px;
                }
            }
        }

        .feat-content {
            padding: 0;

            .feat-content-box {
                padding-right: 10px;

                a {
                    color: $text-color;
                }

                .thumbnail {
                    margin-bottom: 0;
                    height: 115px;
                    overflow: hidden;

                    img {
                        margin-bottom: 5px;
                        object-fit: cover;
                        width: 100%;
                        height: 115px;
                    }
                }

                @media screen and (-ms-high-contrast: none) {
                    .thumbnail {
                        margin-bottom: 0;
                        height: auto;

                        img {
                            margin-bottom: 5px;
                            width: 100%;
                            height: auto;
                        }
                    }
                }

                h2 {
                    margin-top: 20px;
                    font-size: 18px !important;
                    line-height: 1.3;
                    margin-bottom: 15px;

                    &:empty {
                        display: none;
                    }

                    @media (min-width: $screen-view-condition-min) {
                        font-size: 20px !important;
                    }
                }

                p {
                    font-family: $montreal-regular;
                    font-size: 16px !important;
                    margin-bottom: 0;

                    &:empty {
                        display: none;
                    }
                }
            }
        }
    }
}




video.mob-video-hidden {
    @media (max-width: 415px) {
        display: none;
    }
}

h2.events-title {
    font-size: calc(0.75117vw + 17.18px);
}

.eventsHrow div:nth-child(3n) {
    clear: left;
}

.eventsHome .event-container {
    margin-bottom: 30px;
}

@media only screen and (max-width: 415px) {
    .mobile-desktop-src-only {
        display: none;
    }
}

@media only screen and (min-width: 416px) {
    .mobile-video-only {
        display: none;
    }

    .mobile-desktop-src-only {
        display: inline-block;
    }
}

a#all-content-origin-tab-uid {
    display: none;
}

.txt-trans-none {
    text-transform: none;
}
/* offices project start */
.offices-address {
    h3:empty, h2:empty {
        display: none;
    }
}

.office-group {
    padding-left: 25px;

    .office-address, .office-contact {
        position: relative;

        .text {
            font-size: 16px;
            line-height: 1.5;
            font-family: $montreal-regular;
            color: $color-secondary-dark;
        }

        .office-contact .text span {
            color: #000
        }

        .icon {
            position: absolute;
            left: -25px;
            z-index: 1;
            color: #70777E;

            &.pointer {
                width: 15.78px;
                height: 17.94px;
                margin-top: 5px;
            }

            &.phone {
                width: 12.79px;
                margin-top: 3px;
            }
        }
    }
}

.icon-external-link {
    margin-top: -4px;
    margin-right: 15px;
}

.office-hr-box {
    position: absolute;
    width: 100%;
    z-index: 50;
    bottom: 0;
    left: 0;
    padding-left: 15px;
    padding-right: 15px;
    height: 2px;

    .office-hr-line {
        border-bottom: 1px solid #e4e4e6;
        width: 100%;
    }
}


.p-b-0 {
    padding-bottom: 0 !important
}

.p-b-1 {
    padding-bottom: 20px !important
}

.p-b-2 {
    padding-bottom: 40px !important
}

.p-t-0 {
    padding-top: 0 !important
}

.p-t-1 {
    padding-top: 20px !important
}

.p-t-2 {
    padding-top: 40px !important
}


.office-filters {
    .bg-lightgrey {
        background: #f4f4f4 !important
    }

    .text-normal {
        text-transform: none !important;
    }

    a {
        &.reset-link {
            color: #70777E !important;
            font-size: 13px !important;
            font-family: $montreal-regular;
            margin-bottom: 15px;
            line-height: 21px;

            &:hover, &:active {
                text-decoration: underline;
            }
        }
    }

    .office-form {
        label {
            margin-bottom: 1em;
            font-family: $montreal-medium !important;
            color: $color-secondary-dark;
            line-height: 1.625;
            display: block;
            font-size: 14px;
        }

        .ui-selectmenu-button {
            border-bottom: 1px solid #70777E !important;
            background-color: #fff;
            margin-bottom: 1em;
        }
    }
}

.m-b-50p {
    margin-bottom: 50px;
}

.m-b-30p {
    margin-bottom: 30px;
}

.m-b-14p {
    margin-bottom: 14px;
}

.news-title span.icn-arrow-med-blue {
    margin-left: 17px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5LjU5MSIgaGVpZ2h0PSIxNy4wNjEiIHZpZXdCb3g9IjAgMCA5LjU5MSAxNy4wNjEiPgogIDxwYXRoIGlkPSJpY24tYXJyb3ctc21hbGwtYmx1ZSIgZD0iTTgsMTYsMCw4LDMuMzMzLDQuNjY3LDgsMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoOC41MzEgMTYuNTMpIHJvdGF0ZSgxODApIiBmaWxsPSJub25lIiBzdHJva2U9IiNFMDJGMjgiIHN0cm9rZS13aWR0aD0iMS41Ii8+Cjwvc3ZnPgo=");
    width: 9.5px;
    height: 17.061px;
    background-repeat: no-repeat;
    display: inline-block;
    top: 1px;
    position: relative;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}


.news-title:hover span.icn-arrow-med-blue {
    margin-left: 21px;
}
/* offices project end */
/* New twitter icon fontawesome v6 - trello 372*/
@font-face {
    font-family: "Font Awesome 6 Brands";
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url(../fonts/fa-brands-400.woff2) format("woff2"),url(../fonts/fa-brands-400.ttf) format("truetype")
}

.fa-x-twitter::before {
    font-family: "Font Awesome 6 Brands";
    font-weight: 400;
    content: "\e61b";
}
/* New twitter icon fontawesome v6*/
.fa-youtube-btn::before {
    font-family: "Font Awesome 6 Brands";
    font-weight: 400;
    content: "\f167";
}
/* New Youtube icon fontawesome v6*/
/* career revamp */
.m-l-10p {
    margin-left: 10px;
}
/*For career job list component table - start*/
.career-result-link {

    &:hover, &:focus, &:active {
        text-decoration: none;

        span {
            &.icon.job-location {
                background-repeat: no-repeat;
                background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNi43OCIgaGVpZ2h0PSIxOS4wODkiIHZpZXdCb3g9IjAgMCAxNi43OCAxOS4wODkiPgogIDxnIGlkPSJpY24tbG9jYXRpb24iIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuNSAwLjUpIj4KICAgIDxwYXRoIGlkPSJUcmFjw6lfNiIgZGF0YS1uYW1lPSJUcmFjw6kgNiIgZD0iTTEzLjg5MywwQTcuNjI5LDcuNjI5LDAsMCwwLDYsNy4zMzhjMCw0LjExNiwzLjQ1Miw2LjkyOSw3Ljg5LDEwLjYsNC40MzktMy42NzQsNy44OS02LjQ4Nyw3Ljg5LTEwLjZBNy42MjksNy42MjksMCwwLDAsMTMuODkzLDBtMCwxMC41MjNhMy4xMzYsMy4xMzYsMCwwLDEtMy4yMTctMy4wNDlBMy4xMzYsMy4xMzYsMCwwLDEsMTMuODkzLDQuNDMsMy4xMzUsMy4xMzUsMCwwLDEsMTcuMTEsNy40NzdhMy4xMzUsMy4xMzUsMCwwLDEtMy4yMTcsMy4wNDkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC02LjAwMyAtMC4wMDIpIiBmaWxsPSJub25lIiBzdHJva2U9IiM2QzZFNzQiIHN0cm9rZS13aWR0aD0iMSIvPgogIDwvZz4KPC9zdmc+Cg==");
                width: 17px;
                height: 19.089px;
                border: 0 none;
                text-decoration: none;
            }
        }
    }

    h2 {

        &:hover, &:focus, &:active {
            color: $color-secondary;
            text-decoration: none;
        }
    }

    .text {
        &.text-locations {
            color: $color-secondary-dark;
            font-size: 14px;

            &.overflow-container, &.overflow-text-box {
                max-height: 22px;
                /* Set a maximum height for the container */
                overflow: hidden;
                /* Hide the overflow text */
                position: relative;

                .show-more, .show-more.isClosed {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    background-color: white;
                    padding: 0 5px;
                    cursor: pointer;
                    color: $color-secondary;
                    text-decoration: underline;

                    &:before {
                        content: "|";
                        position: absolute;
                        margin-left: -16px;
                        background: #fff;
                        width: 14px;
                        text-align: center;
                        color: #898C8F;
                    }
                }

                .show-more.isOpen {
                    position: relative;
                    background-color: white;
                    padding: 0 0 0 5px;
                    cursor: pointer;
                    display: inline-block;
                    color: $color-secondary;
                    text-decoration: underline;
                    margin-left: 10px;

                    &:before {
                        content: "|";
                        display: inline-block;
                        background: #fff;
                        width: 16px;
                        text-align: center;
                        color: #898C8F;
                    }
                }
            }
        }
    }

    span {
        &.icon.job-location {
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNi43OCIgaGVpZ2h0PSIxOS4wODkiIHZpZXdCb3g9IjAgMCAxNi43OCAxOS4wODkiPgogIDxnIGlkPSJpY24tbG9jYXRpb24iIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuNSAwLjUpIj4KICAgIDxwYXRoIGlkPSJUcmFjw6lfNiIgZGF0YS1uYW1lPSJUcmFjw6kgNiIgZD0iTTEzLjg5MywwQTcuNjI5LDcuNjI5LDAsMCwwLDYsNy4zMzhjMCw0LjExNiwzLjQ1Miw2LjkyOSw3Ljg5LDEwLjYsNC40MzktMy42NzQsNy44OS02LjQ4Nyw3Ljg5LTEwLjZBNy42MjksNy42MjksMCwwLDAsMTMuODkzLDBtMCwxMC41MjNhMy4xMzYsMy4xMzYsMCwwLDEtMy4yMTctMy4wNDlBMy4xMzYsMy4xMzYsMCwwLDEsMTMuODkzLDQuNDMsMy4xMzUsMy4xMzUsMCwwLDEsMTcuMTEsNy40NzdhMy4xMzUsMy4xMzUsMCwwLDEtMy4yMTcsMy4wNDkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC02LjAwMyAtMC4wMDIpIiBmaWxsPSJub25lIiBzdHJva2U9IiM2QzZFNzQiIHN0cm9rZS13aWR0aD0iMSIvPgogIDwvZz4KPC9zdmc+Cg==");
            width: 16.78px;
            height: 19.089px;
            border: 0 none;
            text-decoration: none;
        }
    }
}

.bg-secondary {
    .show-more, .show-more.isClosed {
        background-color: #f2f2f2 !important;

        &:before {
            background: #f2f2f2 !important;
        }
    }

    .show-more.isOpen {
        background-color: #f2f2f2 !important;

        &:before {
            background: #f2f2f2 !important;
        }
    }
}

/*.home-gradient-bg,*/ .secondary-3-column {
    .show-more, .show-more.isClosed {
        background-color: $color-secondary-light-gray !important;

        &:before {
            background: $color-secondary-light-gray !important;
        }
    }

    .show-more.isOpen {
        background-color: $color-secondary-light-gray !important;

        &:before {
            background: $color-secondary-light-gray !important;
        }
    }
}

.light-blue-bg {
    .show-more, .show-more.isClosed {
        background-color: $color-secondary-medium-gray-on-dark-bg !important;

        &:before {
            background: $color-secondary-medium-gray-on-dark-bg !important;
        }
    }

    .show-more.isOpen {
        background-color: $color-secondary-medium-gray-on-dark-bg !important;

        &:before {
            background: $color-secondary-medium-gray-on-dark-bg !important;
        }
    }
}

.ft-content-wrapper {
    &.title-big {
        h2 {
            font-family: $montreal-bold !important;
            font-size: $font-size-39mn-48mx-h2 !important;
            line-height: 1.1;
        }
    }
}

.bg-dark {
    .show-more, .show-more.isClosed {
        background-color: $color-secondary-dark !important;

        &:before {
            background: $color-secondary-dark !important;
        }
    }

    .show-more.isOpen {
        background-color: $color-secondary-dark !important;

        &:before {
            background: $color-secondary-dark !important;
        }
    }
}
/*For career job list component table - end*/
/*For career job opportunities page - start*/
a {
    &.career-result-link {

        &:hover, &:focus, &:active {
            text-decoration: none;

            span {
                &.icon.job-location {
                    background-repeat: no-repeat;
                    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNi43OCIgaGVpZ2h0PSIxOS4wODkiIHZpZXdCb3g9IjAgMCAxNi43OCAxOS4wODkiPgogIDxnIGlkPSJpY24tbG9jYXRpb24iIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuNSAwLjUpIj4KICAgIDxwYXRoIGlkPSJUcmFjw6lfNiIgZGF0YS1uYW1lPSJUcmFjw6kgNiIgZD0iTTEzLjg5MywwQTcuNjI5LDcuNjI5LDAsMCwwLDYsNy4zMzhjMCw0LjExNiwzLjQ1Miw2LjkyOSw3Ljg5LDEwLjYsNC40MzktMy42NzQsNy44OS02LjQ4Nyw3Ljg5LTEwLjZBNy42MjksNy42MjksMCwwLDAsMTMuODkzLDBtMCwxMC41MjNhMy4xMzYsMy4xMzYsMCwwLDEtMy4yMTctMy4wNDlBMy4xMzYsMy4xMzYsMCwwLDEsMTMuODkzLDQuNDMsMy4xMzUsMy4xMzUsMCwwLDEsMTcuMTEsNy40NzdhMy4xMzUsMy4xMzUsMCwwLDEtMy4yMTcsMy4wNDkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC02LjAwMyAtMC4wMDIpIiBmaWxsPSJub25lIiBzdHJva2U9IiM2QzZFNzQiIHN0cm9rZS13aWR0aD0iMSIvPgogIDwvZz4KPC9zdmc+Cg==");
                    width: 17px;
                    height: 19.089px;
                    border: 0 none;
                    text-decoration: none;
                }
            }
        }

        h2 {

            &:hover, &:focus, &:active {
                color: $color-secondary;
                text-decoration: none;
            }
        }

        .text {
            &.text-locations {
                color: #6C6E74;
                font-size: 14px;

                &.overflow-container {
                    max-height: 22px;
                    /* Set a maximum height for the container */
                    overflow: hidden;
                    /* Hide the overflow text */
                    position: relative;

                    .show-more, .show-more.isClosed {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        background-color: white;
                        padding: 0 5px;
                        cursor: pointer;
                        color: $color-secondary;
                        text-decoration: underline;

                        &:before {
                            content: "|";
                            position: absolute;
                            margin-left: -16px;
                            background: #fff;
                            width: 14px;
                            text-align: center;
                            color: #898C8F;
                        }
                    }

                    .show-more.isOpen {
                        position: relative;
                        background-color: white;
                        padding: 0 0 0 5px;
                        cursor: pointer;
                        display: inline-block;
                        color: $color-secondary;
                        text-decoration: underline;
                        margin-left: 10px;

                        &:before {
                            content: "|";
                            display: inline-block;
                            background: #fff;
                            width: 16px;
                            text-align: center;
                            color: #898C8F;
                        }
                    }
                }
            }
        }

        span {
            &.icon.job-location {
                background-repeat: no-repeat;
                background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNi43OCIgaGVpZ2h0PSIxOS4wODkiIHZpZXdCb3g9IjAgMCAxNi43OCAxOS4wODkiPgogIDxnIGlkPSJpY24tbG9jYXRpb24iIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuNSAwLjUpIj4KICAgIDxwYXRoIGlkPSJUcmFjw6lfNiIgZGF0YS1uYW1lPSJUcmFjw6kgNiIgZD0iTTEzLjg5MywwQTcuNjI5LDcuNjI5LDAsMCwwLDYsNy4zMzhjMCw0LjExNiwzLjQ1Miw2LjkyOSw3Ljg5LDEwLjYsNC40MzktMy42NzQsNy44OS02LjQ4Nyw3Ljg5LTEwLjZBNy42MjksNy42MjksMCwwLDAsMTMuODkzLDBtMCwxMC41MjNhMy4xMzYsMy4xMzYsMCwwLDEtMy4yMTctMy4wNDlBMy4xMzYsMy4xMzYsMCwwLDEsMTMuODkzLDQuNDMsMy4xMzUsMy4xMzUsMCwwLDEsMTcuMTEsNy40NzdhMy4xMzUsMy4xMzUsMCwwLDEtMy4yMTcsMy4wNDkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC02LjAwMyAtMC4wMDIpIiBmaWxsPSJub25lIiBzdHJva2U9IiM2QzZFNzQiIHN0cm9rZS13aWR0aD0iMSIvPgogIDwvZz4KPC9zdmc+Cg==");
                width: 16.78px;
                height: 19.089px;
                border: 0 none;
                text-decoration: none;
            }
        }
    }
}
/*For career job opportunities page - end*/
/* custom checkbox in search filter list start */
/* The container */
.label-checkbox {
    position: relative;
    padding-left: 35px;
    padding-right: 0;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: $color-secondary-dark;
    font-family: $montreal-medium;

    &:hover {
        color: $color-secondary;
        text-decoration: none;
    }

    + span {
        color: $color-secondary-dark;
        font-family: $montreal-medium;
        font-size: 16px;
    }
}
/* Hide the browser's default checkbox */
.label-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: $color-white;
    border: 1px solid $color-secondary;
}
/* On mouse-over, add a border color */
.label-checkbox:hover input ~ .checkmark {
    border: 1px solid $color-secondary;
}
/* When the checkbox is checked, add a blue background */
.label-checkbox input:checked ~ .checkmark {
    background-color: $color-secondary;
    border: 1px solid $color-secondary;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
/* Show the checkmark when checked */
.label-checkbox input:checked ~ .checkmark:after {
    display: block;
}
/* Style the checkmark/indicator */
.label-checkbox .checkmark:after {
    left: 6px;
    top: 2px;
    width: 6px;
    height: 11px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
/* custom checkbox in search filter list end */
/* Job revamp Search filter and search result section start*/
.job-filter-search {
    .mobile-back-icon, .mob-filter-done {
        display: none;
    }

    @media (max-width:$screen-sm-max) {
        display: none;

        &.mobile-view {
            width: 100%;
            background: #fff;
            top: 60px;
            left: 0;
            right: 0;
            bottom: 0;
            position: fixed;
            z-index: 1000;
            padding-left: 4vw;
            padding-right: 4vw;
            padding-top: 4vw;
            padding-bottom: 4vw;
            overflow: auto;
            display: block;

            .job-search-accordion h5.accordion-title .toggle-arrow {
                right: calc(4vw + 20px);
            }

            .mobile-back-icon {
                display: inline-block;
                margin-right: 15px;
                margin-top: -4px;
            }

            .mob-filter-done {
                display: inline;
                margin-right: 25px;
            }

            #job-search-accordion1 {
                margin-top: 25px;
            }
        }
    }
}

.mobile-filter-icon {
    @media (max-width:$screen-sm-max) {
        width: 21.194px;
        height: 20.699px;
        border: 0 none;
        margin-top: -4px;
        cursor: pointer;
        margin-right: 10px;
    }

    @media (min-width:$screen-md-min) {
        display: none;
    }
}

#job-cta-component {
    @media (max-width:$screen-sm-max) {
        display: none;
    }
}

#job-cta-component-mobile {
    @media (min-width:$screen-md-min) {
        display: none;
    }
}

.mobile-p-0 {
    @media (max-width:$screen-sm-max) {
        padding-left: 0;
        padding-right: 0;
    }
}

.job-search-accordion {
    display: flex;
    flex-direction: column;
}

.job-search-filter-section {
    margin-bottom: 10px;
    /* Hide the section by default */
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    /* Add smooth transition for height change */
    max-height: 0;
    /* Initially hide the section */

    .checkbox-list {
        list-style: none;
        padding: 0;
        max-height: 210px;
        /* Show a maximum of 8 checkboxes at a time */
        overflow-y: auto;
        /* Add scrollbar for overflow */
        scrollbar-width: thin;
        /* Firefox */
        scrollbar-color: #898C8F #f1f1f1;
        /*scrollbar-color: #F2F2F2 #898C8F;*/
        /* Firefox */

        li {
            margin-bottom: 15px;

            &:first-child {
                margin-top: 5px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            label {
                display: inline;
                word-break: break-all;
            }
        }
    }
}

.job-search-filter-section .checkbox-list::-webkit-scrollbar {
    width: 8px;
    /* Chrome/Safari/Webkit */
}

.job-search-filter-section .checkbox-list::-webkit-scrollbar-thumb {
    background-color: #ccc;
    /* Handle color */
    border-radius: 4px;
}

.job-search-accordion h5.accordion-title {
    cursor: pointer;
    padding: 20px;
    padding-right: 38px;
    margin: 0;
    background-color: $color-secondary-lightest;
    font-weight: normal;
    font-family: $montreal-medium;
    font-size: 16px;
    color: $color-secondary-dark;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.job-search-accordion h5.accordion-title .toggle-arrow {
    /*transition: transform 0.3s ease-out;*/
    background-repeat: no-repeat;
    width: 16px;
    height: 10px;
    position: absolute;
    right: 20px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNS43MDciIGhlaWdodD0iOC41NjEiIHZpZXdCb3g9IjAgMCAxNS43MDcgOC41NjEiPgogIDxwYXRoIGlkPSJpY25fYXJyb3dfZmlsdGVyX2Rvd24iIGRhdGEtbmFtZT0iaWNuIGFycm93IGZpbHRlciBkb3duIiBkPSJNNy41LDE1LDAsNy41LDMuMTI1LDQuMzc1LDcuNSwwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjM1NCA3Ljg1NCkgcm90YXRlKC05MCkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI0UwMkYyOCIgc3Ryb2tlLXdpZHRoPSIxIi8+Cjwvc3ZnPgo=");
}

.job-search-accordion.open h5.accordion-title .toggle-arrow {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNS43MDciIGhlaWdodD0iOC41NjEiIHZpZXdCb3g9IjAgMCAxNS43MDcgOC41NjEiPgogIDxwYXRoIGlkPSJpY25fYXJyb3dfZmlsdGVyX3VwIiBkYXRhLW5hbWU9ImljbiBhcnJvdyBmaWx0ZXIgdXAiIGQ9Ik03LjUsMTUsMCw3LjUsMy4xMjUsNC4zNzUsNy41LDAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE1LjM1NCAwLjcwNykgcm90YXRlKDkwKSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjRTAyRjI4IiBzdHJva2Utd2lkdGg9IjEiLz4KPC9zdmc+Cg==");
    /* Rotate arrow when accordion is open */
}


.job-location-search-bar {
    margin-bottom: 20px;
    width: 100%;
    border-bottom: 1px solid $color-secondary-lightest;
    position: relative;
}

.job-location-search-input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}

.job-search-icon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNy44NDIiIGhlaWdodD0iMTguNDQ3IiB2aWV3Qm94PSIwIDAgMTcuODQyIDE4LjQ0NyI+CiAgPGcgaWQ9Imljbl9zZWFyY2hfZmlsdGVyX3NlYXJjaF9iYXJfaW5hY3RpdmUiIGRhdGEtbmFtZT0iaWNuIHNlYXJjaCBmaWx0ZXIgc2VhcmNoIGJhciBpbmFjdGl2ZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC41IDAuNSkiPgogICAgPGNpcmNsZSBpZD0iRWxsaXBzZV8xIiBkYXRhLW5hbWU9IkVsbGlwc2UgMSIgY3g9IjcuMzA3IiBjeT0iNy4zMDciIHI9IjcuMzA3IiBmaWxsPSJub25lIiBzdHJva2U9IiM4OThjOGYiIHN0cm9rZS13aWR0aD0iMSIvPgogICAgPGxpbmUgaWQ9IkxpZ25lXzEiIGRhdGEtbmFtZT0iTGlnbmUgMSIgeDI9IjQuODc3IiB5Mj0iNC45NjciIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEyLjEwOCAxMi42MjkpIiBmaWxsPSJub25lIiBzdHJva2U9IiM4OThjOGYiIHN0cm9rZS13aWR0aD0iMSIvPgogIDwvZz4KPC9zdmc+Cg==");
    background-repeat: no-repeat;
}

.job-search-clear-icon {
    position: absolute;
    top: 50%;
    right: 21px;
    transform: translateY(-50%);
    cursor: pointer;
    display: none;
    width: 18px;
    height: 18px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNS43MDciIGhlaWdodD0iMTUuNzA3IiB2aWV3Qm94PSIwIDAgMTUuNzA3IDE1LjcwNyI+CiAgPGcgaWQ9Imljbl9jbG9zZV9maWx0ZXJfc2VhcmNoX2JhciIgZGF0YS1uYW1lPSJpY24gY2xvc2UgZmlsdGVyIHNlYXJjaCBiYXIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMzU0IDAuMzU0KSI+CiAgICA8bGluZSBpZD0iTGlnbmVfNzUiIGRhdGEtbmFtZT0iTGlnbmUgNzUiIHgyPSIxNSIgeTI9IjE1IiBmaWxsPSJub25lIiBzdHJva2U9IiNFMDJGMjgiIHN0cm9rZS13aWR0aD0iMSIvPgogICAgPGxpbmUgaWQ9IkxpZ25lXzc2IiBkYXRhLW5hbWU9IkxpZ25lIDc2IiB4MT0iMTUiIHkyPSIxNSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjRTAyRjI4IiBzdHJva2Utd2lkdGg9IjEiLz4KICA8L2c+Cjwvc3ZnPgo=");
    background-repeat: no-repeat;
}


span.job-search-clear-icon[style*="display: block;"] + span.job-search-icon {
    display: none;
}

input[type="text"].form-control-input:focus {
    padding: 0;
}

.form-control-input {
    background-color: $color-white;
    border: 0 none;
    color: $color-secondary-dark;
    width: 100%;
    height: 45px;
    font-size: 14px;
    font-family: $montreal-regular;

    &::placeholder {
        color: #6C6E74;
    }
}

.no-records {
    color: #6C6E74;
    font-size: 14px;
    font-family: $montreal-regular;
}
/* Job revamp Search filter and search result section end*/
/* job revamp - widget start */

.widget-container {
    border-bottom: 1px solid #e4e4e6;
    margin-bottom: 36px;
    padding-bottom: 24px;

    &:empty {
        display: none;
    }

    .widget {
        display: inline-block;
        padding: 5px;
        margin: 5px;
        border-radius: 5px;
        position: relative;

        span {
            &.close-button {
                background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj4KICA8ZyBpZD0iYnRuX2NoZWNrYm94X2Nsb3NlIiBkYXRhLW5hbWU9ImJ0biBjaGVja2JveCBjbG9zZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE1OTIgLTI5MjIuMTQ3KSI+CiAgICA8ZyBpZD0iUmVjdGFuZ2xlXzUyMiIgZGF0YS1uYW1lPSJSZWN0YW5nbGUgNTIyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxNTkyIDI5MjIuMTQ3KSIgZmlsbD0iI0UwMkYyOCIgc3Ryb2tlPSIjRTAyRjI4IiBzdHJva2Utd2lkdGg9IjEiPgogICAgICA8cmVjdCB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHN0cm9rZT0ibm9uZSIvPgogICAgICA8cmVjdCB4PSIwLjUiIHk9IjAuNSIgd2lkdGg9IjE5IiBoZWlnaHQ9IjE5IiBmaWxsPSJub25lIi8+CiAgICA8L2c+CiAgICA8bGluZSBpZD0iTGlnbmVfMjA1IiBkYXRhLW5hbWU9IkxpZ25lIDIwNSIgeDE9IjgiIHkyPSI4IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxNTk4IDI5MjguNSkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIxIi8+CiAgICA8bGluZSBpZD0iTGlnbmVfMjA2IiBkYXRhLW5hbWU9IkxpZ25lIDIwNiIgeDI9IjgiIHkyPSI4IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxNTk4IDI5MjguNSkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIxIi8+CiAgPC9nPgo8L3N2Zz4K");
                background-repeat: no-repeat;
                width: 20px;
                display: inline-block;
                height: 20px;
                padding: 0;
                position: absolute;
                border: 0;
                cursor: pointer;
            }
        }

        label {
            font-family: $montreal-regular;
            color: $color-secondary-dark;
            font-size: 14px;
            margin-left: 30px;
        }
    }
}
/* job revamp - widget end */
/* job revamp - pagination start */
.job-pagination-box {
    display: flex;
    justify-content: right;

    @media (max-width:991px) {
        justify-content: center;
    }

    .pagination {
        display: table-row;
        text-align: center;

        .pagesuspend {
            display: table-cell;
            padding: 0;
            vertical-align: top;
            height: auto;

            a {
                width: 50px;
                font-family: $montreal-regular;
                color: #898C8F;
                font-size: 14px;
                background: none;
                display: inline-block;
                border-bottom: 1px solid #D9D9D9;
                cursor: default;

                &:hover, &:focus {
                    text-decoration: none;
                }
            }
        }

        .pagelist {
            display: table-cell;
            padding: 0;
            vertical-align: top;

            &.prv-arw {
                a {
                    padding-left: 0;
                    width: 25px !important;
                }
            }

            &.nxt-arw {
                a {
                    padding-right: 0;
                    width: 25px !important;
                }
            }

            &.current {
                a {
                    &.pageprev, &.pagenext {
                        font-family: $montreal-bold;
                        color: $color-secondary;
                        font-size: 14px;
                        border-bottom: 1px solid $color-secondary;
                    }
                }
            }

            a {

                &.pageprev, &.pagenext {
                    width: 50px;
                    font-family: $montreal-regular;
                    color: $color-secondary-dark;
                    font-size: 14px;
                    background: none;
                    display: inline-block;
                    border-bottom: 1px solid #D9D9D9;

                    @media (max-width:450px) {
                        width: 44px;
                    }

                    &:hover, &:focus {
                        color: $color-secondary;
                        text-decoration: none;
                    }

                    span {
                        height: inherit;
                        padding-top: 10px;
                        display: table-cell;
                        vertical-align: top;

                        &:before {
                            background: #898C8F;
                        }

                        &:after {
                            background: #898C8F;
                        }

                        &:hover:before, &:focus:before, &:hover:after, &:focus:after {
                            background: $color-secondary;
                        }
                    }
                }
            }
        }
    }
}

#spinner-animation-wrapper {
    display: none;

    .spinner-box {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10000;
        display: flex;
        align-items: center;
        justify-content: center;
        content: "";
        opacity: .7;
        height: 100%;
        width: 100%;
        transition: all .5s ease;
        background: $color-secondary-dark;

        .spinner-bg {
            border-radius: 6px;
            box-shadow: 0 4px 10px #0000004d,0 4px 4px -2px #0000001f;
            padding: 16px;
            background-color: #434243a1;
            margin: 0;
            border: 0;
            box-sizing: border-box;

            .spinner-animation {
                display: block;
                height: 48px;
                padding: 2px;
                width: 48px;
                color: #D9D9D9;
            }
        }
    }
}

.job-search-container {
    .btn {
        padding: 14.5px 20px !important;
    }
    /* Target only Firefox */
    @supports (-moz-appearance: none) {
        .btn {
            padding: 15px 20px !important; /* Specific padding for Firefox */
        }
    }

    .job-search {


        input.form-control {
            background: $color-white;
            color: $color-secondary-dark;
            font-family: $montreal-regular;
            font-size: 16px;
            /*line-height: 21px;*/
            line-height: 1.42857;
            border: 0 none;
            height: 100%;
            padding: 16px 20px;

            &::placeholder {
                color: #6C6E74;
                opacity: 1; /* Firefox */
            }

            &::-ms-input-placeholder { /*  Edge 12 -18 */
                color: #6C6E74;
            }
        }
    }

    button.btn {
        margin: 0;

        @media (min-width:768px) {
            margin-left: 15px;
        }
    }
}

.m-b-10p {
    margin-bottom: 10px;
}
/* job revamp - pagination end */
/* Office search - start */
.office-search-container {
    .office-search {
        input {
            &.form-control {
                background: $color-white;
                color: $color-secondary-dark;
                font-family: $montreal-regular;
                font-size: 16px;
                line-height: 21px;
                border: 0 none;

                &::placeholder {
                    color: #6C6E74;
                    opacity: 1; /* Firefox */
                }

                &::-ms-input-placeholder { /* Edge 12 -18 */
                    color: #6C6E74;
                }
            }
        }

        button {
            &.btn {
                margin: 0 !important;
                padding: 11px !important;
                border: 0 none !important;
                background: #fff !important;
                height: 42px;
                overflow: hidden;

                &:before, &:after {
                    display: none !important;
                }

                &:hover {
                }

                span {
                    color: $color-secondary-dark
                }
            }
        }
    }
}




/* Office search - end */
/*495 - Megamenu optimization - start */
@media (min-width: $screen-lg-min) {
    .main-nav div.sub-nav.sub-nav-box.level-0:not(.isHidden) {
        z-index: 1000;
    }

    .overlayScreen::before {
        background: $color-secondary-dark;
        opacity: .7;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 201;
    }

    .main-header {
        position: relative;
    }

    .mn__list .mn__item {
        margin-right: 0;
    }

    .mn__list a.mn__link.level-0 {
        padding-left: 16px;
        padding-right: 16px;
    }

    .secondary-nav .sn__item {
        margin: 0;
    }

    .country-language-selector {
        height: 100%
    }

    .country-language-selector .cls__btn:after {
        display: inline-block;
    }

    .h-auto {
        height: auto !important;
    }

    .h-auto.isHidden {
        height: 0 !important
    }

    .country-language-selector .cls__subnav.isHidden {
        height: 0;
    }

    .country-language-selector .cls__subnav {
        transition: height .5s ease-out;

        & > .row {
            padding-top: 15px;
        }
    }
}
/* 495 - Megamenu optimization - end */
@media (min-width: 1500px) {
    .country-language-selector .cls__subnav {
        transition: height .5s ease-out;

        & > .row {
            padding-top: 20px;
        }
    }
}

@media (min-width: 1600px) {
    .country-language-selector .cls__subnav {
        transition: height .5s ease-out;

        & > .row {
            padding-top: 25px;
        }
    }
}

@media (min-width: 1800px) {
    .country-language-selector .cls__subnav {
        transition: height .5s ease-out;

        & > .row {
            padding-top: 48px;
        }
    }
}


.mn__list a.mn__link.level-0 {
    text-transform: lowercase;

    &::first-letter {
        text-transform: uppercase;
    }
}
/*Audio component css - start */
.holder .frame {
    position: relative;
}

.holder .frame .js-data-gdpr-not-available-placeholder {
    position: absolute;
    padding: 10px;
    width: 100%;
    font-size: 1.125rem;
    font-weight: bold;
    color: #777;
    cursor: pointer;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #eeeeee;
    z-index: 1;
}

.holder .frame .gdpr-info-box svg {
    text-align: center;
    margin-bottom: 0;
    opacity: 0.5;
    margin-top: 10px;
}

.holder .frame .gdpr-info-box p.title {
    color: $color-secondary-dark;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    font-family: $montreal-bold;
    margin-bottom: 10px;
    line-height: 20px;
}

.holder .frame .gdpr-info-box p.desc {
    color: $color-secondary-dark;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    font-family: $montreal-regular;
    margin-bottom: 15px;
    opacity: 0.8;
}


@media (max-width: 650px) {
    .holder .frame .gdpr-info-box svg {
        display: none
    }

    .holder .frame .gdpr-info-box p.title {
        font-size: 15px;
        line-height: 20px;
    }

    .holder .frame .gdpr-info-box p.desc {
        margin-bottom: 10px;
    }

    .holder .frame .gdpr-info-box a.btn.btn-tertiary {
        padding: 10px 15px;
        font-size: $btn-fonts-size;
    }
}
/*Audio component css - end */
/* carrerjoblist.cshtml - start */
.m-t20-b10 {
    margin-top: 20px;
    margin-bottom: 10px;
}

.job-search-results.latest-news {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.job-head-container h2 {
    color: $color-dark-red;
    font-family: $montreal-medium-italic;
    font-size: calc(.75117vw + 17.18px);
    text-transform: none;
}

.job-head-container p {
    font-family: $montreal-regular;
    font-size: $font-size-16mn-20mx;
    text-transform: none;
}

.overflow-text-box {
    max-height: 22px;
    overflow: hidden;
    position: relative;
}
/* carrerjoblist.cshtml - end */
/* Stock information pricehistory title - start */
.title-pricehistory {
    font-size: 2em;
    color: $color-secondary-dark;
    font-family: $montreal-bold;
}
/* Stock information pricehistory title - end */
/* Error 404 - start */
.box-404 {
    border: 1px solid #e4e4e6
}

.error-desc-font {
    font-family: $montreal-medium;
    font-size: calc(.18779vw + 17.3px);
}
/* Error 404 - end */


/* Press release detail Page - start */
.pressdtlcnt strong {
    font-family: $montreal-medium;

    & + br {
        display: block;
        margin-bottom: 10px;
    }
}

.pressdtlcnt li {
    padding-bottom: 20px;
}

.pressdtlcnt li strong {
    font-weight: 400;
    line-height: 1.3;
    font-family: $montreal-regular;
    font-size: $font-size-16mn-20mx;
    display: inline;
    margin-bottom: 15px;
}

.pressdtlcnt li strong + ul li:last-child {
    padding-bottom: 0;
}

.pressdtlcnt li strong + ul li:last-child strong {
    margin-bottom: 0;
}

.pressdtlcnt a {
    @extend .para-anchor-links;
}
/* Press release detail Page - start */
/* Press release landing page - start */
.pressResultList .press-suptitle {
    font-size: 14px;
    color: $color-secondary-dark;
    line-height: 1.65;
    margin: 0 0 3px;
    font-family: $montreal-medium;
}
/* Press release landing page - end */


*:focus,
a:focus,
button:focus {
    outline: 0 !important;
}

a:not(.btn):focus {
    outline: 0;
}
/*new 660 - start */


.bg-secondary {
    .btn-tertiary {
        border: 1px solid $bg-btn-dark-grey;
    }
}

.bg-dark, .bg-secondary-grey80 {

    .btn-secondary {
        color: $color-secondary-dark;
    }

    .btn-tertiary {
        background: linear-gradient(to bottom, $bg-btn-grey-on-dark-bg 50%, $bg-btn-dark-grey-on-dark-bg 50%);
        background-size: 100% 200%;
        background-position: 0 0;
        transition: background-position 0.2s ease-in-out;

        &:hover,
        &:active {
            background-position: 0 100%;
        }
    }

    .btn-default {
        color: $color-white;
        background: linear-gradient(to bottom, $color-select-menu-hover-active 50%, $color-select-menu-hover-active-on 50%);
        background-size: 100% 200%;
        background-position: 0 0;
        transition: background-position 0.2s ease-in-out;

        &:hover,
        &:active {
            color: $color-white;
            background-position: 0 100%;
        }

        &:after,
        &:before {
            background: $color-secondary;
        }

        &.open {
            background: $color-select-menu-hover-active-on;
        }
    }

    .multi-list-view {
        .mlv__link {
            border: 1px solid $color-dark-primary;
            background: linear-gradient(to bottom, transparent 50%, $color-dark-primary 50%);
            background-size: 100% calc(200% + 10px);
            background-position: 0 0;
            transition: background-position 0.2s ease-in-out;


            &:hover,
            &:active {
                background-position: 0 100%;
            }
        }
    }
}
/*new 660 - end */
/* Rebranding Naviation CSS - start */
.main-header {
    &.bg-dark-header {
        background: $color-navigation-dark;

        .big-padding {
            background-color: $color-navigation-dark;
            border-bottom: 1px solid $divider-grey-line-on-dark-bg;

            @media (min-width: $screen-view-condition-min) {
                &::before {
                    border-bottom: 1px solid $divider-grey-line-on-dark-bg;
                }
            }

            .desktop-header {
                @media (min-width: $screen-lg-min) {
                    background-color: $color-navigation-dark;
                }

                .main-nav {
                    .mn__list li a.mn__link.level-0 {
                        color: $color-white;

                        &:hover {
                            color: $color-light-grey;
                        }

                        &:active {
                            color: $color-white;
                        }
                    }
                }

                .secondary-nav {

                    @media screen and (min-width: $screen-lg-min) {
                        .Ir-Nav ul.mn__list.level-0 > li.mn__item.level-0 a.mn__link.level-0, .Ir-Nav ul.mn__list.level-0 > li.mn__item.level-0 a.mn__link.level-0.targetIsHidden {
                            color: $color-white;

                            &:hover {
                                color: $color-light-grey;
                            }

                            &:active {
                                color: $color-white;
                            }
                        }

                        .Ir-Nav ul.mn__list.level-0 > li.mn__item.level-0 a.mn__link.level-0::before {
                            background-color: $color-dark-red;
                        }
                    }

                    @media (min-width: $screen-lg-min) {
                        border-left: 1px solid $divider-grey-line-on-dark-bg;
                    }
                }

                .main-search {
                    .a-search2-icon {
                        stroke: $color-white;

                        &:hover, &:active {
                            stroke: $color-light-red;
                        }
                    }
                }

                .cl-a {
                    stroke: $color-white;
                }



                .country-language-selector .cls__btn.targetIsShown svg .cl-a {
                    stroke: $color-light-red;
                }

                @media (min-width: $screen-lg-min) {
                    .language-link a {
                        color: $color-white;

                        &:hover {
                            color: $color-light-grey;
                        }

                        &:active {
                            color: $color-white;
                        }
                    }
                }
                /* mobile Nav dark theme colors starts here */
                .main-nav.hidden-lg {
                    @media (max-width: $screen-md-max) {
                        .mn__list {

                            a {
                                color: $text-color;

                                &.mn__link.level-0 {


                                    &.targetIsShown, &:focus, &.active {
                                        text-decoration: none;
                                    }

                                    &:hover {
                                        text-decoration: none;
                                    }
                                }

                                &.targetIsShown, &.active, &:focus, &:hover {
                                    text-decoration: none;
                                }
                            }
                        }


                        .mn__list li a.mn__link.level-0 {
                            color: $text-color;
                            text-decoration: none;

                            &:hover {
                                color: $color-primary;
                                text-decoration: none;
                            }

                            &:active {
                                color: $color-primary;
                                text-decoration: none;
                            }
                        }
                    }
                }
                /* mobile Nav dark theme colors ends here */



                @media (max-width: $screen-md-max) {
                    .country-language-selector .cls__btn.targetIsShown svg .cl-a {
                        stroke: $color-navigation-dark;
                    }

                    .country-language-selector {
                        .cl-a {
                            stroke: $color-navigation-dark;
                        }
                    }
                }
            }

            .mobile-nav {
                background-color: $color-navigation-dark;
                border-bottom: 1px solid #787271;

                .a-close1-icon, .a-close0-icon, .a-search2-mob-icon, .a-close-mob-nw-icon {
                    stroke: $color-white;
                }

                @media (max-width: $screen-md-max) {
                    .main-search::after {
                        border-right: 1px solid #787271;
                    }
                }
            }
            /*search start */
            .main-search--header {
                background-color: $color-navigation-dark;

                .a-close-icon {
                    stroke: $color-white;

                    &:hover, &:active {
                        stroke: $color-dark-red;
                    }
                }


                .CoveoSearchbox .CoveoOmnibox.magic-box .magic-box-input input {
                    color: $color-white;
                    background: $color-navigation-dark;

                    &::placeholder {
                        color: $color-light-grey !important;
                    }
                }

                .CoveoSearchbox .CoveoSearchButton {
                    background: $color-navigation-dark;
                    border-bottom: 1px solid $color-navigation-dark;
                }

                .coveo-search-button-svg {
                    color: $color-white;

                    &:hover, &:active {
                        color: $color-dark-red;
                    }
                }

                .CoveoSearchInterface {
                    background-color: $color-navigation-dark;
                    color: $color-white;
                }

                .magic-box .magic-box-input {
                    background: $color-navigation-dark;
                }

                .CoveoSearchbox .CoveoOmnibox.magic-box {
                    background: $color-navigation-dark;
                }

                .CoveoSearchbox .CoveoOmnibox.magic-box .magic-box-input input {
                    border-bottom: 1px solid $color-navigation-dark;
                }

                .CoveoSearchButton:hover .coveo-magnifier-circle-svg + path {
                    fill: $color-dark-red;
                }
            }
            /*search end */
        }


        @media (min-width: $screen-view-condition) {
            &.show-search .desktop-header:before {
                background: $color-navigation-dark;
            }
        }
    }
}
/* Rebrand Healthcare dark Header start */
.bg-dark-header {
    &.inner-nav-box {
        background: $color-secondary-dark;

        .inner-nav-div-left {

            h4 {
                a {
                    color: $color-white;
                    font-family: $montreal-bold;
                    text-transform: none;


                    &:hover {
                        color: $color-white;
                        text-decoration: underline;
                    }
                }
            }
        }

        .inner-nav-div-right {

            .main-nav a.mn__link.level-0 {
                color: $color-white;
                font-family: $montreal-regular;
                text-transform: none;
                text-decoration: none;

                &:hover {
                    color: $color-white;
                }

                &.active {
                    color: $color-white !important;
                }
            }
        }
    }
    /* Healthcare inner nav - mobile start */
    &.inner-nav-box-mobile {
        .container-fluid {
            background-color: $color-secondary-dark !important;

            .select-selected {
                color: $color-white;
                background-color: $color-secondary-dark;
                border: 0 none;
            }

            .select-items, .select-items div {
                color: $color-white;
            }

            .select-items {
                background-color: $color-secondary-dark;
            }

            .select-items div:hover, .same-as-selected {
                background-color: $color-select-menu-hover-active;
            }
        }
    }
    /* Healthcare inner nav - mobile end */
}
/* Rebrand dark Header end */

.what-we-do-carousel {
    .owl-nav {
        right: 4vw;
    }

    @media (max-width: $screen-xs-max) {
        padding-left: 4vw;

        .owl-stage {
            padding-left: 0 !important;
        }
    }
}

.teaser-summary {
    &.what-we-do {
        h2 {
            margin-bottom: 0;
            margin-top: 20px;

            @media (max-width: $screen-sm-max) {
                margin-bottom: 30px;
            }
        }

        h4.title-h4 a.caption {
            font-size: $font-size-20mn-25mx-h5;
            font-family: $montreal-bold;
            line-height: 1.3;
            color: $color-secondary-dark;

            &:hover, &:active {
                color: $color-secondary-medium-gray;
            }
        }

        .owl-stage {
            &.p-l-0 {
                padding-left: 0 !important;
            }
        }

        .vis-hidden {
            visibility: hidden;
        }

        .var-p-l-0 {
            @media (min-width: $screen-view-condition-min) {
                padding-left: 0;
            }
        }

        p {
            &.para-text {
                font-size: $font-size-20mn-25mx-h5;
            }
        }
    }



    &.whats-new {
        padding-top: clamp(32px, 5vw, 48px);
        padding-bottom: clamp(32px, 5vw, 48px);
        border: 1px solid #727171;
        border-left: none;
        border-right: none;
        margin-left: 0;
        margin-right: 0;

        @media (max-width: $screen-sm-max) {
            border: 0 none;
        }

        h2 {
            margin-bottom: clamp(32px, 5vw, 48px);
            margin-top: 0;
        }

        ul {
            list-style: none;

            li.item-row {
                padding-top: clamp(32px, 5vw, 48px);
                padding-bottom: clamp(24px, 5vw, 32px);

                &:first-child {
                    padding-top: 0;
                }

                &:not(:first-child) {
                    border-top: 1px solid #727171;
                }

                &:last-child {
                    padding-bottom: 0;
                }
            }

            &.content-box {
                display: flex;
                justify-content: space-between;
                align-items: end;




                li:first-child {
                    flex: 0 0 20%; /* Fixes width to 20% */
                    @media (max-width: $screen-sm-max) {
                        display: none;
                    }
                }

                li:nth-child(2) {
                    flex: 1; /* Fills the remaining space */
                }

                .caption {
                    text-transform: uppercase;
                    font-size: $font-size-14mn-16mx;
                    font-family: $montreal-medium;
                    margin-bottom: 10px;
                }

                p, span {

                    &.date {
                        color: $color-primary;
                        font-size: $font-size-20mn-25mx-h5;
                        font-family: $montreal-regular;

                        @media (max-width: $screen-md-max) {
                            font-family: $montreal-medium;
                        }
                    }
                }

                a {
                    font-size: $font-size-20mn-25mx-h5;
                    text-decoration: none;

                    @media (min-width: $screen-md-min) {

                        &.icn-r-arw {
                            display: block;
                            position: relative;
                            text-decoration: none;
                            padding-right: 30px; /* Space for the arrow icon */
                            background: url('data:image/svg+xml;base64,PHN2ZyBpZD0iaWNuX2Fycm93X2xpbmstd2hpdGUtc3ZnIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNS4wNjkiIGhlaWdodD0iMjIuNTM1IiB2aWV3Qm94PSIwIDAgMjUuMDY5IDIyLjUzNSI+PGcgaWQ9Imljbl9hcnJvd19saW5rLXdoaXRlIiBkYXRhLW5hbWU9ImljbiBhcnJvdyBsaW5rIHdoaXRlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtODczLjM4NyAtODQuNTI2KSI+PGcgaWQ9Ikdyb3VwZV8yMyIgZGF0YS1uYW1lPSJHcm91cGUgMjMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC04LjExMyAtNi40NjYpIj48cGF0aCBpZD0iaWNuLWFycm93LXdoaXRlIiBkPSJNMTAuOTA5LDIxLjQ2NiwwLDEwLjczMyw0LjU0Niw2LjI2MSwxMC45MDksMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoOTA1LjUgMTEyLjk5Mykgcm90YXRlKDE4MCkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzE0MTIxMiIgc3Ryb2tlLXdpZHRoPSIxLjUiLz48bGluZSBpZD0iTGlnbmVfODIiIGRhdGEtbmFtZT0iTGlnbmUgODIiIHgxPSIyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoODgxLjUgMTAyLjI0OSkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzE0MTIxMiIgc3Ryb2tlLXdpZHRoPSIxLjUiLz48L2c+PC9nPjwvc3ZnPg== ') no-repeat right bottom;
                            background-size: 25px 22px; /* Adjust size as per your needs */


                            &:hover {
                                background: url('data:image/svg+xml;base64,PHN2ZyBpZD0iaWNuX2Fycm93X2xpbmstd2hpdGUtc3ZnIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNS4wNjkiIGhlaWdodD0iMjIuNTM1IiB2aWV3Qm94PSIwIDAgMjUuMDY5IDIyLjUzNSI+PGcgaWQ9Imljbl9hcnJvd19saW5rLXdoaXRlIiBkYXRhLW5hbWU9ImljbiBhcnJvdyBsaW5rIHdoaXRlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtODczLjM4NyAtODQuNTI2KSI+PGcgaWQ9Ikdyb3VwZV8yMyIgZGF0YS1uYW1lPSJHcm91cGUgMjMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC04LjExMyAtNi40NjYpIj48cGF0aCBpZD0iaWNuLWFycm93LXdoaXRlIiBkPSJNMTAuOTA5LDIxLjQ2NiwwLDEwLjczMyw0LjU0Niw2LjI2MSwxMC45MDksMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoOTA1LjUgMTEyLjk5Mykgcm90YXRlKDE4MCkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmMzcyZiIgc3Ryb2tlLXdpZHRoPSIxLjUiLz48bGluZSBpZD0iTGlnbmVfODIiIGRhdGEtbmFtZT0iTGlnbmUgODIiIHgxPSIyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoODgxLjUgMTAyLjI0OSkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmMzcyZiIgc3Ryb2tlLXdpZHRoPSIxLjUiLz48L2c+PC9nPjwvc3ZnPg==') no-repeat right bottom;
                            }
                        }
                    }

                    &:hover, &:active {
                        text-decoration: none;
                    }
                }
            }
        }

        ul, li {
            padding: 0;
            margin: 0
        }

        li:before {
            content: '';
            position: relative;
            left: auto;
        }
    }


    div.para-text {
        h3 {
            font-size: $font-size-25mn-31mx-h4;
            margin-bottom: 10px;
        }

        h4 {
            font-size: $font-size-20mn-25mx-h5;
            margin-bottom: 10px;
        }
    }
}

.discover-wsp {
    .var-text {
        font-size: $font-size-25mn-31mx-h4;
        line-height: 1.4;
    }
}

.hom-key-facts {

    @media (max-width: $screen-sm-max) {
        display: none;
    }

    text-align: right;
    letter-spacing: 0;

    p {
        font-size: 16px;
        font-family: $montreal-medium;
        color: $text-color;
        margin-bottom: 10px;
    }

    .numbers {
        font-family: $montreal-medium;
        font-size: $font-size-75mn-95mx;
        line-height: 1;
        color: $color-dark-red;
        margin: 0;
        padding: 0;
    }

    .col-xs-12 {
        div:not(:first-child) {
            p {
                margin-top: 40px;
            }
        }
    }
}

.career-opp-box {
    .bg-img-opp {
        position: relative;
        background-size: cover;
        transition: all .3s ease-in-out;
        height: 100%;
        background-image: url(/images/img-homepage-careers.png);

        @media (max-width:$screen-sm-max) {
            background-image: none;
        }

        @media (min-width:$screen-md-min) {
            min-height: 350px;
            background-position: center;
        }

        @media (min-width:$screen-lg-min) {
            min-height: 380px;
            background-position: bottom right;
        }

        @media (min-width:$screen-view-condition-min) {
            min-height: 410px;
            background-position: bottom right;
        }
    }

    .yc-box {
        position: relative;
        height: 100%;
        overflow: visible;


        @media (max-width: $screen-sm-max) {
            display: none;
        }
    }

    .yc {
        width: 200px;
        position: relative;
        overflow: visible;
        margin-bottom: 5px;

        @media (min-width: $screen-md-min) {
            line-height: 50px;
        }

        @media (min-width: $screen-lg-min) {
            line-height: 60px;
        }

        @media (min-width: $screen-view-condition-min) {
            line-height: 50px;
        }
    }

    .repeat {
        width: 333.33%;
        text-align: right;
        overflow: visible;
        color: $color-white;
        text-transform: uppercase;
        white-space: nowrap;
        font-size: 60px;
        transform: translateX(-64%);
    }
}

.width-var {
    @media (min-width: $screen-md-min) {
        width: 66%;
    }

    @media (min-width: 1100px) {
        width: 65.5%;
    }

    @media (min-width: $screen-lg-min) {
        width: 65.33333%;
    }

    @media (min-width: 1600px) {
        width: 64.4%;
    }

    @media (min-width: 1650px) {
        width: 63.6%;
    }

    @media (min-width: 1900px) {
        width: 62%;
    }
}

.career-opp-box, .home-featured-carousel-text-box {
    .w-300 {
        @media (max-width:$screen-sm-max) {
            margin-top: 40px;
            padding-left: 4vw;
            padding-right: 4vw;
        }

        @media (min-width:$screen-md-min) {
            width: 325px;
        }

        @media (min-width:1050px) {
            width: 335px;
        }

        @media (min-width:$screen-lg-min) {
            width: 375px;
        }

        @media (min-width:1300px) {
            width: 400px;
        }

        @media (min-width:1400px) {
            width: 425px;
        }


        @media (min-width:$screen-view-condition-min) {
            width: 435px;
        }
    }
}

.home-featured-carousel-text-box {
    .w-300 {
        @media (max-width:$screen-sm-max) {
            margin-top: 0;
        }
    }
}

.home-featured-carousel-text-box {
    .teaser-summary {
        p {
            font-size: $font-size-20mn-25mx-h5;
        }
    }
}
/* Rebranding Naviation CSS - end */
h2 {
    &.title-h2-48 {
        font-family: $montreal-bold;
        color: $color-secondary-dark;
        font-size: $font-size-39mn-48mx-h2;
        line-height: 1.1;
    }
}


.bg-dark {
    background: $color-secondary-dark;
}

.bg-dark, .bg-secondary-grey80, .bg-black-white {


    h1, h2, h3, h4, h5, h6 {
        color: $color-white !important;
    }

    .btn, p {
        color: $color-white;
    }

    .btn-tertiary {
        background: linear-gradient(to bottom, $bg-btn-grey-on-dark-bg 50%, $bg-btn-dark-grey-on-dark-bg 50%);
        background-size: 100% 200%;
        background-position: 0 0;
        transition: background-position 0.2s ease-in-out;

        &:hover,
        &:active {
            color: $color-white;
            background-position: 0 100%;
        }
    }

    .btn-text {
        &:hover {
            color: $color-white;
        }
    }

    .discover-wsp {
        .var-text, .btn.btn-secondary {
            color: $color-white;
        }
    }

    .contact-bg {
        background-color: transparent;
    }

    .hom-key-facts {
        p {
            color: $color-white;
        }
    }

    .publications-content, .sector-content {
        .caption {
            color: $color-white;

            a {
                &.img-link {
                    color: $color-white;

                    &:hover, &:active {
                        text-decoration: underline;
                        color: $color-white;
                    }
                }
            }

            p {
                &.img-txt {
                    color: $color-white;
                }
            }
        }
    }

    .teaser-summary.whats-new {
        ul {
            &.content-box {
                .caption {
                    color: $color-white;
                }

                p, span {
                    &.date {
                        color: $color-primary;
                    }
                }

                a {
                    color: $color-white;

                    @media (min-width: $screen-md-min) {
                        &.icn-r-arw {
                            background: url('data:image/svg+xml;base64,PHN2ZyBpZD0iaWNuX2Fycm93X2xpbmstd2hpdGUtc3ZnIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNS4wNjkiIGhlaWdodD0iMjIuNTM1IiB2aWV3Qm94PSIwIDAgMjUuMDY5IDIyLjUzNSI+PGcgaWQ9Imljbl9hcnJvd19saW5rLXdoaXRlIiBkYXRhLW5hbWU9ImljbiBhcnJvdyBsaW5rIHdoaXRlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtODczLjM4NyAtODQuNTI2KSI+PGcgaWQ9Ikdyb3VwZV8yMyIgZGF0YS1uYW1lPSJHcm91cGUgMjMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC04LjExMyAtNi40NjYpIj48cGF0aCBpZD0iaWNuLWFycm93LXdoaXRlIiBkPSJNMTAuOTA5LDIxLjQ2NiwwLDEwLjczMyw0LjU0Niw2LjI2MSwxMC45MDksMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoOTA1LjUgMTEyLjk5Mykgcm90YXRlKDE4MCkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIxLjUiLz48bGluZSBpZD0iTGlnbmVfODIiIGRhdGEtbmFtZT0iTGlnbmUgODIiIHgxPSIyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoODgxLjUgMTAyLjI0OSkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIxLjUiLz48L2c+PC9nPjwvc3ZnPg==') no-repeat right bottom;

                            &:hover {
                                background: url('data:image/svg+xml;base64,PHN2ZyBpZD0iaWNuX2Fycm93X2xpbmstd2hpdGUtc3ZnIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNS4wNjkiIGhlaWdodD0iMjIuNTM1IiB2aWV3Qm94PSIwIDAgMjUuMDY5IDIyLjUzNSI+PGcgaWQ9Imljbl9hcnJvd19saW5rLXdoaXRlIiBkYXRhLW5hbWU9ImljbiBhcnJvdyBsaW5rIHdoaXRlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtODczLjM4NyAtODQuNTI2KSI+PGcgaWQ9Ikdyb3VwZV8yMyIgZGF0YS1uYW1lPSJHcm91cGUgMjMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC04LjExMyAtNi40NjYpIj48cGF0aCBpZD0iaWNuLWFycm93LXdoaXRlIiBkPSJNMTAuOTA5LDIxLjQ2NiwwLDEwLjczMyw0LjU0Niw2LjI2MSwxMC45MDksMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoOTA1LjUgMTEyLjk5Mykgcm90YXRlKDE4MCkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmMzcyZiIgc3Ryb2tlLXdpZHRoPSIxLjUiLz48bGluZSBpZD0iTGlnbmVfODIiIGRhdGEtbmFtZT0iTGlnbmUgODIiIHgxPSIyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoODgxLjUgMTAyLjI0OSkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmMzcyZiIgc3Ryb2tlLXdpZHRoPSIxLjUiLz48L2c+PC9nPjwvc3ZnPg==') no-repeat right bottom;
                            }
                        }
                    }

                    &:hover, &:active {
                        text-decoration: none;
                        color: $color-secondary-medium-gray-on-dark-bg;
                    }
                }
            }
        }
    }

    .teaser-summary.what-we-do {
        h4.title-h4 a.caption {
            color: $color-white;

            &:hover, &:active {
                text-decoration: underline;
            }
        }

        p {
            color: $color-white;
        }
    }

    .thumbnail + .caption a.img-link-bold {
        color: $color-white;

        &:hover, &:active {
            color: $color-secondary-medium-gray-on-dark-bg;
        }
    }

    .main-footer-bottom {
        @media (min-width: $screen-md-min) {
            border-top: 1px solid $divider-grey-line-on-dark-bg;
        }
    }

    & .modal-dialog * {
        color: initial; /* Reset text color for all children */
    }
}

.bg-dark, .bg-secondary-grey80 {
    .teaser-summary {
        div, p {
            color: $color-white;
        }

        a:not(.btn) {
            color: $color-white;
            text-decoration: underline;

            &:hover, &:active {
                color: $color-white;
                text-decoration: underline;
            }
        }

        div.para-text {
            div, p {
                color: $color-white;
            }

            a:not(.btn) {
                color: $color-white;
                text-decoration: underline;

                &:hover, &:active {
                    color: $color-white;
                    text-decoration: underline;
                }
            }
        }
    }


    div.para-text {
        div, p {
            color: $color-white;
        }

        a:not(.btn) {
            color: $color-white;
            text-decoration: underline;

            &:hover, &:active {
                color: $color-white;
                text-decoration: underline;
            }
        }
    }

    .breadcrumb > .active, .breadcrumb, li, ul, .breabcrumb li.active a, ol.breadcrumb li.active a, .large-title, .large-title p, .large-title span, .page-summary, .page-summary p, .key-number-group .key-number .keytitle, .publications-content div, .publications-content p, .sector-content div, .sector-content p {
        color: $color-white;
    }

    a:not(.btn) {
        color: $color-white;
        text-decoration: underline;

        &:hover, &:active {
            color: $color-white;
            text-decoration: underline;
        }
    }

    .caption-m, .date-m {
        color: $color-white !important;
    }

    .contact-card .contact-country img.icon.pointer {
        filter: brightness(0) invert(1);
    }

    .multi-list-view .mlv__link {
        color: $color-white;

        &:hover, &:active {
            text-decoration: none;
        }
    }

    .caption a {
        &:hover, &:active {
            text-decoration: none;
        }
    }

    & .modal-dialog * {
        color: initial; /* Reset text color for all children */
    }


    .modal-dialog {
        a.ss__link {
            text-decoration: none;
            color: $color-white;

            &:hover, &:active {
                color: $color-white;
                text-decoration: none;
            }
        }

        .btn-tertiary {
            span, span + .U--mb--10 {
                color: $color-white;
            }
        }
    }

    .modal-related-files {
        .modal-dialog {
            .modal-body {
                .multi-list-view {
                    .mlv__link {
                        border: 0 none;
                        border-bottom: 1px solid $color-primary;
                        background: linear-gradient(to bottom, $color-select-menu-hover-active 50%, $color-primary 50%);
                        background-size: 100% calc(200% + 10px);
                        background-position: 0 0;
                        transition: background-position 0.2s ease-in-out;

                        &:hover,
                        &:active {
                            background-position: 0 100%;
                        }

                        &:hover {

                            p, span {
                                color: $color-white;
                            }

                            .related-file-thumbnail {
                                path,
                                polygon {
                                    fill: $color-white;
                                }
                            }

                            svg {
                                path,
                                circle,
                                rect,
                                line {
                                    fill: none;
                                    stroke: $color-white;
                                    -webkit-transition: all 0.3s ease-in-out;
                                    -o-transition: all 0.3s ease-in-out;
                                    -moz-transition: all 0.3s ease-in-out;
                                    transition: all 0.3s ease-in-out;
                                }
                            }
                        }
                    }

                    p, span {
                        color: $color-white;
                    }
                }
            }
        }
    }
}



.bg-black-white {

    .btn {
        &.btn-secondary, &.btn-ghost, &.btn-text {
            color: $color-secondary-dark;

            &:hover,
            &:active {
                color: $color-white;
            }
        }
    }

    .block-grid-item .publications-content h2.text_2lines, .project-list h2.text_2lines {
        color: $color-secondary-dark !important;

        &:hover, &:active {
            color: $color-secondary-dark-gray !important;
        }
    }

    .carousel-nav-container .owl-prev, .owl-carousel .owl-prev {
        background: linear-gradient(to left,$color-primary 50%, transparent 50%);
        background-size: 200% 100%;
        background-position: 0 100%;
        transition: background-position 0.2s ease-in-out, color 0.2s ease-in-out;

        &:hover {
            background-position: 100% 0;
        }

        button {
            &:before {
                background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5LjA5MSIgaGVpZ2h0PSIxNi4wNjEiIHZpZXdCb3g9IjAgMCA5LjA5MSAxNi4wNjEiPgogIDxwYXRoIGlkPSJpY24tYXJyb3ctbWVkaXVtLXdoaXRlLXJpZ2h0IiBkPSJNNy41LDE1LDAsNy41LDMuMTI1LDQuMzc1LDcuNSwwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4LjAzIDE1LjUzKSByb3RhdGUoMTgwKSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmZmZmIiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8L3N2Zz4K");
            }

            &:hover {
                &:before {
                    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5LjA5MSIgaGVpZ2h0PSIxNi4wNjEiIHZpZXdCb3g9IjAgMCA5LjA5MSAxNi4wNjEiPgogIDxwYXRoIGlkPSJpY24tYXJyb3ctbWVkaXVtLXdoaXRlLXJpZ2h0IiBkPSJNNy41LDE1LDAsNy41LDMuMTI1LDQuMzc1LDcuNSwwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4LjAzIDE1LjUzKSByb3RhdGUoMTgwKSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmZmZmIiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8L3N2Zz4K")
                }
            }
        }
    }

    .carousel-nav-container .owl-next, .owl-carousel .owl-next {
        background: linear-gradient(to right,$color-primary 50%,transparent 50%);
        background-size: 200% 100%;
        background-position: 100% 0;
        transition: background-position 0.2s ease-in-out, color 0.2s ease-in-out;

        &:hover {
            background-position: 0 0;
        }


        button {
            &:before {
                background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5LjA5MSIgaGVpZ2h0PSIxNi4wNjEiIHZpZXdCb3g9IjAgMCA5LjA5MSAxNi4wNjEiPgogIDxwYXRoIGlkPSJpY24tYXJyb3ctbWVkaXVtLXdoaXRlLXJpZ2h0IiBkPSJNNy41LDE1LDAsNy41LDMuMTI1LDQuMzc1LDcuNSwwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4LjAzIDE1LjUzKSByb3RhdGUoMTgwKSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmZmZmIiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8L3N2Zz4K");
            }

            &:hover {
                &:before {
                    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5LjA5MSIgaGVpZ2h0PSIxNi4wNjEiIHZpZXdCb3g9IjAgMCA5LjA5MSAxNi4wNjEiPgogIDxwYXRoIGlkPSJpY24tYXJyb3ctbWVkaXVtLXdoaXRlLXJpZ2h0IiBkPSJNNy41LDE1LDAsNy41LDMuMTI1LDQuMzc1LDcuNSwwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4LjAzIDE1LjUzKSByb3RhdGUoMTgwKSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmZmZmIiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8L3N2Zz4K")
                }
            }
        }
    }

    .latest-news, .feature-project {
        .sub-text {
            color: $color-white;
        }
    }
}

.secondary-white {
    .carousel-nav-container .owl-prev, .owl-carousel .owl-prev {
        background: linear-gradient(to left,$color-primary 50%, transparent 50%);
        background-size: 200% 100%;
        background-position: 0 100%;
        transition: background-position 0.2s ease-in-out, color 0.2s ease-in-out;

        &:hover {
            background-position: 100% 0;
        }

        button {
            &:before {
                background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5LjA5MSIgaGVpZ2h0PSIxNi4wNjEiIHZpZXdCb3g9IjAgMCA5LjA5MSAxNi4wNjEiPgogIDxwYXRoIGlkPSJpY24tYXJyb3ctbWVkaXVtLXdoaXRlLXJpZ2h0IiBkPSJNNy41LDE1LDAsNy41LDMuMTI1LDQuMzc1LDcuNSwwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4LjAzIDE1LjUzKSByb3RhdGUoMTgwKSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMTQxMjEyIiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8L3N2Zz4K);
            }

            &:hover {
                &:before {
                    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5LjA5MSIgaGVpZ2h0PSIxNi4wNjEiIHZpZXdCb3g9IjAgMCA5LjA5MSAxNi4wNjEiPgogIDxwYXRoIGlkPSJpY24tYXJyb3ctbWVkaXVtLXdoaXRlLXJpZ2h0IiBkPSJNNy41LDE1LDAsNy41LDMuMTI1LDQuMzc1LDcuNSwwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4LjAzIDE1LjUzKSByb3RhdGUoMTgwKSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmZmZmIiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8L3N2Zz4K")
                }
            }
        }
    }

    .carousel-nav-container .owl-next, .owl-carousel .owl-next {
        background: linear-gradient(to right,$color-primary 50%,transparent 50%);
        background-size: 200% 100%;
        background-position: 100% 0;
        transition: background-position 0.2s ease-in-out, color 0.2s ease-in-out;

        &:hover {
            background-position: 0 0;
        }


        button {
            &:before {
                background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5LjA5MSIgaGVpZ2h0PSIxNi4wNjEiIHZpZXdCb3g9IjAgMCA5LjA5MSAxNi4wNjEiPgogIDxwYXRoIGlkPSJpY24tYXJyb3ctbWVkaXVtLXdoaXRlLXJpZ2h0IiBkPSJNNy41LDE1LDAsNy41LDMuMTI1LDQuMzc1LDcuNSwwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4LjAzIDE1LjUzKSByb3RhdGUoMTgwKSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMTQxMjEyIiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8L3N2Zz4K);
            }

            &:hover {
                &:before {
                    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5LjA5MSIgaGVpZ2h0PSIxNi4wNjEiIHZpZXdCb3g9IjAgMCA5LjA5MSAxNi4wNjEiPgogIDxwYXRoIGlkPSJpY24tYXJyb3ctbWVkaXVtLXdoaXRlLXJpZ2h0IiBkPSJNNy41LDE1LDAsNy41LDMuMTI1LDQuMzc1LDcuNSwwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4LjAzIDE1LjUzKSByb3RhdGUoMTgwKSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmZmZmIiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8L3N2Zz4K")
                }
            }
        }
    }
}

.resp-2-col {
    display: flex;
    justify-content: space-between;
    align-items: center;


    .left {
        flex: 1;
    }

    .right {
        text-align: right;
    }


    @media (max-width: $screen-sm-max) {
        flex-direction: column;
        align-items: flex-start;


        .right {
            margin-top: 15px;
            width: 100%;
        }
    }
}

.rebrand-bnr {

    @media (min-width: $screen-lg-min) {
        padding-left: 4vw;
        padding-right: 4vw;
    }

    @media (min-width: $screen-view-condition-min) {
        padding-left: 0 !important;
        padding-right: 0 !important;
        max-width: 1366px;
        margin: auto !important;
    }

    header {
        &.page-header {
            &.banner-video {
                .teaser-summary {
                    h1, h2 {
                        font-size: clamp(40px, calc(40px + (120 - 40) * ((100vw - 320px) / (1920 - 320))), 120px);
                        line-height: 1.1;
                    }

                    &.dark-theme {
                        h1, h2 {
                            color: $color-white;
                        }
                    }
                }
            }
        }
    }
}
/* add below css to show insight image in bigger size on homepage - centered instead of rectangular during homepage rebrand*/
.publications-content {
    .block-grid-item {
        .thumbnail.var-img {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            position: relative;
            overflow: hidden;
        }
    }
}
/* Rebrand Margins // start */
.m-t-48d-32m {
    margin-top: clamp(32px, 5vw, 48px) !important;
}

.m-b-48d-32m {
    margin-bottom: clamp(32px, 5vw, 48px) !important;
}

.m-b-96d-80m {
    margin-bottom: clamp(80px, 5vw, 96px) !important;
}

.m-t-96d-80m {
    margin-top: clamp(80px, 5vw, 96px) !important;
}

.m-b-72d-48m {
    margin-bottom: clamp(48px, 5vw, 72px) !important;
}

.m-t-120d-80m {
    margin-top: clamp(80px, 5vw, 120px) !important;
}

.m-b-120d-80m {
    margin-bottom: clamp(80px, 5vw, 120px) !important;
}

.m-b-m80 {
    @media (max-width: $screen-sm-max) {
        margin-bottom: 80px;
    }
}

.m-t-m20 {
    @media (max-width: $screen-sm-max) {
        margin-top: 20px;
    }
}

.m-t-m32 {
    @media (max-width: $screen-sm-max) {
        margin-top: 32px;
    }
}

.m-b-m0 {
    @media (max-width: $screen-sm-max) {
        margin-bottom: 0;
    }
}
/* Rebrand Margins // end */
.para-text:empty {
    display: none;
}
/*Global animation for anchor - start */
a:not(.btn) {
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.esg-docs {
    a {
        font-size: 15px;
        font-family: $montreal-medium;
    }
}
