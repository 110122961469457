.modal {
    &-dialog {
    }

    &-content {
    }

    &-header {
        .close {
            float: right;
        }
    }
}
