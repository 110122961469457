﻿.affix-top {
    position: relative;
}

/* Fix Bootstrap issue AFFIX github.com/twbs/bootstrap/issues/11006 */
.affix {
    bottom: 0;
    position: fixed !important;
}

.affix,
.affix-bottom {
    z-index: 2;
    width: 100%;
}

.affix-bottom {
    position: absolute;
}
