hr {
    margin: 50px 4vw;
    /*margin: 70px 4vw;*/
    border-top-color: $color-secondary-light;

    @media (max-width: $screen-xs-min) {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    @media (min-width: $screen-xs-min) {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    @media (min-width: $screen-sm-min) {
        margin-top: 35px;
        margin-bottom: 35px;
    }

    @media (min-width: $screen-md-min) {
        margin-top: 45px;
        margin-bottom: 45px;
    }

    @media (min-width: $screen-lg-min) {
        margin-top: 55px;
        margin-bottom: 55px;
    }
    /* 6318 - Website display in different screen resolutions */
    @media (min-width: $screen-view-condition-min) {
        margin: 55px auto;
        max-width: $screen-laptop-min;
    }

    .pagemode-edit & {
        /* fix 5979 - Sitecore - It Is Very Difficult To Click On A Divider In The Experience Editor*/
        border-top-width: 10px;
    }
}

hr.insight {
    margin-top: 20px;
    margin-bottom: 20px;
}

div[class^="col-"] hr {
    margin-left: 0;
    margin-right: 0;

    &.micro-separator {
        width: 20px;
        margin: 20px auto;
        border-top-color: $color-secondary;
    }
}
