$fa-font-path: "/fonts";
/* Fonts are called from mainsite instead of CDN because Azure Akamai does not support CORS */
@font-face {
    font-family: 'FontAwesome';
    src: url('#{$fa-font-path}/font-awesome.eot');
    src: url('#{$fa-font-path}/font-awesome.eot?#iefix') format('embedded-opentype'), url('#{$fa-font-path}/font-awesome.woff2') format('woff2'), url('#{$fa-font-path}/font-awesome.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


/*@font-face {
    font-family: 'gt-sectra-bold';
    src: url('#{$fa-font-path}/gt-sectra-bold.eot');
    src: url('#{$fa-font-path}/gt-sectra-bold.eot?#iefix') format('embedded-opentype'), url('#{$fa-font-path}/gt-sectra-bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'gt-sectra-medium';
    src: url('#{$fa-font-path}/gt-sectra-medium.eot');
    src: url('#{$fa-font-path}/gt-sectra-medium.eot?#iefix') format('embedded-opentype'), url('#{$fa-font-path}/gt-sectra-medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'gt-sectra-regular';
    src: url('#{$fa-font-path}/gt-sectra-regular.eot');
    src: url('#{$fa-font-path}/gt-sectra-regular.eot?#iefix') format('embedded-opentype'), url('#{$fa-font-path}/gt-sectra-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'styrene-bold';
    src: url('#{$fa-font-path}/styrene-bold.eot');
    src: url('#{$fa-font-path}/styrene-bold.eot?#iefix') format('embedded-opentype'), url('#{$fa-font-path}/styrene-bold.woff2') format('woff2'), url('#{$fa-font-path}/styrene-bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'styrene-medium';
    src: url('#{$fa-font-path}/styrene-medium.eot');
    src: url('#{$fa-font-path}/styrene-medium.eot?#iefix') format('embedded-opentype'), url('#{$fa-font-path}/styrene-medium.woff2') format('woff2'), url('#{$fa-font-path}/styrene-medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'styrene-medium-italic';
    src: url('#{$fa-font-path}/styrene-medium-italic.eot');
    src: url('#{$fa-font-path}/styrene-medium-italic.eot?#iefix') format('embedded-opentype'), url('#{$fa-font-path}/styrene-medium-italic.woff2') format('woff2'), url('#{$fa-font-path}/styrene-medium-italic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'styrene-regular';
    src: url('#{$fa-font-path}/styrene-regular.eot');
    src: url('#{$fa-font-path}/styrene-regular.eot?#iefix') format('embedded-opentype'), url('#{$fa-font-path}/styrene-regular.woff2') format('woff2'), url('#{$fa-font-path}/styrene-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}*/


@font-face {
    font-family: 'icomoon';
    src: url('#{$fa-font-path}/icomoon.eot?w1nynm');
    src: url('#{$fa-font-path}/icomoon.eot?w1nynm#iefix') format('embedded-opentype'), url('#{$fa-font-path}/icomoon.ttf?w1nynm') format('truetype'), url('#{$fa-font-path}/icomoon.woff?w1nynm') format('woff'), url('#{$fa-font-path}/icomoon.svg?w1nynm#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

.icomoon, [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
    content: "\e908";
}

.icon-time-filled:before {
    content: "\e907";
}

.icon-info:before {
    content: "\e906";
}

.icon-marker:before {
    content: "\e904";
}

.icon-clock:before {
    content: "\e905";
}

.icon-chevron:before {
    content: "\e903";
}

.icon-arrow-left:before {
    content: "\e902";
}

.icon-wsp-logo:before {
    content: "\e901";
}

.icon-im-search-icon:before {
    content: "\e900";
}

/* 581 - Licensed fonts - replace on wsp.com website - start */
/* PPNeueMontreal Bold Italic */
@font-face {
    font-family: 'PPNeueMontreal-BoldItalic';
    src: url('/fonts/PPNeueMontreal-BoldItalic.woff2') format('woff2'), url('/fonts/PPNeueMontreal-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

/* PPNeueMontreal Medium Italic */
@font-face {
    font-family: 'PPNeueMontreal-MediumItalic';
    src: url('/fonts/PPNeueMontreal-MediumItalic.woff2') format('woff2'), url('/fonts/PPNeueMontreal-MediumItalic.woff') format('woff');
    font-weight: 500; /* Medium weight */
    font-style: italic;
}

/* PPNeueMontreal Bold */
@font-face {
    font-family: 'PPNeueMontreal-Bold';
    src: url('/fonts/PPNeueMontreal-Bold.woff2') format('woff2'), url('/fonts/PPNeueMontreal-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

/* PPNeueMontreal Medium */
@font-face {
    font-family: 'PPNeueMontreal-Medium';
    src: url('/fonts/PPNeueMontreal-Medium.woff2') format('woff2'), url('/fonts/PPNeueMontreal-Medium.woff') format('woff');
    font-weight: 500; /* Medium weight */
    font-style: normal;
}

/* PPNeueMontreal Italic */
@font-face {
    font-family: 'PPNeueMontreal-Italic';
    src: url('/fonts/PPNeueMontreal-Italic.woff2') format('woff2'), url('/fonts/PPNeueMontreal-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

/* PPNeueMontreal Regular */
@font-face {
    font-family: 'PPNeueMontreal-Regular';
    src: url('/fonts/PPNeueMontreal-Regular.woff2') format('woff2'), url('/fonts/PPNeueMontreal-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
/* 581 - Licensed fonts - replace on wsp.com website - end */
