$spacer-x: 20px;
$spacer-y: 20px;


// Implementation of utility classes found in Bootstrap 4

.p {
  &-x {
    @for $i from 0 through 20 {
      &-#{$i} {
        padding-right: $spacer-x * $i !important;
        padding-left: $spacer-x * $i !important;
      }
    }
  }
  &-y {
    @for $i from 0 through 20 {
      &-#{$i} {
        padding-top: $spacer-x * $i !important;
        padding-bottom: $spacer-x * $i !important;
      }
    }
  }
  &-t {
    @for $i from 0 through 20 {
      &-#{$i} {
        padding-top: $spacer-y * $i !important;
      }
    }
  }
  &-b {
    @for $i from 0 through 20 {
      &-#{$i} {
        padding-bottom: $spacer-y * $i !important;
      }
    }
  }
  &-l {
    @for $i from 0 through 20 {
      &-#{$i} {
        padding-left: $spacer-x * $i !important;
      }
    }
  }
  &-r {
    @for $i from 0 through 20 {
      &-#{$i} {
        padding-right: $spacer-x * $i !important;
      }
    }
  }

}

.m {
  &-x {
    @for $i from 0 through 20 {
      &-#{$i} {
        margin-right: $spacer-x * $i !important;
        margin-left: $spacer-x * $i !important;
      }
    }
  }
  &-y {
    @for $i from 0 through 20 {
      &-#{$i} {
        margin-top: $spacer-x * $i !important;
        margin-bottom: $spacer-x * $i !important;
      }
    }
  }
  &-t {
    @for $i from 0 through 20 {
      &-#{$i} {
        margin-top: $spacer-y * $i !important;
      }
    }
  }
  &-b {
    @for $i from 0 through 20 {
      &-#{$i} {
        margin-bottom: $spacer-y * $i !important;
      }
    }
  }
  &-l {
    @for $i from 0 through 20 {
      &-#{$i} {
        margin-left: $spacer-x * $i !important;
      }
    }
  }
  &-r {
    @for $i from 0 through 20 {
      &-#{$i} {
        margin-right: $spacer-x * $i !important;
      }
    }
  }

}
