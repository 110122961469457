.key-date-group {
    h2 {
        color: $color-primary;
        @extend .new__typo__02; //Mockup 1-04 (Our story)
        margin-bottom: 35px;
    }

    .key-date-list {
        .key-date {
            position: relative;
            border-top: 1px solid $color-secondary-light;
            padding: 20px 0 20px 135px;

            &:last-child {
                border-bottom: 1px solid $color-secondary-light;
            }

            @media (min-width: 992px) {
                padding: 25px 0 25px 220px;
            }

            .keynumber {
                color: $color-primary;
                @extend .new__typo__02; //Mockup 1-04 (Our story)
                padding: 0;
                position: absolute;
                top: calc(50% - (39px / 2 ));
                left: 0;
            }

            .keytitle {
                text-transform: uppercase;
                color: $color-secondary-dark;
                @extend .new__typo__06; //Mockup 1-04 (Our story)
                text-align: left;
                padding: 0;
                margin-bottom: 0;
            }
        }
    }
}
