.btn {
    border-radius: 0;
    border: 0;
    padding: 15px 20px;
    letter-spacing: 0;
    width: 100%;
    font-weight: normal;
    font-family: $montreal-medium;
    margin-bottom: 15px;
    margin-right: 15px;
    -webkit-transition: color 0.2s ease-in-out, background 0.4s ease-in-out;
    -o-transition: color 0.2s ease-in-out, background 0.4s ease-in-out;
    -moz-transition: color 0.2s ease-in-out, background 0.4s ease-in-out;
    transition: color 0.2s ease-in-out, background 0.4s ease-in-out;
    /*-webkit-transition: ease-in-out 0.3s;
    -o-transition: ease-in-out 0.3s;
    -moz-transition: ease-in-out 0.3s;
    transition: ease-in-out 0.3s;*/
    text-transform: none;
    text-decoration: none;

    @media (min-width: $screen-md-min) {
        padding: 15px 20px;
        width: auto;
    }

    @at-root a.btn {
        font-weight: normal;
    }

    &.no-margin-right {
        margin-right: 0;
    }

    &.btn-grey {
        background-color: $color-secondary-light;
        padding: 15px 25px;
        font-size: $btn-fonts-size;
        color: $color-secondary;
        line-height: 1.143;
        text-align: center;

        &:hover, &:active {
            background-color: #d8d7dc;
        }
    }
    /*&.btn-ghost {*/
    /*background: transparent;
        color: $color-secondary;
        border: 1px solid $color-secondary;*/
    /*text-align: center;
        white-space: normal;
        border: 1px solid $color-secondary;
        color: $color-secondary;
        background-color: transparent;
        font-size: $btn-fonts-size;*/
    /*&:hover,
        &:active {*/
    /*background-color: $color-secondary-on;
            color: #fff;*/
    /*background-color: $color-secondary;
            color: $color-white;
        }
    }*/

    &.btn-default {
        /*background: $color-secondary-lightest;
        border: 1px solid $color-secondary-lightest;
        position: relative;
        color: $color-secondary-dark;
        font-size: $btn-fonts-size;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;*/

        color: $color-secondary-dark;
        font-size: $btn-fonts-size;
        background: linear-gradient(to bottom, $color-secondary-lightest 50%, $color-secondary-light-gray 50%);
        background-size: 100% 200%;
        background-position: 0 0;
        transition: background-position 0.2s ease-in-out;

        &:hover,
        &:active {
            color: $color-secondary-dark;
            background-position: 0 100%;
        }



        &.el-btn,
        &.txt-L-pad-R58,
        &.manifesto {
            text-align: left;
            padding-right: 58px;
        }
        /*&:hover,
        &:active {
            background-color: $color-secondary-light-gray;
            color: $color-secondary;
            border: 1px solid $color-secondary-light-gray;
            box-shadow: none;
        }*/

        &:after,
        &:before {
            content: "";
            background: $color-secondary;
            display: inline-block;
            height: 1px;
            width: 11px;
            position: absolute;
            top: 25px;
            right: 28px;
            margin: 0 auto;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            -webkit-transition: -webkit-transform 0.3s ease-in-out;
            -o-transition: transform 0.3s ease-in-out;
            -moz-transition: transform 0.3s ease-in-out;
            transition: transform 0.3s ease-in-out;
        }

        &:after {
            right: 20px;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }

        &.open {
            
            /*color: $color-secondary;*/
            /*border: 1px solid $color-secondary;*/
            background: $color-secondary-light-gray;

            &:after {
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                -webkit-transition: -webkit-transform 0.3s ease-in-out;
                -o-transition: transform 0.3s ease-in-out;
                -moz-transition: transform 0.3s ease-in-out;
                transition: transform 0.3s ease-in-out;
            }

            &:before {
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
                -webkit-transition: -webkit-transform 0.3s ease-in-out;
                -o-transition: transform 0.3s ease-in-out;
                -moz-transition: transform 0.3s ease-in-out;
                transition: transform 0.3s ease-in-out;
            }
        }
    }

    &.btn-secondary, &.btn-ghost {
        border: 1px solid $color-primary;
        color: $color-secondary-dark;
        font-size: $btn-fonts-size;
        background: linear-gradient(to bottom, transparent 50%, $color-primary 50%);
        background-size: 100% calc(200% + 10px);
        background-position: 0 0;
        transition: background-position 0.2s ease-in-out;


        &:hover,
        &:active {
            color: $color-white;
            background-position: 0 100%;
        }
        /*&:focus {
            outline: 3px solid $color-high-contrast;
        }*/
    }

    &.btn-tertiary {
        color: $color-secondary-dark;
        font-size: $btn-fonts-size;
        background: linear-gradient(to bottom, $bg-btn-grey 50%, $bg-btn-dark-grey 50%);
        background-size: 100% 200%;
        background-position: 0 0;
        transition: background-position 0.2s ease-in-out;

        &:hover,
        &:active {
            color: $color-secondary-dark;
            background-position: 0 100%;
        }


        &.btn-anchor-contact {
            color: $color-secondary;

            @media (min-width: $screen-md-min) {
                border: 0 none;
                border-left: 1px solid $color-secondary-light-gray;
            }

            &:hover,
            &:active {
                background-color: $color-secondary-lightest;
                color: $color-secondary;
            }

            @media (max-width: $screen-sm-max) {
                border: 1px solid $color-secondary;
            }

            &.vis-hidden {
                visibility: hidden;
                pointer-events: none;

                @media (max-width: $screen-sm-max) {
                    display: none;
                }
            }

            .scroll-up.affix & {
                border-bottom: 1px solid $color-secondary-light-gray;
                border-top: 1px solid $color-secondary-light-gray;
            }
        }
    }

    &.btn-primary {
        border-color: $color-primary;
        color: $color-white;
        font-size: $btn-fonts-size;
        background: linear-gradient(to bottom, $color-primary 50%, $color-dark-primary 50%);
        background-size: 100% 200%;
        background-position: 0 0;
        transition: background-position 0.2s ease-in-out;



        &:hover,
        &:active {
            color: $color-white;
            border-color: $color-dark-primary;
            background-position: 0 100%;
        }
    }

    &.btn-text {
        color: $color-secondary-dark;
        background-color: transparent;
        font-size: $btn-fonts-size;
        font-family: $montreal-medium;
        padding: 0;
        text-align: left;
        width: auto;
        letter-spacing: 0;

        span.icn-arrow-small-blue {
            margin-left: 7px;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3LjA4OCIgaGVpZ2h0PSIxMi4wNTUiIHZpZXdCb3g9IjAgMCA3LjA4OCAxMi4wNTUiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOm5vbmU7c3Ryb2tlOiNFMDJGMjg7c3Ryb2tlLXdpZHRoOjEuNXB4O308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iYSIgZD0iTTUuNSwxMC45OTQsMCw1LjUsMi4yOTEsMy4yMDcsNS41LDAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDYuMDI4IDExLjUyNCkgcm90YXRlKDE4MCkiLz48L3N2Zz4=");
            width: 8px;
            height: 12px;
            background-repeat: no-repeat;
            display: inline-block;
            top: 1px;
            position: relative;
            -webkit-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
        }

        span.icn-arrow-small-red {
            margin-left: 7px;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3LjA4OCIgaGVpZ2h0PSIxMi4wNTUiIHZpZXdCb3g9IjAgMCA3LjA4OCAxMi4wNTUiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOm5vbmU7c3Ryb2tlOiNFMDJGMjg7c3Ryb2tlLXdpZHRoOjEuNXB4O308L3N0eWxlPjwvZGVmcz48cGF0aCBjbGFzcz0iYSIgZD0iTTUuNSwxMC45OTQsMCw1LjUsMi4yOTEsMy4yMDcsNS41LDAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDYuMDI4IDExLjUyNCkgcm90YXRlKDE4MCkiLz48L3N2Zz4=");
            width: 8px;
            height: 12px;
            background-repeat: no-repeat;
            display: inline-block;
            top: 2px;
            position: relative;
            -webkit-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
        }

        &:hover,
        &:active {
            background-color: transparent;
            color: $color-secondary-dark;

            span.icn-arrow-small-blue, span.icn-arrow-small-red {
                margin-left: 11px; /* move arrow to right on hover, active */
            }
        }

        &:active {
            box-shadow: none;
        }

        .btn-space & {
            &:hover,
            &:active {
                span.icn-arrow-small-blue {
                    margin-left: 7px;
                }
            }
        }
    }

    input {
        // for copy url to clipboard
        border: 0;
        padding: 4px;
        font-size: 12px;
    }
}

/*** AddToCalendar Blue Style  * http://addtocalendar.com  */
.addtocalendar var {
    display: none;
}

.addtocalendar {
    position: relative;
    display: inline-block;
    background: transparent !important;
    width: 100%;
    margin-top: 0;
}

.atcb-link {
    display: block;
    outline: none !important;
    cursor: pointer;
}

.atcb-link:focus ~ ul,
.atcb-link:active ~ ul,
.atcb-list:hover {
    visibility: visible;
}

.atcb-list {
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    width: 170px;
    z-index: 900;
}

.atcb-list,
.atcb-item {
    list-style: none;
    margin: 0;
    padding: 0;
    background: #fff;
}

.atcb-item {
    float: none;
    text-align: left;
}

.atcb-item-link {
    text-decoration: none;
    outline: none;
    display: block;
}

.atcb-item.hover,
.atcb-item:hover {
    position: relative;
    z-index: 900;
    cursor: pointer;
    text-decoration: none;
    outline: none;
}

/* Blue */

.atc-style-blue .atcb-link,
.atc-style-blue .atcb-link:hover,
.atc-style-blue .atcb-link:active /*,
.atc-style-blue .atcb-link:focus*/ {
    border-radius: 0;
    border: 0;
    padding: 15px 17px;
    letter-spacing: 0.5px;
    width: 100%;
    font-weight: normal;
    text-decoration: none;
    font-family: $montreal-regular;
    margin-bottom: 0;
    margin-right: 15px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-transform: none;
    text-align: center;
    background-color: $color-secondary;
    color: $color-white;
    font-size: $btn-fonts-size;
}

.atc-style-blue .atcb-link:hover,
.atc-style-blue .atcb-link:active,
.atc-style-blue .atcb-link:focus {
    text-decoration: none;
    background-color: $color-secondary-on;
}

.atc-style-blue .atcb-list {
    width: 100%;
}

.atc-style-blue .atcb-list,
.atc-style-blue .atcb-item {
    background: $color-secondary-light-gray;
    color: $text-color;
}

.atc-style-blue .atcb-item,
.atc-style-blue .atcb-item-link {
    line-height: 1.3em;
    vertical-align: middle;
    zoom: 1;
}

.atc-style-blue .atcb-item-link,
.atc-style-blue .atcb-item-link:hover,
.atc-style-blue .atcb-item-link:active {
    color: $text-color;
    font-family: $montreal-regular;
    font-size: $btn-fonts-size;
    text-decoration: none;
    outline: none;
    padding: 10px 20px;
}

.atc-style-blue .atcb-item-link:hover,
.atc-style-blue .atcb-item-link:active {
    color: $color-white;
}

.atc-style-blue .atcb-item.hover,
.atc-style-blue .atcb-item:hover {
    background: $color-secondary;
}
