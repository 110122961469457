.logo-overlay {
    background-repeat: no-repeat !important;
    background-size: auto 30%, cover !important;
    background-position: top right, center center !important;
    background-attachment: scroll !important;

    &.mobileHeroImage {
        background-size: auto 20%,cover !important;
        background-position: top right -32px,50% 50% !important;
    }

    &.bg-parallax {
        background-size: auto 30%, cover !important;
        background-attachment: scroll !important;
        background-position: top right -24px, 50% 50% !important;

        @media all and (min-width: $screen-md-min) {
            background-position: top right -32px, 50% 50% !important;
        }

        @media all and (min-width: $screen-lg-min) {
            background-position: top right -2.7vw, center top !important;
            background-size: auto 30%, 100% auto !important;
            background-attachment: scroll, fixed !important;
        }

        @media all and (min-width: $screen-view-condition-min) {
            background-position: top right -2.8vw, center top !important;
        }

        &.compact845 {
            background-size: auto 20%, cover !important;


            @media all and (min-width: $screen-md-min) {
                background-size: auto 20%, cover !important;
            }

            @media all and (min-width: $screen-lg-min) {
                background-size: auto 25%, 100% auto !important;
            }

            @media all and (min-width: $screen-view-condition-min) {
            }
        }
    }
}

.logo-overlay-pic, .logo-overlay-pic-on-video {
    opacity: 0
}


div[id^="divbanner"] {
    #desktop-header {
        &.video-file.logo-overlay {
            .logo-overlay-pic-on-video {
                opacity: 1;
                background-attachment: scroll;
                background-repeat: no-repeat;
                background-image: url(/-/media/project/wsp/projects/overlaylogo.svg);
                min-height: 200px;
                position: absolute;
                right: 0;
                top: 0;
                width: 100%;
                z-index: 2;

                @media (min-width:992px) {
                    background-size: 26% auto;
                    background-position: top right -32px;
                }

                @media (min-width:1100px) {
                    background-size: 24% auto;
                    background-position: top right -32px;
                }

                @media (min-width:1200px) {
                    background-size: 22.8% auto;
                    background-position: top right -2.7vw;
                }

                @media (min-width:1360px) {
                    background-size: 23% auto;
                    background-position: top right -2.7vw;
                }

                @media (min-width:1500px) {
                    background-position: top right -2.8vw;
                    background-size: 24.2% auto;
                }
            }
        }
    }
}

.mobileHeroImage.logo-overlay {
    .logo-overlay-pic {
        opacity: 1;

        @media all and (max-width: $screen-sm-max) {
            background-image: url('/-/media/project/wsp/projects/overlaylogo.svg');
            min-height: 200px;
            
            width: 220px;
            /*height: 200px;*/
            position: absolute;
            right: 0;
            top: 0;
            z-index: 2;
            background-size: auto 50%,cover;
            background-position: top right -35px;
            background-repeat: no-repeat;
        }

        @media all and (max-width: $screen-xs-max) {
            background-size: auto 40%,cover;
        }
    }
}
