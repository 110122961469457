.scInsertionHandleCenter {
    height: 24px !important;
}

.scInsertionHandle {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
}

/* HOTFIX */
//6232 - Homepage - Making a difference in our communities
.feature-project {
    .col-md-4.orange-title {
        h2 {
            @media (min-width: $screen-lg-min) {
                margin-bottom: 0;
            }
        }
    }

    .row {
        margin-bottom: calc(40px + 1.4vw);

        @media (min-width: $screen-md-min) {
            margin-bottom: calc(40px + .15vw);
        }
    }
}
