.large-title,
.large-title p,
.large-title span {
    margin: 0;
    padding: 0;
    text-transform: none;
    color: $color-secondary-dark;
    font-family: $montreal-bold !important;
    font-size: $font-size-49mn-61mx-h1 !important;
    //@extend .new__typo__04; //What we stand for, Careers

    @media (min-width: $screen-md-min) {
        line-height: 1.2;
    }

    .title & {
        /*font-size: calc(.75117vw + 17.18px);
        line-height: 1.365;
        font-weight: 400;*/
        font-family: $montreal-bold !important;
        font-size: $font-size-39mn-48mx-h2;
        line-height: 1.1;
        text-transform: none;
    }

    .title-big & {
        text-transform: none;
    }
}

.bg-primary {
    .title, .title-big {
        .large-title {
            color: $color-white;
        }
    }
}
