﻿.secondary-nav {
    order: 0;
    display: block;
    width: 100%;
    /* In mobile menu padding is not required for this item */
    .Ir-Nav.sn__item {
        @media (max-width: $screen-md-max) {
            padding: 0;

            &:first-child {
                .main-nav {
                    li.mn__item.level-0 {
                        border-top: 1px solid $color-secondary-light-gray;
                    }
                }
            }

            &:last-child {
                .main-nav {
                    li.mn__item.level-0:last-child {
                        border-bottom: 1px solid $color-secondary-light-gray;
                    }
                }
            }

            .arw_dwn:after {
                right: 4vw;
            }

            .arw_up:after {
                right: 4vw;
            }

            .arw_rgt:after {
                right: 4vw;
            }

            .mn__list a {
                font-family: $montreal-regular;
                color: $color-secondary-dark;
                text-transform: none;
                padding: 20px 2px 15px;
                text-decoration: none;
                font-size: 16px;

                &.targetIsShown,
                &:focus,
                &.active {
                    text-decoration: none;
                }

                &:hover {
                    text-decoration: none;
                    color: $color-secondary-on;
                }

                &:hover:after {
                    color: $color-secondary-dark;
                }



                &.subnav-trigger:after {
                    top: 10px;
                }
            }

            .mn__item.level-0 {
                border-bottom: none;
            }

            .mn__item > .sub-nav a {
                font-family: $montreal-regular;
                font-size: 16px;

                &.level-1 {
                    padding-top: 8px;
                    padding-bottom: 20px;
                }

                &.level-3 {
                    padding-top: 0;
                    padding-bottom: 25px;
                }
            }



            .mn__item > .sub-nav .back-link a {
                padding: 25px 0 25px 25px;
                color: $color-secondary-dark;
                font-family: $montreal-medium;
            }

            .mn__item > .sub-nav .top-category-link a {
                font-family: $montreal-bold;
                padding-top: 0;
                padding-left: 25px;
                padding-bottom: 25px;
            }
        }
    }

    @media (max-width: $screen-md-max) {
        li:not(Ir-Nav) .back-link, li:not(Ir-Nav) .top-category-link {
            display: none;
        }
    }



    @media (min-width: $screen-lg-min) {
        width: auto;
        display: block;
        margin-top: 0;
        height: 100%;
        border-left: 1px solid #D9D9D9;
        padding-left: 12px;


        .affix & {
            margin-top: 0;
        }
    }

    .sn__list {
        list-style: none;
        margin: 0;
        padding: 0;

        @media (min-width: $screen-lg-min) {
            display: flex;
            justify-content: flex-end;
            height: 100%;
        }
    }


    .sn__item {
        float: left;
        margin: 0;
        padding: 0 20px;
        width: 100%;

        @media (max-width: $screen-md-max) {
            padding: 0 4vw;

            .subnav {
                clear: both;
            }

            .subnav ul {
                list-style: none;
            }
        }

        @media (max-width: $screen-md-max) {

            .arw_rgt:after {
                content: "";
                position: absolute;
                right: 0;
                box-sizing: border-box;
                width: 20px;
                text-align: center;
                text-rendering: auto;
                font-family: font-awesome;
                font-size: 22px;
                cursor: pointer;
                z-index: 100;
                line-height: 0.7;
                height: 20px;
            }

            .arw_dwn:after {
                content: "\f107";
                position: absolute;
                right: 0;
                box-sizing: border-box;
                width: 20px;
                text-align: center;
                text-rendering: auto;
                font-family: font-awesome;
                font-size: 22px;
                cursor: pointer;
                z-index: 100;
                line-height: 0.7;
                height: 20px;
            }

            .arw_up:after {
                content: "\f106";
                position: absolute;
                right: 0;
                box-sizing: border-box;
                width: 20px;
                text-align: center;
                text-rendering: auto;
                font-family: font-awesome;
                font-size: 22px;
                cursor: pointer;
                z-index: 100;
                line-height: 0.7;
                height: 20px;
            }
        }



        @media (min-width: $screen-lg-min) {
            padding: 0;
            width: auto;
            margin: 0 5px 0 0;
            height: 100%;
        }

        &:last-child {
            margin-right: 25px;



            @media (min-width: $screen-lg-min) {
                margin-right: 5px;
            }
        }
    }

    .sn__link {
        float: left;
        color: $color-secondary-dark;
        font-size: 18px;
        font-family: $montreal-regular;
        font-weight: 700;
        text-decoration: none;
        position: relative;
        padding: 30px 0;
        border-bottom: 1px solid $color-secondary-light;
        width: 100%;

        @media (max-width: $screen-md-max) {
            font-family: $montreal-bold;
            font-size: 14px;
            font-weight: normal;
            border-bottom: none;
            padding: 20px 0;
            padding-left: 2px;
            padding-right: 2px;
            line-height: 1;

            &:hover {
                text-decoration: none;
                color: $color-secondary-on;
            }

            &:hover:after {
                color: $color-secondary-dark;
            }
        }




        &.subnav-trigger:after {
            position: absolute;
            color: $text-color;
            content: "\f105";
            font-size: 22px;
            right: 5px;
            top: 2px;
            text-rendering: auto;
            font-family: font-awesome;
            z-index: 100;
            line-height: 0.7;
            height: 20px;
        }

        &.targetIsShown,
        &.targetIsHidden {
            &:after {
            }
        }

        @media (min-width: $screen-lg-min) {
            font-size: 14px;
            padding: 30px 0 33px; /*19px 0 0;*/ /*19px 10px 43px;*/ /* 19px 6px 43px; 9thJan2020*/
            letter-spacing: 0.3px;
            width: auto;
            border-bottom: 0;
            font-family: $montreal-medium;
            font-weight: normal;
            padding-left: 10px;
            padding-right: 10px;

            .affix & {
                padding: 30px 10px 33px;
            }



            &.targetIsShown {
                color: $color-secondary-on;
            }

            + .subnav {
            }

            &:hover,
            &:focus,
            &:active {
                color: $color-secondary-on;
            }

            &.targetIsShown,
            &.targetIsHidden {
                &:after {
                }
            }

            &.targetIsShown {
                &:after {
                }

                &:hover,
                &:active,
                &:focus {
                    color: $color-secondary-on;
                    /* 6315 Menu change, info - removed hover effect on secondary nav for link background */
                }
            }

            &.targetIsHidden {
                &:after {
                }

                + .subnav {
                    height: 0 !important;
                }
            }



            &:before {
                content: "";
                background-color: $color-secondary-on;
                bottom: -1px;
                display: block;
                height: 5px;
                position: absolute;
                width: 0;
                transition: width 0.5s ease;
                left: 0;
            }

            &.targetIsShown,
            &:focus,
            &.active,
            &:hover {
                text-decoration: none;
                color: $color-secondary-on;

                &:before {
                    width: 100%;
                }
            }
        }
    }

    .subnav.mobile-sub {
        position: absolute;
        top: 0;
        height: calc(100vh - 60px) !important;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: #fff;
        right: 0;
        width: 0;
        transition: all 1s ease;

        .sn__sub-list {
            list-style: none;
            position: absolute;
            top: 0;
            right: 0;
            padding-left: 7px;
            width: calc(100vw + 7px);
            height: calc(100vh - 60px) !important;
        }
    }

    .mobile-opened + .subnav.mobile-sub {
        width: 100vw;
        right: -100vw;
    }

    .sn__sub-link {
        font-size: 14px;
        font-family: $montreal-medium;
        display: block;
        width: 100%;
        padding: 0 0 30px 0;
        color: $text-color;

        @media (max-width: $screen-md-max) {
            padding: 8px 0 20px 0;


            &:hover {
                text-decoration: none;
                color: $color-secondary-on;
            }

            &:hover:after {
                color: $color-secondary-dark;
            }
        }
    }

    .sn__sub-item {
        padding: 0 20px;

        @media (max-width: $screen-md-max) {
            padding: 0;
        }
    }

    .back-link .sn__sub-link {
        font-size: 14px;
        color: $color-secondary-dark;
        padding: 30px 0;
    }

    .top-category-link a {
        font-size: 14px;
        color: $color-secondary-dark;
        display: block;
        width: 100%;
        padding-left: 25px;
        font-family: $montreal-bold;
    }

    @media (min-width: $screen-lg-min) {
        .subnav {
            text-transform: none;
            position: absolute;
            overflow: hidden;
            height: 0;
            display: block;
            background-color: $color-white;
            width: 100vw;
            z-index: 1;
            top: 100%;
            left: 0;
            transition: none;
            padding: 0 4.861111vw;


            .affix & {
                top: 101%;
            }

            .sn__sub-list {
                width: auto;
                position: static;
                list-style: none;
                padding: 0;
            }

            &.transitioning {
                transition: height .5s ease-out;
            }


            ul {
                list-style: none;
                padding: 0;
            }
        }

        .sn__sub-item {
            margin: 0;
            padding: 0 15px;
            text-align: center;
            display: table;

            &.top-category-link,
            &.back-link {
                display: none;
            }
        }

        .sn__sub-link {
            color: $color-secondary-on;
            display: table-cell;
            line-height: 2.357;
            padding: 10px 0;
            position: relative;
            text-decoration: none;
            border-bottom: 0;
            height: 90px;
            vertical-align: middle;

            &:before {
                content: "";
                display: block;
                height: 5px;
                position: absolute;
                width: 0;
                left: 0;
                bottom: -1px;
                background-color: $color-secondary-on;
                transition: width 0.5s ease;
            }

            &.active,
            &:hover,
            &:focus {

                &:before {
                    width: 100%;
                }
            }
        }
    }

    @media (min-width: $screen-view-condition-min) {

        .subnav {

            .affix & {
                top: 101.15%;
            }

            .sn__sub-list {
                max-width: $screen-laptop-min;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}


.country-language-selector,
.secondary-nav {
    .back-link .sn__sub-link {
        position: relative;
        padding-left: 25px;

        &:before {
            /*@extend .icomoon;
           
            content: "\e902";
            font-size: 15px;*/
            content: "\f104";
            position: absolute;
            left: 0;
            top: 26px;
            font-family: font-awesome;
            font-size: 22px;
        }
    }
}



/* fix for 6204 - Investors menu - out of place*/
.affix .secondary-nav .Ir-Nav {
    margin-top: -10px;

    @media (min-width: $screen-lg-min) {
        margin-top: 0;
    }
}
