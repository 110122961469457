.project-info,
.related-info,
.SectorInfo {
    margin-bottom: 21px;

    @media (min-width: $screen-sm-min) {
        margin-bottom: 24px;
    }

    .row {
        h2 {
            color: $color-secondary-dark;
            @extend .new__typo__06; //Project (one)
        }

        .typo--h4,
        h4 {
            color: $color-secondary-dark-gray;
            text-transform: uppercase;
            line-height: 1.3;
            font-size: 14px;
            font-family: $montreal-medium;
            margin-bottom: 1px;
            letter-spacing: 0;

            @media (min-width: $screen-sm-min) {
                /*letter-spacing: 0.1px;*/
                margin-bottom: 7px;
            }
        }

        ul {
            margin-bottom: 32px;
            list-style: none;
            padding: 0;
            margin-left: 0;

            li {
                color: $color-secondary-dark;
                padding: 0;
                font-size: 16px;
                font-family: $montreal-medium;
                line-height: 1.3;
                letter-spacing: 0;


                &.view-all {
                    margin-top: -5px;

                    @media (min-width: $screen-sm-min) {
                        margin-top: -2px;
                    }
                }

                &:before {
                    content: none;
                }

                a {
                    @extend .para-anchor-links;
                }
            }
        }
    }
}

.m-b-60 {
    margin-bottom: 60px;
}

.bg-grey-box {
    background-color: #f2f2f2 !important;
    color: $color-secondary-dark;
    overflow: hidden;
    padding-top: 60px !important;
    padding-bottom: 60px !important;

    @media (max-width:$screen-sm-max) {
        .col-sm-4.col-narrow {
            margin-bottom: 55px;

            &:last-child {
                margin-bottom: auto;
            }
        }
    }

    .ft-three-column-4-4-4 .col-sm-4.col-narrow .thumbnail {
        overflow: visible !important;
        height: 70px;
        margin-bottom: 20px;

        a {
            overflow: visible !important;

            img:hover {
                /*transform-origin: top left;
                transition: all 0.3s ease;*/
            }
        }

        .img-responsive {
            width: 60px;
            float: left;

            &:after {
                clear: both;
            }
        }
    }
}



.bg-secondary, .bg-primary, .secondary-3-column, .secondary-white, .bg-white, .bg-dark, .ft-three-column-4-4-4, .bg-black-white {
    @media (max-width:$screen-sm-max) {
        .col-sm-4.col-narrow {
            margin-bottom: 55px;

            &:last-child {
                margin-bottom: auto;
            }
        }
    }

    @media (min-width:$screen-md-min) {
        .col-sm-4.col-narrow {
            margin-bottom: 35px;
        }
    }
}

.feature-summary {
    p {
        color: $color-secondary-dark;
        font-family: $montreal-regular;
        font-size: $font-size-16mn-20mx;
    }

    a {
        text-decoration: none;

        &:hover {
            text-decoration: none;

            h2 {
                color: $color-dark-red;
            }

            p {
                color: $color-secondary-dark;
            }
        }

        p {
            color: $color-secondary-dark;
            font-family: $montreal-regular;
            font-size: $font-size-16mn-20mx;
        }
    }

    .thumbnail {
        overflow: visible !important;
        height: 70px;
        margin-bottom: 20px;

        .img-responsive {
            width: 60px;
            float: left;

            &:after {
                clear: both;
            }
        }

        a {
            overflow: visible !important;

            img:hover {
            }
        }
    }
}

.bg-dark {
    .feature-summary {
        h2, p {
            color: $color-white;
        }

        a {
            text-decoration: none;

            &:hover {
                text-decoration: none;

                h2, p {
                    color: $color-white;
                }
            }

            p {
                color: $color-white;
            }
        }
    }
}

.bg-primary {
    .feature-summary {
        a {
            text-decoration: none;

            &:hover {
                text-decoration: none;

                h2, p {
                    color: $color-white;
                }
            }
        }
    }
}

.proj-ad-box-txt-rgt .content {

    @media (max-width:$screen-sm-max) {
        max-width: 100% !important;
    }

    @media (min-width:$screen-md-min) {
        max-width: 50% !important;
    }

    @media (min-width:992px) {
        max-width: 40% !important;
    }

    .call-to-action.bg-media {
    }

    h3 {
        color: $color-dark-red;
        font-size: 30px;
        font-family: $montreal-medium-italic;
        line-height: 39px;
        text-align: left;
        text-transform: none;
    }

    p {
        color: $color-secondary-dark !important;
        font-size: 20px !important;
        line-height: 30px !important;
        font-family: $montreal-regular !important;
        text-align: left !important;
    }

    a.btn {
        float: left;

        &:after {
            clear: both;
        }
    }
}

.big-padding.m-t-2 blockquote {
    margin-top: 50px;
}



@media (min-width:992px) {

    .full-width article.Services.multi-list-view {
        padding-right: unset;
    }
}



.container-fluid.empty-div ~ .full-width.ft-two-column-6-6 {

    @media (max-width:$screen-sm-max) {
        padding-left: 4vw;
        padding-right: 4vw;
    }

    .row.flex {

        .dyn-margin-left {
            @media (min-width:$screen-md-min) {
                position: relative;
                top: 47%;
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
                min-width: 300px;
                max-width: 100%;
                padding-right: 4vw;
            }
        }

        .dyn-margin-right {

            @media (min-width:$screen-md-min) {
                position: relative;
                top: 47%;
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
                min-width: 300px;
                max-width: 100%;
                padding-left: 4vw;
            }
        }
    }
}
