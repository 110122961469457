
div ul.offices-text {
    margin: 0;
    padding: 0;

    li {
        margin: 0;
        padding: 0;
        list-style-type: none;
        letter-spacing: 0.5px;
        color: $color-secondary-dark;

        &:before {
            position:static;
            content: none;
            display: inline-block;
            width: 10px;
            background: $color-secondary-dark;
            height: 2px;
            margin-right: 30px;
            vertical-align: middle;

            @media (min-width: $screen-md-min) {
                content: "";
            }
        }
    }
}

.offices-text {
    text-transform: uppercase;
    color: $color-secondary-dark;
    @extend .new__typo__06; //Our offices
    padding: 0;
    margin-bottom: 20px;

    @media (min-width: $screen-md-min) {
        margin-bottom: 0;
    }
}

a.office-btn {
    margin-top: 30px;
}

.offices-location {
    color: $color-secondary-dark;
    @extend .new__typo__12;
}

.contact-info {
    &-location {
        h2 {
            color: $color-secondary-dark;
            @extend .new__typo__12; //Our offices
        }
    }

    &-text {
        text-transform: uppercase;
        color: $color-secondary-dark;
        @extend .new__typo__06; //Our offices
    }
}
