﻿.cards-coniatiner {
    flex-wrap: wrap;
    display: flex;
}

.person-list .row,
.contact-card-row {
    div.col-sm-6:nth-child(2n+1),
    div.col-md-6:nth-child(2n+1) {
        clear: left;

        .modal-content & {
            clear: none;
        }
    }

    div.col-md-4 {
        @media (min-width: $screen-md-min) {
            &:nth-child(2n+1) {
                clear: none;
            }

            &:nth-child(3n+1) {
                clear: left;

                .modal-content & {
                    clear: none;
                }
            }
        }
    }
}

.contact-card {
    position: relative;
    margin-bottom: 61px;
    width: 100%;
    padding-bottom: 0;

    &.display-table,
    > .display-table {
        width: 100%;
        display: table;
    }

    .contact-us-modal & {
        display: block;
        margin-bottom: 48px;
    }

    .image,
    .contact-info {
        display: table-cell;
        vertical-align: middle;

        .contact-us-modal & {
            text-align: center;
            display: block;
        }
    }

    .image {
        vertical-align: top;
        width: percentage(134/327);

        @media (min-width: $screen-sm-min) {
            width: percentage(158/421);
        }

        img {
            display: block;
            width: 100%;
            max-height: 278px;
            border-radius: 100%;
            overflow: hidden;
            margin-left: auto;
            margin-right: auto;

            @media (max-width: $screen-sm-max) {
                max-height: 132px;
                max-width: 132px;
            }
        }

        .contact-us-modal & {
            width: 100%;
            margin-bottom: 21px;

            @media (min-width: $screen-sm-min) {
                width: 100%;
            }

            img {
                overflow: hidden;
                display: inline-block;
                border-radius: 100%;
                max-width: 132px;
                max-height: 128px;
            }
        }
    }

    .contact-info {
        max-width: 193px;
        width: percentage(193/327);
        padding: 0 fluid(0px, 25px) 0 fluid(21px, 25px);

        @media (min-width: $screen-sm-min) {
            width: percentage(263/421);
        }

        .contact-us-modal & {
            width: 100%;
            padding: 0;

            @media (min-width: $screen-sm-min) {
                width: 100%;
            }
        }
    }

    .contact-country {
        display: -webkit-box;
        margin-bottom: 20px;
        overflow: hidden;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        margin-top: -3px;
        text-overflow: ellipsis;
        /* 175 - Offices project detail page country icon placement */
        img.icon.pointer {
            height: 18px;
            margin-right: 10px;
        }
    }

    .name {
        font-family: $montreal-bold;
        font-size: fluid(13px, 16px);
        margin-bottom: 10px;
        display: block;
        height: auto;
        /*-webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        text-overflow: ellipsis;*/
        .contact-us-modal & {
            font-size: fluid(13px, 14px);
            height: auto;
            width: 100%;
        }
    }

    .position {
        display: -webkit-box;
        font-family: $montreal-regular;
        font-size: fluid(12px, 14px);
        margin-bottom: 20px;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;

        .contact-us-modal & {
            width: 100%;
        }
    }

    .fa {
        display: inline-block;
        margin-right: 5px;
    }

    .btn {
        @media (min-width: $screen-sm-min) {
            width: auto;
            margin-bottom: 0;
            margin-right: 0;
        }

        .col-narrow &,
        .contact-us-modal & {
            width: 100%;
            margin-top: 0px;
        }

        .col-narrow & {
            margin-top: 20px;
        }
    }

    .btn-ghost, .btn-tertiary {
        &:after {
            @extend .fa;
            content: "";
            display: inline-block;
            margin-left: 7px;
            vertical-align: top;
            font-size: 12px;
            margin-top: 4px;
        }
    }
    /* Trello - Wrong scaling of the title font size for Contact card on right side of page in narrow column*/
    .col-narrow & {

        @media (max-width:$screen-lg) { /* till 1200px */
            h2 {
                font-size: calc(.75117vw + 14px);
            }
        }

        @media (min-width:$screen-lg-min) and (max-width:$screen-view-condition) { /* 1200px to 1500px */
            h2 {
                font-size: calc(.75117vw + 11px);
            }
        }

        @media (min-width:$screen-view-condition-min) { /* from 1500px */
            h2 {
                font-size: calc(.75117vw + 15px);
            }
        }
    }
}

.js-form-container {
    position: relative;
    width: 100vw;
    left: 0;
    overflow: hidden;
    height: auto;
    z-index: 1;

    .validation-summary-errors {
        display: none;
    }

    &.transition {
        transition: all 1s ease;
    }

    &.closed {
        height: 0 !important;
    }

    > div {
        background-color: $color-secondary-lightest;
        margin-top: 61px;
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .form-control {
        background-color: $color-white;
    }
}

.active-border {
    padding-top: 61px;
    display: block;
    border-bottom: 2px solid $color-secondary;
    position: absolute;
    width: 0;
    left: 0;
    transition: width 0.4s ease;

    .form-loaded & {
        width: 100%;
    }
}


.js-contact-name {
    font-size: 14px;
    text-transform: uppercase;
    font-family: $montreal-medium;
    margin-bottom: 50px;

    span {
        color: $color-primary;
    }
}

.rounded-image {
    display: inline-block;
    overflow: hidden;
    border-radius: 100%;
}

.author-image {
    float: left;
    width: 134px;
    margin: 0 0 30px 0;

    @media (min-width: $screen-sm-min) {
        width: percentage(133/880);
        margin: 0 percentage(44/880) 0 0;
    }

    img {
        width: 100%;
        height: auto;
        display: block;
    }
}

.author-description {
    float: left;
    width: 100%;

    @media (min-width: $screen-sm-min) {
        width: percentage(703/880);
    }

    h2 {
        font-family: $montreal-medium;
        font-size: fluid(32px, 60px);
        color: $color-primary;
        line-height: fluid(35px, 70px);
        margin: 0 0 6px 0;

        @media (min-width: $screen-sm-min) {
            margin: 0 0 6px 0;
        }
    }

    p {
        font-family: $montreal-medium;
        font-size: fluid(18px, 20px);
        color: #333e48;
        line-height: 1.66667;

        @media (min-width: $screen-sm-min) {
            font-family: $montreal-regular;
        }
    }
}

.author-text {
    margin-top: 30px;
    float: left;
    width: 100%;
    font-family: $montreal-regular;
    font-size: fluid(15px, 20px);
    color: $text-color;
    line-height: 1.66667;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    height: calc(1.66667em * 3);

    @media (min-width: $screen-sm-min) {
        margin-top: 39px;
        font-family: $montreal-medium;
    }
}

.contact-phone,
.contact-email {
    font-size: 14px;
    font-family: $montreal-regular;
    line-height: 1.3;
}

.contact-phone {

    a {
        &:focus, &.active {
            text-decoration: none;
        }

        &:hover {
            text-decoration: none;
            color: $color-secondary-on;
        }
    }
}


.button-link-container {
    > a {
        @extend .btn;
        @extend .btn-secondary;
        @extend .no-margin-right;
    }
}

.col-narrow {
    .contact-card .image {
        display: block;
        width: percentage(132/200);
        max-width: 200px;
        margin: 0 auto 30px;
        border-radius: 100%;
        overflow: hidden;

        img {
            max-height: 200px;
            max-width: 200px;
        }
    }

    .contact-card .contact-info {
        width: 100%;
        text-align: center;
        padding: 0;
        margin: 0;
        display: block;
        max-width: none;

        .name,
        .position {
            display: inline-block;
            height: auto;
            width: 100%;
        }

        .position {
            margin-bottom: 0;
        }
    }
}

.marT20 {
    margin-top: 20px !important;
}
