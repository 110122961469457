﻿.logo-type {
    img {
        margin: 30px auto;
        display: block;

        @media (min-width: $screen-sm-min) {
            margin: 0;
        }
    }

    .lt-info {
        table {
            width: 100%;
        }

        .lt-preview {
            display: block;

            @media (min-width: $screen-sm-min) {
                display: table-cell;
            }
        }

        .lt-link,
        .lt-size,
        .lt-type {
            display: none;
            padding: 0 0 40px 0;

            &.last {
                margin: 0;
            }

            @media (min-width: $screen-sm-min) {
                display: table-cell;
            }
        }

        .lt-preview {
            vertical-align: top;

            @media (min-width: $screen-sm-min) {
                width: percentage(1/3);
            }
        }

        .lt-link {
            text-transform: uppercase;
        }
    }
}
